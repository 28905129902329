import PlaidService from 'api/APIServices/PlaidService';
import * as PlaidType from 'api/APIMaster/PlaidType';
// custom function
import * as Local from 'service/utils/LocalStorageData';

const Plaid = new PlaidService();

export const createToken = async (update: boolean, accessToken: string) => {

    const data = {
        customerIdentifier: Local.getLocalData('dwUserIdentifier') as string,
        countryCode: 'US',
        languageCode: 'en',
        updateMode: update
    }

    return await Plaid.getToken(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const generateProcessorToken = async (data: PlaidType.ProcessorTokenRequestModel, accessToken: string) => {

    return await Plaid.generateProcessorToken(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}