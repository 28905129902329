// default library
import * as React from 'react';
// auth0 library
import { useAuth0 } from '@auth0/auth0-react';
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
//custom auth0 hook function
import { useLogoutHook } from 'helper/Logout';

const LogoutComponent: React.FC = () => {
    const logoutUser = useLogoutHook();
    const { user } = useAuth0();
    const intl = useIntl();

    return (
        <>
            <div className="onboard-mini-nav d-flex align-items-center gap-3 pe-2 me-2">
                <div className="onboard-ellipsis-dropdown dropdown dropstart d-block d-md-none">
                    <button className="border-0 p-0 position-relative" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <img src={intl.formatMessage({ id: 'ELLIPSIS_GREY_ICON' })} className="inactive-icon position-absolute m-auto" alt="" />
                        <img src={intl.formatMessage({ id: 'ELLIPSIS_GREEN_ICON' })} className="active-icon position-absolute m-auto" alt="" />
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <a className="dropdown-item">
                                {user?.email}
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" onClick={logoutUser}>
                                {IntlMessages('log_out')}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="onboard-email-id d-md-block d-none">
                    {user?.email}
                </div>
                <div className="onboard-log-out-btn d-md-block d-none">
                    <button className="d-flex align-items-center justify-content-center gap-1" type="button" onClick={logoutUser}>
                        {IntlMessages('log_out')}
                        <span className="gi-onboard-icon d-flex align-items-center position-relative">
                            <img src={intl.formatMessage({ id: 'RIGHT_GREEN_ARROW' })} className="inactive position-absolute" alt="" />
                            <img src={intl.formatMessage({ id: 'RIGHT_WHITE_ARROW' })} className="active position-absolute" alt="" />
                        </span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default LogoutComponent