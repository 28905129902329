//default library
import React from 'react'

//import image
import { useIntl, } from 'react-intl'
const GiraffeLearnBannerSection: React.FC = () => {
    const intl = useIntl()

    return (
        <div className='d-lg-block d-none'>
            <img src={intl.formatMessage({ id: 'RIGHT_IMAGE_BANNER' })}
                className="w-100"
                alt="RIGHT_IMAGE_BANNER"
            />
        </div>
    )
}

export default GiraffeLearnBannerSection