// default library
import * as React from 'react';
// message import
import IntlMessages from 'helper/IntlMessages';

const EncryptionInformation: React.FC = () => {
    const formCrsURL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-form-crs.pdf`;
    const formAdv2URL = `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-adv-2a.pdf`;

    return (
        <p>
            {IntlMessages('onboarding_footer_text1')}
            <a href={formCrsURL} target="_blank" rel="noreferrer"> {IntlMessages('onboarding_footer_form_crs')}</a>,&nbsp;
            <a href={formAdv2URL} target="_blank" rel="noreferrer">{IntlMessages('onboarding_footer_form_adv_2')}</a>.&nbsp;
            {IntlMessages('onboarding_footer_text2')}
        </p>
    )
}

export default EncryptionInformation