// type declaration
type CallbackFunction = (value: number) => void;

type CountAnimation = (
  startValue: number,
  endValue: number,
  duration: number,
  callback: CallbackFunction
) => void


export const CounterAnimation: CountAnimation = (startValue, endValue, duration, callback) => {
  if (startValue === endValue) return;

  const range: number = endValue - startValue;
  let current: number = startValue;
  const increment: number = endValue > startValue ? 1 : -1;
  const stepTime: number = Math.abs(Math.floor(duration / range));

  const timer = setInterval(() => {
    current += increment;
    if (current === endValue) {
      clearInterval(timer);
    }
    callback(current);
  }, stepTime);
};
