//default library
import * as React from 'react';
//message provider
import IntlMessages from 'helper/IntlMessages';
//import images
import { useIntl } from 'react-intl';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//types props
import * as PropsFunction from 'interface/PropsFunction';
import * as DriveWealthType from 'api/APIMaster/DriveWealthType';
//api call
import * as DriveWealthService from "middleware/DriveWealthService";
//custom function
import { downloadFile, resetStyle } from 'service/utils/DownloadFile';
//custom components
import PaginationNew from 'components/PaginationComponent/PaginationNew';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

// Define the props type
export type Props = {
    taxStatementData: DriveWealthType.TaxStatementResponseModel;
};

const AccountDetailCard: React.FC<Props> = (props: Props) => {
    const { taxStatementData } = props; // Destructure props

    const [errorLog, setErrorLog] = React.useState<any>({});//error message
    const [errorAPI, setErrorAPI] = React.useState<boolean>(false);//error flag

    const [currentTaxStatementData, setCurrentTaxStatementData] = React.useState<DriveWealthType.TaxStatementResponseModel>([]);//current tax statement data
    const [totalTaxStatementData, setTotalTaxStatementData] = React.useState<DriveWealthType.TaxStatementResponseModel>([]);// tax statement data ,date wise
    const [dataLoad, setDataLoad] = React.useState<boolean>(false);//loading flag
    const [pdfLoad, setPdfLoad] = React.useState<boolean>(false);//pdf flag

    const intl = useIntl();

    // Custom hooks 
    const { initiateAction } = useAccessToken();

    React.useEffect(() => {
        if (totalTaxStatementData.length === 0) {
            setTotalTaxStatementData(taxStatementData);
            setDataLoad(true);
        }
    }, []);

    // Pagination callback
    const onPageChanged: PropsFunction.OnPageChanged = (data): void => {
        const { currentPage, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentData = totalTaxStatementData.slice(offset, offset + pageLimit);
        setCurrentTaxStatementData(currentData);
        setTimeout(() => {
            resetStyle(currentData.map((e) => e.fileKey));
        }, 0);
    };

    // Download PDF callback
    const downLoadPDF = async (e: React.MouseEvent<HTMLAnchorElement>): Promise<void> => {
        setPdfLoad(true);
        const fileKey = (e.target as HTMLElement).id
        const accessToken = await initiateAction();
        const downLoadURL = await DriveWealthService.downloadPdfDetails(fileKey, accessToken);

        if (downLoadURL.response) {
            setErrorLog(downLoadURL)
            setErrorAPI(true);
        } else {
            downloadFile(downLoadURL, fileKey);
            setPdfLoad(false);
        }

    };

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {pdfLoad && <LoadingComponent />}

            <div className="row" >
                <div className="col-12">
                    <div className="gi-d-ac-card-main p-md-1 p-2 mb-2 mb-10">
                        <div className="gi-d-ac-new-card pt-md-4 p-md-5 pb-md-4 pt-0 p-3">
                            <div className="row" >
                                <div className="col-12">
                                    <div className='table-responsive' data-testid='account-card' >
                                        <table className="table gi-d-table-card mt-20" role="presentation">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><span>{IntlMessages('table_account_name')}</span></th>
                                                    <th scope="col"><span>{IntlMessages('table_form_type')}</span></th>
                                                    <th scope="col"><span>{IntlMessages('table_status')}</span></th>
                                                    <th scope="col"><span> </span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTaxStatementData.length > 0
                                                    ? currentTaxStatementData.map((e, i) => (
                                                        <tr className='account-detail-tr' key={i} data-testid="table-content">
                                                            <td>
                                                                <p>
                                                                    <strong className="d-block" >{e.accountName}</strong>
                                                                    {IntlMessages('taxes_static_text1')}
                                                                </p>
                                                            </td>
                                                            <td><span>{e.formType}</span></td>
                                                            <td><span >{e.status}</span></td>
                                                            <td>
                                                                {e.fileKey && (
                                                                    <div data-testid='file-pdf'>
                                                                        <a onClick={downLoadPDF} id={e.fileKey} style={{ display: 'block' }} data-testid='download-pdf'>
                                                                            <img src={intl.formatMessage({ id: 'PDF_ICON' })} alt="" id={e.fileKey} />
                                                                        </a>
                                                                        <a id={`${e.fileKey}-view`} target="_blank" rel="noreferrer" style={{ display: 'none' }}>
                                                                            {IntlMessages('button_view')}
                                                                        </a>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    : <tr className="bg-light" data-testid="table-content">
                                                        <td colSpan={12}>
                                                            <span>{IntlMessages('table_no_data')}</span>
                                                        </td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {dataLoad && <PaginationNew totalRecords={totalTaxStatementData.length} pageLimit={5} pageNeighbors={1} onPageChanged={onPageChanged} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccountDetailCard;