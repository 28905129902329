//default library
import * as React from 'react';
//config
import { annualIncomeRange } from 'config/DefaultValue';
//custom component
import SelectOnBoarding from 'components/SelectComponent/SelectOnBoarding';
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
//helper message
import IntlMessages from 'helper/IntlMessages';
// dummy data
import * as OnBoardingData from "service/DummyData/OnBoardingData";
//types import
import * as OnBoarding from "interface/OnBoarding";
import * as PropsFunction from "interface/PropsFunction";
// custom function
import * as Local from 'service/utils/LocalStorageData'

//defining props
type Props = {
    propsData: PropsFunction.PropsPageDataFunction;
}

const NextWorth: React.FC<Props> = (props: Props) => {
    const { propsData } = props//destructuring props

    const [netWorth, setNetWorth] = React.useState<OnBoarding.OptionData>(OnBoardingData.defaultOptionValue);//netWorth with initial value from OnBoardingData.labelCitizenship
    const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({});//fieldDetails with initial value from OnBoardingData.annualIncome

    const nextPage = 'page12';
    const sendText = false;
    const isRouteChange = true;
    const submitText = false;
    const parentButton = false;

    React.useEffect(() => {
        //Accessing data from localStorage
        const localStorageData = Local.getLocalData('onBoardingDetails');
        propsData({
            previousPage: 'page10',
            pageNumber: 12,
        });

        if (localStorageData?.netWorth) {
            const amount = annualIncomeRange.find((e) => e.value === localStorageData.netWorth)
            amount && setNetWorth({
                label: amount.label,
                value: amount.value,
            });
        }
    }, []);

    // set input value
    const setInputValue: OnBoarding.SelectedOption = (fieldValue): void => {
        setNetWorth({
            label: fieldValue.label,
            value: fieldValue.value,
        });
    };

    //update fieldDetails based on netWorth
    React.useEffect(() => {
        setFieldDetails({ netWorth: netWorth.value });
    }, [netWorth])

    return (
        <>
            <div className="gi-ob-slide-card" data-testid='net-worth'>
                {/* static text */}
                <div className="gi-ob-full-name-text gi-ob-worth-text">
                    <h5>{IntlMessages('net_worth_text1')}</h5>
                    <p>{IntlMessages('net_worth_text2')}</p>
                </div>

                {/* net worth */}
                <div className="gi-ob-full-name-form gi-ob-worth-form">
                    <div className="gi-ob-input-box gi-ob-worth-input">
                        <SelectOnBoarding
                            name={'netWorth'}
                            option={annualIncomeRange}
                            placeholder={'placeholder_net_worth'}
                            fieldValue={netWorth}
                            inputValue={setInputValue}
                            ariaLabel={"net-worth"} />
                    </div>
                </div>
            </div>

            {/* desktop button */}
            <div className="gi-ob-let-btn-group gi-ob-employment-btn-group">
                <ButtonComponent
                    fieldDetails={fieldDetails}
                    nextRoute={nextPage}
                    sendText={sendText}
                    isFooter={false}
                    isRouteChange={isRouteChange}
                    submitText={submitText}
                    parentButton={parentButton}
                />
            </div>

            {/* footer button for mobile */}
            <KYCFooterButton
                letStartText={false}
                fieldDetails={fieldDetails}
                nextRoute={nextPage}
                sendText={sendText}
                isRouteChange={isRouteChange}
                submitText={submitText}
                parentButton={parentButton}
            />
        </>
    );
};

export default NextWorth;


































