// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
//types props
import * as PropsFunction from "interface/PropsFunction";

export type Props = {
    openModal: boolean;
    isKycPending: boolean;
    deductionDate: number;
    onHandleClose: PropsFunction.SimpleFunction;
}
const MonthlySuccessModal: React.FC<Props> = (props: Props) => {
    const { openModal, isKycPending, deductionDate, onHandleClose } = props;

    const intl = useIntl();

    return (
        <Modal
            centered
            size='sm'
            show={openModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            contentClassName='border-0'
            className={`gi-ob-green-bottom-curve enter-code-modal enter-code-modal-success`}
        >
            {/* close modal */}
            <button className="btn-close opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>

            <div className="modal-body px-4">
                <div className="gi-ob-setup-complete-modal-text text-center py-4 mt-3 mb-2 pb-4 px-2">
                    <img src={intl.formatMessage({ id: 'GIRAFFE_WITH_HEART' })} alt="GIRAFFE_WITH_HEART" />
                    {isKycPending
                        ? <>
                            <h5 className="mt-3 mb-3">
                                {IntlMessages('monthly_saving_success_pending_text1', { br: <br /> })}
                            </h5>
                            <p className="mb-4">
                                {IntlMessages('monthly_saving_success_pending_text2', { br: <br /> })}
                            </p>
                        </>
                        : <>
                            <h5 className="mt-3 mb-3">
                                {IntlMessages('monthly_saving_success_text1', { br: <br /> })}
                            </h5>
                            <p className="mb-4">
                                {IntlMessages('monthly_saving_success_text2', { br: <br /> })}
                                <strong>
                                    {(new Date(Date.now())).getDate() <= deductionDate
                                        ? IntlMessages('monthly_saving_success_this')
                                        : IntlMessages('monthly_saving_success_next')
                                    }
                                </strong>
                                {IntlMessages('monthly_saving_success_text3')}
                                <br />
                                {IntlMessages('monthly_saving_success_text4')}
                            </p>
                        </>
                    }
                    <button type="button" onClick={onHandleClose}>
                        {IntlMessages('button_ok_2')}
                    </button>
                </div>
            </div>
        </Modal>
    )
}

export default MonthlySuccessModal