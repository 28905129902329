// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { getKYCData, setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as UserService from 'middleware/UserService';
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// default data
import { amountIntentOption } from 'config/DefaultValue';
// custom component
import TabSelectComponent from 'components/SelectComponent/TabSelectComponent';
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import InfoModalIL2 from 'components/ModalComponent/InfoModalIL2';

export type Props = {
    propsData: PropsFunction.PropsStringNumberFunction
}

const AmountIntendToInvest: React.FC<Props> = (props: Props) => {

    const { propsData } = props;
    const history = useHistory();

    const dispatch = useDispatch();
    const kycData = useSelector(getKYCData);

    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state
    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false) //check page data change or not for api call
    const [amountInfoModal, setAmountInfoModal] = React.useState<boolean>(false); //open tax info modal

    const [amountIntent, setAmountIntent] = React.useState<string>(''); //change the button
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    React.useEffect(() => {
        propsData('page7', 8)
        setAmountIntent(kycData.amountIntent)
    }, [])

    //  updating input values in state
    const setInputValue = (_fieldName: string, fieldValue: string): void => {
        setAmountIntent(fieldValue)
        setIsDataChanged(true)
    }

    // close amount Investment info modal
    const onHandleClose = (): void => {
        setAmountInfoModal(false)
    };

    // open amount Investment info modal
    const openModal = (): void => {
        const selectedOption = amountIntentOption.find(e => e.value === amountIntent);
        selectedOption && !selectedOption.active && isDataChanged ? setAmountInfoModal(true) : clickNext();
    };


    // goto next page
    const clickNext = async (): Promise<void> => {
        onHandleClose(); //close modal
        const amountIntentPercentage = amountIntentOption.filter(e => e.value === amountIntent)[0] ?? 1;
        const netWorth = (Number(kycData.investmentAmount) * 100) / amountIntentPercentage.minValue;

        const newKycData = {
            ...kycData,
            amountIntent,
            netWorthTotal: netWorth
        }
        dispatch(setKYCData(newKycData));

        if (isDataChanged) {
            setIsLoading(true);
            const accessToken = await initiateAction();
            const updateUser = await UserService.userDataUpdateIL(newKycData, accessToken); //update user api call
            setIsLoading(false);
            if (updateUser.response || updateUser.request) {
                setErrorLog(updateUser)
                setIsErrorAPI(true)
            } else {
                history.push('/on-boarding/page9')
            }
        } else {
            history.push('/on-boarding/page9')
        }
    }

    React.useEffect(() => {
        setIsDisabled(true)
        if (amountIntent) {
            setIsDisabled(false)
        }
    }, [amountIntent])

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation">
                <div className="row">
                    {/* investment purpose label */}
                    <div className="col-12">
                        <div className="gi-ob-heading my-4">
                            <h5>{IntlMessages('kyc_amount_intend_label')}</h5>
                            <p className="mt-4 pb-2">{IntlMessages('kyc_amount_intend_sub_label', { br: <br /> })}</p>
                        </div>
                    </div>

                    {/* page content */}
                    <div className="col-md-11">
                        <div className="row gx-4 gy-4">
                            {/* investment purpose tab selection */}
                            <div className="col-12">
                                <TabSelectComponent
                                    name={'amountIntent'}
                                    fieldValue={amountIntent}
                                    option={amountIntentOption}
                                    isShowInvestmentClass={true}
                                    inputValue={setInputValue}
                                    dataTestID='amount-intent'
                                />
                            </div>
                        </div>
                    </div>

                    {/* next button */}
                    <div className="col-12">
                        <IsraelKYCButton isDisabled={isDisabled} isSendButton={false} continueClick={openModal} />
                    </div>
                </div>
            </div>

            <InfoModalIL2
                textHeading={IntlMessages('kyc_note')}
                openModal={amountInfoModal}
                messageBody={IntlMessages('kyc_amount_intend_modal_body_text', { br: <br /> })}
                onHandleClose={onHandleClose}
                onHandleSuccess={clickNext}
            />
        </>
    )
}

export default AmountIntendToInvest