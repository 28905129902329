// default library
import * as React from 'react';
import { useHistory } from "react-router-dom";
//auth0 library
import { Auth0Provider, AppState } from "@auth0/auth0-react";
//Config import
import { SESSION_EXPIRED } from "config/Config";
// custom function
import * as Locale from 'service/utils/LocalStorageData';

export type Props = {
    children: React.ReactNode;
}

const Auth0WithHistory: React.FC<Props> = (props: Props) => {
    const { children } = props;
    const history = useHistory();

    const onRedirectCallback = (appState: AppState): void => {
        history.push(appState.returnTo || `${window.location.pathname}`);
        Locale.setLocalData('expiredTime', (Date.now() + (SESSION_EXPIRED + 12 * 1000)));
        Locale.setLocalData('isAuthenticate', true);
    };

    return (
        <Auth0Provider
            domain={import.meta.env.REACT_APP_DOMAIN as string}
            clientId={import.meta.env.REACT_APP_CLIENT_ID as string}
            authorizationParams={{
                redirect_uri: window.location.origin,
                onRedirectCallback: onRedirectCallback,
                audience: import.meta.env.REACT_APP_AUDIENCE as string,
                useRefreshTokens: true,
                cacheLocation: 'memory',
            }}
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0WithHistory