// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// type import
import * as PropsFunction from 'interface/PropsFunction';

export type Props = {
    openInfoModal: boolean,
    onHandleClose: PropsFunction.SimpleFunction,
    bigModal: boolean,
    textHeading: JSX.Element | string,
    messageBody: JSX.Element | string,
}

const HomeInfoModal: React.FC<Props> = (props: Props) => {

    const { openInfoModal, textHeading, messageBody, bigModal, onHandleClose } = props

    return (
        <Modal
            centered
            show={openInfoModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            className={`gi-ob-white-bottom-curve gi-port-ao-pc-companies-modal  ${bigModal && 'gi-dash-ao-future'}`}
        >
            <Modal.Header className='px-3 py-2'>
                <button type="button" className="btn-close p-0 m-0 border-0 opacity-100" onClick={onHandleClose} data-testid='close-modal'></button>
            </Modal.Header>

            <Modal.Body className='px-5 pt-5 pb-4'>
                <div className="gi-dash-account-modal">
                    <h5>
                        <h5>{textHeading}</h5>
                    </h5>
                    {messageBody}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default HomeInfoModal;