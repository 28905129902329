// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { getKYCData, setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as UserService from 'middleware/UserService';
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as UserType from "api/APIMaster/UserType";
import * as OnBoarding from 'interface/OnBoarding';
//regex from number
import * as Config from "config/Config";
// custom component
import AutoCompleteIsrael from 'components/AutoComplete/AutoCompleteIsrael';
import InputTextComponent from 'components/InputComponent/InputTextComponent'
import ErrorKYC from 'components/ErrorComponent/ErrorKYC';
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent';

export type Props = {
    propsData: PropsFunction.PropsStringNumberFunction,
    staticData: UserType.OnboardingStaticDataResponseModel
}

const annualReportNotificationList: OnBoarding.CheckBoxList = [
    {
        label: IntlMessages('account_setting_email_report'),
        name: 'Email',
        active: true
    },
    {
        label: IntlMessages('account_setting_postal_service_report'),
        name: 'Postal service',
        active: false
    },
]

const Address: React.FC<Props> = (props: Props) => {
    const { propsData, staticData } = props;
    const history = useHistory();

    const dispatch = useDispatch();
    const kycData = useSelector(getKYCData);

    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state
    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false) //check page data change or not for api call

    const [addressAutoFill, setAddressAutoFill] = React.useState<string>('');//auto field text
    const [province, setProvince] = React.useState<string>('');//auto field text
    const [isDataLoad, setIsDataLoad] = React.useState<boolean>(false);//auto field text
    const [city, setCity] = React.useState<string>('');//auto field text
    const [countryIdentifier, setCountryIdentifier] = React.useState<string>('');//auto field country
    const [houseNumber, setHouseNumber] = React.useState<string>('');//auto field text
    const [houseNumberError, setHouseNumberError] = React.useState<string | PropsFunction.IntlMessages>('');//auto field text error
    const [apartmentUnit, setApartmentUnit] = React.useState<string>('');//apartment unit
    const [apartmentUnitError, setApartmentUnitError] = React.useState<string | PropsFunction.IntlMessages>('');//apartment unit error
    const [postalCode, setPostalCode] = React.useState<string>(''); //postal code
    const [postalCodeError, setPostalCodeError] = React.useState<string | PropsFunction.IntlMessages>(''); //postal code error
    const [isValidAddress, setIsValidAddress] = React.useState<boolean>(false); //postal code

    const [annualReportNotification, setAnnualReportNotification] = React.useState<OnBoarding.CheckBoxList>(annualReportNotificationList); // notification object for annual report

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    React.useEffect(() => {
        propsData('/on-boarding/page2', 3)

        setAddressAutoFill(kycData.street1 ?? '')
        setCity(kycData.city ?? '')
        setHouseNumber(kycData.houseNumber ?? '')
        setApartmentUnit(kycData.apartmentUnit ?? '')
        setPostalCode(kycData.postalCode ?? '')
        setProvince(kycData.province ?? '')
        setCountryIdentifier(kycData.countryIdentifier ?? '')

        setAnnualReportNotification((prev) => {
            const annualReportNotification = [...prev];
            const index = annualReportNotification.findIndex(e => e.name === kycData.reportsMailingChoice);
            if (index >= 0) {
                annualReportNotification.forEach(e => e.active = false);
                annualReportNotification[index].active = true;
            }
            return annualReportNotification
        })

        setIsDataLoad(true)
    }, [])

    // auto complete field data
    const setAutoCompleteValue: PropsFunction.AutoCompleteIsrael = (params) => {
        setAddressAutoFill(params.street1);
        setProvince(params.province);
        setCity(params.city);
        setPostalCode(params.postalCode);
        setIsValidAddress(params.isValid);
        setCountryIdentifier(params.country);
        setIsDataChanged(true);
        setHouseNumber(params.streetNumber);
    };

    // set input data 
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        if (fieldName === 'houseNumber') {
            const value = fieldValue.replace(Config.ONLY_NUMBER, '')
            setHouseNumber(value)
        }
        if (fieldName === 'apartmentUnit') {
            const value = fieldValue.replace(Config.ONLY_NUMBER, '')
            setApartmentUnit(value)
        }
        if (fieldName === 'postalCode') {
            const value = fieldValue.replace(Config.POSTAL_CODE_INPUT, '')
            setPostalCode(value);
        }

        setIsDataChanged(true)
    }

    // set notification email value
    const setInputValueCheckbox = (fieldName: string, fieldValue: boolean): void => {
        // annual report notification object
        setAnnualReportNotification((prev) => {
            const annualReportNotification = [...prev]
            const index = annualReportNotification.findIndex(e => e.name === fieldName);
            const activeFields = annualReportNotification.findIndex(e => e.active)
            if (index >= 0 && activeFields !== index) {
                annualReportNotification.forEach(e => e.active = false);
                annualReportNotification[index].active = fieldValue;
            }
            return annualReportNotification
        });

        setIsDataChanged(true)
    }

    // goto next page
    const clickNext = async (): Promise<void> => {
        const newKycData = {
            ...kycData,
            street1: addressAutoFill,
            city,
            houseNumber,
            apartmentUnit,
            postalCode,
            province,
            countryIdentifier,
            reportsMailingChoice: [...new Set(annualReportNotification.filter(e => e.active).map((e) => e.name))].join(', ')
        }
        dispatch(setKYCData(newKycData));

        if (isDataChanged) {
            setIsLoading(true);
            const accessToken = await initiateAction();
            const updateUser = await UserService.userDataUpdateIL(newKycData, accessToken); //update user api call
            setIsLoading(false);
            if (updateUser.response || updateUser.request) {
                setErrorLog(updateUser)
                setIsErrorAPI(true)
            } else {
                history.push('/on-boarding/page4')
            }
        } else {
            history.push('/on-boarding/page4')
        }
    }

    // set-up error message 
    React.useEffect(() => {
        // house number validation
        houseNumber && houseNumber.length > Config.MAX_NUMBER_36 ? setHouseNumberError(['error_max_length', { number: Config.MAX_NUMBER_36 }]) : setHouseNumberError('');

        // apartment unit validation
        apartmentUnit && apartmentUnit.length > Config.MAX_NUMBER_36 ? setApartmentUnitError(['error_max_length', { number: Config.MAX_NUMBER_36 }]) : setApartmentUnitError('');

        // postal code validation
        postalCode && postalCode.length > 7 ? setPostalCodeError(['error_max_length', { number: 7 }]) : setPostalCodeError('');
    }, [houseNumber, apartmentUnit, postalCode])

    // validate field name for the next screen
    const validateData = (): void => {
        // check dob
        if (!addressAutoFill || apartmentUnitError) {
            setIsDisabled(true)
        } else if (!city) {
            setIsDisabled(true)
        } else if (!postalCode || postalCodeError || postalCode.length !== 7) {
            setIsDisabled(true)
        } else if (!houseNumber || houseNumberError) {
            setIsDisabled(true)
        } else {
            // if all validation is correct then enable continue button
            setIsDisabled(false)
        }
    }

    React.useEffect(() => {
        validateData()
    }, [addressAutoFill, apartmentUnitError, city, houseNumber, houseNumberError, apartmentUnit, postalCode, postalCodeError])

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation" data-testid="address-page">
                <div className="row">
                    {/* page content */}
                    <div className="col-md-11">
                        <div className="row gx-4 gy-4">
                            {/* page header */}
                            <div className="col-12">
                                <div className="gi-ob-heading mt-4">
                                    <h5>{IntlMessages('kyc_address_header')}</h5>
                                </div>
                            </div>

                            {/* auto complete, house no, apartment */}
                            <div className="col-md-6">
                                <div className="row gx-4 gy-4">
                                    {/* auto complete */}
                                    <div className="col-12">
                                        <div className="gi-ob-form form-group">
                                            {isDataLoad && (
                                                <AutoCompleteIsrael
                                                    labelText={'kyc_address_street'}
                                                    labelClassName={'mb-2'}
                                                    className="react-google-place-il"
                                                    inputValue={setAutoCompleteValue}
                                                    fieldValue={addressAutoFill}
                                                    countryList={staticData.countryList}
                                                    textCenter={false}
                                                    dataTestId={"auto-complete-value"}
                                                />
                                            )}
                                            {isValidAddress && <ErrorKYC message={'error_invalid_address'} fieldName={'autoAddress'} />}
                                        </div>
                                    </div>
                                    {/* house no, apartment */}
                                    <div className="col-12">
                                        <div className="row gx-3">
                                            {/* house no */}
                                            <div className="col-4">
                                                <div className="gi-ob-form form-group">
                                                    <InputTextComponent
                                                        labelClassName={"mb-2"}
                                                        labelText={'kyc_address_house_no'}
                                                        fieldValue={houseNumber}
                                                        name={'houseNumber'}
                                                        placeholder={''}
                                                        readOnly={false}
                                                        disabled={false}
                                                        isError={Boolean(houseNumberError)}
                                                        errorMessage={houseNumberError}
                                                        inputValue={setInputValue}
                                                        className={''}
                                                        dataTestId={'house-number'}
                                                    />
                                                </div>
                                            </div>
                                            {/* apartment */}
                                            <div className="col-4">
                                                <div className="gi-ob-form form-group">
                                                    <InputTextComponent
                                                        labelClassName={"mb-2"}
                                                        labelText={'kyc_address_apartment'}
                                                        fieldValue={apartmentUnit}
                                                        name={'apartmentUnit'}
                                                        placeholder={''}
                                                        readOnly={false}
                                                        disabled={false}
                                                        isError={Boolean(apartmentUnitError)}
                                                        errorMessage={apartmentUnitError}
                                                        inputValue={setInputValue}
                                                        className={''}
                                                        dataTestId={'apartment-unit'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* city, postal code */}
                            <div className="col-md-6">
                                <div className="row gx-4 gy-4">
                                    {/* city */}
                                    <div className="col-12">
                                        <div className="gi-ob-form form-group">
                                            <InputTextComponent
                                                labelClassName={"mb-2"}
                                                labelText={'kyc_address_city'}
                                                fieldValue={city}
                                                name={'city'}
                                                placeholder={''}
                                                readOnly={true}
                                                disabled={false}
                                                isError={false}
                                                errorMessage={''}
                                                inputValue={setInputValue}
                                                className={''}
                                                dataTestId={'city-name'}
                                            />
                                        </div>
                                    </div>
                                    {/* postal code */}
                                    <div className="col-6">
                                        <div className="gi-ob-form form-group">
                                            <InputTextComponent
                                                labelClassName={"mb-2"}
                                                labelText={'kyc_address_postal_code'}
                                                fieldValue={postalCode}
                                                name={'postalCode'}
                                                placeholder={''}
                                                readOnly={false}
                                                disabled={false}
                                                isError={Boolean(postalCodeError)}
                                                errorMessage={postalCodeError}
                                                inputValue={setInputValue}
                                                className={`${postalCode && postalCode.length === 7 && 'right-success-icon'}`}
                                                dataTestId={'postal-code'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="row">
                                    <div className="gi-ob-form form-group mt-3">
                                        <label >{IntlMessages('account_setting_notification_report')}</label>
                                    </div>
                                    {annualReportNotification.map((e, i) => (
                                        <div className={"col-12 col-md-3 mt-3"} key={i}>
                                            <div className="form-check " >
                                                <InputCheckboxComponent
                                                    labelText={e.label}
                                                    fieldName={e.name}
                                                    fieldValue={e.name}
                                                    checkValue={e.active}
                                                    inputClass={'form-check-input mt-1'}
                                                    labelClass={'gi-ob-form-check-label form-check-label mt-1'}
                                                    inputValue={setInputValueCheckbox}
                                                    dataTestId={e.name}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* next button */}
                    <div className="col-12">
                        <IsraelKYCButton isDisabled={isDisabled} isSendButton={false} continueClick={clickNext} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Address