// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// 

// custom component
import LottieAnimation from 'components/AnimationComponent/LottieAnimation';
// Animation JSON
import GiraffeLearnBannerAnimation from 'service/animation/GiraffeLearnBanner.json';

const GiraffeLearnBanner: React.FC = () => {

    const history = useHistory();

    const gotoLearn = (): void => {
        history.push('/dashboard/financial-playlist')
    }

    return (
        <div className="giraffe-learn-banner" onClick={gotoLearn}>
            <LottieAnimation
                animationData={GiraffeLearnBannerAnimation}
                width={'auto'}
                height={'auto'}
            />
        </div>
    );
}

export default GiraffeLearnBanner;