import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// default value
import * as DefaultValue from 'config/DefaultValue'
// type import
import * as DefaultType from 'interface/DefaultValueType';
import { RootState } from 'reduxStore/store';
// custom function
import * as Local from 'service/utils/LocalStorageData';

export type InitialState = DefaultType.LocalData & {
    isReducerCalled: boolean,
    apiLang: string;
}

export const initialState: InitialState = {
    language: 'en_US',
    label: 'English - US',
    direction: 'ltr',
    code: 'USA',
    countryCode: 'USA',

    isReducerCalled: false,
    apiLang: '',
};

const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setCurrentLanguage: (state: InitialState, action: PayloadAction<string>): InitialState => {
            const language = DefaultValue.localeOptions.find(e => e.code === action.payload);

            const language2 = language ? language.language : state.language
            Local.setLocalData('currentLanguage', language2)

            return {
                language: language2,
                label: language ? language.label : state.label,
                direction: language ? language.direction : state.direction ? state.direction : 'ltr',
                code: language ? language.code : state.code,
                countryCode: language ? language.countryCode : state.countryCode,
                isReducerCalled: true,
                apiLang: state.apiLang ? state.apiLang : language2,
            }
        },
        setCurrentLanguageAPI: (state: InitialState, action: PayloadAction<{ countryCode: string, lang: string }>): InitialState => {
            const language = DefaultValue.localeOptions.find(e => ((e.countryCode === action.payload.countryCode) && (e.language === action.payload.lang)));

            const language2 = language ? language.language : state.language
            Local.setLocalData('currentLanguage', language2)

            return {
                language: language2,
                label: language ? language.label : state.label,
                direction: language ? language.direction : state.direction ? state.direction : 'ltr',
                code: language ? language.code : state.code,
                countryCode: language ? language.countryCode : state.countryCode,
                isReducerCalled: true,
                apiLang: state.apiLang ? state.apiLang : language2,
            }
        },
        setCurrentAPILang: (state: InitialState, action: PayloadAction<string>): InitialState => {
            const language = DefaultValue.localeOptions.find(e => e.code === action.payload);
            const updatedLocale = language ? language.language : Local.getCurrentLanguage();

            return {
                ...state,
                apiLang: updatedLocale ? updatedLocale : state.apiLang,
            }
        },
        setCurrentAPILan2: (state: InitialState, action: PayloadAction<string>): InitialState => {

            return {
                ...state,
                apiLang: action.payload,
            }
        }
    }
});

export const { setCurrentLanguage, setCurrentLanguageAPI, setCurrentAPILang, setCurrentAPILan2 } = languageSlice.actions;

export default languageSlice.reducer;

export const getLocale = (state: RootState) => state.language;
