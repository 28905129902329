import * as OnBoarding from "interface/OnBoarding";
import * as PropsFunction from "interface/PropsFunction";

export const defaultOptionValue: PropsFunction.OptionData = {
  label: "",
  value: "",
  active: false,
}

export const userDetails: OnBoarding.OnBoardingData = {
  customerFirstName: '',
  customerMiddleName: '',
  customerLastName: '',
  customerPhoneNumber: '',
  customerEmail: '',
  customerAddress: '',
}