// default library
import * as React from 'react';
// auth0
import { useAuth0 } from '@auth0/auth0-react';
// default value
import * as DefaultValue from 'config/DefaultValue';
// api call
import * as UserService from 'middleware/UserService';
// react redux
import { useSelector, useDispatch } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
import { setCurrentAppCommonData, getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//types props
import * as PropsFunction from "interface/PropsFunction";
// custom component
import LocationSelectorModal from 'components/ModalComponent/LocationSelectorModal';
import KYCEmailSetupModal from 'components/ModalComponent/KYCEmailSetupModal';
import LoadingComponent2 from 'components/LoadingComponent/LoadingComponent2';

export type Props = {
    openModal: boolean;
    locationValue: string;
    workEmail: string;
    changeValue: PropsFunction.ParamsIdStringFunction;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleContinue: PropsFunction.SimpleFunction;
    onHandleDashboard: PropsFunction.SimpleFunction;
}

const LocationEmailModal: React.FC<Props> = (props: Props) => {
    const { openModal, locationValue, workEmail, changeValue, onHandleClose, onHandleContinue, onHandleDashboard, } = props;

    const dispatch = useDispatch();
    const locale = useSelector(getLocale);
    const appCommonData = useSelector(getAppCommonData);

    const { user } = useAuth0();
    const { initiateAction } = useAccessToken();

    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [openLocationModal, setOpenLocationModal] = React.useState<boolean>(false);
    const [workEmailSetupModal, setWorkEmailSetupModal] = React.useState<boolean>(false); //work email setup
    const [loadingModal, setLoadingModal] = React.useState<boolean>(false); //loading modal

    React.useEffect(() => {
        setOpenLocationModal(openModal);
        setWorkEmailSetupModal(false);
    }, [openModal]);

    // update user country and locale
    const confirmLocation = async (): Promise<void> => {
        const language = DefaultValue.localeOptions.find(e => e.code === locationValue);
        if (user?.email && language) {
            const email = user.email as string;

            setOpenLocationModal(false);
            setLoadingModal(true);
            const accessToken = await initiateAction();
            const updateUser = await UserService.updateUserLang(language, email, locale.apiLang, accessToken); //update user api call

            setLoadingModal(false);

            if (updateUser.response || updateUser.request) {
                setErrorLog(updateUser)
                setIsErrorAPI(true)
            } else {
                workEmailModal();

                dispatch(setCurrentAppCommonData({
                    ...appCommonData,
                    kycCountry: language.countryCode,
                }))

            }
        }

    }

    // work email modal
    const workEmailModal = (): void => {
        if (locationValue === 'ISR' && !workEmail) {
            setWorkEmailSetupModal(true);
        } else {
            onHandleContinue()
        }
    }

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {/* Location Modal open */}
            <LocationSelectorModal
                openModal={openLocationModal}
                locationValue={locationValue}
                onHandleClose={onHandleClose}
                confirmLocation={confirmLocation}
                changeValue={changeValue}
            />

            {/* work email setup */}
            <KYCEmailSetupModal
                openModal={workEmailSetupModal}
                onHandleClose={onHandleClose}
                onHandleContinue={onHandleContinue}
                onHandleDashboard={onHandleDashboard}
            />

            {/* loading modal */}
            <LoadingComponent2
                openModal={loadingModal}
                onHandleClose={onHandleClose}
            />
        </>
    )
}

export default LocationEmailModal