// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { getKYCData, setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as UserService from 'middleware/UserService';
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as UserType from "api/APIMaster/UserType"
//regex from number
import { MAX_NUMBER_64 } from "config/Config";
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
// custom component
import TabSelectComponent from 'components/SelectComponent/TabSelectComponent';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import InputTextComponent from 'components/InputComponent/InputTextComponent'
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

export type Props = {
    propsData: PropsFunction.PropsStringNumberFunction
    staticData: UserType.OnboardingStaticDataResponseModel
}

const positionExcludedList: string[] = ["AUDITOR", "ADJUSTER", "MANAGER", "AMBASSADOR", "POLITICIAN", "BUSINESS_OWNER", "DEALER", "INVESTMENT"]

const EmploymentDetails: React.FC<Props> = (props: Props) => {

    const { propsData, staticData } = props;
    const history = useHistory();

    const dispatch = useDispatch();
    const kycData = useSelector(getKYCData);
    const locale = useSelector(getLocale);

    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state
    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false) //check page data change or not for api call

    const [employmentStatusOption, setEmploymentStatusOption] = React.useState<PropsFunction.Option>([]); //employment status
    const [employmentStatus, setEmploymentStatus] = React.useState<string>(''); //employment status
    const [showEmploymentDetails, setEmploymentDetails] = React.useState<boolean>(false);

    const [companyName, setCompanyName] = React.useState<string>(''); // company name
    const [companyNameError, setCompanyNameError] = React.useState<string>(''); // company name error

    const [position, setPosition] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); // company position
    const [occupationField, setOccupationField] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //field of the occupation

    const [positionList, setPositionList] = React.useState<PropsFunction.Option>([]);//list of position options, initialized as an empty array
    const [fieldOccupationList, setFieldOccupationList] = React.useState<PropsFunction.Option>([]);//industry options, initialized as an empty array

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    // setup dropdown option
    React.useEffect(() => {
        // mapping employmentTypeList and employmentPositionList data for Select components
        const fieldOccupation = staticData.employmentTypeList.map(e => ({
            active: e.active,
            label: e.nameLocale,
            value: e.identifier,
        }));

        const employmentPositionList = staticData.employmentPositionList
            .filter(e => locale.countryCode === 'ISR' ? !positionExcludedList.includes(e.name) : true)
            .map(e => ({
                active: e.active,
                label: e.nameLocale,
                value: e.identifier,
            }));

        // for employment status data
        const employmentStatusData = staticData.employmentStatusList.map(e => ({
            value: e.identifier,
            label: e.nameLocale,
            active: (e.name === 'EMPLOYED' || e.name === 'SELF_EMPLOYED') ? true : false
        }));

        const sortedEmploymentStatusData = employmentStatusData.sort((a, b) => (a.active === b.active) ? 0 : a.active ? -1 : 1); //sort the employment status
        setEmploymentStatusOption(sortedEmploymentStatusData)
        setPositionList(employmentPositionList);
        setFieldOccupationList(fieldOccupation);
    }, [])

    // set default value
    React.useEffect(() => {
        propsData('/on-boarding/page3', 4)

        setEmploymentStatus(kycData.employmentStatus);
        const showDetails = employmentStatusOption.find(e => e.value === kycData.employmentStatus);
        showDetails && setEmploymentDetails(showDetails.active)

        setCompanyName(kycData.companyName);

        const position = staticData.employmentPositionList.find(e => e.identifier === kycData.position);
        position && setPosition({
            active: position.active,
            label: position.nameLocale,
            value: position.identifier,
        });

        const occupationField = staticData.employmentTypeList.find(e => e.identifier === kycData.occupationField)
        occupationField && setOccupationField({
            active: occupationField.active,
            label: occupationField.nameLocale,
            value: occupationField.identifier,
        });
    }, [employmentStatusOption])

    //  updating input values in state
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        if (fieldName === 'employmentStatus') {
            setEmploymentStatus(fieldValue);
            const showDetails = employmentStatusOption.find(e => e.value === fieldValue);
            showDetails && setEmploymentDetails(showDetails.active)
            setCompanyName('');
            setPosition(OnBoardingData.defaultOptionValue);
            setOccupationField(OnBoardingData.defaultOptionValue);
        }
        fieldName === 'companyName' && setCompanyName(fieldValue);
        setIsDataChanged(true)
    }

    // select value from dropdown
    const selectDropDownValue: PropsFunction.SelectedOption = (fieldName, value) => {
        setIsDataChanged(true)
        fieldName === 'position' && setPosition(value);
        fieldName === 'occupationField' && setOccupationField(value);
    }

    // goto next page
    const clickNext = async (): Promise<void> => {
        const newKycData = {
            ...kycData,
            employmentStatus,
            companyName,
            position: position.value.toString(),
            occupationField: occupationField.value.toString(),
        }
        dispatch(setKYCData(newKycData));

        if (isDataChanged) {
            setIsLoading(true);
            const accessToken = await initiateAction();
            const updateUser = await UserService.userDataUpdateIL(newKycData, accessToken); //update user api call
            setIsLoading(false);
            if (updateUser.response || updateUser.request) {
                setErrorLog(updateUser)
                setIsErrorAPI(true)
            } else {
                history.push('/on-boarding/page5')
            }
        } else {
            history.push('/on-boarding/page5')
        }
    }

    // validate field name for the next screen
    const validateData = (): void => {
        // check dob
        if (!employmentStatus) {
            setIsDisabled(true)
        } else if (showEmploymentDetails) {
            if (!companyName || companyName.length < 2 || companyNameError) {
                setIsDisabled(true)
            } else if (!position.value) {
                setIsDisabled(true)
            } else if (!occupationField.value) {
                setIsDisabled(true)
            } else {
                // if all validation is correct then enable continue button
                setIsDisabled(false)
            }
        } else {
            // if all validation is correct then enable continue button
            setIsDisabled(false)
        }
    }

    // set-up error message for the first and last name
    React.useEffect(() => {
        // company name 
        companyName && companyName.length > MAX_NUMBER_64 ? setCompanyNameError('error_max_length_64') : setCompanyNameError('');

    }, [companyName])

    // validation function call
    React.useEffect(() => {
        validateData()
    }, [employmentStatus, companyName, companyNameError, position, occupationField])

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation" data-testid="page-page">
                <div className="row">
                    {/* page header */}
                    <div className="col-12">
                        <div className="gi-ob-heading my-4">
                            <h5>{IntlMessages('kyc_employment_detail_header')}</h5>
                        </div>
                    </div>

                    {/* page content */}
                    <div className="col-md-12" data-testid="employment">
                        <div className="row gx-4 gy-4">
                            {/* Employment Status */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <label className="mt-2">
                                        {IntlMessages('kyc_employment_detail_label')}
                                    </label>
                                </div>
                                <TabSelectComponent
                                    name={'employmentStatus'}
                                    fieldValue={employmentStatus}
                                    option={employmentStatusOption}
                                    isShowInvestmentClass={false}
                                    inputValue={setInputValue}
                                    dataTestID={"employment-status"}
                                />
                            </div>

                            {/* Employment Details */}
                            {showEmploymentDetails && (
                                <div className="col-12" data-testid='emp-details'>
                                    <div className="row gx-4 gy-4 mt-1">
                                        {/* Company Name */}
                                        <div className="col-md-4">
                                            <div className="gi-ob-form form-group">
                                                <InputTextComponent
                                                    labelClassName={"mb-2"}
                                                    labelText={'kyc_employment_detail_company_name'}
                                                    className=''
                                                    fieldValue={companyName}
                                                    name={'companyName'}
                                                    placeholder={''}
                                                    readOnly={false}
                                                    disabled={false}
                                                    isError={Boolean(companyNameError)}
                                                    errorMessage={companyNameError}
                                                    inputValue={setInputValue}
                                                    dataTestId={'company-name'}
                                                />
                                            </div>
                                        </div>

                                        {/* Position */}
                                        <div className="col-md-4">
                                            <div className="gi-ob-form form-group">
                                                <SelectDropdown
                                                    labelClassName={'mb-2'}
                                                    labelText={'kyc_employment_detail_position'}
                                                    name={'position'}
                                                    placeholder={''}
                                                    option={positionList}
                                                    fieldValue={position}
                                                    className={'custom-react-dropdown-il'}
                                                    ariaLabel={'position'}
                                                    isDisabled={false}
                                                    inputValue={selectDropDownValue}
                                                    isModalDropdown={false}

                                                />
                                            </div>
                                        </div>
                                        {/* Occupation Field */}
                                        <div className="col-md-4">
                                            <div className="gi-ob-form form-group">
                                                <SelectDropdown
                                                    labelClassName={'mb-2'}
                                                    labelText={'kyc_employment_detail_field'}
                                                    name={'occupationField'}
                                                    placeholder={''}
                                                    option={fieldOccupationList}
                                                    fieldValue={occupationField}
                                                    className={'custom-react-dropdown-il'}
                                                    ariaLabel={'occupation-field'}
                                                    isDisabled={false}
                                                    inputValue={selectDropDownValue}
                                                    isModalDropdown={false} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* next button */}
                    <div className="col-12">
                        <IsraelKYCButton isDisabled={isDisabled} isSendButton={false} continueClick={clickNext} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmploymentDetails