// default library
import * as React from 'react';
import moment from 'moment'
//chart
import ReactApexChart from 'react-apexcharts';
import ApexCharts from 'apexcharts';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// message import
import IntlMessages from 'helper/IntlMessages';
// default value
import * as DefaultValue from 'config/DefaultValue';
// chart option
import { BasketPerformanceChart } from 'config/ChartOptions';
// other lang import
import OtherTranslations from 'lang/OtherTranslations';
// type import
import * as BasketType from 'api/APIMaster/BasketType';
import * as PropsFunction from 'interface/PropsFunction';
// custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter'
import { DateRange, monthDiff } from 'service/utils/DateFilter'
// custom component
import SvgIcon from 'components/CommonComponent/SvgIcon';
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent';

const showCheckBox = import.meta.env.REACT_APP_ENVIRONMENT !== 'prod' ? true : false

type Props = {
    performanceData: BasketType.GetBasketPerformanceHistoricalData;
    clickPerformance: PropsFunction.SimpleFunction;
}

type PerformanceSeries = {
    name: string;
    data: number[]
}[]

const PortfolioPerformance: React.FC<Props> = (props: Props) => {

    const { performanceData, clickPerformance } = props;
    const locale = useSelector(getLocale);

    const [selectedRange, setSelectedRange] = React.useState<string>('one-month');
    const [performanceSeries, setPerformanceSeries] = React.useState<PerformanceSeries>([]);
    const [compareSP500, setCompareSP500] = React.useState<boolean>(showCheckBox); //compare S&P500 with performance checkbox

    // dynamic tick amount based on the min and max value
    const dynamicTickAmount = (minValue: number, maxValue: number): number => {
        const range = Array(maxValue - minValue)
            .map((_, i) => minValue + i).length;  //get the total number between min and max value

        if (range < 6) {
            return Math.trunc(range); // display range tick
        } else {
            return 6; // display 6 ticks
        }
    };

    // change range of the performance graph
    const changeDateRange = (e: React.MouseEvent): void => {
        updateSeriesPerformance((e.target as HTMLLIElement).id)
    }

    // update the apex chart option
    const updateChartOptionPerformance = (seriesList: BasketType.GetBasketPerformanceHistoricalData): void => {

        const diffMonth = monthDiff(new Date(seriesList[0].performanceDate), new Date(seriesList[seriesList.length - 1].performanceDate))

        const format = diffMonth < 6 ? `MM/DD` : `MMM 'YY`

        const minValue = Math.floor(Math.min(...seriesList.map(e => e.gain)))
        const maxValue = Math.ceil(Math.max(...seriesList.map(e => e.gain)))

        // update apex chart options
        let annotations = {}
        let axisBorder = {
            show: true
        }

        // apex chart
        if (maxValue > 0 && minValue < 0) {
            annotations = {
                yaxis: [{
                    y: 0,
                    strokeDashArray: 0,
                    borderColor: '#ffffff',
                    opacity: 0.3,
                }]
            }
            axisBorder = {
                show: false
            }
        } else {
            axisBorder = {
                show: true
            }
        }

        const newUpdateOptions = {
            annotations: annotations,
            xaxis: {
                ...BasketPerformanceChart.xaxis,
                axisBorder,
                labels: {
                    ...BasketPerformanceChart.xaxis?.labels,
                    format: diffMonth < 6 ? 'MM/dd' : 'MMM \'yy'
                },
                categories: seriesList.map((e) => moment(new Date(e.performanceDate).getTime()).format(format)),
            },
            yaxis: [{
                ...BasketPerformanceChart.yaxis,
                tickAmount: dynamicTickAmount(minValue, maxValue),
                opposite: locale.direction !== 'rtl'
            }],
            tooltip: {
                ...BasketPerformanceChart.tooltip,
                style: {
                    ...BasketPerformanceChart.tooltip?.style,
                    fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
                }
            }
        }

        ApexCharts.exec('performance-basket', 'updateOptions', { ...newUpdateOptions }, false, false, false);
        ApexCharts.exec('performance-basket', 'setLocale', locale.direction === 'rtl' ? 'he' : 'en')
    }

    // compare checkbox 
    const sp500Click: PropsFunction.PropsCheckedInputFunction = (_name, value): void => {
        setCompareSP500(value)
    }

    // update the series
    const updateSeriesPerformance = (range: string): void => {
        //get date range of selected option
        const dateRange = DateRange(range)

        if (performanceData.length) {
            const seriesList = dateRange.startDate
                ? performanceData.filter(e => new Date(e.performanceDate).getTime() >= new Date(dateRange.startDate).getTime())
                : performanceData

            updateChartOptionPerformance(seriesList)

            const seriesPerformance = [{
                name: OtherTranslations[locale.language].series1,
                data: seriesList.map((e) => (e.gain))
            }]

            compareSP500 && seriesPerformance.push({
                name: OtherTranslations[locale.language].sp500,
                data: seriesList.map((e) => (e.sp500Gain))
            })

            setSelectedRange(range);
            setPerformanceSeries(seriesPerformance);
        }

    }

    React.useEffect(() => {
        updateSeriesPerformance(selectedRange)
    }, [compareSP500])

    const performanceAmount = performanceData[performanceData.length - 1]?.gain

    return (
        <div className="gi-dash-ao-performance-card py-4">
            <div className="row gap-lg-0 gap-4">
                <div className="col-lg-3">
                    <div className="gi-dash-ao-total-text">
                        <h6 className="d-flex align-items-center gap-2">
                            {IntlMessages('transaction_performance')}
                            <a className="d-flex align-items-center" onClick={clickPerformance} data-testid='performance-info'>
                                <SvgIcon iconId='info-icon' width={16} height={16} />
                            </a>
                        </h6>
                        <h5 className="mt-lg-3 mt-1" data-testid='performance-amt'>{performanceAmount > 0 ? `+${currencyFormatter(performanceAmount)}` : currencyFormatter(performanceAmount)}</h5>
                    </div>
                </div>
                <div className="col-lg-9">
                    {/*  */}
                    <div className="gi-dash-ao-performance-tabs ps-2 ms-1">
                        <ul className="d-flex align-items-center">
                            {DefaultValue.dateRange.map((e, i) => {
                                return (
                                    <li key={i} className={`${e.value === selectedRange && 'active'}`} id={e.value.toString()} onClick={changeDateRange}>
                                        <a id={e.value.toString()} data-testid={e.value}>
                                            {e.label}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    {/*  */}
                    <div className="gi-dash-ao-performance-graph">
                        <div className="row">
                            <div className="col-12">
                                <ReactApexChart options={BasketPerformanceChart} series={performanceSeries} type='area' height={180} dir='ltr' />
                            </div>
                        </div>
                    </div>
                    {/*  */}
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="compare-checkbox form-check">
                                <InputCheckboxComponent
                                    labelText={IntlMessages('compare_sp500_graph')}
                                    labelClass={'form-check-label'}
                                    fieldName={`sp500`}
                                    fieldValue={'sp500'}
                                    checkValue={compareSP500}
                                    inputClass={'form-check-input'}
                                    inputValue={sp500Click}
                                    dataTestId={'compare-check'}
                                />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="gi-port-ao-performance-legend d-flex align-items-center justify-content-between pb-4 mb-1">
                                <ul className="d-flex gap-4 pe-4 mr-2">
                                    <li className="portfolio-legend">
                                        <a href="#!">
                                            {IntlMessages('label_your_portfolio')}
                                        </a>
                                    </li>
                                    {compareSP500 && (
                                        <li className="sp-legend">
                                            <a data-testid='compare_sp500'>
                                                {IntlMessages('compare_sp500')}
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PortfolioPerformance