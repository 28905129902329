//default library
import * as React from 'react';
//local message
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';

type Props = {
    message: PropsFunction.IntlMessages | string;
    dashboard: boolean;
    display: string;
}

const ErrorComponent: React.FC<Props> = (props: Props) => {
    const { message, dashboard, display } = props;

    return (
        <div className={`${dashboard ? 'error-text' : 'gi-onboard-error'}`} style={{ display: display }}>
            {typeof (message) === 'object'
                ? IntlMessages(...message)
                : IntlMessages(message)
            }
        </div>
    )
}

export default ErrorComponent