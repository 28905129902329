// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// message import
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    previousPage: string;
    currentPageIndex: number;
    showHeader: boolean;
    showDots: boolean;
}

const IsraelKYCHeader: React.FC<Props> = (props: Props) => {
    const { previousPage, currentPageIndex, showHeader, showDots } = props;
    const history = useHistory();
    const intl = useIntl()

    const timesToRender = Array.from({ length: 12 }, (_v, i) => i);

    // goto previous page
    const gotoPreviousPage = (): void => {
        history.push(previousPage);
    }

    // goto dashboard page
    const gotoDashboard = (): void => {
        history.push('/dashboard');
    }

    return (
        <>
            {showHeader && (
                <header className="gi-ob-header-main">
                    <div className="gi-ob-il-header py-4">
                        <div className="container-fluid px-md-4 px-3">
                            <div className="row">
                                <div className="col-2">
                                    <div className="d-flex align-items-center justify-content-start h-100">
                                        <button type="button" className="gi-ob-back-btn d-md-block d-none" onClick={gotoPreviousPage}>
                                            <img src={intl.formatMessage({ id: 'IL_ONBOARDING_BACK_INACTIVE' })} alt="" className="inactive" />
                                            <img src={intl.formatMessage({ id: 'IL_ONBOARDING_BACK_ACTIVE' })} alt="" className="active" />
                                            {IntlMessages('il_header_return')}
                                        </button>
                                        <button type="button" className="gi-ob-back-btn d-md-none d-block" onClick={gotoPreviousPage}>
                                            <img src={intl.formatMessage({ id: 'IL_ONBOARDING_BACK_INACTIVE' })} alt="" className="inactive" />
                                            <img src={intl.formatMessage({ id: 'IL_ONBOARDING_BACK_ACTIVE' })} alt="" className="active" />
                                        </button>
                                    </div>
                                </div>
                                <div className="col-8">
                                    {showDots && (
                                        <div className="gi-ob-header-nav h-100">
                                            <ul className="nav justify-content-center align-items-center h-100 gap-8">
                                                {timesToRender.map((i) => (
                                                    <li className="nav-item" key={i}>
                                                        <a className={`nav-link p-0 rounded-circle ${currentPageIndex > i ? 'active' : ''}`} href="#"></a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                                <div className="col-2">
                                    <div className="d-flex align-items-center justify-content-end h-100">
                                        <button type="button" className="gi-ob-close-btn" onClick={gotoDashboard}>
                                            <img src={intl.formatMessage({ id: 'IL_ONBOARDING_CLOSE_INACTIVE' })} alt="" className="inactive" />
                                            <img src={intl.formatMessage({ id: 'IL_ONBOARDING_CLOSE_ACTIVE' })} alt="" className="active" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gi-ob-logo d-md-block d-none">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 pt-1">
                                    <div className="text-center pt-4 pb-4">
                                        <img src={intl.formatMessage({ id: 'IL_ONBOARDING_GIRAFFE' })} alt="" className="active" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            )}
        </>
    )
}

export default IsraelKYCHeader