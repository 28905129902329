import moment from "moment";

export type ReturnDateRange = {
    startDate: string;
    endDate: string;
}

export const DateRange = (dropdownValue: string): ReturnDateRange => {
    const currentDate: Date = new Date(Date.now());

    let startDate = ''
    let endDate = moment(new Date(Date.now())).format('YYYY-MM-DD')

    if (dropdownValue === 'past-week') {
        startDate = moment(new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 7)).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'one-month') {
        startDate = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'three-month') {
        startDate = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'six-month') {
        startDate = moment(new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'ytd') {
        startDate = moment(new Date(currentDate.getFullYear(), 0, 1)).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'one-year') {
        startDate = moment(new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'two-year') {
        startDate = moment(new Date(currentDate.getFullYear() - 2, currentDate.getMonth(), currentDate.getDate())).format('YYYY-MM-DD')
    }
    else if (dropdownValue === 'all-time') {
        startDate = ''
        endDate = ''
    }

    return (
        {
            startDate: startDate,
            endDate: endDate
        }
    )
}

export const monthDiff = (start: Date, end: Date): number => {
    const months = (end.getFullYear() - start.getFullYear()) * 12 - start.getMonth() + end.getMonth();

    return months <= 0 ? 0 : months;
}