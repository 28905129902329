// default library
import * as React from 'react';
import { useIntl } from 'react-intl';
// mask input library
import MaskedInput from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';
//types props
import * as PropsFunction from "interface/PropsFunction";

type Props = {
    name: string;
    className: string;
    placeholder: string;
    fieldType: string;
    fieldValue: string;
    dataTestId: string;
    readOnly: boolean;
    guide: boolean
    inputValue: PropsFunction.PropsInputValueFunction;
}

//  masks for different input fields
const dobMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
const phoneNumberMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, " ", "-", " ", /\d/, /\d/, /\d/, /\d/];
const ssnNumberMask = [/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
const otpMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

const MaskInput2: React.FC<Props> = (props: Props) => {
    const { placeholder, name, className, readOnly, fieldType, fieldValue, guide, dataTestId, inputValue } = props;//Destructuring props
    const intl = useIntl()

    let mask: (string | RegExp)[] = [];
    let pipe: any = '';

    if (name === 'dateOfBirth') {
        mask = dobMask;
        pipe = createAutoCorrectedDatePipe('mm/dd/yyyy');
    } else if (name === 'phoneNumber') {
        mask = phoneNumberMask;
    } else if (name === 'ssnNumber') {
        mask = ssnNumberMask;
    } else if (name === 'verificationCode') {
        mask = otpMask;
    }

    //function for changing input value
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const target = e.target
        inputValue(target.name, target.value);
    };

    return (
        <MaskedInput
            type={fieldType}
            name={name}
            className={className}
            id={name}
            placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
            mask={mask} //mask field
            pipe={pipe} //implement pipe to handle input value
            keepCharPositions={true}
            onChange={handleOnChange} //invoke on every key press
            value={fieldValue} //set default value
            readOnly={readOnly} //check field is readOnly or not
            guide={guide} //hide guide of mask input
            data-testid={dataTestId}
        />
    )
}

export default MaskInput2