// default library
import * as  React from 'react';
// js counter animation
import { CounterAnimation } from 'service/utils/CounterAnimation';

export type Props = {
    delayAnimation: number;
    startNumber: number;
    counterAnimation: number
}

const NumberAnimation: React.FC<Props> = (props: Props) => {
    const { delayAnimation, startNumber, counterAnimation } = props;//destructuring props

    const [counter, setCounter] = React.useState<number>(startNumber);

    React.useEffect(() => {
        CounterAnimation(startNumber, counterAnimation, delayAnimation, (x: number) => {
            setCounter(x);
        });
        return () => setCounter(startNumber);
    }, [counterAnimation, delayAnimation, startNumber]);

    return (
        <>
            {counter}
        </>
    );
};

export default NumberAnimation;













