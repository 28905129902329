//default library
import * as React from 'react';
//custom component
import SelectOnBoarding from 'components/SelectComponent/SelectOnBoarding';
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
//helper message
import IntlMessages from 'helper/IntlMessages';
// dummy data
import * as OnBoardingData from "service/DummyData/OnBoardingData";
//types import
import * as OnBoarding from "interface/OnBoarding";
import * as PropsFunction from "interface/PropsFunction";
import * as UserType from "api/APIMaster/UserType"
//local storage import
import * as Local from 'service/utils/LocalStorageData'

//defining props
type Props = {
    propsData: PropsFunction.PropsPageDataFunction;
    staticData: UserType.OnboardingStaticDataResponseModel
}

const InvestingExperience: React.FC<Props> = (props: Props) => {
    const { propsData, staticData } = props;//destructuring props

    const [currentInvestingExperience, setCurrentInvestingExperience] = React.useState<OnBoarding.OptionData>(OnBoardingData.defaultOptionValue);//current investing experience with the default value from OnBoardingData.labelCitizenship
    const [investingExperienceList, setInvestingExperienceList] = React.useState<OnBoarding.OptionData[]>([]);//investing experience list
    const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({});//field details with the default value from OnBoardingData.newExperienceIdentifier

    const nextPage = 'page13';
    const sendText = false;
    const isRouteChange = true;
    const submitText = false;
    const parentButton = false;

    React.useEffect(() => {
        const localStorageData = Local.getLocalData('onBoardingDetails');

        propsData({
            previousPage: 'page11',
            pageNumber: 13,
        });

        //   Mapping investor experience list
        const investorExperienceList = staticData.investorExperienceList.map((value) => ({
            value: value.identifier,
            label: value.nameLocale,
        }));

        setInvestingExperienceList(investorExperienceList);

        // store local storage value if present
        if (localStorageData?.experienceIdentifier) {
            const investorExperience = investorExperienceList.find((e) => e.value === localStorageData.experienceIdentifier)
            investorExperience && setCurrentInvestingExperience({
                label: investorExperience.label,
                value: investorExperience.value,
            });
        }
    }, []);

    // set input value
    const setInputValue: OnBoarding.SelectedOption = (fieldValue) => {
        setCurrentInvestingExperience({
            label: fieldValue.label,
            value: fieldValue.value,
        });

    };

    //updating field details
    React.useEffect(() => {
        setFieldDetails({ experienceIdentifier: currentInvestingExperience.value });
    }, [currentInvestingExperience]);

    return (
        <>
            <div className="gi-ob-slide-card" data-testid='investing-experience'>
                {/* static text */}
                <div className="gi-ob-full-name-text gi-ob-investing-experience-text">
                    <h5>{IntlMessages('investing_experience_text1')}</h5>
                    <p>{IntlMessages('investing_experience_text2')}</p>
                </div>

                {/* investing experience */}
                <div className="gi-ob-full-name-form gi-ob-investing-experience-form">
                    <div className="gi-ob-input-box gi-ob-investing-experience-input">
                        {investingExperienceList.length > 0 && (
                            <SelectOnBoarding
                                name={'investingExperience'}
                                option={investingExperienceList}
                                placeholder={'placeholder_investing_experience'}
                                fieldValue={currentInvestingExperience}
                                inputValue={setInputValue}
                                ariaLabel={'investing-label'} />
                        )}
                    </div>
                </div>
            </div>

            {/* desktop button */}
            <div className="gi-ob-let-btn-group gi-ob-employment-btn-group">
                <ButtonComponent
                    fieldDetails={fieldDetails}
                    nextRoute={nextPage}
                    sendText={sendText}
                    isFooter={false}
                    isRouteChange={isRouteChange}
                    submitText={submitText}
                    parentButton={parentButton}
                />
            </div>

            {/* footer button for mobile */}
            <KYCFooterButton
                letStartText={false}
                fieldDetails={fieldDetails}
                nextRoute={nextPage}
                sendText={sendText}
                isRouteChange={isRouteChange}
                submitText={submitText}
                parentButton={parentButton}
            />
        </>
    );
};

export default InvestingExperience;


