import * as DashboardType from "api/APIMaster/DashboardType";
import { AxiosClient, RequestParams, ExceptionResponse } from "api/AxiosClient";
import * as URLList from 'api/URLList'


class DashboardService<SecurityDataType = unknown> extends AxiosClient<SecurityDataType> {

    constructor() {
        super({
            baseURL: URLList.BASE_URL.DASHBOARD
        });
    }

    /**
       * @description Returns list of basket details
       *
       * @name GetBasketBasicDetails
       * @summary Get basket basic details
       * @request GET:/user/basket-basic-details
       * @secure
       */
    getBasketBasicDetails = (params: RequestParams = {}) =>
        this.request<DashboardType.GetBasketBasicDetailsByUser1Data, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.BASKET_BASIC_DETAILS.path,
            method: URLList.DASHBOARD_SERVICE.BASKET_BASIC_DETAILS.method,
            secure: true,
            ...params,
        });

    /**
     * @description Returns true if fund transfer initiated successfully else false
     *
     * @name TransferFund
     * @summary Transfer fund
     * @request POST:/user/transfer-fund
     * @secure
     */
    transferFund = (data: DashboardType.FundTransferRequestModel, params: RequestParams = {}) =>
        this.request<DashboardType.TransferFundData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.FUND_TRANSFER.path,
            method: URLList.DASHBOARD_SERVICE.FUND_TRANSFER.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
    * @description Returns user portfolio summary
    *
    * @name GetPortfolioSummary
    * @summary Get portfolio summary
    * @request POST:/user/portfolio-summary
    * @secure
    */
    getPortfolioSummary = (data: DashboardType.PortfolioSummaryRequestModel, params: RequestParams = {}) =>
        this.request<DashboardType.GetPortfolioSummaryData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.PORTFOLIO_SUMMARY.path,
            method: URLList.DASHBOARD_SERVICE.PORTFOLIO_SUMMARY.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns list of fund locations
     *
     * @name GetFundLocations
     * @summary Get list of fund locations
     * @request GET:/user/fund-locations
     * @secure
     */
    getFundLocations = (params: RequestParams = {}) =>
        this.request<DashboardType.GetFundLocationsData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.FUND_LOCATION.path,
            method: URLList.DASHBOARD_SERVICE.FUND_LOCATION.method,
            secure: true,
            ...params,
        });

    /**
     * @description Returns CustomImpactDetailResponseModel
     *
     * @name GetAllImpactDetail
     * @summary Get all impact detail
     * @request POST:/user/impact-detail
     * @secure
     */
    getAllImpactDetail = (data: DashboardType.LocaleRequestModel, params: RequestParams = {}) =>
        this.request<DashboardType.GetAllImpactDetailData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.IMPACT_DETAIL.path,
            method: URLList.DASHBOARD_SERVICE.IMPACT_DETAIL.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns user account performance
     *
     * @name GetAccountPerformance
     * @summary Get account performance
     * @request POST:/user/account-performance
     * @secure
     */
    getAccountPerformance = (data: DashboardType.AccountPerformanceRequestModel, params: RequestParams = {}) =>
        this.request<DashboardType.GetAccountPerformanceData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.ACCOUNT_PERFORMANCE.path,
            method: URLList.DASHBOARD_SERVICE.ACCOUNT_PERFORMANCE.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns fund transfer pending statuses
     *
     * @name IsFundTransferPending
     * @summary Is fund transfer pending
     * @request GET:/user/is-fund-transfer-pending
     * @secure
     */
    isFundTransferPending = (params: RequestParams = {}) =>
        this.request<DashboardType.IsFundTransferPendingData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.IS_FUND_TRANSFER_PENDING.path,
            method: URLList.DASHBOARD_SERVICE.IS_FUND_TRANSFER_PENDING.method,
            secure: true,
            ...params,
        });

    /**
     * @description Return gift detail
     *
     * @name GetGiftDetailByGiftIdentifier
     * @summary Get Gift Detail
     * @request POST:/user/gift-detail
     * @secure
     */
    getGiftDetailByGiftIdentifier = (data: DashboardType.GiftDetailRequestModel, params: RequestParams = {}) =>
        this.request<DashboardType.GetGiftDetailByGiftIdentifierData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.GET_GIFT_DETAIL.path,
            method: URLList.DASHBOARD_SERVICE.GET_GIFT_DETAIL.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
       * @description Return account fees
       *
       * @name AccountFeesData
       * @summary Get Account Fees
       * @request POST:/user/account-fees
       * @secure
       */
    accountFeesDetails = (data: DashboardType.CommonActivityRequestModel, params: RequestParams = {}) =>
        this.request<DashboardType.GetAccountFeesData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.ACCOUNT_FEES.path,
            method: URLList.DASHBOARD_SERVICE.ACCOUNT_FEES.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
    * @description Return dividends detail
    *
    * @name DividendsData
    * @summary  Dividends Detail
    * @request POST:/user/dividends
    * @secure
    */
    dividendsDetails = (data: DashboardType.CommonActivityRequestModel, params: RequestParams = {}) =>
        this.request<DashboardType.GetDividendsData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.POST_DIVIDENDS.path,
            method: URLList.DASHBOARD_SERVICE.POST_DIVIDENDS.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
    * @description Return Investing History detail
    *
    * @name InvestingHistoryData
    * @summary  Investing History
    * @request POST:/user/investing-history
    * @secure
    */
    investingHistoryDetails = (data: DashboardType.CommonActivityRequestModel, params: RequestParams = {}) =>
        this.request<DashboardType.GetInvestingHistoryData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.INVESTING_HISTORY.path,
            method: URLList.DASHBOARD_SERVICE.INVESTING_HISTORY.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
    * @description Return Transaction detail
    *
    * @name TransactionData
    * @summary  Transaction detail
    * @request POST:/user/transactions
    * @secure
    */
    transactionDetails = (data: DashboardType.CommonActivityRequestModel, params: RequestParams = {}) =>
        this.request<DashboardType.GetTransactionsData, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.POST_TRANSACTION.path,
            method: URLList.DASHBOARD_SERVICE.POST_TRANSACTION.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Withdraw money
     *
     * @name WithdrawMoney
     * @summary Withdraw Money for IL user
     * @request POST:/user/withdraw-money
     * @secure
     */
    withdrawMoney = (data: DashboardType.WithdrawMoneyRequestModal, params: RequestParams = {}) =>
        this.request<DashboardType.WithdrawMoneyResponseModal, void | ExceptionResponse>({
            path: URLList.DASHBOARD_SERVICE.WITHDRAW_MONEY.path,
            method: URLList.DASHBOARD_SERVICE.WITHDRAW_MONEY.method,
            body: data,
            secure: true,
            ...params,
        });
}

export default DashboardService