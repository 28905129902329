// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//types props
import * as PropsFunction from "interface/PropsFunction";
// react redux
import { useSelector } from 'react-redux';
import { getKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// api call
import * as UserService from "middleware/UserService";
// custom component
import MaskInputComponent from 'components/InputComponent/MaskInputComponent';
import LoadingComponent3 from 'components/LoadingComponent/LoadingComponent3';
import SuccessModalNew from 'components/ModalComponent/SuccessModalNew';

export type Props = {
    openModal: boolean;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleSuccess: PropsFunction.SimpleFunction;
}

const OTPVerificationModal: React.FC<Props> = (props: Props) => {

    const { openModal, onHandleClose, onHandleSuccess } = props;
    const { initiateAction } = useAccessToken();
    const kycData = useSelector(getKYCData);
    const intl = useIntl();

    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [verificationCode, setVerificationCode] = React.useState<string>('')

    const [oTPModal, setOTPModal] = React.useState<boolean>(false)
    const [isVerified, setIsVerified] = React.useState<boolean>(false)
    const [isError, setIsError] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const messageBody: JSX.Element = <div className="enter-code-modal-text text-center py-5 px-1">
        <img src={intl.formatMessage({ id: 'IL_ONBOARDING_SUCCESS_CHECK' })} alt="" />
    </div>

    React.useEffect(() => {
        setOTPModal(openModal)
        if (openModal) {
            sendOTP(); //call when otp modal is open
        }
        setIsError(false)
    }, [openModal])

    // send otp api call
    const sendOTP = async (): Promise<void> => {
        setIsLoading(true);

        const accessToken = await initiateAction();
        const sendOTP = await UserService.sendOtp(kycData.phoneNumber, accessToken);// sendOTP api call

        setIsLoading(false)

        if (sendOTP.response || sendOTP.request) {
            setErrorLog(sendOTP)
            setIsErrorAPI(true)
        } else {
            setVerificationCode('')
            setIsVerified(false)
        }
    }

    // verify otp api call
    const verifyOTP = async (): Promise<void> => {
        setIsLoading(true)

        const accessToken = await initiateAction();//the auth0 access token
        const verifyOtp = await UserService.verifyOTP(verificationCode, kycData.phoneNumber, accessToken);//Calling the verifyOTP function 

        setIsLoading(false);

        if (verifyOtp.response || verifyOtp.request) {
            setErrorLog(verifyOtp)
            setIsErrorAPI(true)
        } else {
            // if otp is verify
            if (verifyOtp) {
                setOTPModal(false)
                setIsVerified(true)

                setTimeout(() => {
                    onHandleSuccess()
                }, 1000);
            } else {
                setIsVerified(false)
                setIsError(true)
            }
        }
    }

    //  updating input values in state
    const setInputValue = (_fieldName: string, fieldValue: string): void => {
        setVerificationCode(fieldValue)
    };

    React.useEffect(() => {
        verificationCode.length === 6 && verifyOTP()
    }, [verificationCode])

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {/* opt modal */}
            <Modal
                centered
                size='sm'
                show={oTPModal}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-white-bottom-curve enter-code-modal`}
            >
                {!isVerified && <button className="btn-close opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>}
                <Modal.Body className={'py-5 px-4'}>

                    {isLoading && <LoadingComponent3 />}
                    {!isLoading && (
                        <div className="enter-code-modal-text px-1" data-testid='otp-page'>
                            {isError
                                ? <h5 className="mb-3 pb-1 text-error">{IntlMessages('error_invalid_otp')}</h5>
                                : <>
                                    <h5>{IntlMessages('kyc_otp_modal_heading')}</h5>
                                    <p className="mt-3 mb-4">{IntlMessages('kyc_otp_modal_text', { br: <br /> })}</p>
                                </>
                            }

                            <div className="gi-ob-form form-group mb-2 w-75 mx-auto">
                                <MaskInputComponent
                                    placeholder={'placeholder_zip_code'}
                                    name={'verificationCode'}
                                    readOnly={false}
                                    fieldType={'password'}
                                    fieldValue={verificationCode}
                                    inputValue={setInputValue}
                                    guide={false}
                                    dataTestId='verification-code'
                                    labelText={''}
                                    className={'text-center'}
                                    errorMessage={''}
                                    isError={isError}
                                />
                            </div>
                            <span className="d-block pt-1" onClick={sendOTP}>
                                <a href="#" data-testid='send-otp'>
                                    {IntlMessages('btn_send_otp')}
                                </a>
                            </span>
                        </div>
                    )}
                </Modal.Body>
            </Modal>

            {/* success modal */}
            <SuccessModalNew
                openModal={isVerified}
                bodyClassName='py-5 px-4'
                messageBody={messageBody}
                showCloseButton={false}
                onHandleClose={onHandleClose}
            />
        </>
    )
}

export default OTPVerificationModal