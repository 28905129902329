// default library
import * as React from 'react';
import { Redirect } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// datadog
import { datadogLogs } from '@datadog/browser-logs';
//auth0 library
import { useAuth0 } from "@auth0/auth0-react";
//custom component
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, setCurrentLanguageAPI, getLocale } from 'reduxStore/reducers/LanguageReducer';
import { getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
// jwt token decode
import jwt from 'jwt-decode';

export type Props = {
    pageRedirect: boolean;
    defaultLocale: string;
}

const IL_FLOW = JSON.parse(import.meta.env.REACT_APP_IL_FLOW)

const LandingPage: React.FC<Props> = (props: Props) => {

    const { pageRedirect, defaultLocale } = props;

    const { isAuthenticated, loginWithRedirect, user } = useAuth0();
    const { initiateAction } = useAccessToken()

    const dispatch = useDispatch();
    const locale = useSelector(getLocale);
    const appCommonData = useSelector(getAppCommonData);

    React.useEffect(() => {
        (async () => {
            if (!locale.isReducerCalled && isAuthenticated) {
                if (IL_FLOW) {
                    if (appCommonData.kycCountry) {
                        dispatch(setCurrentLanguageAPI({ countryCode: appCommonData.kycCountry, lang: defaultLocale }));
                    } else {
                        const accessToken = await initiateAction()
                        const countryCode = jwt<string>(accessToken)[import.meta.env.REACT_APP_COUNTRY_CODE];
                        dispatch(setCurrentLanguage(countryCode));
                    }
                } else {
                    dispatch(setCurrentLanguage('USA'))
                }
            }
        })()
    }, [appCommonData.kycCountry])

    //onClick login
    if (!isAuthenticated) {
        try {
            loginWithRedirect();
        } catch (e) {
            datadogLogs.logger.error('Error from Auth0:- ', {
                auth0Error: {
                    message: e.message,
                    user: user?.email
                }
            });
        }

        localStorage.clear()
    }

    return (
        <>
            {pageRedirect && <Redirect push to='/dashboard' />}
            {(appCommonData.isKycProcessing && appCommonData.kycCountry !== 'ISR') && <Redirect push to='/on-boarding/kyc-pending' />}
            <LoadingComponent />
        </>
    )
}

export default LandingPage