// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//auth0 library
import { useAuth0 } from '@auth0/auth0-react';
// moment
import moment from 'moment';
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as UserType from "api/APIMaster/UserType";
import * as PropsFunction from 'interface/PropsFunction';
// dummy data
import * as UserData from 'service/DummyData/UserData';
// scroll top
import ScrollToTop from 'helper/ScrollToTop';
//regex from number
import { MAX_NUMBER_36, ONLY_NUMBER, NAME_REGEX } from "config/Config";
//age calculator
import AgeCalculator from 'service/utils/AgeCalculator';
// api call
import * as UserService from 'middleware/UserService';
// custom component
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import InputTextComponent from 'components/InputComponent/InputTextComponent'
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import DatePickerComponent from 'components/DatePickerComponent/DatePickerComponent';
import IsraelKYCFooter from 'components/FooterComponent/IsraelKYCFooter';
import IsraelKYCHeader from 'components/HeaderComponent/IsraelKYCHeader';

const KYCFailureForm: React.FC = () => {
    const { user } = useAuth0();
    const history = useHistory();
    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state

    const [userDetails, setUserDetails] = React.useState<UserType.GetDetailData>(UserData.userData); // user details
    const [kycError, setKycError] = React.useState<string[]>([]); //user kyc rejected fields name array

    const [firstNameHe, setFirstNameHe] = React.useState<string>(''); //first name hebrew
    const [firstNameHeError, setFirstNameHeError] = React.useState<string>(''); //error message for first name hebrew

    const [lastNameHe, setLastNameHe] = React.useState<string>(''); //last name hebrew
    const [lastNameHeError, setLastNameHeError] = React.useState<string>(''); //error message for last name hebrew

    const [docID, setDocID] = React.useState<string>(''); //document id
    const [docIDError, setDocIDError] = React.useState<string>(''); //error message for document id

    const [dob, setDob] = React.useState<Date | null>(null); //date of birth
    const [dobError, setDobError] = React.useState<string>(''); //error message for date of birth

    const [firstNameEn, setFirstNameEn] = React.useState<string>(''); //first name english
    const [firstNameEnError, setFirstNameEnError] = React.useState<string>(''); //error message for first name english

    const [lastNameEn, setLastNameEn] = React.useState<string>(''); //last name english
    const [lastNameEnError, setLastNameEnError] = React.useState<string>(''); //error message for last name english

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    React.useEffect(() => {
        initialAPICall();

        const sectionOneBG = document.getElementById('sectionOne')
        if (sectionOneBG) {
            // sectionOneBG.style.background = 'none'
            sectionOneBG.removeAttribute('style');
        }
    }, [])

    // set default value based on the kyc error fields
    React.useEffect(() => {
        isFirstNameHeError ? setFirstNameHe(userDetails.metadataList.find((item: UserType.Metadata) => item.fieldName === 'firstNameLocale')?.fieldValue ?? '') : setFirstNameHe(''); //first name in hebrew
        isLastNameHeError ? setLastNameHe(userDetails.metadataList.find((item: UserType.Metadata) => item.fieldName === 'lastNameLocale')?.fieldValue ?? '') : setLastNameHe(''); //last name in hebrew
        isDOBError ? userDetails.dob && setDob(new Date(userDetails.dob)) : setDob(null); //date of birth
        isFirstNameEnError ? setFirstNameEn(userDetails.firstNameEncrypted) : setFirstNameEn(''); //first name in english
        isLastNameEnError ? setLastNameEn(userDetails.lastNameEncrypted) : setLastNameEn(''); //last name in english
        isTaxIdNumberError ? setDocID(userDetails.taxIdNumber) : setDocID(''); //user document id
    }, [kycError])

    // initial api call
    const initialAPICall = async (): Promise<void> => {
        window.scroll(0, 0)

        setIsLoading(true); //loading start

        const accessToken = await initiateAction() //get access token
        const [userDetails, kycRejectDetail] = await Promise.all([
            UserService.getDetail(accessToken), //get the user details
            UserService.kycRejectedDetails(accessToken), //get the user rejected details
        ])

        setIsLoading(false); //loading stop

        if (userDetails.request || userDetails.response || kycRejectDetail.request || kycRejectDetail.response) {
            setIsError(true);
            (userDetails.request || userDetails.response) && setErrorLog(userDetails);
            (kycRejectDetail.request || kycRejectDetail.response) && setErrorLog(kycRejectDetail);
        } else {

            setUserDetails(userDetails);

            setKycError(kycRejectDetail.kycError ? kycRejectDetail.kycError.split('|') : []); //user rejected fields name array
        }
    }

    //  updating input values in state
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        fieldName === "firstNameHe" && setFirstNameHe(fieldValue);
        fieldName === "lastNameHe" && setLastNameHe(fieldValue);
        fieldName === "firstNameEn" && setFirstNameEn(fieldValue);
        fieldName === "lastNameEn" && setLastNameEn(fieldValue);

        if (fieldName === "docID") {
            const value = fieldValue.replace(ONLY_NUMBER, '')
            setDocID(value);
        }
    };

    // set dob selection date
    const setDateValue: PropsFunction.PropsDateFunction = (date) => {
        setDob(date);
        date && AgeCalculator(date) < 18 ? setDobError('error_dob_18') : setDobError('');
    }

    // validate field name for the next screen
    const validateData = (): void => {
        if (!firstNameHe || firstNameHeError || firstNameHe.length < 2) {
            setIsDisabled(true)
        } else if (!lastNameHe || lastNameHeError || lastNameHe.length < 2) {
            setIsDisabled(true)
        } else if (!docID || docIDError || docID.length < 7) {
            setIsDisabled(true)
        } else if (!dob || dobError) {
            setIsDisabled(true)
        } else if (!firstNameEn || firstNameEnError || firstNameEn.length < 2) {
            setIsDisabled(true)
        } else if (!lastNameEn || lastNameEnError || lastNameEn.length < 2) {
            setIsDisabled(true)
        } else {
            // if all validation is correct then enable continue button
            setIsDisabled(false)
        }
    }

    // set-up error message for the first and last name
    React.useEffect(() => {
        // first name hebrew validation
        firstNameHe.length > MAX_NUMBER_36 ? setFirstNameHeError('error_max_length_36_name') : setFirstNameHeError('');

        // last name hebrew validation
        lastNameHe.length > MAX_NUMBER_36 ? setLastNameHeError('error_max_length_36_name') : setLastNameHeError('');

        // israeli document id
        docID.length > 9 ? setDocIDError('error_invalid_ID_card') : setDocIDError('');

        // first name english validation
        if (firstNameEn && (firstNameEn.length > MAX_NUMBER_36 || !firstNameEn.match(NAME_REGEX))) {
            firstNameEn.length > MAX_NUMBER_36 && setFirstNameEnError('error_max_length_36_name');
            !firstNameEn.match(NAME_REGEX) && setFirstNameEnError('error_first_name');
        } else {
            setFirstNameEnError('')
        }

        // last name english validation
        if (lastNameEn && (lastNameEn.length > MAX_NUMBER_36 || !lastNameEn.match(NAME_REGEX))) {
            lastNameEn.length > MAX_NUMBER_36 && setLastNameEnError('error_max_length_36_name');
            !lastNameEn.match(NAME_REGEX) && setLastNameEnError('error_last_name');
        } else {
            setLastNameEnError('')
        }
    }, [firstNameHe, lastNameHe, docID, firstNameEn, lastNameEn]);

    // validation function call
    React.useEffect(() => {
        validateData()
    }, [firstNameHe, firstNameHeError, lastNameHe, lastNameHeError, docID, docIDError, dob, dobError, firstNameEn, firstNameEnError, lastNameEn, lastNameEnError])

    const updateUserKYCDetails = async (): Promise<void> => {

        userDetails.metadataList.forEach(e => {
            if (e.fieldName === 'firstNameLocale') {
                e.fieldValue = firstNameHe;
            } else if (e.fieldName === 'lastNameLocale') {
                e.fieldValue = lastNameHe;
            }
        })

        const formData: UserType.UserRequestModel = {
            identifier: userDetails.identifier,
            name: userDetails.name,
            firstName: firstNameEn,
            middleName: null,
            lastName: lastNameEn,
            email: user?.email ?? '',
            marketing: true,
            phone: userDetails.phoneEncrypted,
            language: userDetails.language,
            taxIdNumber: isTaxIdNumberError ? null : docID,
            citizenship: userDetails.citizenship,
            dob: moment(dob).format('YYYY-MM-DD'),
            politicallyExposedNames: userDetails.politicallyExposedNames,
            irsBackupWithholdings: false,
            showKycStatus: true,
            usTaxPayer: userDetails.usTaxPayer,
            userAddress: userDetails.userAddress,
            userEmployment: userDetails.userEmployment,
            userInvestor: userDetails.userInvestor,
            userDisclosure: userDetails.userDisclosure,
            metadataList: userDetails.metadataList,
            kycCountry: userDetails.kycCountry,
            locale: userDetails.locale,
            gender: userDetails.gender
        }

        setIsLoading(true); //loading start

        const accessToken = await initiateAction() //get access token
        const updatedData = await UserService.userDataUpdateAC(formData, accessToken);

        setIsLoading(false); //loading stop
        if (updatedData.response || updatedData.request) {
            setIsError(true)
            setErrorLog(updatedData)
        } else {
            history.push('/dashboard'); //redirect to dashboard
        }
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    const isFirstNameHeError: boolean = !kycError.includes('firstNameLocale'); //check kyc error first name hebrew has error or not in the kyc error
    const isLastNameHeError: boolean = !kycError.includes('lastNameLocale'); //check kyc error last name hebrew has error or not in the kyc error
    const isDOBError: boolean = !kycError.includes('dob'); //check kyc error date of birth has error or not in the kyc error
    const isFirstNameEnError: boolean = !kycError.includes('firstName'); //check kyc error first name english has error or not in the kyc error
    const isLastNameEnError: boolean = !kycError.includes('lastName'); //check kyc error last name english has error or not in the kyc error
    const isTaxIdNumberError: boolean = !kycError.includes('taxIdNumber'); //check kyc error document number has error or not in the kyc error

    return (
        <>
            <ScrollToTop />

            {isLoading && <LoadingComponent />}

            {/* page header */}
            <IsraelKYCHeader
                previousPage={'/dashboard'}
                currentPageIndex={0}
                showHeader={true}
                showDots={false}
            />

            {/* page content */}
            <section className={'gi-ob-center-part'}>
                <div className="container-md">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="px-sm-3 px-4">
                                <div className="px-sm-0 px-3">
                                    <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation kyc-failure-form">
                                        <div className="row">
                                            {/* page data */}
                                            <div className="col-md-11">
                                                <div className="row gx-4 gy-4">
                                                    {/* page header */}
                                                    <div className="col-12">
                                                        <div className="gi-ob-heading mt-4">
                                                            <h5>
                                                                {IntlMessages('kyc_reject_form_text1')}
                                                            </h5>
                                                        </div>
                                                    </div>

                                                    {/* firstNameHe, lastNameHe, dob */}
                                                    <div className="col-md-6">
                                                        {/* firstNameHe */}
                                                        <div className="col mt-4 mt-md-3 pt-md-1">
                                                            <div className={`gi-ob-form form-group ${isFirstNameHeError ? 'form-disabled' : ''}`}>
                                                                <InputTextComponent
                                                                    labelClassName={"mb-2"}
                                                                    labelText={'kyc_first_name_label'}
                                                                    className=''
                                                                    fieldValue={firstNameHe}
                                                                    name={'firstNameHe'}
                                                                    placeholder={'placeholder_IL_first_name'}
                                                                    readOnly={false}
                                                                    disabled={isFirstNameHeError}
                                                                    isError={Boolean(firstNameHeError)}
                                                                    errorMessage={firstNameHeError}
                                                                    inputValue={setInputValue}
                                                                    dataTestId={"first-name-he"}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* lastNameHe */}
                                                        <div className="col mt-4 mt-md-3 pt-md-1">
                                                            <div className={`gi-ob-form form-group ${isLastNameHeError ? 'form-disabled' : ''}`}>
                                                                <InputTextComponent
                                                                    labelClassName={"mb-2"}
                                                                    labelText={'kyc_last_name_label'}
                                                                    className=''
                                                                    fieldValue={lastNameHe}
                                                                    name={'lastNameHe'}
                                                                    placeholder={'placeholder_IL_last_name'}
                                                                    readOnly={false}
                                                                    disabled={isLastNameHeError}
                                                                    isError={Boolean(lastNameHeError)}
                                                                    errorMessage={lastNameHeError}
                                                                    inputValue={setInputValue}
                                                                    dataTestId={'last-name-he'}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* dob */}
                                                        <div className="col mt-4 mt-md-3 pt-md-1">
                                                            <div className={`gi-ob-form form-group ${isDOBError ? 'form-disabled' : ''}`}>
                                                                <DatePickerComponent
                                                                    labelText={'kyc_dob_label'}
                                                                    labelClassName={'mb-2'}
                                                                    dateValue={dob}
                                                                    newDate={setDateValue}
                                                                    name={'dob'}
                                                                    className={'calender-icon'}
                                                                    errorMessage={dobError}
                                                                    disabled={isDOBError}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* firstNameEn, lastNameEn, docId */}
                                                    <div className="col-md-6 mt-0 mt-md-4">
                                                        {/* firstNameEn */}
                                                        <div className="col mt-4 mt-md-3 pt-md-1 ltr">
                                                            <div className={`gi-ob-form gi-ob-form-eng form-group ${isFirstNameEnError ? 'form-disabled' : ''}`}>
                                                                <InputTextComponent
                                                                    labelClassName={"mb-2"}
                                                                    labelText={'kyc_first_name_label_en'}
                                                                    fieldValue={firstNameEn}
                                                                    name={'firstNameEn'}
                                                                    placeholder={'placeholder_name_en'}
                                                                    readOnly={false}
                                                                    disabled={isFirstNameEnError}
                                                                    isError={Boolean(firstNameEnError)}
                                                                    errorMessage={firstNameEnError}
                                                                    inputValue={setInputValue}
                                                                    className={''}
                                                                    dataTestId={'first-name'}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* lastNameEn */}
                                                        <div className="col mt-4 mt-md-3 pt-md-1 ltr">
                                                            <div className={`gi-ob-form gi-ob-form-eng form-group ${isLastNameEnError ? 'form-disabled' : ''}`}>
                                                                <InputTextComponent
                                                                    labelClassName={"mb-2"}
                                                                    labelText={'kyc_last_name_label_en'}
                                                                    fieldValue={lastNameEn}
                                                                    name={'lastNameEn'}
                                                                    placeholder={'placeholder_name_en'}
                                                                    readOnly={false}
                                                                    disabled={isLastNameEnError}
                                                                    isError={Boolean(lastNameEnError)}
                                                                    errorMessage={lastNameEnError}
                                                                    inputValue={setInputValue}
                                                                    className={''}
                                                                    dataTestId={'last-name'}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* docId */}
                                                        <div className="col mt-4 mt-md-3 pt-md-1">
                                                            <div className={`gi-ob-form form-group ${isTaxIdNumberError ? 'form-disabled' : ''}`}>
                                                                <InputTextComponent
                                                                    labelClassName={"mb-2"}
                                                                    labelText={'kyc_last_doc_id'}
                                                                    className=''
                                                                    fieldValue={isTaxIdNumberError ? '' : docID}
                                                                    name={'docID'}
                                                                    placeholder={isTaxIdNumberError ? '' : 'placeholder_IL_doc_id'}
                                                                    readOnly={false}
                                                                    disabled={isTaxIdNumberError}
                                                                    isError={Boolean(docIDError)}
                                                                    errorMessage={docIDError}
                                                                    inputValue={setInputValue}
                                                                    dataTestId={'doc-id'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* next button */}
                                            <div className="col-12 kyc-failure-btn">
                                                <IsraelKYCButton isDisabled={isDisabled} isSendButton={true} continueClick={updateUserKYCDetails} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* page footer */}
            <IsraelKYCFooter showLogo={false} />
        </>
    )
}

export default KYCFailureForm