//default library
import * as React from 'react'
//Auth0 library
import { useAuth0 } from "@auth0/auth0-react";

import { datadogLogs } from '@datadog/browser-logs';

const SignUpComponent = () => {
    //redirect login page
    const { loginWithRedirect, user } = useAuth0();

    React.useEffect(() => {
        try {
            loginWithRedirect({
                authorizationParams: {
                    screen_hint: "signup",
                }
            });
        } catch (e) {
            datadogLogs.logger.error('Error from Auth0:- ', {
                auth0Error: {
                    message: e.message,
                    user: user?.email
                }
            });
        }
    }, [])

    return (
        <></>
    )
}

export default SignUpComponent
