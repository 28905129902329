//default library
import * as React from 'react';
// intl message library
import { useIntl } from 'react-intl';
//custom component
import BarChartPortfolio from 'components/ChartSection/BarChartPortfolio';
import LineChartPortfolio from 'components/ChartSection/LineChartPortfolio';
import TablePortfolioChart from 'components/TableComponent/TablePortfolioChart';
import InfoModal from 'components/ModalComponent/InfoModal';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types props
import * as BasketType from "api/APIMaster/BasketType"

//defining props
export type Props = {
    sp500GraphData: BasketType.BasketPerformanceComparisonData
}

type ButtonType = {
    id: number;
    name: string;
    activeIcon: string;
    inActiveIcon: string;
}


const MultiGraphSection: React.FC<Props> = (props: Props) => {
    const { sp500GraphData } = props//destructuring props
    const intl = useIntl();

    const [activeButton, setActiveButton] = React.useState<string>('bar-chart');//default select
    const [infoModal, setInfoModal] = React.useState<boolean>(false);//source info modal

    const buttonType: ButtonType[] = [
        { id: 1, name: 'bar-chart', activeIcon: intl.formatMessage({ id: 'BAR_CHART_ACTIVE_ICON' }), inActiveIcon: intl.formatMessage({ id: 'BAR_CHART_INACTIVE_ICON' }) },
        { id: 2, name: 'table-chart', activeIcon: intl.formatMessage({ id: 'TABLE_CHART_ACTIVE_ICON' }), inActiveIcon: intl.formatMessage({ id: 'TABLE_CHART_INACTIVE_ICON' }) },
        { id: 3, name: 'line-chart', activeIcon: intl.formatMessage({ id: 'LINE_CHART_ACTIVE_ICON' }), inActiveIcon: intl.formatMessage({ id: 'LINE_CHART_INACTIVE_ICON' }) },
    ];

    //handle source modal
    const openInfoModal = (): void => {
        setInfoModal(true);
    };
    //close modal
    const onHandleClose = (): void => {
        setInfoModal(false);
    };

    const handleButtonClick = (name: string): void => {
        setActiveButton(name);
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="gift-p-compare-card mt-lg-2 mt-0 px-3 py-lg-5 pt-5 pb-4">
                        <div className="row justify-content-center">
                            <div className="col-md-10">
                                <div className="row justify-content-between gx-0">
                                    {/* graph button */}
                                    <div className="col-lg-4">
                                        <div className="gift-p-compare-text mt-4 py-1">
                                            <h5>{IntlMessages('create_basket_text14')} </h5>
                                            <p className="mt-3">{IntlMessages('create_basket_text15')}</p>

                                            <div className="gift-p-compare-btn-groupe d-flex align-items-center pt-lg-1 pt-0 mt-lg-4 mt-5 gap-5">
                                                <div className="gift-p-compare-graph-btns d-flex align-items-center gap-3">
                                                    {buttonType.map((e, i) => (
                                                        <button
                                                            key={i}
                                                            type="button"
                                                            data-testid={e.name}
                                                            className={`gift-p-compare-graph-btn p-0 d-flex align-items-center justify-content-center ${e.name === activeButton ? 'active' : ''}`}
                                                            onClick={() => handleButtonClick(e.name)}
                                                        >
                                                            <span className={`icon ${e.name}`}>
                                                                <img src={e.inActiveIcon} className="icon-inactive" alt="" />
                                                                <img src={e.activeIcon} className="icon-active" alt="" />
                                                            </span>
                                                        </button>
                                                    ))}
                                                </div>

                                                {/* source btn */}
                                                <div className="g-source-btns">
                                                    <button
                                                        type="button"
                                                        className="gift-p-compare-plus-btn d-flex align-items-center justify-content-center gap-2"
                                                        onClick={openInfoModal}
                                                        data-testid='info-modal'
                                                    >
                                                        <span className="icon">
                                                            <img src={intl.formatMessage({ id: 'PLUS_INACTIVE_ICON' })} className="icon-inactive"
                                                                alt="PLUS_INACTIVE_ICON" />
                                                            <img src={intl.formatMessage({ id: 'PLUS_ACTIVE_ICON' })} className="icon-active" alt="PLUS_ACTIVE_ICON" />
                                                        </span>
                                                        {IntlMessages('portfolio_source')}

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className="col-lg-7">
                                        {/* bar chart */}
                                        <div className={`gift-p-compare-graph ${activeButton === 'bar-chart' ? 'active' : ''}`} data-id="bar-chart">
                                            <BarChartPortfolio sp500GraphData={sp500GraphData.yearlyPerformance} />
                                        </div>

                                        {/* table */}
                                        <div className={`gift-p-compare-graph ${activeButton === 'table-chart' ? 'active' : ''}`} data-id="table-chart">
                                            <div className="g-data-table-graph-table px-lg-4 px-0 py-4">
                                                <label className='pb-2'>{IntlMessages('portfolio_table_title')}</label>
                                                <TablePortfolioChart sp500GraphData={sp500GraphData.yearlyPerformance} />
                                            </div>
                                        </div>

                                        {/* line chart */}
                                        <div className={`gift-p-compare-graph ${activeButton === 'line-chart' ? 'active' : ''}`} data-id="line-chart">
                                            <LineChartPortfolio sp500GraphData={sp500GraphData.monthlyPerformance} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* source info modal */}
            <InfoModal openInfoModal={infoModal} messageBody={IntlMessages('create_basket_text13', { br: <br /> })} textHeading={''} onHandleClose={onHandleClose} data-testid='close-modal' />
        </>
    );
};

export default MultiGraphSection;










