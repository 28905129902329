// default library
import * as React from 'react';
// intl message
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    listLabel: string;
    listData: string | JSX.Element;
    showVerify: boolean;
    labelClassName: string;
    dataClassName: string;
    iText: string;
    bText: string | JSX.Element;
}

const ListView: React.FC<Props> = (props: Props) => {

    const { listLabel, labelClassName, listData, showVerify, dataClassName, iText, bText } = props;

    return (
        <ul className="gi-d-ac-tr px-md-4 px-0">
            <li className="gi-d-ac-th">
                <span className={labelClassName}>
                    {IntlMessages(listLabel)}
                </span>
            </li>
            <li className="gi-d-ac-td">
                <span className={dataClassName}> {listData}</span>
                {iText && <i>{iText}</i>}
                {bText && <b>{bText}</b>}
                {showVerify && <a> {IntlMessages('common_verify2')}</a>}
            </li>
        </ul>
    )
}

export default ListView