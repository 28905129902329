//default library
import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//api call
import * as LearnService from "middleware/LearnService";
// dummy data
import * as LearnData from 'service/DummyData/LearnData';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// custom function
import { getUserName } from 'service/utils/UtilFunctions';
import * as Local from 'service/utils/LocalStorageData';
//types props
import * as LearnType from 'api/APIMaster/LearnType';
import * as Dashboard from 'interface/Dashboard'
//custom components
import CurrentWeekSection from 'components/SectionComponent/CurrentWeekSection';
import ScheduleSection from 'components/SectionComponent/ScheduleSection';
import PageHeader from 'components/PageHeaderComponent/PageHeader';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import GiraffeLearnBannerSection from 'components/RightSidebarComponent/GiraffeLearnBannerSection';

const GiraffeLearnPage: React.FC = () => {
    const history = useHistory()
    const { initiateAction } = useAccessToken();
    const { user } = useAuth0();
    const locale = useSelector(getLocale);

    const [errorAPI, setErrorAPI] = React.useState<boolean>(false);//error flag
    const [errorLog, setErrorLog] = React.useState<any>({});

    const [isLoading, setIsLoading] = React.useState<boolean>(true);//loading flag
    const [scheduleSection, setScheduleSection] = React.useState<Dashboard.ScheduleSection>(LearnData.scheduleSectionData);//schedule section data
    const [userVideoList, setUserVideoList] = React.useState<Dashboard.UserVideoListData>(LearnData.userVideoListData);//user video list
    const [identifier, setIdentifier] = React.useState<string>('');

    React.useEffect(() => {
        initialAPICall();
    }, [locale.language]);

    const initialAPICall = async (): Promise<void> => {
        window.scroll(0, 0);
        const forWeekData = Local.getLocalData('currentWeek')//get current if user come back from video page
        forWeek(forWeekData);
    };

    const playVideo = (videoIdentifier: string): void => {
        const { forWeek } = scheduleSection;
        history.push(`/videos?cid=${identifier}&vid=${videoIdentifier}`)
        Local.setLocalData('currentWeek', forWeek);
    };

    const forWeek = async (newForWeek: string): Promise<void> => {
        window.scroll(0, 0);
        setIsLoading(true);
        const accessToken = await initiateAction();
        const userContent = await LearnService.getUserContent(newForWeek, accessToken);

        if (userContent.response) {
            setErrorLog(userContent)
            setErrorAPI(true);
        } else {
            const newScheduleSection = {
                forWeek: userContent.forWeek,
                nextWeek: userContent.nextWeek,
                previousWeek: userContent.previousWeek,
            };

            const featuredVideo = userContent.userContentVideoResponseList
                .filter((e: LearnType.UserContentVideoResponseList) => e.featured);//featured video for current week

            const thumbnailVideo = userContent.userContentVideoResponseList
                .filter((e: LearnType.UserContentVideoResponseList) => !e.featured)
                .sort((a: LearnType.UserContentVideoResponseList, b: LearnType.UserContentVideoResponseList) => a.priority - b.priority);//thumbnail video with sort priority

            const newUserVideoList = {
                forWeek: userContent.forWeek,
                featuredVideo: featuredVideo ? featuredVideo[0] : {},
                thumbnailVideo: thumbnailVideo,
            };

            setScheduleSection(newScheduleSection);
            setUserVideoList(newUserVideoList);
            setIdentifier(userContent.identifier);
            setIsLoading(false);
        }
    };

    const localName = getUserName(locale.language);
    const userName = localName.userName ? localName.userName : user?.email?.split('@')[0];


    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading
                ? <LoadingComponent />
                : <div data-testid="giraffe-learn-page">
                    {/* page header */}
                    <PageHeader
                        className={'g-heading px-sm-0 px-3'}
                        textStrong={'giraffe_learn_welcome'}
                        userName={userName}
                        otherAttr={''}
                    />

                    <div className="row gx-2" >
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-12">
                                    {/* Giraffe view week */}
                                    <ScheduleSection
                                        learnList={true}
                                        scheduleSection={scheduleSection}
                                        forWeek={forWeek}
                                    />

                                    {/* Current week card */}
                                    <CurrentWeekSection
                                        userVideoList={userVideoList}
                                        playVideo={playVideo}
                                        scheduleSection={scheduleSection}
                                        forWeek={forWeek}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Banner section */}
                        <div className="col-lg-3">
                            <GiraffeLearnBannerSection />
                        </div>

                        {/* Bank space */}
                        <div className="row">
                            <div className="col-12">
                                <div className="h-dash"></div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default GiraffeLearnPage;
