//default library
import * as  React from 'react';
import { useHistory } from 'react-router-dom';
//auth library
import { useAuth0 } from '@auth0/auth0-react';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//custom component
import KycCompleteFooter from 'components/FooterComponent/KycCompleteFooter';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import ErrorKYC from 'components/ErrorComponent/ErrorKYC';
import PlaidComponent from 'components/PlaidComponent/PlaidComponent';
//helper message
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
//types import
import * as OnBoarding from "interface/OnBoarding";
// api call
import * as UserService from "middleware/UserService"
import * as OnBoardingData from "service/DummyData/OnBoardingData";

const BankConnect: React.FC = () => {
    const { user } = useAuth0();
    const { initiateAction } = useAccessToken();
    const history = useHistory();
    const intl = useIntl();

    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [userName, setUserName] = React.useState<string>('');//storing username
    const [errorMessage, setErrorMessage] = React.useState<string>('');//error message
    const [errorBankConnecting, setErrorBankConnecting] = React.useState<boolean>(false);
    const [plaidData, setPlaidData] = React.useState<OnBoarding.OnBoardingData>(OnBoardingData.userDetails);// storing userDetails

    React.useEffect(() => {
        (async () => {
            setIsLoading(true);

            const accessToken = await initiateAction();
            const userData = await UserService.getDetail(accessToken);//UserService to get user details using the access token

            setIsLoading(false);

            // Check if there's an error in the response
            if (userData.response || userData.request) {
                setErrorLog(userData)
                setIsError(true)
            } else {
                const userAddress = userData.userAddress; // Extract user address from the response data

                // Set various state variables with user data
                setUserName(userData.name);
                setPlaidData({
                    customerFirstName: userData.firstNameEncrypted,
                    customerMiddleName: userData.middleNameEncrypted,
                    customerLastName: userData.lastNameEncrypted,
                    customerPhoneNumber: userData.phoneEncrypted,
                    customerEmail: user?.email ?? "",
                    customerAddress: `${userAddress.street1} ${userAddress.city} ${userAddress.province} ${userAddress.postalCode}`,
                });
            }
        })()

    }, []);

    //handle Plaid errors
    const handlePlaidError = (value: { message: string }): void => {
        // error message based on the Plaid error value
        const errorMessage =
            value.message === `Personal data doesn't match bank data`
                ? `error_connecting_account`//error message key for this case
                : `error_connecting_account1`;//another specific error message key for other cases

        setErrorMessage(errorMessage);
        setIsLoading(false);
        setErrorBankConnecting(true);
    };

    //handle the dashboard click event
    const handleDashboardClick = (): void => {
        setIsLoading(false);
        history.push({
            pathname: '/dashboard',
            state: {
                updateAccessToken: true,
                bankConnectPage: true,
                isILKyc: false,
            },
        })
    };

    const isLoadingFunction = (): void => {
        setIsLoading(true)
    }

    const openPlaid = (): void => {
        setErrorBankConnecting(false)
    }

    const dummyFunction = (): void => { }


    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }


    return (
        <>
            {isLoading && <LoadingComponent />}

            <div className="gi-ob-body-main">
                <section className="gi-ob-confirm-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-ob-white-logo">
                                    <img src={intl.formatMessage({ id: 'WHITE_LOGO_GIRAFFE' })} alt="WHITE_LOGO_GIRAFFE" />
                                </div>
                                <div className="gi-ob-rock-card">
                                    <div className="gi-ob-bank-text">
                                        <h5>{IntlMessages('bank_connect_text1')}</h5>
                                        {errorBankConnecting &&
                                            <ErrorKYC
                                                message={errorMessage}
                                                fieldName={'errorMessage'}
                                            />
                                        }
                                        <PlaidComponent
                                            className={'gi-kyc-connect-btn'}
                                            isLoading={isLoadingFunction}
                                            onHandleClose={dummyFunction}
                                            componentDidMount={handleDashboardClick}
                                            buttonName={IntlMessages('button_continue')}
                                            userName={userName}
                                            openPlaid={openPlaid}
                                            handleErrorModal={handlePlaidError}
                                            plaidData={plaidData}
                                            tokenGenerate={dummyFunction}
                                        />
                                        <span onClick={handleDashboardClick} data-testid="bank-connect">
                                            {IntlMessages('bank_connect_text2')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <KycCompleteFooter />
            </div>
        </>
    );
};

export default BankConnect;


