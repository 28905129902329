//default library
import * as  React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
// message import
import { useIntl } from 'react-intl';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types props
import * as PropsFunction from "interface/PropsFunction";

export type Props = {
    placeholder: string;
    popoverMessage: string;
    name: string;
    dataTestId: string;
    maxLength: number;
    value: string;
    inputClick: PropsFunction.SimpleFunction;
    inputValue: PropsFunction.ParamsIdStringFunction;
}

const InputComponentOverlay: React.FC<Props> = (props: Props) => {
    const { inputClick, inputValue, placeholder, dataTestId, popoverMessage, name, maxLength, value } = props; //destructuring props

    const [isPopoverVisible, setIsPopoverVisible] = React.useState<boolean>(false);
    const intl = useIntl();

    //handle onChange event
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        inputValue(e.target.value);
    };

    //handle click
    const handleInputClick = (): void => {
        inputClick();
        setIsPopoverVisible(!isPopoverVisible);
    };

    return (
        <>
            <OverlayTrigger
                trigger="click"
                rootClose
                overlay={
                    <Popover id="tooltip-disabled" className="max-popover">
                        <Popover.Body>
                            {IntlMessages(popoverMessage)}
                        </Popover.Body>
                    </Popover>
                }
            >
                <input
                    type="text"
                    maxLength={maxLength}
                    className="gi-p-name-popover"
                    placeholder={intl.formatMessage({ id: placeholder })}
                    name={name}
                    value={value}
                    onChange={handleOnChange}
                    onClick={handleInputClick}
                    data-testid={dataTestId}
                />
            </OverlayTrigger>
        </>
    );
};

export default InputComponentOverlay;








