// default library
import * as React from 'react';
// type import
import * as DefaultType from 'interface/DefaultValueType';
import * as PropsFunction from "interface/PropsFunction";

export type Props = {
    name: string;
    checkValue: string;
    inputClass: string;
    labelClass: string;
    divClassName: string;
    isSimpleRadio: boolean;
    radioOption: DefaultType.Options;
    dataTestId: string;
    inputValue: PropsFunction.PropsInputValueFunction;
}

const InputRadioComponent: React.FC<Props> = (props: Props) => {

    const { checkValue, name, radioOption, divClassName, dataTestId, inputClass, labelClass, isSimpleRadio, inputValue } = props;

    // function for input value changes
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const target = e.target
        inputValue(target.name, target.value);// Call the inputValue function with the updated value
    };

    return (
        <>
            {radioOption.map((e, i) => {
                const checked = e.value === checkValue;
                if (isSimpleRadio) {
                    return (
                        <React.Fragment key={i}>
                            <input
                                type='radio'
                                name={name} //set field name
                                id={`${e.id}-${name}`} //set field name
                                value={e.value} //set field value
                                checked={checked}
                                data-testid={dataTestId}
                                className={`${inputClass}`}
                                onChange={handleOnChange} //invoke on every key press
                            />
                            <label className={`${labelClass}`} htmlFor={`${e.id}-${name}`}>
                                {e.label}
                            </label>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <div className={divClassName} key={i}>
                            <input
                                type='radio'
                                name={name} //set field name
                                id={`${e.id}-${name}`} //set field name
                                value={e.value} //set field value
                                checked={checked}
                                data-testid={dataTestId}
                                className={`form-check-input mt-0 ${inputClass}`}
                                onChange={handleOnChange} //invoke on every key press
                            />
                            <label className={` ${labelClass}`} htmlFor={`${e.id}-${name}`}>
                                {e.label}
                            </label>
                        </div>
                    )
                }
            })}
        </>
    )
}

export default InputRadioComponent