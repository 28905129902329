// default library
import * as React from 'react';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';

export type Props = {
    isDisabled: boolean;
    isSendButton: boolean;
    continueClick: PropsFunction.SimpleFunction
}

const IsraelKYCButton: React.FC<Props> = (props: Props) => {

    const { isDisabled, isSendButton, continueClick } = props;
    const intl = useIntl();

    return (
        <div className="gi-ob-continue-box text-center">
            <button type="submit" className="gi-ob-continue-btn" onClick={continueClick} disabled={isDisabled} data-testid='continue-button'>
                <span className="icon">
                    <img src={intl.formatMessage({ id: 'IL_ONBOARDING_LEFT_ICON' })} alt="IL_ONBOARDING_LEFT_ICON" />
                </span>
                <span className="text">
                    {IntlMessages(isSendButton ? 'button_send' : 'btn_continued')}
                </span>
            </button>
        </div>
    )
}

export default IsraelKYCButton