//age calculator
export default function AgeCalculator(dateOfBirth: Date): number {
    const newDOB = new Date(dateOfBirth)
    newDOB.setDate(dateOfBirth.getDate() + 2); //add one day

    const dateNow = new Date() //store current date
    //check current year is leap 
    const isLeap = (year: number) => {
        return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
    }

    //get days of month
    let days = Math.floor((dateNow.getTime() - newDOB.getTime()) / 1000 / 60 / 60 / 24);

    //set default age is 0
    let age = 0;

    for (let y = newDOB.getFullYear(); y <= dateNow.getFullYear(); y++) {
        const daysInYear = isLeap(y) ? 366 : 365;
        if (days >= daysInYear) {
            days -= daysInYear;
            age++;
        }
    }
    return age
}