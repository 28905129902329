//default library
import * as  React from 'react'
import Modal from 'react-bootstrap/Modal'
//auth0 library
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as BasketService from "middleware/BasketService"
// intl message library 
import { useIntl } from 'react-intl';
// currency formatter
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
//prefect scroll bar react
import ScrollBar from "react-perfect-scrollbar";
// device detect library
import { isMobile } from "react-device-detect";
import "react-perfect-scrollbar/dist/css/styles.css";
// custom component
import CompanyListModal from 'components/ModalComponent/CompanyListModal';
import LoadingModal from 'components/LoadingComponent/LoadingModal';
//types props
import * as BasketType from "api/APIMaster/BasketType"
import * as DashboardType from "api/APIMaster/DashboardType"
import * as PropsFunction from "interface/PropsFunction";
// message import
import IntlMessages from 'helper/IntlMessages';

//defining props
export type Props = {
    acceptModalOpen: boolean;
    userExcludedList: string[];
    getGiftReceive: DashboardType.GiftDetailResponseModel;
    selectedEsgcValueList: BasketType.GiftEsgcValueRequestModel[];
    esgcValueList: BasketType.BasketCustomizationEsgcValueResponseModel[];
    redeemGift: PropsFunction.SimpleFunction;
    onHandleClose: PropsFunction.SimpleFunction;
}

const GiftAcceptModal: React.FC<Props> = (props: Props) => {

    const intl = useIntl();
    const { initiateAction } = useAccessToken();

    const { acceptModalOpen, userExcludedList, getGiftReceive, selectedEsgcValueList, esgcValueList, redeemGift, onHandleClose } = props; //destructuring props

    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs
    const [errorAPI, setErrorAPI] = React.useState<boolean>(false);//error occurred flag

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading flag

    const [totalCompanyListModal, setTotalCompanyListModal] = React.useState<boolean>(false);//total company list modal
    const [totalCompanyList, setTotalCompanyList] = React.useState<BasketType.BasketCompanyResponseModel[]>([]);//total company list

    React.useEffect(() => {
        setTotalCompanyList([])
    }, [userExcludedList, selectedEsgcValueList])

    //API Call
    const companyBluePrintAPI = async (): Promise<void> => {

        if (totalCompanyList.length === 0) {
            setIsLoading(true);

            const summaryData = {
                selectedEsgcValueList: selectedEsgcValueList,
                userExcludedList: userExcludedList,
            };

            const accessToken = await initiateAction();
            const companyBlueprint = await BasketService.getBasketBlueprint(summaryData, accessToken);
            setIsLoading(false);

            if (companyBlueprint.response || companyBlueprint.request) {
                setErrorLog(companyBlueprint);
                setErrorAPI(true);
            }
            else {
                setTotalCompanyList(
                    companyBlueprint.map((e: { name: string; tickerSymbol: string; weight: number; industry: string; }) => ({
                        companyName: e.name,
                        tickerSymbol: e.tickerSymbol,
                        weight: e.weight,
                        industry: e.industry,
                        value: 0,
                    }))
                );
            }
        }
    };

    //handle close modal
    const onHandleCloseModal = (): void => {
        setTotalCompanyListModal(false)
        onHandleClose()
    }
    //handle company modal
    const totalCompanyListModalFunction = (): void => {
        setTotalCompanyListModal(true)
        companyBluePrintAPI()
    }

    const subScrollData: JSX.Element = <>
        <div className="all-set-green-esgc-header pb-lg-4 pt-lg-2 pb-0 pt-3">
            <h5>{IntlMessages('gift_redeem_accept_text5')}</h5>
        </div>
        {/* ESG-C value list */}
        <div className="all-set-green-esgc-body d-flex flex-column gap-lg-4 gap-0">
            {esgcValueList.map((e, i) => {
                const selectedEsgc = selectedEsgcValueList.find((a) => a.esgcValueIdentifier === e.identifier);
                if (selectedEsgc) {
                    return (
                        <div className="all-set-green-esgc-value-card d-flex align-items-center gap-2 py-lg-1 py-2" key={i}>
                            {/* image */}
                            <div className="all-set-green-esgc-value-img py-lg-0 py-1">
                                <img src={e.icon} className="img-fluid" alt="" />
                            </div>
                            {/* name */}
                            <div className="all-set-green-esgc-value-text ps-1 py-lg-0 py-1" data-testid="value-name">
                                <h6>{e.name}</h6>
                            </div>
                        </div>
                    );
                }
            })}
        </div>
    </>

    const scrollData: JSX.Element = <>
        <div className="all-set-green-main py-lg-5 px-lg-3 pt-4 px-2">
            <div className="row">
                {/* amount and name */}
                <div className="col-lg-6">
                    <div className="all-set-green-gift-card position-relative pb-lg-0 pb-3">
                        <div className="row">
                            <div className="col-12">
                                <div className="all-set-green-gift-img position-relative">
                                    <img src={intl.formatMessage({ id: 'GIFT_CARD_ALL_SET' })} className="img-fluid" alt="GIFT_CARD_ALL_SET" />
                                    <span data-testid="gift-amount">${getGiftReceive.amount}</span>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="all-set-green-gift-main d-flex flex-column gap-lg-4 gap-2 ps-lg-5 ps-3">
                                    {/* amount */}
                                    <div className="all-set-green-gift-text d-lg-block d-flex align-items-center gap-1">
                                        <span>{IntlMessages('gift_amount')} </span>
                                        <h5 className="ltr" data-testid="gift-currency-amount">{currencyFormatter(getGiftReceive.amount)}</h5>
                                    </div>
                                    {/* giver name */}
                                    <div className="all-set-green-gift-text d-lg-block d-flex align-items-center gap-1">
                                        <span>{IntlMessages('gift_card_by')}</span>
                                        <h6 data-testid="gift-giver-name">{getGiftReceive.giverName}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className="col-lg-6">
                    {isMobile
                        ? <div className='all-set-green-esgc-card px-lg-0 px-3 scroll-thumb' >
                            {subScrollData}
                        </div>
                        : <ScrollBar component="div" className='all-set-green-esgc-card px-lg-0 px-3'>
                            {subScrollData}
                        </ScrollBar>
                    }
                </div>
            </div>
        </div>
    </>

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {/* Redeemed Modal */}
            <Modal
                size='xl'
                centered
                show={acceptModalOpen && !totalCompanyListModal}
                onHide={onHandleCloseModal}
                backdrop="static"
                keyboard={false}
                className="all-set-modal"
                contentClassName='border-0'
            >
                <Modal.Body className="p-lg-4 ps-lg-5 p-1 pt-5 position-relative">
                    <button type="button"
                        className="btn-close border-0 opacity-100 position-absolute"
                        onClick={onHandleCloseModal} data-testid='close-modal'>
                    </button>
                    <div className="row gx-5">
                        {/* text */}
                        <div className="col-lg-4 order-lg-0 order-1">
                            <div className="d-flex align-items-center ps-lg-2 py-lg-5 h-100">
                                <div className="all-set-confirm-text p-lg-0 pb-lg-5 p-4 text-lg-start text-center w-100">
                                    <h4>{IntlMessages('gift_redeem_accept_text1')}</h4>
                                    <h6 className="mt-lg-2 mt-4" data-testid="total-company-list">
                                        <a href='#!' onClick={totalCompanyListModalFunction}>
                                            {IntlMessages('gift_redeem_accept_text2')}
                                        </a>
                                        &nbsp;{IntlMessages('gift_redeem_accept_text3', { br: <br /> })}
                                    </h6>
                                    <button
                                        type="button"
                                        className="d-flex align-items-center justify-content-center gap-2 border-0 mt-4 mx-lg-0 mx-auto"
                                        onClick={redeemGift}
                                    >
                                        <i className="check-icon d-block"></i> {IntlMessages('button_confirm')}
                                    </button>
                                    <span className="d-lg-flex d-none align-items-center justify-content-lg-start justify-content-center gap-2 mt-4 pt-2">
                                        <img src={intl.formatMessage({ id: 'WATCH_ICON' })} alt="WATCH_ICON" />
                                        {IntlMessages('gift_redeem_modal_text2')}
                                    </span>
                                    <p className="d-lg-block d-none mt-3">
                                        {IntlMessages('gift_redeem_accept_text4')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* ESG-C value list */}
                        <div className="col-lg-8 order-lg-1 order-0">
                            <div className="all-set-green-card">
                                {isMobile
                                    ? <div className="content overflow-auto scroll-thumb">
                                        {scrollData}
                                    </div>
                                    : <ScrollBar component="div" className="content">
                                        {scrollData}
                                    </ScrollBar>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* total company list */}
            <CompanyListModal
                companyListModal={acceptModalOpen && totalCompanyListModal && !isLoading}
                totalCompanyTable={true}
                companyList={totalCompanyList}
                companyCount={totalCompanyList.length}
                onHandleClose={onHandleCloseModal}
                excludedCompanyTable={false}
                addedCompanyTable={false} />

            {/* loading modal */}
            {(acceptModalOpen && totalCompanyListModal && isLoading) && (
                <LoadingModal
                    openLoadingModal={true}
                    title={''}
                    onHandleClose={onHandleCloseModal}
                />
            )}
        </>
    );

}

export default GiftAcceptModal
