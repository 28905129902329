// default library
import * as React from 'react';
//import useIntl hook
import { useIntl } from 'react-intl';
//config
import { ONLY_NUMBER, ONLY_NUMBER_DECIMAL } from 'config/Config';
//type props
import * as PropsFunction from "interface/PropsFunction";

//defining  props
export type Props = {
    placeholder: string;
    name: string;
    value: number | string;
    className: string;
    isDecimalAllow: boolean;
    inputValue: PropsFunction.ParamsIdStringFunction;
}

const InputComponentCurrency: React.FC<Props> = (props: Props) => {
    const { placeholder, name, value, className, isDecimalAllow, inputValue } = props;
    const intl = useIntl();

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        // Check for only number and set to the field
        const valueInput = e.target.value;
        let replaceValue = '';
        if (isDecimalAllow) {
            const isValue = valueInput.match(ONLY_NUMBER_DECIMAL)
            replaceValue = isValue ? isValue[0] : '';

        } else {
            replaceValue = valueInput.replace(ONLY_NUMBER, '');
        }
        inputValue(replaceValue);
    };

    return (
        <>
            <input
                type='text'
                className={`form-control form-control-lg ${className}`}
                placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
                name={name}
                value={value}
                onChange={handleOnChange}
            />
            {(name !== 'sellAmount' || value) && <span className="gi-symbol">$</span>}
        </>
    );
}

export default InputComponentCurrency;