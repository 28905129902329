// default library
import * as React from 'react';
// intl message
import { useIntl } from 'react-intl';
// type import
import * as PropsFunction from "src/interface/PropsFunction";

export type Props = {
    name: string;
    placeholder: string;
    className: string;
    fieldValue: string;
    dataTestId: string;
    inputValue: PropsFunction.PropsInputValueFunction;
}

const TextAreaComponent2: React.FC<Props> = (props: Props) => {

    const { name, placeholder, className, fieldValue, inputValue, dataTestId } = props
    const intl = useIntl();

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const target = e.target
        inputValue(target.name, target.value);
    };

    return (
        <textarea
            name={name}
            id={name}
            placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''}
            className={className}
            value={fieldValue}
            data-testid={dataTestId}
            onChange={handleOnChange}
        />
    );
};

export default TextAreaComponent2;
