// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// api call
import * as UserService from 'middleware/UserService';
// react redux
import { useSelector, useDispatch } from 'react-redux';
import { getAppCommonData, setCurrentAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';

const KYCPending: React.FC = () => {

    const { initiateAction } = useAccessToken();
    const history = useHistory();
    const intl = useIntl();

    const dispatch = useDispatch()
    const appCommonData = useSelector(getAppCommonData);

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    React.useEffect(() => {

        const reloadPage = async (): Promise<void> => {
            const accessToken = await initiateAction() //get access token
            const userDetails = await UserService.getDetail(accessToken); //user details api call

            if (userDetails.response || userDetails.request) {
                setErrorLog(userDetails)
                setIsError(true)
            } else {

                dispatch(setCurrentAppCommonData({
                    ...appCommonData,
                    isKycRejected: userDetails.isInternalKycRejected,
                    isKycProcessing: userDetails.isInternalKycPending,
                    isKycManualReview: userDetails.isInternalKycManualReview,
                    isKycDenied: userDetails.isInternalKycDenied,
                    popUpCounter: 1, //increment the pop up counter
                }));

                if (userDetails.isInternalKycDenied) {
                    history.push('/on-boarding/kyc-denied');
                } else if (userDetails.isInternalKycRejected) {
                    history.push('/on-boarding/kyc-reject');
                } else if (userDetails.isInternalKycPending) {
                    history.push('/on-boarding/kyc-pending');
                } else if (userDetails.isInternalKycManualReview) {
                    history.push('/on-boarding/kyc-review');
                } else {
                    history.push('/on-boarding/kyc-success');
                }
            }
        }

        const intervalId = setInterval(reloadPage, 10000); // call reload function every 10 sec
        // Cleanup function 
        return () => {
            clearInterval(intervalId);
        };
    }, [])

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="gi-ob-magnificent-text text-center">
                        <h5>{IntlMessages('kyc_pending_magnificent')}</h5>
                        <h6 className="mt-3 gi-ob-upload-text">
                            {IntlMessages('kyc_pending_question1')}
                        </h6>
                        <p className="mt-2">
                            {IntlMessages('kyc_pending_question2')}
                        </p>
                        <div className="text-center mt-4">
                            <img src={intl.formatMessage({ id: 'SPINNER_ON_BOARDING' })} alt="SPINNER_ON_BOARDING" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KYCPending