// default library
import * as React from 'react';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as DashboardType from 'api/APIMaster/DashboardType';
// custom component
import MonthlyEmailModal from 'components/ModalComponent/MonthlyEmailModal';
import MonthlySavingSetupModal from 'components/ModalComponent/MonthlySavingSetupModal';
import MonthlySuccessModal from 'components/ModalComponent/MonthlySuccessModal';
import LoadingComponent2 from 'components/LoadingComponent/LoadingComponent2';

export type Props = {
    isModalOpen: boolean;
    loadingSavingModal: boolean;
    organizationName: string | null;
    deductionDate: number;
    defaultCurrency: string | null;
    getFundLocation: DashboardType.GetFundLocationsData;
    isKycPending: boolean;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleSuccessEmail: PropsFunction.SimpleFunction;
    onHandleSuccess: PropsFunction.SimpleFunction;
    onHandleNotListEmail: PropsFunction.SimpleFunction;
}

const MonthlySavingModals: React.FC<Props> = (props: Props) => {
    const { isModalOpen, loadingSavingModal, organizationName, deductionDate, defaultCurrency, getFundLocation, isKycPending, onHandleClose, onHandleSuccessEmail, onHandleSuccess, onHandleNotListEmail } = props;

    const [emailSetUpModal, setEmailSetUpModal] = React.useState<boolean>(false); //open monthly saving setup modal
    const [savingModal, setSavingModal] = React.useState<boolean>(false); //open monthly saving setup modal
    const [successModal, setSuccessModal] = React.useState<boolean>(false); //open monthly saving success modal

    /**
     * Open close saving modal
     * open saving modal when
     * saving modal flag is true and
     * modal open flag is true
     */
    React.useEffect(() => {
        setEmailSetUpModal(isModalOpen && !organizationName); //open email setup modal when isModalOpen=true && isSavingModal=false
        setSavingModal(isModalOpen && organizationName !== null); //open monthly saving setup modal when isModalOpen=true && isSavingModal=true
        setSuccessModal(false);
    }, [isModalOpen])

    /**
     * close email setup modal
     * open monthly saving setup modal
     */
    const onHandleSavingModal = (): void => {
        setSavingModal(true); //open monthly saving setup modal
        setEmailSetUpModal(false); //close email setup modal
    }
    /**
     * close monthly saving setup modal
     * open success modal
     */
    const onHandleSuccessSavingSetup = (): void => {
        setSavingModal(false); //close monthly saving setup modal
        setSuccessModal(true); //open success modal
    }

    return (
        <>
            {/* email setup modal */}
            <MonthlyEmailModal
                openModal={emailSetUpModal}
                onHandleClose={onHandleClose}
                onHandleSavingModal={onHandleSavingModal}
                onHandleSuccessEmail={onHandleSuccessEmail}
                onHandleNotListEmail={onHandleNotListEmail}
            />

            {/* monthly saving modal */}
            <MonthlySavingSetupModal
                openModal={savingModal}
                organizationName={organizationName}
                deductionDate={deductionDate}
                defaultCurrency={defaultCurrency}
                getFundLocation={getFundLocation}
                onHandleClose={onHandleClose}
                onHandleSuccess={onHandleSuccessSavingSetup}
            />

            {/* success modal */}
            <MonthlySuccessModal
                openModal={successModal}
                isKycPending={isKycPending}
                deductionDate={deductionDate}
                onHandleClose={onHandleSuccess}
            />

            {/* loading modal */}
            <LoadingComponent2
                openModal={loadingSavingModal && savingModal && !emailSetUpModal}
                onHandleClose={onHandleClose}
            />
        </>
    )
}

export default MonthlySavingModals