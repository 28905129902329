import GIRAFFE_VIEW_ACTIVE from 'images/giraffe-view-active.svg';
import DASHBOARD_LOGO from 'images/dashboard-logo.svg';
import LOGO_DASHBOARD from 'images/logo.svg';
import BRIGHT_RIGHT_ICON from 'images/bright-right-icon.svg';
import WHITE_RIGHT_ICON from 'images/white-right-icon.svg';
import WHITE_PLAY_ICON from 'images/white-play-icon.svg';
import WHITE_NEXT_ICON from 'images/white-next-icon.svg';
import BRIGHT_PLAY_ICON from 'images/bright-play-icon.svg';
import CALENDER_ICON from 'images/calendar-icon.svg';
import EXPERT_ACHIEVEMENT from 'images/expert-achievements.svg';
import DELETE_ICON from 'images/delete-icon.svg';
import PDF_ICON from 'images/pdf-icon.svg';
import DOWN_ARROW_BLACK from 'images/down-arrow-black.svg';
import UP_ARROW_BLACK from 'images/up-arrow-black.svg';
import DOWN_ARROW_WHITE from 'images/down-arrow-white.svg';
import UP_ARROW_WHITE from 'images/up-arrow-white.svg';
import GIRAFFE_SMALL_LOGO from 'images/girafee-small-img.svg';
import BACKGROUND_404 from 'images/bg-404.svg';
import BACKGROUND_404_MOB from 'images/bg-404-mob.svg';
import TIME_OUT_LOGO from 'images/time-out-logo.svg';
import NEW_GIRAFFE_LOGO2 from 'images/logo-giraffe.svg';
import LINE_CHART from 'images/line-chart.svg';
import UNLINK_BANK_SVG from 'images/transaction-animation.svg';
import GIRAFFE_MODAL_ICON from 'images/modal-giraffe-icon.svg';
import BOOST_UP from 'images/boost-up.svg';
import MINI_MBA_CAP from 'images/cap-green.svg';
import WHITE_GIRAFFE_ERROR from 'images/white-giraffe-error.svg';
import LEFT_WHITE_ARROW from 'images/left-arrow-white-icon.svg';
import MY_IMPACT_SUSTAINABLE_2 from 'images/sustainable-climate-02.svg';
import HOME_ICON from 'images/nav-icon/home.svg';
import LINE_CHART_ICON from 'images/nav-icon/line-chart.svg';
import LEARN_ICON from 'images/nav-icon/learn.svg';
import GIFT_ICON from 'images/nav-icon/gift.svg';
import MY_IMPACT from 'images/nav-icon/my-impact.svg';
import HOME_ICON_ACTIVE from 'images/nav-icon/home-active.svg';
import LINE_CHART_ICON_ACTIVE from 'images/nav-icon/line-chart-active.svg';
import LEARN_ICON_ACTIVE from 'images/nav-icon/learn-active.svg';
import GIFT_ICON_ACTIVE from 'images/nav-icon/gift-active.svg';
import MY_IMPACT_ACTIVE from 'images/nav-icon/my-impact-active.svg';
import FINISH_SETUP_ICON from 'images/nav-icon/finish-setup-icon.svg';
import WATCH_ICON from 'images/gift/watch-icon.svg';
import SUBSCRIPTION_GIRAFFE from 'images/emailSubscription/unsubscribe-giraffe-and-heart.svg';
import LEARN_CARD_BG from 'images/learn-card-bg.png';
import GIRAFFE_FRAME from 'images/giraffe-frame.png';
import DRIVE_WEALTH_LOGO from 'images/drive-wealth-logo.png';
import RIGHT_IMAGE_BANNER from 'images/right-image-basket.png';
import PENDING_LINE_CHART_PNG from 'images/pending-line-chart-grey.png';
import SP_WEIGHT from 'images/sp-weight.png';
import GIFT_PENDING_CARD from 'images/gift/gift-card.png';
import HOURGLASS_ANIMATION from 'images/gif/hourglass-animation.gif';
import BANK_CONNECT_ANIMATION from 'images/gif/bank-connect-green-animation.gif';
import DOWN_ARROW_RED_ICON from 'images/icons/down-arrow-red.svg';
import UP_ARROW_WHITE_ICON from 'images/icons/up-arrow-white.svg';
import PLUS_INACTIVE_ICON from 'images/icons/plus-inactive.svg';
import PLUS_ACTIVE_ICON from 'images/icons/plus-active.svg';
import BAR_CHART_INACTIVE_ICON from 'images/icons/bar-chart-inactive.svg';
import BAR_CHART_ACTIVE_ICON from 'images/icons/bar-chart-active.svg';
import TABLE_CHART_INACTIVE_ICON from 'images/icons/table-chart-inactive.svg';
import TABLE_CHART_ACTIVE_ICON from 'images/icons/table-chart-active.svg';
import LINE_CHART_INACTIVE_ICON from 'images/icons/line-chart-inactive.svg';
import LINE_CHART_ACTIVE_ICON from 'images/icons/line-chart-active.svg';
import GIRAFFE_FREE_ICON from 'images/icons/giraffe-free-icon.svg';
import ADDED_COMPANIES_ICON from 'images/icons/added-companies-icon.svg';
import REMOVED_COMPANIES_ICON from 'images/icons/removed-companies-icon.svg';
import LINKEDIN_ICON from 'images/icons/linkedin-icon.svg';
import FACEBOOK_ICON from 'images/icons/facebook-icon.svg';
import TIMER_WATCH_ICON from 'images/icons/timer-watch-icon.svg';
import LG_INFO_ICON from 'images/icons/lg-info-icon.svg';
import ELLIPSIS_GREEN_ICON from 'images/icons/three-line-active.svg';
import ELLIPSIS_GREY_ICON from 'images/icons/three-line-inactive.svg';
import RIGHT_GREEN_ARROW from 'images/icons/right-green-arrow.svg';
import RIGHT_WHITE_ARROW from 'images/icons/right-white-arrow.svg';
import ACCOUNT_SETTING_INACTIVE_MOB from 'images/icons/setting-icon-inactive.svg';
import ACCOUNT_SETTING_ACTIVE_MOB from 'images/icons/setting-icon-active.svg';
import LOG_OUT_INACTIVE from 'images/icons/log-out-inactive.svg';
import LOG_OUT_ACTIVE from 'images/icons/log-out-active.svg';
import TROUBLE_ICON from 'images/icons/trouble-icon.svg';
import PIE_CHART_NEW from 'images/new-portfolio/pie-chart.png';
import SPINNER_IMG from 'images/new-portfolio/spinner-card.png';
import INFO_ICON_PORTFOLIO from 'images/new-portfolio/info-i-icon.svg';
import GREEN_ARROW_RIGHT from 'images/new-portfolio/green-arrow-right.svg';
import WHITE_ARROW_RIGHT from 'images/new-portfolio/white-arrow-right.svg';
import SCORED_VALUE_IMG from 'images/new-portfolio/scrored-value.svg';
import SUSTAINABLE_DESKTOP from 'images/esgc-bg/sustainable-climate.jpg';
import SUSTAINABLE_MOBILE from 'images/esgc-bg/sustainable-climate-mobile.jpg';
import GIFT_GIRAFFE_LOGO from 'images/gift/logo.svg';
import GIFT_GREEN_MAP from 'images/gift/green-map.svg';
import GIFT_NEW_CARD from 'images/gift/gift-new-card.png';
import GIFT_LEARN from 'images/gift/gift-learn-img-02.png';
import GIFT_CARD_ALL_SET from 'images/gift/gift-card-all-set.png';
import GIFT_LEARN_XS from 'images/gift/gift-learn-img-02-xs.png';
import DOWN_WHITE_ARROW from 'images/gift/down-white-arrow.svg';
import GLOBE_WHITE_ICON from 'images/gift/globe-white-icon.svg';
import GIRAFFE_ICON_EMPTY from 'images/gift/gift-icon.svg';
import REDEEM_GIFT_CARD from 'images/gift/redeem-gift-card-img.png';
import REDEEM_GIFT_CARD_XS from 'images/gift/redeem-gift-card-img-xs.png';
import DASHBOARD_LEARN from 'images/dashboard/dash-learn-img.png';
import DASHBOARD_LEARN_XS from 'images/dashboard/dash-learn-img-xs.png';
import DASHBOARD_GIFT_IMG from 'images/dashboard/dashboard-gift-img.png';
import DASHBOARD_GIFT_IMG_MOB from 'images/dashboard/dashboard-gift-img-mob.png';
import GIFT_CARD_DASHBOARD from 'images/dashboard/gift-card-dashboard.png';
import GIFT_CARD_DASHBOARD_XS from 'images/dashboard/gift-card-dashboard-xs.png';
import INFO_LIGHT_GREEN_ICON from 'images/dashboard/info-light-green-icon.svg';
import GIRAFFE_ICON from 'images/dashboard/giraffe-icon.svg';
import NOTIFICATION_CIRCLE from 'images/dashboard/circle-bell-icon.svg';
import GIRAFFE_FUND_ICON from 'images/dashboard/giraffe-fund-icon.svg';
import PAY_CHECK from 'images/on-boarding/pay-check.svg';
import PAY_CHECK_XS from 'images/on-boarding/pay-check-xs.svg';
import KYC_ONBOARDING_LOGO from 'images/on-boarding/logo-onboarding.svg';
import LOCK_ICON_GREEN from 'images/on-boarding/lock-icon-green.svg';
import LOCK_ICON_WHITE from 'images/on-boarding/lock-icon.svg';
import RANGE_LINE from 'images/on-boarding/range-line.svg';
import RANGE_LINE_XS from 'images/on-boarding/range-line-xs.svg';
import WHITE_LOGO_GIRAFFE from 'images/on-boarding/white-logo-onboarding.svg';
import SPINNER_ON_BOARDING from 'images/on-boarding/spinner.svg';
import NEW_GIFT_CARD from 'images/on-boarding/new-gift-card-img.png';
import INFO_GREY_ICON from 'images/on-boarding/info-grey-icon.svg';
import GIRAFFE_LOGO_NEW from 'images/header/new-header-logo.svg';
import GIRAFFE_LOGO_NEW_MOB from 'images/header/new-header-logo-mob.svg';
import GIRAFFE_LOGO_NEW_FOOTER from 'images/header/new-footer-logo.svg';
import DASHBOARD_HEADER_LOGO from 'images/header/dashboard-header-logo.svg';
import DASHBOARD_HEADER_LOGO_MOB from 'images/header/dashboard-header-logo-mob.svg';
import GREEN_RIGHT from 'images/icons/green-right.svg';
import GREY_WRONG from 'images/icons/grey-wrong.svg';
import IL_ONBOARDING_BACK_INACTIVE from 'images/on-boarding-il/right-icon-inactive.svg';
import IL_ONBOARDING_BACK_ACTIVE from 'images/on-boarding-il/right-icon-active.svg';
import IL_ONBOARDING_CLOSE_INACTIVE from 'images/on-boarding-il/close-icon-inactive.svg';
import IL_ONBOARDING_CLOSE_ACTIVE from 'images/on-boarding-il/close-icon-active.svg';
import IL_ONBOARDING_GIRAFFE from 'images/on-boarding-il/giraffe-logo.svg';
import IL_ONBOARDING_GIRAFFE_WHITE from 'images/on-boarding-il/giraffe-logo-white.svg';
import IL_ONBOARDING_LEFT_ICON from 'images/on-boarding-il/left-icon.svg';
import IL_ONBOARDING_SUCCESS_CHECK from 'images/on-boarding-il/success-check-icon.svg';
import IL_ONBOARDING_INFO_GREY from 'images/on-boarding-il/info-icon-grey.svg';
import IL_ONBOARDING_PDF_INFO from 'images/on-boarding-il/pdf-icon.svg';
import IL_ONBOARDING_CHECK_GREEN from 'images/on-boarding-il/check-green-icon.svg';
import IL_ONBOARDING_CHECK_RED from 'images/on-boarding-il/close-red-icon.svg';
import IL_ONBOARDING_GIRAFFE_HEART from 'images/on-boarding-il/heart-with-giraffe.svg';
import BOTTOM_ARROW_GREEN from 'images/dashboard/bottom-arrow-green-circle.svg';
import BOTTOM_ARROW_RED from 'images/dashboard/bottom-arrow-red-circle.svg';
import LOCK_ICON_GREEN_WITHDRAW from 'images/dashboard/lock-green-icon.svg';
import LOCK_ICON_RED_WITHDRAW from 'images/dashboard/lock-red-icon.svg';
import GIRAFFE_ICON_WITHDRAW from 'images/dashboard/giraffe-icon-withdraw.svg';
import BLACK_SMILEY_ICON from 'images/dashboard/black-smily-icon.svg';
import INFO_GREEN_ICON from 'images/dashboard/info-icon-green.svg';
import UP_ARROW_RED_CIRCLE from 'images/dashboard/up-arrow-red-circle.svg';
import GIRAFFE_WITH_HEART from 'images/dashboard/giraffe-wit-heart-new.svg';
import IL_LINKEDIN_ICON_CURVE_DESK from 'images/icons/linkedin-icon-curve-il.svg';
import IL_LINKEDIN_ICON_MOB from 'images/icons/linkedin-icon-il.svg';
import IL_QUORA_ICON from 'images/icons/quora-icon.svg';
import IL_THREAD_ICON from 'images/icons/thread-icon.svg';

const IMAGES: { [key: string]: string } = {
    /* SVG */
    GIRAFFE_VIEW_ACTIVE: GIRAFFE_VIEW_ACTIVE,
    DASHBOARD_LOGO: DASHBOARD_LOGO,
    LOGO_DASHBOARD: LOGO_DASHBOARD,
    BRIGHT_RIGHT_ICON: BRIGHT_RIGHT_ICON,
    WHITE_RIGHT_ICON: WHITE_RIGHT_ICON,
    WHITE_PLAY_ICON: WHITE_PLAY_ICON,
    WHITE_NEXT_ICON: WHITE_NEXT_ICON,
    BRIGHT_PLAY_ICON: BRIGHT_PLAY_ICON,
    CALENDER_ICON: CALENDER_ICON,
    EXPERT_ACHIEVEMENT: EXPERT_ACHIEVEMENT,
    DELETE_ICON: DELETE_ICON,
    PDF_ICON: PDF_ICON,
    DOWN_ARROW_BLACK: DOWN_ARROW_BLACK,
    UP_ARROW_BLACK: UP_ARROW_BLACK,
    DOWN_ARROW_WHITE: DOWN_ARROW_WHITE,
    UP_ARROW_WHITE: UP_ARROW_WHITE,
    GIRAFFE_SMALL_LOGO: GIRAFFE_SMALL_LOGO,
    BACKGROUND_404: BACKGROUND_404,
    BACKGROUND_404_MOB: BACKGROUND_404_MOB,
    TIME_OUT_LOGO: TIME_OUT_LOGO,
    NEW_GIRAFFE_LOGO2: NEW_GIRAFFE_LOGO2,
    LINE_CHART: LINE_CHART,
    UNLINK_BANK_SVG: UNLINK_BANK_SVG,
    GIRAFFE_MODAL_ICON: GIRAFFE_MODAL_ICON,
    BOOST_UP: BOOST_UP,
    MINI_MBA_CAP: MINI_MBA_CAP,
    WHITE_GIRAFFE_ERROR: WHITE_GIRAFFE_ERROR,
    LEFT_WHITE_ARROW: LEFT_WHITE_ARROW,

    // My Impact
    MY_IMPACT_SUSTAINABLE_2: MY_IMPACT_SUSTAINABLE_2,

    // new nav icon svg
    HOME_ICON: HOME_ICON,
    LINE_CHART_ICON: LINE_CHART_ICON,
    LEARN_ICON: LEARN_ICON,
    GIFT_ICON: GIFT_ICON,
    MY_IMPACT: MY_IMPACT,
    HOME_ICON_ACTIVE: HOME_ICON_ACTIVE,
    LINE_CHART_ICON_ACTIVE: LINE_CHART_ICON_ACTIVE,
    LEARN_ICON_ACTIVE: LEARN_ICON_ACTIVE,
    GIFT_ICON_ACTIVE: GIFT_ICON_ACTIVE,
    MY_IMPACT_ACTIVE: MY_IMPACT_ACTIVE,
    FINISH_SETUP_ICON: FINISH_SETUP_ICON,
    WATCH_ICON: WATCH_ICON,

    /* Email Subscription */
    SUBSCRIPTION_GIRAFFE: SUBSCRIPTION_GIRAFFE,

    /* PNG */
    LEARN_CARD_BG: LEARN_CARD_BG,
    GIRAFFE_FRAME: GIRAFFE_FRAME,
    DRIVE_WEALTH_LOGO: DRIVE_WEALTH_LOGO,
    RIGHT_IMAGE_BANNER: RIGHT_IMAGE_BANNER,
    PENDING_LINE_CHART_PNG: PENDING_LINE_CHART_PNG,
    SP_WEIGHT: SP_WEIGHT,
    GIFT_PENDING_CARD: GIFT_PENDING_CARD,

    /* GIF */
    HOURGLASS_ANIMATION: HOURGLASS_ANIMATION,
    BANK_CONNECT_ANIMATION: BANK_CONNECT_ANIMATION,


    /* ICON */
    DOWN_ARROW_RED_ICON: DOWN_ARROW_RED_ICON,
    UP_ARROW_WHITE_ICON: UP_ARROW_WHITE_ICON,
    PLUS_INACTIVE_ICON: PLUS_INACTIVE_ICON,
    PLUS_ACTIVE_ICON: PLUS_ACTIVE_ICON,
    BAR_CHART_INACTIVE_ICON: BAR_CHART_INACTIVE_ICON,
    BAR_CHART_ACTIVE_ICON: BAR_CHART_ACTIVE_ICON,
    TABLE_CHART_INACTIVE_ICON: TABLE_CHART_INACTIVE_ICON,
    TABLE_CHART_ACTIVE_ICON: TABLE_CHART_ACTIVE_ICON,
    LINE_CHART_INACTIVE_ICON: LINE_CHART_INACTIVE_ICON,
    LINE_CHART_ACTIVE_ICON: LINE_CHART_ACTIVE_ICON,
    GIRAFFE_FREE_ICON: GIRAFFE_FREE_ICON,
    ADDED_COMPANIES_ICON: ADDED_COMPANIES_ICON,
    REMOVED_COMPANIES_ICON: REMOVED_COMPANIES_ICON,
    LINKEDIN_ICON: LINKEDIN_ICON,
    FACEBOOK_ICON: FACEBOOK_ICON,
    TIMER_WATCH_ICON: TIMER_WATCH_ICON,
    LG_INFO_ICON: LG_INFO_ICON,
    ELLIPSIS_GREEN_ICON: ELLIPSIS_GREEN_ICON,
    ELLIPSIS_GREY_ICON: ELLIPSIS_GREY_ICON,
    RIGHT_GREEN_ARROW: RIGHT_GREEN_ARROW,
    RIGHT_WHITE_ARROW: RIGHT_WHITE_ARROW,
    ACCOUNT_SETTING_INACTIVE_MOB: ACCOUNT_SETTING_INACTIVE_MOB,
    ACCOUNT_SETTING_ACTIVE_MOB: ACCOUNT_SETTING_ACTIVE_MOB,
    LOG_OUT_INACTIVE: LOG_OUT_INACTIVE,
    LOG_OUT_ACTIVE: LOG_OUT_ACTIVE,
    GREEN_RIGHT: GREEN_RIGHT,
    GREY_WRONG: GREY_WRONG,
    TROUBLE_ICON: TROUBLE_ICON,

    /* PORTFOLIO */
    PIE_CHART_NEW: PIE_CHART_NEW,
    SPINNER_IMG: SPINNER_IMG,
    INFO_ICON_PORTFOLIO: INFO_ICON_PORTFOLIO,
    GREEN_ARROW_RIGHT: GREEN_ARROW_RIGHT,
    WHITE_ARROW_RIGHT: WHITE_ARROW_RIGHT,
    SCORED_VALUE_IMG: SCORED_VALUE_IMG,

    /* MY Impact */
    SUSTAINABLE_DESKTOP: SUSTAINABLE_DESKTOP,
    SUSTAINABLE_MOBILE: SUSTAINABLE_MOBILE,

    /* Gift */
    GIFT_GIRAFFE_LOGO: GIFT_GIRAFFE_LOGO,
    GIFT_GREEN_MAP: GIFT_GREEN_MAP,
    GIFT_NEW_CARD: GIFT_NEW_CARD,
    GIFT_LEARN: GIFT_LEARN,
    GIFT_CARD_ALL_SET: GIFT_CARD_ALL_SET,
    GIFT_LEARN_XS: GIFT_LEARN_XS,
    DOWN_WHITE_ARROW: DOWN_WHITE_ARROW,
    GLOBE_WHITE_ICON: GLOBE_WHITE_ICON,
    GIRAFFE_ICON_EMPTY: GIRAFFE_ICON_EMPTY,
    REDEEM_GIFT_CARD: REDEEM_GIFT_CARD,
    REDEEM_GIFT_CARD_XS: REDEEM_GIFT_CARD_XS,

    /* Dashboard */
    DASHBOARD_LEARN: DASHBOARD_LEARN,
    DASHBOARD_LEARN_XS: DASHBOARD_LEARN_XS,
    DASHBOARD_GIFT_IMG: DASHBOARD_GIFT_IMG,
    DASHBOARD_GIFT_IMG_MOB: DASHBOARD_GIFT_IMG_MOB,
    GIFT_CARD_DASHBOARD: GIFT_CARD_DASHBOARD,
    GIFT_CARD_DASHBOARD_XS: GIFT_CARD_DASHBOARD_XS,
    INFO_LIGHT_GREEN_ICON: INFO_LIGHT_GREEN_ICON,
    GIRAFFE_ICON: GIRAFFE_ICON,
    NOTIFICATION_CIRCLE: NOTIFICATION_CIRCLE,
    BOTTOM_ARROW_GREEN: BOTTOM_ARROW_GREEN,
    BOTTOM_ARROW_RED: BOTTOM_ARROW_RED,
    LOCK_ICON_GREEN_WITHDRAW: LOCK_ICON_GREEN_WITHDRAW,
    LOCK_ICON_RED_WITHDRAW: LOCK_ICON_RED_WITHDRAW,
    GIRAFFE_ICON_WITHDRAW: GIRAFFE_ICON_WITHDRAW,
    GIRAFFE_FUND_ICON: GIRAFFE_FUND_ICON,
    BLACK_SMILEY_ICON: BLACK_SMILEY_ICON,
    INFO_GREEN_ICON: INFO_GREEN_ICON,
    UP_ARROW_RED_CIRCLE: UP_ARROW_RED_CIRCLE,
    GIRAFFE_WITH_HEART: GIRAFFE_WITH_HEART,

    /* On boarding */
    PAY_CHECK: PAY_CHECK,
    PAY_CHECK_XS: PAY_CHECK_XS,
    KYC_ONBOARDING_LOGO: KYC_ONBOARDING_LOGO,
    LOCK_ICON_GREEN: LOCK_ICON_GREEN,
    LOCK_ICON_WHITE: LOCK_ICON_WHITE,
    RANGE_LINE: RANGE_LINE,
    RANGE_LINE_XS: RANGE_LINE_XS,
    WHITE_LOGO_GIRAFFE: WHITE_LOGO_GIRAFFE,
    SPINNER_ON_BOARDING: SPINNER_ON_BOARDING,
    NEW_GIFT_CARD: NEW_GIFT_CARD,
    INFO_GREY_ICON: INFO_GREY_ICON,

    /* Header */
    GIRAFFE_LOGO_NEW: GIRAFFE_LOGO_NEW,
    GIRAFFE_LOGO_NEW_MOB: GIRAFFE_LOGO_NEW_MOB,
    GIRAFFE_LOGO_NEW_FOOTER: GIRAFFE_LOGO_NEW_FOOTER,
    DASHBOARD_HEADER_LOGO: DASHBOARD_HEADER_LOGO,
    DASHBOARD_HEADER_LOGO_MOB: DASHBOARD_HEADER_LOGO_MOB,

    /* Israel KYC ICON */
    IL_ONBOARDING_BACK_INACTIVE: IL_ONBOARDING_BACK_INACTIVE,
    IL_ONBOARDING_BACK_ACTIVE: IL_ONBOARDING_BACK_ACTIVE,
    IL_ONBOARDING_CLOSE_INACTIVE: IL_ONBOARDING_CLOSE_INACTIVE,
    IL_ONBOARDING_CLOSE_ACTIVE: IL_ONBOARDING_CLOSE_ACTIVE,
    IL_ONBOARDING_GIRAFFE: IL_ONBOARDING_GIRAFFE,
    IL_ONBOARDING_GIRAFFE_WHITE: IL_ONBOARDING_GIRAFFE_WHITE,
    IL_ONBOARDING_LEFT_ICON: IL_ONBOARDING_LEFT_ICON,
    IL_ONBOARDING_SUCCESS_CHECK: IL_ONBOARDING_SUCCESS_CHECK,
    IL_ONBOARDING_INFO_GREY: IL_ONBOARDING_INFO_GREY,
    IL_ONBOARDING_PDF_INFO: IL_ONBOARDING_PDF_INFO,
    IL_ONBOARDING_CHECK_RED: IL_ONBOARDING_CHECK_RED,
    IL_ONBOARDING_CHECK_GREEN: IL_ONBOARDING_CHECK_GREEN,
    IL_ONBOARDING_GIRAFFE_HEART: IL_ONBOARDING_GIRAFFE_HEART,
    IL_LINKEDIN_ICON_CURVE_DESK: IL_LINKEDIN_ICON_CURVE_DESK,
    IL_LINKEDIN_ICON_MOB: IL_LINKEDIN_ICON_MOB,
    IL_QUORA_ICON: IL_QUORA_ICON,
    IL_THREAD_ICON: IL_THREAD_ICON,
}


export default IMAGES