import * as Route from 'interface/Route'

import MainRoute from "routes/MainRoute";
import GiftLandingPage from "pages/LandingPage/GiftLandingPage";
import PageNotFound from "pages/NotFoundPage";
import GiraffeLearnVideoModal from "components/ModalComponent/GiraffeLearnVideoModal";
import EmailSubscription from "pages/EmailSubscription/EmailSubscription";
import SignUpComponent from "components/LoginComponent/SignUpComponent";

import OnBoardingStartPage from 'pages/OnBoardingPage/OnBoardingStartPage';
import * as PersonalDetails from 'pages/OnBoardingPage/KYCDetails/PersonalDetails'
import * as EmploymentDetails from 'pages/OnBoardingPage/KYCDetails/EmploymentDetails'
import * as InvestingDetails from 'pages/OnBoardingPage/KYCDetails/InvestingDetails'
import * as KYCComplete from 'pages/OnBoardingPage/KYCDetails/KYCComplete';

// israel KYC
import * as IsraelKYC from 'pages/OnBoardingPage/Israel';


export const initialRoutes: Route.Route[] = [
    {
        path: '/gift/:id',
        Component: GiftLandingPage,
        isActive: true
    },
    {
        path: '/videos',
        Component: GiraffeLearnVideoModal,
        isActive: true
    },
    {
        path: '/error',
        Component: PageNotFound,
        isActive: true
    },
    {
        path: '/email-subscription/:id',
        Component: EmailSubscription,
        isActive: true
    },
    {
        path: '/signup',
        Component: SignUpComponent,
        isActive: true
    },
    {
        path: '*',
        Component: MainRoute,
        isActive: true
    }
]

export const onBoardingIntiRoute: Route.Route[] = [
    {
        path: '/on-boarding',
        Component: OnBoardingStartPage,
        isActive: true
    },
    {
        path: '/on-boarding/kyc-pending',
        Component: KYCComplete.TaskComplete,
        isActive: true
    },
    {
        path: '/on-boarding/congratulation',
        Component: KYCComplete.Congratulation,
        isActive: true
    },
    {
        path: '/on-boarding/bank-connect',
        Component: KYCComplete.BankConnect,
        isActive: true
    }
]

export const onBoardingRoute: Route.Route[] = [
    {
        path: '/on-boarding/page1',
        Component: PersonalDetails.PersonalDetailPage1,
        isActive: true
    }, // Username page
    {
        path: '/on-boarding/page2',
        Component: PersonalDetails.PersonalDetailPage2,
        isActive: true
    }, // Phone number page
    {
        path: '/on-boarding/verification',
        Component: PersonalDetails.PersonalDetailPage3,
        isActive: true
    }, // OTP verification page
    {
        path: '/on-boarding/page3',
        Component: PersonalDetails.PersonalDetailPage4,
        isActive: true
    }, // Address page
    {
        path: '/on-boarding/page4',
        Component: PersonalDetails.PersonalDetailPage5,
        isActive: true
    }, // DOB and SSN page
    {
        path: '/on-boarding/page5',
        Component: PersonalDetails.PersonalDetailPage6,
        isActive: true
    }, // Country page
    {
        path: '/on-boarding/page6',
        Component: PersonalDetails.PersonalDetailPage7,
        isActive: true
    }, // Tax payer page
    {
        path: '/on-boarding/page7',
        Component: EmploymentDetails.EmploymentDetailPage1,
        isActive: true
    }, // Employment status page
    {
        path: '/on-boarding/page8',
        Component: EmploymentDetails.EmploymentDetailPage2,
        isActive: true
    }, // Employment detail page
    {
        path: '/on-boarding/page9',
        Component: EmploymentDetails.EmploymentDetailPage3,
        isActive: true
    }, // Employment stock page
    {
        path: '/on-boarding/page10',
        Component: EmploymentDetails.EmploymentDetailPage4,
        isActive: true
    }, // Annual income page
    {
        path: '/on-boarding/page11',
        Component: InvestingDetails.InvestingDetailPage1,
        isActive: true
    }, // New worth page
    {
        path: '/on-boarding/page12',
        Component: InvestingDetails.InvestingDetailPage2,
        isActive: true
    }, // Investing experience page
    {
        path: '/on-boarding/page13',
        Component: InvestingDetails.InvestingDetailPage3,
        isActive: true
    }, // Risk tolerance page
    {
        path: '/on-boarding/page14',
        Component: KYCComplete.GiraffeTC,
        isActive: true
    }, // Giraffe T&C page
]

export const israelKYCRoute: Route.Route[] = [
    {
        path: '/on-boarding',
        Component: IsraelKYC.KYCStartPage,
        isActive: true
    },
    {
        path: '/on-boarding/page1',
        Component: IsraelKYC.KYCPage1,
        isActive: true
    },
    {
        path: '/on-boarding/page2',
        Component: IsraelKYC.KYCPage2,
        isActive: true
    },
    {
        path: '/on-boarding/page3',
        Component: IsraelKYC.KYCPage3,
        isActive: true
    },
    {
        path: '/on-boarding/page4',
        Component: IsraelKYC.KYCPage4,
        isActive: true
    },
    {
        path: '/on-boarding/page5',
        Component: IsraelKYC.KYCPage5,
        isActive: true
    },
    {
        path: '/on-boarding/page6',
        Component: IsraelKYC.KYCPage6,
        isActive: true
    },
    {
        path: '/on-boarding/page7',
        Component: IsraelKYC.KYCPage7,
        isActive: true
    },
    {
        path: '/on-boarding/page8',
        Component: IsraelKYC.KYCPage8,
        isActive: true
    },
    {
        path: '/on-boarding/page9',
        Component: IsraelKYC.KYCPage9,
        isActive: true
    },
    {
        path: '/on-boarding/page10',
        Component: IsraelKYC.KYCPage10,
        isActive: true
    },
    {
        path: '/on-boarding/page11',
        Component: IsraelKYC.KYCPage11,
        isActive: true
    },
    {
        path: '/on-boarding/page12',
        Component: IsraelKYC.KYCPage12,
        isActive: true
    },
    {
        path: '/on-boarding/kyc-pending',
        Component: IsraelKYC.KYCPending,
        isActive: true
    },
    {
        path: '/on-boarding/kyc-success',
        Component: IsraelKYC.KYCSuccess,
        isActive: true
    },
    {
        path: '/on-boarding/kyc-review',
        Component: IsraelKYC.KYCReview,
        isActive: true
    },
    {
        path: '/on-boarding/kyc-denied',
        Component: IsraelKYC.KYCDenied,
        isActive: true
    },
    {
        path: '/on-boarding/kyc-reject',
        Component: IsraelKYC.KYCReject,
        isActive: true
    },
]
