// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
import { useHistory } from 'react-router-dom';
// intl message
import IntlMessages from 'helper/IntlMessages';
//types props
import * as PropsFunction from "interface/PropsFunction";

export type Props = {
    openModal: boolean;
    isKYCDenied: boolean;
    onHandleClose: PropsFunction.SimpleFunction;
}

const KYCStatusModal: React.FC<Props> = (props: Props) => {
    const { openModal, isKYCDenied, onHandleClose } = props;

    const history = useHistory();

    const onHandleBtnClick = (): void => {
        isKYCDenied ? onHandleClose() : history.push('/kyc-failure-form');
    }

    return (
        <Modal
            centered
            size='sm'
            show={openModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            contentClassName='border-0 '
            className={`gi-ob-dark-green-bottom-curve enter-code-modal kyc-status-modal`}
        >
            {/* close modal */}
            <button className="btn-close opacity-100" type="button" onClick={onHandleClose} aria-label="Close" data-testid='close-sell'></button>
            {/* main body */}
            <Modal.Body className={'pt-4 pb-3 mt-4 px-4 mb-4 '}>
                <div className="enter-code-modal-text px-1">
                    <h5>{IntlMessages(isKYCDenied ? 'kyc_denied_modal_text1' : 'kyc_reject_modal_text1')}</h5>
                    <p className="mt-4">
                        {IntlMessages(isKYCDenied ? 'kyc_denied_modal_text2' : 'kyc_reject_modal_text2')}
                    </p>

                    <button type="button" className="mt-4" onClick={onHandleBtnClick}>
                        {IntlMessages(isKYCDenied ? 'kyc_denied_modal_text3' : 'kyc_reject_modal_text3')}
                    </button>

                    <span className="d-block pb-4 mt-4">
                        {IntlMessages(isKYCDenied ? 'kyc_denied_modal_text4' : 'kyc_reject_modal_text4')}
                        <br />
                        <a href="mailto:support@giraffe.us">
                            support@giraffe.us
                        </a>
                    </span>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default KYCStatusModal