//default library
import * as React from 'react';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types props
import * as DefaultValueType from 'interface/DefaultValueType';
import * as PropsFunction from "interface/PropsFunction";
// custom component
import InputComponentOverlay from 'components/OverlayComponent/InputComponentOverlay';
import InputRadioComponent from 'components/InputComponent/InputRadioComponent';
// other lang import
import OtherTranslations from 'lang/OtherTranslations';

//defining props
export type Props = {
    editBasketName: string;
    basketName: string;
    inputBasketName: PropsFunction.ParamsIdStringFunction;
    confirmBasket: PropsFunction.SimpleFunction;
}

const PortfolioConfirmSection: React.FC<Props> = (props: Props) => {

    const locale = useSelector(getLocale);

    const defaultBasketName: DefaultValueType.Options = [
        { value: OtherTranslations[locale.language].basketImpact, label: OtherTranslations[locale.language].basketImpact, id: 'impact-fund' },
        { value: OtherTranslations[locale.language].basketFiveYear, label: OtherTranslations[locale.language].basketFiveYear, id: 'five-year-savings' },
        { value: OtherTranslations[locale.language].basketSchool, label: OtherTranslations[locale.language].basketSchool, id: 'school-fund' },
    ]

    const { inputBasketName, confirmBasket, basketName } = props;//destructuring props

    const [isRadio, setIsRadio] = React.useState<boolean>(false)

    // select basket name from the default option
    const selectBasketName = (_name: string, value: string): void => {
        setIsRadio(true)
        basketRename(value);
    };

    const inputClick = (): void => {
        isRadio && inputBasketName('')
        setIsRadio(false)
    }

    // basket name given by the user
    const inputBasketNameHandler = (value: string): void => {
        setIsRadio(false)
        inputBasketName(value);
    };

    const basketRename = (value: string): void => {
        inputBasketName(value);
    }

    const basketNameInput = isRadio && defaultBasketName.find(e => e.value === basketName);

    return (
        <div className="row" data-testid="portfolio-confirmation-page">
            <div className="col-12">
                <div className="gift-p-portfolio-name-body">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-lg-9">
                            <div className="gift-p-portfolio-name-main p-lg-0 pt-lg-4 p-4 pb-5 mb-4">
                                <div className="row justify-content-center align-items-center gx-5 gy-5">
                                    {/* default name */}
                                    <div className="col-lg-4">
                                        <div className="gift-p-portfolio-name-start">
                                            <div className="gift-p-portfolio-name-funds d-flex flex-column gap-3 position-relative pb-lg-0 pb-5">
                                                <InputRadioComponent
                                                    name={'basketName'}
                                                    checkValue={basketName}
                                                    radioOption={defaultBasketName}
                                                    inputValue={selectBasketName}
                                                    inputClass={'form-check-input'}
                                                    labelClass={'form-check-label'}
                                                    divClassName={'gift-p-portfolio-radio-green-custom form-check'}
                                                    isSimpleRadio={false}
                                                    dataTestId={'basket-name'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*  */}
                                    <div className="col-lg-8">
                                        <div className="gift-p-portfolio-name-end">
                                            <div className="gift-p-portfolio-name-form">
                                                <label className="d-block mb-2 pb-1">
                                                    {IntlMessages('create_basket_name_label')}
                                                </label>
                                                <div className="gift-p-portfolio-name-input-group d-flex flex-sm-row flex-column gap-sm-3 gap-4">
                                                    <InputComponentOverlay
                                                        maxLength={18}
                                                        placeholder={'portfolio_placeholder'}
                                                        popoverMessage={'portfolio_popover_text2'}
                                                        name={'basketName'}
                                                        value={basketNameInput ? '' : basketName}
                                                        inputClick={inputClick}
                                                        inputValue={inputBasketNameHandler}
                                                        dataTestId={"basket-name-overlay"}
                                                    />
                                                    <button
                                                        type="button"
                                                        className="create-portfolio-btn d-flex align-items-center justify-content-center gap-4 mt-lg-0 mt-1"
                                                        disabled={basketName.length <= 1}
                                                        onClick={confirmBasket}
                                                        data-testid="confirm-click"
                                                    >
                                                        {IntlMessages('button_create_portfolio')}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortfolioConfirmSection;


