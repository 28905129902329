// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// moment
import moment from 'moment';
//auth0 library
import { useAuth0 } from '@auth0/auth0-react';
// intl message
import IntlMessages from 'helper/IntlMessages';
// config
import * as Config from 'config/Config';
// default value
import * as DefaultValue from 'config/DefaultValue';
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
// custom function
import { amountRangeCalculator, amountRangeCalculator2 } from 'service/utils/AmountRange';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as UserType from "api/APIMaster/UserType";
// custom component
import AccountSettingModalHeader from 'components/HeaderComponent/AccountSettingModalHeader';
import InputTextComponent from 'components/InputComponent/InputTextComponent'
import LabelValueField from "components/LabelComponent/LabelValueField";
import AutoComplete from 'components/AutoComplete/AutoComplete';
import AutoCompleteIsrael from 'components/AutoComplete/AutoCompleteIsrael';
import ErrorKYCIsrael from 'components/ErrorComponent/ErrorKYCIsrael';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import InputRadioComponent from 'components/InputComponent/InputRadioComponent';
import TextAreaComponent3 from 'components/InputComponent/TextAreaComponent3';

export type Props = {
    openEditModalFlag: boolean;
    openEditModal: string;
    userData: UserType.GetDetailData;
    staticData: UserType.OnboardingStaticDataResponseModel;
    updatedData: (formData: UserType.UserRequestModel) => void;
    onHandleClose: PropsFunction.SimpleFunction;
}

const positionExcludedList: string[] = ["AUDITOR", "ADJUSTER", "MANAGER", "AMBASSADOR", "POLITICIAN", "BUSINESS_OWNER", "DEALER", "INVESTMENT"]

const AccountEditModal: React.FC<Props> = (props: Props) => {

    const { openEditModalFlag, openEditModal, userData, staticData, updatedData, onHandleClose } = props;
    const { user } = useAuth0();

    const [headText, setHeadText] = React.useState<string>(''); //modal heading text
    const [paragraphText, setParagraphText] = React.useState<string>(''); //modal subheading text

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //disabled update button until all field has valid data
    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false); //check data is change or not

    // personal information
    const [addressAutoFill, setAddressAutoFill] = React.useState<string>(''); //auto field text
    const [street1, setStreet1] = React.useState<string>(''); //street 1 for data submit
    const [postalCode, setPostalCode] = React.useState<string>(''); //postal code
    const [isError, setIsError] = React.useState(false); //auto complete address error
    const [isAddressLoad, setIsAddressLoad] = React.useState(false); //load auto complete address
    const [houseNumber, setHouseNumber] = React.useState<string>('');//auto field text

    const [apartmentUnit, setApartmentUnit] = React.useState<string>(''); //apartment unit
    const [apartmentUnitError, setApartmentUnitError] = React.useState<string | PropsFunction.IntlMessages>(''); //apartment unit

    const [city, setCity] = React.useState<string>(''); //auto complete city,
    const [province, setProvince] = React.useState<string>(''); //auto complete province,
    const [countryAutoComplete, setCountryAutoComplete] = React.useState<string>(''); //auto complete country,

    // current employment information
    const [employmentStatusList, setEmploymentStatusList] = React.useState<PropsFunction.Option>([]); //employment status list
    const [employmentStatus, setEmploymentStatus] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //employment status
    const [showEmploymentDetails, setEmploymentDetails] = React.useState<boolean>(false); //show company, industry, position

    const [industryList, setIndustryList] = React.useState<PropsFunction.Option>([]); //industry list
    const [industry, setIndustry] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //industry

    const [positionList, setPositionList] = React.useState<PropsFunction.Option>([]); //position list
    const [position, setPosition] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //position

    const [empStatusCompanyName, setEmpStatusCompanyName] = React.useState<string>(''); //company name
    const [empStatusCompanyNameError, setEmpStatusCompanyNameError] = React.useState<string>(''); // company name error


    // broker dealer
    const [brokerDealer, setBrokerDealer] = React.useState<string>('false'); //broker dealer(question 1)

    const [stockOwner, setStockOwner] = React.useState<string>('false'); //stock owner(question 2)
    const [companyName, setCompanyName] = React.useState<string>(''); //company name
    const [companyNameError, setCompanyNameError] = React.useState<string>(''); //company name
    const [tickerSymbol, setTickerSymbol] = React.useState<string>(''); //ticker symbol
    const [tickerSymbolError, setTickerSymbolError] = React.useState<string>(''); //ticker symbol

    const [publicOfficial, setPublicOfficial] = React.useState<string>('false'); //public position(question 3)
    const [publicOfficialText, setPublicOfficialText] = React.useState<string>(''); //family member name
    const [publicOfficialTextError, setPublicOfficialTextError] = React.useState<string>(''); //family member name


    // investing information
    const [investingExpList, setInvestingExpList] = React.useState<PropsFunction.Option>([]); //investing experience list
    const [investingExp, setInvestingExp] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //investing experience

    const [riskToleranceList, setRiskToleranceList] = React.useState<PropsFunction.Option>([]); //risk tolerance list
    const [riskTolerance, setRiskTolerance] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //risk tolerance

    const [amountRangList, setAmountRangList] = React.useState<PropsFunction.Option>([]); //amount rang list
    const [annualIncomeRange, setAnnualIncomeRange] = React.useState<PropsFunction.Option>([]); //amount rang list for annual income
    const [annualIncome, setAnnualIncome] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //annual income
    const [netWorth, setNetWorth] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //net worth

    React.useEffect(() => {
        {/* My account */ }
        if (openEditModal === 'myAccount') {
            setHeadText('account_setting_modal_my_account_head')
            setParagraphText('account_setting_modal_my_account_text1')
        }
        {/* Personal information */ }
        if (openEditModal === 'personalData') {
            setHeadText('account_setting_modal_personal_info_head')
            setParagraphText('account_setting_modal_personal_info_text1')
        }
        {/* Current Employment Status */ }
        if (openEditModal === 'statusData') {
            setHeadText('account_setting_status')
            setParagraphText('')
        }
        {/* Broker Dealer and Political Exposure  */ }
        if (openEditModal === 'brokerData') {
            setHeadText('account_setting_broker')
            setParagraphText('')
        }
        {/* Investing Information */ }
        if (openEditModal === 'investingData') {
            setHeadText('account_setting_investing')
            setParagraphText('')
        }

        // set default value for validation button check when modal is close
        if (!openEditModalFlag) {
            setIsDataChanged(false);
            setIsDisabled(true);
            setIsAddressLoad(false);
        }
    }, [openEditModal, openEditModalFlag])

    // update user data
    const onHandleUpdate = (): void => {
        const userAddress: UserType.UserAddressCommonModel = {
            street1: street1,
            street2: userData.kycCountry === 'ISR' ? houseNumber : apartmentUnit,
            street3: userData.kycCountry === 'ISR' && apartmentUnit ? apartmentUnit : null,
            city: city,
            province: province,
            postalCode: postalCode,
            countryIdentifier: countryAutoComplete
        }

        const userEmployment: UserType.UserEmploymentCommonModel = {
            statusIdentifier: employmentStatus.value.toString(),
            company: empStatusCompanyName ? empStatusCompanyName : null,
            typeIdentifier: industry.value ? industry.value.toString() : null,
            positionIdentifier: position.value ? position.value.toString() : null,
            affiliatedWithBroker: JSON.parse(brokerDealer),
            directorOf: JSON.parse(stockOwner) ? `${companyName} ${tickerSymbol}` : null,
        }

        const userInvestor: UserType.UserInvestorCommonModel = {
            experienceIdentifier: investingExp.value.toString(),
            annualIncome: annualIncome.value,
            networthTotal: netWorth.value,
            riskToleranceIdentifier: riskTolerance.value.toString()
        }

        const formData: UserType.UserRequestModel = {
            identifier: userData.identifier,
            name: userData.name,
            firstName: userData.firstNameEncrypted,
            middleName: null,
            lastName: userData.lastNameEncrypted,
            email: user?.email ?? '',
            marketing: true,
            phone: userData.phoneEncrypted,
            language: userData.language,
            taxIdNumber: null, //always null in only account setting data
            citizenship: userData.citizenship,
            dob: userData.dob,
            politicallyExposedNames: JSON.parse(publicOfficial) ? publicOfficialText : null,
            irsBackupWithholdings: false,
            showKycStatus: true,
            usTaxPayer: userData.usTaxPayer,
            userAddress: userAddress,
            userEmployment: userEmployment,
            userInvestor: userInvestor,
            userDisclosure: userData.userDisclosure,
            metadataList: userData.metadataList,
            kycCountry: userData.kycCountry,
            locale: userData.locale,
            gender: userData.gender
        }

        updatedData(formData);
    }

    // auto complete field data US
    const setAutoCompleteValueUS: PropsFunction.PropsInputAutoComplete = (params): void => {
        setAddressAutoFill(params.addressAutoFill);
        setStreet1(params.addressAutoFill);
        setPostalCode(params.postalCode);
        setIsError(params.errorFlag);
        setCity(params.city);
        setProvince(params.province);
        setCountryAutoComplete(params.country);
        setIsDataChanged(true); //default data change
    }

    // auto complete field data US
    const setAutoCompleteValueIL: PropsFunction.AutoCompleteIsrael = (params) => {
        setAddressAutoFill(params.street1);
        setProvince(params.province);
        setCity(params.city);
        setPostalCode(params.postalCode);
        setIsError(params.isValid);
        setCountryAutoComplete(params.country);
        setIsDataChanged(true);
        setHouseNumber(params.streetNumber);
    };

    // set input type value
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        setIsDataChanged(true); //default data change
        fieldName === 'apartmentUnit' && setApartmentUnit(fieldValue);
        fieldName === 'empStatusCompanyName' && setEmpStatusCompanyName(fieldValue);
        fieldName === 'brokerDealer' && setBrokerDealer(fieldValue);
        if (fieldName === 'stockOwner') {
            setStockOwner(fieldValue);
            setCompanyName('');
            setCompanyNameError('');
            setTickerSymbol('');
            setTickerSymbolError('');
        }
        if (fieldName === 'publicOfficial') {
            setPublicOfficial(fieldValue);
            setPublicOfficialText('');
            setPublicOfficialTextError('');
        }
        fieldName === 'companyName' && setCompanyName(fieldValue);
        fieldName === 'tickerSymbol' && setTickerSymbol(fieldValue);
        fieldName === 'publicOfficialText' && setPublicOfficialText(fieldValue);
        if (fieldName === 'postalCode') {
            const value = fieldValue.replace(Config.POSTAL_CODE_INPUT, '');
            setPostalCode(value);
        }
    }

    // select value from dropdown
    const selectDropDownValue: PropsFunction.SelectedOption = (fieldName, value) => {
        setIsDataChanged(true); //default data change
        fieldName === 'investingExp' && setInvestingExp(value);
        fieldName === 'riskTolerance' && setRiskTolerance(value);
        fieldName === 'annualIncome' && setAnnualIncome(value);
        fieldName === 'netWorth' && setNetWorth(value);
        fieldName === 'industry' && setIndustry(value);
        fieldName === 'position' && setPosition(value);
        if (fieldName === 'employmentStatus') {
            setEmploymentStatus(value);
            const showDetails = employmentStatusList.find(e => e.value === value.value);
            showDetails && setEmploymentDetails(showDetails.active);
            setEmpStatusCompanyName('');
            setIndustry(OnBoardingData.defaultOptionValue);
            setPosition(OnBoardingData.defaultOptionValue);
        }
    }

    /* ====================================== PERSONAL INFORMATION START ====================================== */
    // default value
    React.useEffect(() => {
        const userAddress = userData.userAddress;
        setAddressAutoFill([userAddress.street1, userAddress.city, userAddress.province].filter(Boolean).join(', ')); //auto complete address
        setStreet1(userAddress.street1); //street1
        setApartmentUnit(userAddress.street2 ?? ''); //apartment unit
        setHouseNumber(userAddress.street3 ?? ''); //street number
        setPostalCode(userAddress.postalCode); //postal code
        setCity(userAddress.city);
        setProvince(userAddress.province);
        setCountryAutoComplete(userAddress.countryIdentifier);
        setIsError(false); //reset the error
        openEditModalFlag && setIsAddressLoad(true);
    }, [openEditModalFlag]);

    // set-up error message
    React.useEffect(() => {
        (apartmentUnit && apartmentUnit.length > Config.MAX_NUMBER_36)
            ? setApartmentUnitError(['error_max_length', { number: 36 }])
            : setApartmentUnitError('');
    }, [apartmentUnit]);

    // validate information
    React.useEffect(() => {
        if (apartmentUnitError) {
            setIsDisabled(true)
        } else if (!postalCode) {
            setIsDisabled(true)
        } else if (userData.kycCountry === 'ISR' && postalCode.length !== 7) {
            setIsDisabled(true)
        } else if (isError) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [isError, addressAutoFill, apartmentUnit, apartmentUnitError, postalCode]);
    /* ======================================= PERSONAL INFORMATION END ======================================= */


    /* ====================================== CURRENT EMPLOYMENT STATUS START ====================================== */
    // set-up dropdown value
    React.useEffect(() => {
        // employment status
        setEmploymentStatusList(staticData.employmentStatusList.map(e => ({
            active: (e.name === 'EMPLOYED' || e.name === 'SELF_EMPLOYED') ? true : false,
            label: e.nameLocale,
            value: e.identifier,
        })));

        // industry
        setIndustryList(staticData.employmentTypeList.map(e => ({
            active: e.active,
            label: e.nameLocale,
            value: e.identifier,
        })));

        // position
        setPositionList(staticData.employmentPositionList
            .filter(e => userData.kycCountry === 'ISR' ? !positionExcludedList.includes(e.name) : true)
            .map(e => ({
                active: e.active,
                label: e.nameLocale,
                value: e.identifier,
            })));
    }, [openEditModalFlag]);

    React.useEffect(() => {
        if (employmentStatusList.length > 0) {
            const employmentStatus = staticData.employmentStatusList.filter(e => e.identifier === userData.userEmployment.statusIdentifier)[0];
            const showDetails = employmentStatusList.find(e => e.value === employmentStatus.identifier);
            showDetails && setEmploymentDetails(showDetails.active)
        }
    }, [employmentStatusList]);

    // set default value
    React.useEffect(() => {
        // employment status
        const employmentStatus = staticData.employmentStatusList.filter(e => e.identifier === userData.userEmployment.statusIdentifier)[0];
        employmentStatus && setEmploymentStatus({
            active: employmentStatus.active,
            label: employmentStatus.nameLocale,
            value: employmentStatus.identifier,
        });
        const showDetails = employmentStatusList.find(e => e.value === employmentStatus.identifier);
        showDetails && setEmploymentDetails(showDetails.active)

        // employee industry
        const industry = staticData.employmentTypeList.filter(e => e.identifier === userData.userEmployment.typeIdentifier)[0];
        industry && setIndustry({
            active: industry.active,
            label: industry.nameLocale,
            value: industry.identifier,
        });
        !industry && setIndustry(OnBoardingData.defaultOptionValue);

        // employee position
        const position = staticData.employmentPositionList.filter(e => e.identifier === userData.userEmployment.positionIdentifier)[0];
        position && setPosition({
            active: position.active,
            label: position.nameLocale,
            value: position.identifier,
        });
        !position && setPosition(OnBoardingData.defaultOptionValue);

        setEmpStatusCompanyName(userData.userEmployment.company ?? '');

    }, [openEditModalFlag]);

    // set-up error message
    React.useEffect(() => {
        (empStatusCompanyName && empStatusCompanyName.length > Config.MAX_NUMBER_64)
            ? setEmpStatusCompanyNameError('error_max_length_64')
            : setEmpStatusCompanyNameError('');
    }, [empStatusCompanyName]);

    // validate information
    React.useEffect(() => {
        if (!employmentStatus.value) {
            setIsDisabled(true)
        } else if (showEmploymentDetails) {
            if (!empStatusCompanyName || empStatusCompanyName.length < 2 || empStatusCompanyNameError) {
                setIsDisabled(true)
            } else if (!industry.value) {
                setIsDisabled(true)
            } else if (!position.value) {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }
        } else {
            setIsDisabled(false)
        }
    }, [employmentStatus, industry, position, empStatusCompanyName, empStatusCompanyNameError]);
    /* ======================================= CURRENT EMPLOYMENT STATUS END ======================================= */


    /* ====================================== BROKER DEALER START ====================================== */
    // set default value
    React.useEffect(() => {
        const userEmployment = userData.userEmployment
        // question 1
        userEmployment.affiliatedWithBroker ? setBrokerDealer('true') : setBrokerDealer('false');

        // question 2
        if (userEmployment.directorOf) {
            setStockOwner('true');
            setCompanyName(userEmployment.directorOf.split(' ')[0]); //company name
            setTickerSymbol(userEmployment.directorOf.split(' ')[1]); //ticket symbol
        } else {
            setStockOwner('false');
            setCompanyName(''); //company name
            setTickerSymbol(''); //ticket symbol
        }

        // question 3
        if (userData.politicallyExposedNames) {
            setPublicOfficial('true');
            setPublicOfficialText(userData.politicallyExposedNames); //family name
        } else {
            setPublicOfficial('false');
            setPublicOfficialText(''); //family name
        }
    }, [openEditModalFlag])

    // set-up error message
    React.useEffect(() => {
        // company name validation
        (companyName && (companyName.length > Config.MAX_NUMBER_244)) ? setCompanyNameError('error_max_length_244') : setCompanyNameError('');

        // ticker symbol validation
        if (tickerSymbol && ((tickerSymbol.length > Config.MAX_NUMBER_10) || (tickerSymbol.match(Config.TICKER_REGEX)))) {
            (tickerSymbol.length > Config.MAX_NUMBER_10) && setTickerSymbolError('error_ticker_max_length');
            (tickerSymbol.match(Config.TICKER_REGEX)) && setTickerSymbolError('error_ticker_message');
        } else {
            setTickerSymbolError('')
        }
        // public figure name
        (publicOfficialText && (publicOfficialText.length > Config.MAX_NUMBER_255)) ? setPublicOfficialTextError('error_max_length_255_textarea') : setPublicOfficialTextError('');

    }, [publicOfficialText, companyName, tickerSymbol])

    // validate broker dealer information
    React.useEffect(() => {
        if (publicOfficial === 'true' && (!publicOfficialText || publicOfficialTextError || publicOfficialText.length < 3)) {
            setIsDisabled(true)
        } else if (stockOwner === 'true' && (!companyName || companyNameError || companyName.length < 2)) {
            setIsDisabled(true)
        } else if (stockOwner === 'true' && (!tickerSymbol || tickerSymbolError || tickerSymbol.length < 2)) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [publicOfficial, publicOfficialText, stockOwner, companyName, tickerSymbol, brokerDealer])
    /* ======================================= BROKER DEALER STATUS END ======================================= */


    /* ====================================== INVESTING INFORMATION START ====================================== */
    // set-up dropdown value
    React.useEffect(() => {
        // investing experience
        setInvestingExpList(staticData.investorExperienceList.map(e => ({
            active: e.active,
            label: e.nameLocale,
            value: e.identifier,
        })));

        // risk tolerance
        setRiskToleranceList(staticData.investorRiskToleranceList.map(e => ({
            active: e.active,
            label: e.nameLocale,
            value: e.identifier,
        })));

        // amount range for annual income
        setAmountRangList(DefaultValue.amountRange.map(e => ({
            active: true,
            label: e.label,
            value: e.value,
        })));

        // amount range for net worth
        setAnnualIncomeRange(DefaultValue.annualIncomeRange.map(e => ({
            active: true,
            label: e.label,
            value: e.value,
        })));
    }, [openEditModalFlag]);

    // set default value
    React.useEffect(() => {
        // employee investing experience
        const investingExp = staticData.investorExperienceList.filter(e => e.identifier === userData.userInvestor.experienceIdentifier)[0];
        investingExp && setInvestingExp({
            active: investingExp.active,
            label: investingExp.nameLocale,
            value: investingExp.identifier,
        });

        // employee annual income
        const annualIncome = DefaultValue.amountRange.filter(e => e.value === amountRangeCalculator(userData.userInvestor.annualIncome))[0];
        annualIncome && setAnnualIncome({
            active: true,
            label: annualIncome.label,
            value: annualIncome.value,
        });

        // employee net worth
        const netWorthTotal = DefaultValue.annualIncomeRange.filter(e => e.value === amountRangeCalculator2(userData.userInvestor.networthTotal))[0];
        netWorthTotal && setNetWorth({
            active: true,
            label: netWorthTotal.label,
            value: netWorthTotal.value,
        });

        // employee risk tolerance
        const riskTolerance = staticData.investorRiskToleranceList.filter(e => e.identifier === userData.userInvestor.riskToleranceIdentifier)[0];
        riskTolerance && setRiskTolerance({
            active: riskTolerance.active,
            label: riskTolerance.nameLocale,
            value: riskTolerance.identifier,
        });
    }, [openEditModalFlag])

    // validate investing information
    React.useEffect(() => {
        if (!investingExp.value) {
            setIsDisabled(true)
        } else if (!riskTolerance.value) {
            setIsDisabled(true)
        } else if (!annualIncome.value) {
            setIsDisabled(true)
        } else if (!netWorth.value) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [investingExp, riskTolerance, annualIncome, netWorth])
    /* ======================================= INVESTING INFORMATION End ======================================= */


    const showGY2 = openEditModal === 'brokerData'; // add class name gy-2
    const showGX3 = openEditModal === 'personalData'; // add class name gx-3
    const showGY4 = openEditModal === 'myAccount' || openEditModal === 'personalData' || openEditModal === 'statusData' || openEditModal === 'investingData'; // add class name gy-4

    /* PERSONAL INFORMATION */
    const phoneNumberUS = `${userData.phoneEncrypted ? userData.phoneEncrypted.replace(/\D/g, '').slice(1).replace(/(\d{3})(\d{3})(\d{4})/, '+1 ($1) $2 - $3') : ''}`; // user phone number
    // user phone number IL
    const phoneNumberIL = `${userData.phoneEncrypted ? userData.phoneEncrypted.replace(/\D/g, '').slice(3).replace(/(\d{3})(\d{7})/, '$1 $2') : ''}`;
    const country = staticData.countryList.filter((e: UserType.CountryResponseModel) => e.identifier === userData.citizenship)[0]; // user country
    //ssnNumber
    const ssnNumber = (userData.taxIdNumber && userData.taxIdNumber.length > 8)
        ? `${userData.taxIdNumber.slice(0, 3)}-${userData.taxIdNumber.slice(3, 5)}-${userData.taxIdNumber.slice(5)}`
        : '';

    // gender
    const gender = userData.gender ? userData.gender.replace(/^./, match => match.toUpperCase()) : '';

    return (
        <Modal
            centered
            size='lg'
            show={openEditModalFlag}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            className="gi-ob-grey-bottom-curve enter-code-modal"
        >
            {/* modal heading */}
            <Modal.Header className="px-sm-5 p-4 border-bottom-0">
                <AccountSettingModalHeader
                    headText={headText}
                    paragraphText={paragraphText}
                />
            </Modal.Header>

            {/* close button */}
            <button type="button" className="btn-close opacity-100" onClick={onHandleClose}></button>

            {/* modal body */}
            <Modal.Body className="gi-ob-withdraw-modal-body px-sm-5 px-4 py-sm-4 py-2">
                <div className="py-3 p-sm-0 px-3">
                    <div className={`row ${showGY2 && 'gy-2'} ${showGX3 && 'gx-3'} ${showGY4 && 'gy-4'}`}>
                        {/* My account */}
                        {(openEditModal === 'myAccount') && (
                            <>
                                {/* email */}
                                <div className="col-sm-5">
                                    <LabelValueField
                                        divClassName={'gi-ob-form form-group'}
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        strongClassName={'d-block'}
                                        labelText={'account_setting_modal_email'}
                                        strongText={user?.email ?? ''}
                                    />
                                </div>
                                {/* phone number */}
                                <div className="col-sm-7">
                                    <LabelValueField
                                        divClassName={'gi-ob-form form-group'}
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        strongClassName={'d-block ltr text-start'}
                                        labelText={userData.kycCountry === 'ISR' ? 'account_setting_phone_il' : 'account_setting_modal_phone'}
                                        strongText={userData.kycCountry === 'ISR' ? phoneNumberIL : phoneNumberUS}
                                    />
                                </div>
                            </>
                        )}

                        {/* Personal information */}
                        {(openEditModal === 'personalData') && (
                            <>
                                {/* first name */}
                                <div className="col-sm-5">
                                    <LabelValueField
                                        divClassName={'gi-ob-form form-group'}
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        strongClassName={'d-block'}
                                        labelText={'account_setting_first'}
                                        strongText={userData.kycCountry === 'ISR'
                                            ? (userData.metadataList?.find(item => item.fieldName === 'firstNameLocale')?.fieldValue ?? '')
                                            : userData.firstNameEncrypted
                                        }
                                    />
                                </div>
                                {/* last name */}
                                <div className="col-sm-7">
                                    <LabelValueField
                                        divClassName={'gi-ob-form form-group'}
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        strongClassName={'d-block'}
                                        labelText={'account_setting_last'}
                                        strongText={userData.kycCountry === 'ISR'
                                            ? (userData.metadataList?.find(item => item.fieldName === 'lastNameLocale')?.fieldValue ?? '')
                                            : userData.lastNameEncrypted
                                        }
                                    />
                                </div>
                                {/* auto complete address */}
                                <div className="col-sm-6">
                                    {userData.kycCountry === 'ISR'
                                        ? <>
                                            {isAddressLoad && (
                                                <AutoCompleteIsrael
                                                    labelText={'account_setting_address'}
                                                    labelClassName={'gi-ob-modal-form-label'}
                                                    className="react-google-place-il"
                                                    inputValue={setAutoCompleteValueIL}
                                                    fieldValue={addressAutoFill}
                                                    countryList={staticData.countryList}
                                                    textCenter={false}
                                                    dataTestId={"auto-complete-value"}
                                                />
                                            )}
                                            {isError && <ErrorKYCIsrael message={'error_invalid_address'} fieldName={'autoAddress'} />}
                                        </>
                                        : <>
                                            <label className="gi-ob-modal-form-label" htmlFor="addressAutoFill">{IntlMessages('account_setting_address')}</label>
                                            {isAddressLoad && (
                                                <AutoComplete
                                                    className="react-google-place"
                                                    inputValue={setAutoCompleteValueUS}
                                                    value={addressAutoFill}
                                                    countryList={staticData.countryList}
                                                    textCenter={false}
                                                    dataTestId={"auto-complete-value"}
                                                />
                                            )}
                                            {isError && <ErrorKYCIsrael message={'error_invalid_address'} fieldName={'addressAutoFill'} />}
                                        </>}
                                </div>
                                {/* apartment & zip code */}
                                <div className="col-sm-6">
                                    <div className="row gx-3">
                                        {/* apartment */}
                                        <div className="col-5">
                                            <div className="gi-ob-form form-group">
                                                <InputTextComponent
                                                    labelClassName={'gi-ob-modal-form-label mb-1'}
                                                    labelText={'account_setting_modal_apart'}
                                                    className=''
                                                    fieldValue={apartmentUnit}
                                                    name={'apartmentUnit'}
                                                    placeholder={''}
                                                    readOnly={false}
                                                    disabled={false}
                                                    isError={Boolean(apartmentUnitError)}
                                                    errorMessage={apartmentUnitError}
                                                    inputValue={setInputValue}
                                                    dataTestId={'apartment-unit'}
                                                />
                                            </div>
                                        </div>
                                        {/* zip code */}
                                        <div className="col-7">
                                            <div className="gi-ob-form form-group">
                                                <InputTextComponent
                                                    labelClassName={'gi-ob-modal-form-label mb-1'}
                                                    labelText={'account_setting_modal_zip_code'}
                                                    className={`${postalCode && 'right-success-icon'}`}
                                                    fieldValue={postalCode}
                                                    name={'postalCode'}
                                                    placeholder={''}
                                                    readOnly={userData.kycCountry !== 'ISR'}
                                                    disabled={false}
                                                    isError={false}
                                                    errorMessage={''}
                                                    inputValue={setInputValue}
                                                    dataTestId={'postal-code'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* country */}
                                <div className="col-sm-5">
                                    <LabelValueField
                                        divClassName={'gi-ob-form form-group'}
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        strongClassName={'d-block'}
                                        labelText={'account_setting_citizenship'}
                                        strongText={country && country.name}
                                    />
                                </div>
                                {/* ssn number & ID number*/}
                                <div className="col-sm-4">
                                    {userData.kycCountry === 'ISR'
                                        ? <LabelValueField
                                            divClassName={'gi-ob-form form-group'}
                                            labelClassName={'gi-ob-modal-form-label mb-1'}
                                            strongClassName={'d-block ltr text-start'}
                                            labelText={'account_setting_id_number'}
                                            strongText={userData.taxIdNumber}
                                        />
                                        : <LabelValueField
                                            divClassName={'gi-ob-form form-group'}
                                            labelClassName={'gi-ob-modal-form-label mb-1'}
                                            strongClassName={'d-block'}
                                            labelText={'account_setting_ssn'}
                                            strongText={ssnNumber}
                                        />
                                    }
                                </div>
                                {/* data of birth */}
                                <div className="col-sm-3">
                                    <LabelValueField
                                        divClassName={'gi-ob-form form-group'}
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        strongClassName={'d-block'}
                                        labelText={'account_setting_dob'}
                                        strongText={moment(userData.dob).format('MM/DD/YYYY')}
                                    />
                                </div>
                                {/* gender, firstName and lastName in english*/}
                                {userData.kycCountry === 'ISR' && (
                                    <>
                                        {/* gender */}
                                        <div className="col-sm-4">
                                            <LabelValueField
                                                divClassName={'gi-ob-form form-group'}
                                                labelClassName={'gi-ob-modal-form-label mb-1'}
                                                strongClassName={'d-block'}
                                                labelText={'account_setting_gender'}
                                                strongText={gender}
                                            />
                                        </div>
                                        {/* first name english */}
                                        <div className="col-sm-4">
                                            <LabelValueField
                                                divClassName={'gi-ob-form form-group'}
                                                labelClassName={'gi-ob-modal-form-label mb-1'}
                                                strongClassName={'d-block'}
                                                labelText={'account_setting_first_en'}
                                                strongText={userData.firstNameEncrypted}
                                            />
                                        </div>
                                        {/* last name english */}
                                        <div className="col-sm-4">
                                            <LabelValueField
                                                divClassName={'gi-ob-form form-group'}
                                                labelClassName={'gi-ob-modal-form-label mb-1'}
                                                strongClassName={'d-block'}
                                                labelText={'account_setting_last_en'}
                                                strongText={userData.lastNameEncrypted}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {/* Current Employment Status */}
                        {(openEditModal === 'statusData') && (
                            <>
                                {/* employment status */}
                                <div className={`${showEmploymentDetails ? "col-sm-6" : "col-12"}`}>
                                    <SelectDropdown
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'account_setting_status1'}
                                        name={'employmentStatus'}
                                        placeholder={''}
                                        option={employmentStatusList}
                                        fieldValue={employmentStatus}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'employment-status'}
                                        isDisabled={false}
                                        inputValue={selectDropDownValue}
                                        isModalDropdown={true}
                                    />
                                </div>

                                {/* company name, industry, position */}
                                {showEmploymentDetails && (
                                    <>
                                        {/* company name */}
                                        <div className="col-sm-6">
                                            <div className="gi-ob-form form-group">
                                                <InputTextComponent
                                                    labelClassName={'gi-ob-modal-form-label mb-1'}
                                                    labelText={'account_setting_company_name'}
                                                    className=''
                                                    fieldValue={empStatusCompanyName}
                                                    name={'empStatusCompanyName'}
                                                    placeholder={'placeholder_company_name'}
                                                    readOnly={false}
                                                    disabled={false}
                                                    isError={Boolean(empStatusCompanyNameError)}
                                                    errorMessage={empStatusCompanyNameError}
                                                    inputValue={setInputValue}
                                                    dataTestId={'emp-status-company-name'}
                                                />
                                            </div>
                                        </div>

                                        {/* industry */}
                                        <div className="col-sm-6">
                                            <SelectDropdown
                                                labelClassName={'gi-ob-modal-form-label mb-1'}
                                                labelText={'account_setting_industry'}
                                                name={'industry'}
                                                placeholder={''}
                                                option={industryList}
                                                fieldValue={industry}
                                                className={'custom-react-dropdown-il'}
                                                ariaLabel={'industry'}
                                                isDisabled={false}
                                                inputValue={selectDropDownValue}
                                                isModalDropdown={true}
                                            />
                                        </div>

                                        {/* position */}
                                        <div className="col-sm-6">
                                            <SelectDropdown
                                                labelClassName={'gi-ob-modal-form-label mb-1'}
                                                labelText={'account_setting_position'}
                                                name={'position'}
                                                placeholder={''}
                                                option={positionList}
                                                fieldValue={position}
                                                className={'custom-react-dropdown-il'}
                                                ariaLabel={'position'}
                                                isDisabled={false}
                                                inputValue={selectDropDownValue}
                                                isModalDropdown={true}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {/* Broker Dealer and Political Exposure  */}
                        {(openEditModal === 'brokerData') && (
                            <>
                                {/* question 1 */}
                                <div className="col-12">
                                    <div className="gi-ob-form form-group">
                                        <label className="gi-ob-modal-form-label mb-2">
                                            {IntlMessages('employment_stock_text1')}
                                        </label>
                                    </div>
                                    <div className="py-2">
                                        <InputRadioComponent
                                            name={'brokerDealer'}
                                            checkValue={brokerDealer}
                                            radioOption={DefaultValue.actionValue}
                                            inputValue={setInputValue}
                                            inputClass={''}
                                            labelClass={'gi-ob-form-check-label form-check-label'}
                                            isSimpleRadio={false}
                                            dataTestId={'broker-dealer'}
                                            divClassName='form-check form-check-inline'
                                        />
                                    </div>

                                    {brokerDealer === 'true' && (
                                        <div className="pt-2 pb-3">
                                            <div className="gi-ob-broker-details-text">
                                                <h5 className="mb-0">
                                                    {IntlMessages('employment_stock_text4')}
                                                </h5>
                                                <p className="mb-0">
                                                    {IntlMessages('employment_stock_text5')}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* horizontal line */}
                                <div className="col-12">
                                    <hr className="mt-2 mb-3" />
                                </div>

                                {/* question 2 */}
                                <div className="col-12">
                                    <div className="gi-ob-form form-group">
                                        <label className="gi-ob-modal-form-label mb-2">
                                            {IntlMessages('employment_stock_text2')}
                                        </label>
                                    </div>
                                    <div className="py-2">
                                        <InputRadioComponent
                                            name={'stockOwner'}
                                            checkValue={stockOwner}
                                            radioOption={DefaultValue.actionValue}
                                            inputValue={setInputValue}
                                            inputClass={''}
                                            labelClass={'gi-ob-form-check-label form-check-label '}
                                            isSimpleRadio={false}
                                            dataTestId={'stock-owner'}
                                            divClassName='form-check form-check-inline'
                                        />
                                    </div>

                                    {stockOwner === 'true' && (
                                        <div className="pt-2 pb-3">
                                            <div className="row gy-3">
                                                <div className="col-12">
                                                    <div className="gi-ob-broker-details-text">
                                                        <p className="mb-0">{IntlMessages('employment_stock_text6')}</p>
                                                    </div>
                                                </div>
                                                {/* company name */}
                                                <div className="col-sm-6 col-10">
                                                    <div className="gi-ob-form form-group">
                                                        <InputTextComponent
                                                            labelClassName={''}
                                                            labelText={''}
                                                            className={'gi-ob-white-input bg-white'}
                                                            fieldValue={companyName}
                                                            name={'companyName'}
                                                            placeholder={'kyc_employment_detail_company_name'}
                                                            readOnly={false}
                                                            disabled={false}
                                                            isError={Boolean(companyNameError)}
                                                            errorMessage={companyNameError}
                                                            inputValue={setInputValue}
                                                            dataTestId={'company-name'}
                                                        />
                                                    </div>
                                                </div>
                                                {/* ticker symbol */}
                                                <div className="col-sm-4 col-7">
                                                    <div className="gi-ob-form form-group">
                                                        <InputTextComponent
                                                            labelClassName={''}
                                                            labelText={''}
                                                            className={'gi-ob-white-input bg-white'}
                                                            fieldValue={tickerSymbol}
                                                            name={'tickerSymbol'}
                                                            placeholder={'kyc_employment_detail_ticker'}
                                                            readOnly={false}
                                                            disabled={false}
                                                            isError={Boolean(tickerSymbolError)}
                                                            errorMessage={tickerSymbolError}
                                                            inputValue={setInputValue}
                                                            dataTestId={'ticker-symbol'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* horizontal line */}
                                <div className="col-12">
                                    <hr className="mt-2 mb-3" />
                                </div>

                                {/* question 3 */}
                                <div className="col-12">
                                    <div className="gi-ob-form form-group">
                                        <label className="gi-ob-modal-form-label mb-2">
                                            {IntlMessages('employment_stock_text3')}
                                        </label>
                                    </div>
                                    <div className="py-2">
                                        <InputRadioComponent
                                            name={'publicOfficial'}
                                            checkValue={publicOfficial}
                                            radioOption={DefaultValue.actionValue}
                                            inputValue={setInputValue}
                                            inputClass={''}
                                            labelClass={'gi-ob-form-check-label form-check-label '}
                                            isSimpleRadio={false}
                                            dataTestId={'public-official'}
                                            divClassName='form-check form-check-inline'
                                        />
                                    </div>

                                    {publicOfficial === 'true' && (
                                        <div className="pt-2 pb-3">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="gi-ob-broker-details-text">
                                                        <p className="mb-0">
                                                            {IntlMessages('employment_stock_text7')}
                                                        </p>
                                                    </div>
                                                    <div className="gi-ob-form form-group mt-3">
                                                        <TextAreaComponent3
                                                            labelText={''}
                                                            fieldValue={publicOfficialText}
                                                            name={'publicOfficialText'}
                                                            rows={5}
                                                            className={'gi-ob-white-textarea text-left'}
                                                            placeholder={'employment_page3_label3_1'}
                                                            errorMessage={publicOfficialTextError}
                                                            inputValue={setInputValue}
                                                            dataTestId={'public-official-text'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}

                        {/* Investing Information */}
                        {(openEditModal === 'investingData') && (
                            <>
                                {/* investing experience */}
                                <div className="col-sm-6">
                                    <SelectDropdown
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'account_setting_investing_exp'}
                                        name={'investingExp'}
                                        placeholder={''}
                                        option={investingExpList}
                                        fieldValue={investingExp}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'investing-exp'}
                                        isDisabled={false}
                                        inputValue={selectDropDownValue}
                                        isModalDropdown={true}
                                    />
                                </div>
                                {/* annual income */}
                                <div className="col-sm-6">
                                    <SelectDropdown
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'account_setting_annual_income'}
                                        name={'annualIncome'}
                                        placeholder={''}
                                        option={amountRangList}
                                        fieldValue={annualIncome}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'annual-income'}
                                        isDisabled={false}
                                        inputValue={selectDropDownValue}
                                        isModalDropdown={true}
                                    />
                                </div>
                                {/* net worth */}
                                <div className="col-sm-6">
                                    <SelectDropdown
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'account_setting_net_worth'}
                                        name={'netWorth'}
                                        placeholder={''}
                                        option={annualIncomeRange}
                                        fieldValue={netWorth}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'net-worth'}
                                        isDisabled={false}
                                        inputValue={selectDropDownValue}
                                        isModalDropdown={true}
                                    />
                                </div>
                                {/* risk tolerance */}
                                <div className="col-sm-6">
                                    <SelectDropdown
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'account_setting_risk_tolerance'}
                                        name={'riskTolerance'}
                                        placeholder={''}
                                        option={riskToleranceList}
                                        fieldValue={riskTolerance}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'risk-tolerance'}
                                        isDisabled={false}
                                        inputValue={selectDropDownValue}
                                        isModalDropdown={true}
                                    />
                                </div>
                            </>
                        )}

                        {/* update and cancel button */}
                        <div className="col-12">
                            <div className="py-3 d-flex align-items-center justify-content-center gap-2 mt-2">
                                {/* cancel btn */}
                                <button type="button" className="gi-ob-cancel-btn" onClick={onHandleClose}>
                                    {IntlMessages('button_cancel')}
                                </button>
                                {/* update btn */}
                                {openEditModal !== 'myAccount' && <button type="button" className="gi-ob-withdraw-btn" disabled={isDisabled || !isDataChanged} onClick={onHandleUpdate}>
                                    {IntlMessages('button_update')}
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AccountEditModal