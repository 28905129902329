//default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';

export type Props = {
    openModal: boolean;
    onHandleClose: PropsFunction.SimpleFunction;
}

const FundModalIL: React.FC<Props> = (props: Props) => {
    const { openModal, onHandleClose } = props;
    const intl = useIntl();

    // close modal

    return (
        <Modal
            centered
            show={openModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            className={'enter-code-modal modal-md'}
        >
            <button type="button" className="btn-close opacity-100" onClick={onHandleClose}></button>

            <Modal.Body className="px-5">
                <div className="gi-ob-funds-modal-text text-center py-4 mt-3 mb-3 pb-4 px-4">
                    <img src={intl.formatMessage({ id: 'GIRAFFE_FUND_ICON' })} alt="" loading='lazy' />
                    <h5 className="mt-3 pt-1 mb-4">{IntlMessages('fund_static_il_text1')}</h5>
                    <p className="mb-3 px-4">{IntlMessages('fund_static_il_text2')}</p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FundModalIL