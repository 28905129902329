// default library
import * as React from 'react';
// custom component
import PaginationNew from 'components/PaginationComponent/PaginationNew';
// type import
import * as DashboardType from 'api/APIMaster/DashboardType';
import IntlMessages from 'helper/IntlMessages';

type Props = {
    addCompany: boolean;
    totalCompanyList: DashboardType.ImpactCompanyResponseModel[];
}

const MyImpactTable: React.FC<Props> = (props: Props) => {

    const { addCompany, totalCompanyList } = props;

    const [currentTotalCompanyList, setCurrentTotalCompanyList] = React.useState<DashboardType.ImpactCompanyResponseModel[]>([]);
    const [dataLoadCompany, setDataLoadCompany] = React.useState<boolean>(true);

    const onPageChangedCompany = (data: any): void => {
        setDataLoadCompany((prev) => !prev);

        const { currentPage, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentTotal = [...totalCompanyList].slice(offset, offset + pageLimit);
        setCurrentTotalCompanyList(currentTotal);
        setDataLoadCompany((prev) => !prev);

    };

    return (
        <>
            {dataLoadCompany && (
                <div className="table-responsive">
                    <table className="table gi-impact-table table-striped mt-4 mb-0" role="presentation" data-testid='table'>
                        <thead className={`${addCompany ? 'thead-success' : 'thead-danger'}`}>
                            <tr>
                                <th scope="col" data-testid="company-name"> <strong> {IntlMessages('impact_company_name')}</strong></th>
                                {addCompany
                                    ? <th scope="col" data-testid="add-company-text"><strong> {IntlMessages('impact_table_text1')}</strong></th>
                                    : <th scope="col" data-testid="industry"> <strong> {IntlMessages('impact_table_text2')}</strong></th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {[...currentTotalCompanyList].map((value, i) => {
                                return (
                                    <tr key={i} data-testid='data-row'>
                                        <th scope="col"><strong>{value.name}</strong></th>
                                        <td>
                                            {addCompany
                                                ? <p> {value.description}</p>
                                                : <p> {value.industry}</p>
                                            }
                                        </td>
                                    </tr>)
                            })}
                        </tbody>
                    </table>
                </div>
            )}

            <PaginationNew
                totalRecords={totalCompanyList.length}
                pageLimit={5}
                pageNeighbors={1}
                onPageChanged={onPageChangedCompany}
            />

        </ >
    )
}

export default MyImpactTable