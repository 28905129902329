import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ScrollBar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
// device detect library
import { isMobile } from "react-device-detect";
import * as PropsFunction from 'src/interface/PropsFunction';

export type Props = {
    openInfoModal: boolean;
    messageBody: string | React.ReactNode;
    textHeading: string
    onHandleClose: PropsFunction.SimpleFunction;
};

const InfoModal: React.FC<Props> = (props: Props) => {
    const { openInfoModal, messageBody, textHeading, onHandleClose } = props;

    const scrollData: JSX.Element = <>
        <h5>
            {textHeading}
        </h5>
        <p className='mb-0'>{messageBody}</p>
    </>

    return (
        <Modal
            centered
            show={openInfoModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            className='gi-ob-white-bottom-curve gi-port-ao-pc-companies-modal source-pop-up'
        >
            <Modal.Header className='px-3 py-2'>
                <button type="button" className="btn-close p-0 m-0 border-0 opacity-100" onClick={onHandleClose} data-testid="close-icon"></button>
            </Modal.Header>

            <Modal.Body className='ps-lg-5 pe-lg-4 pe-3 ps-4 pt-5 pb-4'>
                {isMobile
                    ? <div className='scroll-thumb pe-5 gi-dash-account-modal source-info-height' >
                        {scrollData}
                    </div>
                    : <ScrollBar component="div" className='gi-dash-account-modal pe-5 source-info-height'>
                        {scrollData}
                    </ScrollBar>
                }
            </Modal.Body>
        </Modal>
    );
};

export default InfoModal;
