//default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// api call
import * as DataService from 'middleware/DataService';
import * as DashboardService from 'middleware/DashboardService';
//regex from number
import { ONLY_NUMBER, EXCEPT_NUMBER } from "config/Config";
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as DataType from 'api/APIMaster/DataType';
// custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
//custom component
import LoadingModal from 'components/LoadingComponent/LoadingModal';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import InputTextComponent from 'components/InputComponent/InputTextComponent';
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent';
import LabelValueField from 'components/LabelComponent/LabelValueField';
import SuccessModalIL from 'components/ModalComponent/SuccessModalIL';

export type Props = {
    isModalOpen: boolean;
    withdrawalAmount: number;
    cashSettlement: number;
    onHandleClose: PropsFunction.ParamsBooleanFunction;
    componentDidMount: PropsFunction.SimpleFunction;
}

const WithdrawMoneyModalIL: React.FC<Props> = (props: Props) => {
    const { isModalOpen, withdrawalAmount, cashSettlement, onHandleClose, componentDidMount } = props;

    const intl = useIntl();
    const { initiateAction } = useAccessToken();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [openWithdrawModal, setOpenWithdrawModal] = React.useState<boolean>(false); //withdraw modal
    const [openLoadingModal, setOpenLoadingModal] = React.useState<boolean>(false); //loading modal
    const [openLoadingModalMessage, setOpenLoadingModalMessage] = React.useState<string>(''); //loading modal message
    const [openSuccessModal, setOpenSuccessModal] = React.useState<boolean>(false); //success modal
    const [openOwnerErrorModal, setOwnerErrorModal] = React.useState<boolean>(false); //owner name account error modal

    const [allBankList, setAllBankList] = React.useState<DataType.BankList[]>([]); //list of all bank list
    const [bankOptionList, setBankOptionList] = React.useState<PropsFunction.Option>([]); //bank list
    const [branchOptionList, setBranchOptionList] = React.useState<PropsFunction.Option>([]); //branch list

    const [amount, setAmount] = React.useState<string>(''); //withdraw amount
    const [amountError, setAmountError] = React.useState<string | PropsFunction.IntlMessages>(''); //when any error in withdraw amount

    const [bank, setBank] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //selected bank name
    const [branch, setBranch] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue); //selected branch name

    const [accountNumber, setAccountNumber] = React.useState<string>(''); //account number
    const [accountNumberError, setAccountNumberError] = React.useState<string | PropsFunction.IntlMessages>(''); //account number error
    const [ownerName, setOwnerName] = React.useState<string>(''); //owner name
    const [ownerNameError, setOwnerNameError] = React.useState<string | PropsFunction.IntlMessages>(''); //owner name error

    const [termCheck, setTermCheck] = React.useState<boolean>(false); //is term checked or not

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    React.useEffect(() => {
        setOpenWithdrawModal(isModalOpen)

        // call bank list api
        if (isModalOpen && allBankList.length === 0) {
            getBankList();
        }

        // reset the value when modal close
        if (!isModalOpen) {

            setAmount('');
            setAmountError('');

            setBank(OnBoardingData.defaultOptionValue);
            setBranch(OnBoardingData.defaultOptionValue);

            setAccountNumber('');
            setOwnerName('');
            setTermCheck(false);
        }
    }, [isModalOpen])

    // get all bank and branch list
    const getBankList = async (): Promise<void> => {
        setOpenWithdrawModal(false);
        setOpenLoadingModal(true);

        const accessToken = await initiateAction();
        const bankBranchList = await DataService.getAllBankList(accessToken);

        setOpenLoadingModal(false);
        setOpenWithdrawModal(true);

        if (bankBranchList.request || bankBranchList.response) {
            setIsError(true);
            setErrorLog(bankBranchList);
        } else {
            setAllBankList(bankBranchList.bankList);
            const bankList = bankBranchList.bankList.map((e: DataType.BankList) => ({
                label: `${e.code} - ${e.name}`,
                value: e.id,
                active: e.active,
            }))

            setBankOptionList(bankList);
        }
    }

    // set the input data
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        if (fieldName === 'amount') {
            setAmountError('')
            const value = fieldValue.replace(ONLY_NUMBER, '');
            setAmount(value ? `$ ${value}` : '')
        }
        fieldName === 'accountNumber' && setAccountNumber(fieldValue.replace(ONLY_NUMBER, ''));

        fieldName === 'ownerName' && setOwnerName(fieldValue.replace(EXCEPT_NUMBER, ''));
    }

    // select dropdown value
    const selectDropDownValue: PropsFunction.SelectedOption = (fieldName, fieldValue) => {
        if (fieldName === 'bank') {
            setBank(fieldValue); //set the bank data
            setBranch(OnBoardingData.defaultOptionValue); //reset the branch data
            setTermCheck(false); //deselect the terms check
            const selectedBank = allBankList.find(e => e.id === Number(fieldValue.value)); //find the selected bank branch list
            if (selectedBank) {
                const branchList = selectedBank.bankBranchList.map(e => ({
                    label: `${e.code} - ${e.name}`,
                    value: e.id,
                    active: e.active,
                }))

                setBranchOptionList(branchList); //create branch list 
            }
        }
        fieldName === 'branch' && setBranch(fieldValue);
    }

    // checkbox input value
    const setInputValueCheckbox = (fieldName: string, fieldValue: boolean): void => {
        fieldName === 'termCheck' && setTermCheck(fieldValue);
    }

    // submit withdraw money data api call
    const onHandleWithdraw = async (): Promise<void> => {
        const amountWithdrawal = Number(amount.split('$')[1])
        if ((amountWithdrawal <= withdrawalAmount) && (amountWithdrawal >= 10)) {
            setOpenLoadingModalMessage('fund_preparing')
            setOpenWithdrawModal(false); //withdrawal modal close
            setOpenLoadingModal(true); //loading modal open

            const withdrawData = {
                amount: amountWithdrawal,
                bankAccountNumber: accountNumber,
                bankAccountOwner: ownerName,
                bankId: Number(bank.value),
                branchId: Number(branch.value),
            }

            const accessToken = await initiateAction();
            const withDrawMoney = await DashboardService.withdrawMoney(withdrawData, accessToken);

            setOpenLoadingModal(false); //loading modal close

            if (withDrawMoney.request || withDrawMoney.response) {
                if (withDrawMoney.response.status === 500 && withDrawMoney.response.data.message === `Bank account owner name doesn't match`) {
                    setOwnerErrorModal(true); //open owner name error modal
                } else {
                    setIsError(true);
                    setErrorLog(withDrawMoney);
                }
            } else {
                setOpenSuccessModal(true); //open success modal
            }
        } else if (amountWithdrawal <= 10) {
            setAmountError('error_minimum_amount_withdraw')
        } else if (amountWithdrawal >= withdrawalAmount) {
            setAmountError(['error_maximum_amount_withdraw', { amount: currencyFormatter(withdrawalAmount).split('.')[0] }])
        }
    }

    // close the modal
    const onHandleCloseFunction = (): void => {
        onHandleClose(false); //modal close
        setOpenWithdrawModal(false); //close withdraw modal
        setOpenLoadingModal(false); //close loading modal
        setOpenSuccessModal(false); //close success modal
    }

    // close the account owner name error modal
    const closeOwnerErrorModal = (): void => {
        setOpenWithdrawModal(true); //open withdraw modal for retry
        setOwnerErrorModal(false); //close error modal
    }

    // close the success modal 
    const onHandleSuccessModal = (): void => {
        onHandleCloseFunction(); // close all modal
        componentDidMount(); //update the parent component
    }

    React.useEffect(() => {
        accountNumber && accountNumber.length > 60 ? setAccountNumberError(['error_max_length', { number: 60 }]) : setAccountNumberError(''); //account number
        ownerName && ownerName.length > 255 ? setOwnerNameError(['error_max_length', { number: 255 }]) : setOwnerNameError(''); //owner name
    }, [accountNumber, ownerName])

    // validation check
    React.useEffect(() => {
        if (withdrawalAmount < 1) {
            setIsDisabled(true)
        } else if (!amount || amountError) {
            setIsDisabled(true)
        } else if (!bank.value) {
            setIsDisabled(true)
        } else if (!branch.value) {
            setIsDisabled(true)
        } else if (!accountNumber || accountNumberError || accountNumber.length < 2) {
            setIsDisabled(true)
        } else if (!ownerName || ownerNameError || ownerName.length < 2) {
            setIsDisabled(true)
        } else if (!termCheck) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [amount, amountError, bank, branch, accountNumber, accountNumberError, ownerName, ownerNameError, termCheck])

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {/* withdrawal money modal */}
            <Modal
                centered
                show={openWithdrawModal}
                onHide={onHandleCloseFunction}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-grey-bottom-curve modal-md enter-code-modal`}
            >
                {/* modal header */}
                <Modal.Header className='py-4 mt-2 px-4 px-sm-5 border-bottom-0'>
                    <div className="withdrawal-modal-header px-4">
                        <h5>{IntlMessages('withdraw_head1', { br: <br /> })}</h5>
                    </div>
                </Modal.Header>

                {/* close button */}
                <button type="button" className="btn-close opacity-100" onClick={onHandleCloseFunction}></button>

                {/* content data */}
                <Modal.Body className='gi-ob-withdraw-modal-body px-4 px-sm-5 py-3'>
                    <div className="px-4 py-3">
                        <div className="row gy-4">
                            {/* amount */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <InputTextComponent
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'withdraw_amount_il'}
                                        className={''}
                                        fieldValue={amount}
                                        name={'amount'}
                                        placeholder={'$'}
                                        readOnly={false}
                                        disabled={false}
                                        isError={false}
                                        errorMessage={amountError}
                                        inputValue={setInputValue}
                                        dataTestId={'withdraw-amount'}
                                    />
                                    {/* withdraw and pending transaction amount */}
                                    {!amountError && (<ul className="gi-ob-custom-multiple-error ps-0 mb-0 mt-2">
                                        <li className={`valid-error my-1`}>
                                            <span className="icon">
                                                <img className={`valid-icon`} src={intl.formatMessage({ id: 'BOTTOM_ARROW_GREEN' })} alt="" />
                                            </span>
                                            {IntlMessages('withdraw_cash_available')} <span className="px-1">|</span> {currencyFormatter(withdrawalAmount).split('.')[0]}
                                        </li>
                                        {cashSettlement > 0 && (
                                            <li className={`valid-error my-1`}>
                                                <span className="icon">
                                                    <img className={`valid-icon`} src={intl.formatMessage({ id: 'LOCK_ICON_GREEN_WITHDRAW' })} alt="" />
                                                </span>
                                                {IntlMessages('withdraw_cash_settlement')} <span className="px-1">|</span> {currencyFormatter(cashSettlement).split('.')[0]}
                                            </li>
                                        )}
                                    </ul>)}
                                </div>
                            </div>
                            {/* withdraw label text */}
                            <div className="col-12">
                                <LabelValueField
                                    divClassName={'gi-ob-form form-group'}
                                    labelClassName={'gi-ob-modal-form-label'}
                                    labelText={'withdraw_cash_label'}
                                    strongClassName={''}
                                    strongText={''}
                                />
                            </div>
                            {/* bank list */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <SelectDropdown
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'withdraw_bank_number'}
                                        name={'bank'}
                                        placeholder={'placeholder_dropdown_select'}
                                        option={bankOptionList}
                                        fieldValue={bank}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'bank-list'}
                                        isDisabled={false}
                                        inputValue={selectDropDownValue}
                                        isModalDropdown={true}
                                    />
                                </div>
                            </div>
                            {/* branch number */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <SelectDropdown
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'withdraw_branch_number'}
                                        name={'branch'}
                                        placeholder={'placeholder_dropdown_select'}
                                        option={branchOptionList}
                                        fieldValue={branch}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'branch-list'}
                                        isDisabled={!bank.value}
                                        inputValue={selectDropDownValue}
                                        isModalDropdown={true}
                                    />
                                </div>
                            </div>
                            {/* account number */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <InputTextComponent
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'withdraw_account_number'}
                                        className={''}
                                        fieldValue={accountNumber}
                                        name={'accountNumber'}
                                        placeholder={''}
                                        readOnly={false}
                                        disabled={false}
                                        isError={false}
                                        errorMessage={accountNumberError}
                                        inputValue={setInputValue}
                                        dataTestId={'account-number'}
                                    />
                                </div>
                            </div>
                            {/* account owner name */}
                            <div className="col-12">
                                <div className="gi-ob-form form-group">
                                    <InputTextComponent
                                        labelClassName={'gi-ob-modal-form-label mb-1'}
                                        labelText={'withdraw_account_name'}
                                        className={''}
                                        fieldValue={ownerName}
                                        name={'ownerName'}
                                        placeholder={''}
                                        readOnly={false}
                                        disabled={false}
                                        isError={false}
                                        errorMessage={ownerNameError}
                                        inputValue={setInputValue}
                                        dataTestId={'owner-name'}
                                    />
                                </div>
                            </div>
                            {/* term and condition checkbox */}
                            <div className="col-12">
                                <div className="form-check mt-2 pb-2 pe-3">
                                    <InputCheckboxComponent
                                        labelText={IntlMessages('withdraw_term_checkbox')}
                                        fieldName={'termCheck'}
                                        fieldValue={'termCheck'}
                                        checkValue={termCheck}
                                        inputClass={'form-check-input mt-1'}
                                        labelClass={'gi-ob-form-check-label-02 form-check-label mt-1'}
                                        inputValue={setInputValueCheckbox}
                                        dataTestId={'term-agreed'}
                                    />
                                </div>
                            </div>
                            {/* static text DriveWealth */}
                            <div className="col-12">
                                <LabelValueField
                                    divClassName={'gi-ob-form form-group'}
                                    labelClassName={'gi-ob-modal-form-label'}
                                    labelText={'withdraw_static_text1'}
                                    strongClassName={''}
                                    strongText={''}
                                />
                            </div>
                            {/* Cancel & withdraw button */}
                            <div className="col-12">
                                <div className="pt-3 pb-4 my-2 d-flex align-items-center justify-content-center gap-2">
                                    <button type="button" className="gi-ob-cancel-btn" onClick={onHandleCloseFunction}>{IntlMessages('button_cancel')}</button>
                                    <button type="button" className="gi-ob-withdraw-btn" onClick={onHandleWithdraw} disabled={isDisabled}>{IntlMessages('button_withdraw2')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* loading modal */}
            <LoadingModal
                openLoadingModal={openLoadingModal}
                title={openLoadingModalMessage}
                onHandleClose={onHandleCloseFunction}
            />

            {/* success modal */}
            <SuccessModalIL
                isModalOpen={openSuccessModal}
                onHandleClose={onHandleSuccessModal}
            />

            {/* account owner name error modal */}
            <Modal
                centered
                show={openOwnerErrorModal}
                onHide={closeOwnerErrorModal}
                backdrop="static"
                keyboard={false}
                className={`gi-ob-white-bottom-curve modal-md enter-code-modal`}
                contentClassName='border-0'
            >
                {/* close button */}
                <button type="button" className="btn-close opacity-100" onClick={closeOwnerErrorModal}></button>

                {/* modal body */}
                <Modal.Body className='px-5'>
                    <div className="gi-ob-trouble-modal-text text-center py-4 mt-3 mb-3 pb-4 px-2">
                        <img src={intl.formatMessage({ id: 'TROUBLE_ICON' })} alt="" />
                        <h5 className="mt-3 mb-4">{IntlMessages('withdraw_bank_owner_name_error1')}</h5>
                        <p className="mb-4">{IntlMessages('withdraw_bank_owner_name_error2')}</p>
                        <button type="button" className="mt-2" onClick={closeOwnerErrorModal}>{IntlMessages('button_ok_2')}</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WithdrawMoneyModalIL