//default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// type import
import * as PropsFunction from 'interface/PropsFunction';
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';

export type Props = {
    isModalOpen: boolean;
    onHandleClose: PropsFunction.SimpleFunction;
}

const SuccessModalIL: React.FC<Props> = (props: Props) => {
    const { isModalOpen, onHandleClose } = props;
    const intl = useIntl();

    return (
        <Modal
            centered
            show={isModalOpen}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            className={`gi-ob-green-bottom-curve modal-md enter-code-modal enter-code-modal-success`}
        >
            {/* close button */}
            <button type="button" className="btn-close opacity-100" onClick={onHandleClose}></button>

            {/* modal body */}
            <Modal.Body>
                <div className="gi-ob-transfer-modal-text text-center py-4 mt-3 mb-2 pb-4  px-1">
                    <img src={intl.formatMessage({ id: 'GIRAFFE_ICON_WITHDRAW' })} alt="" />
                    <h5 className="mt-3 mb-2">{IntlMessages('fund_success')}</h5>
                    <p className="mb-3">
                        {IntlMessages('withdraw_bank_transfer')}
                    </p>
                    <button type="button" onClick={onHandleClose}>
                        {IntlMessages('button_ok_2')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default SuccessModalIL