/**
 * Resize the kyc tooltip error message
 * set the width with js
 * @param {...String}
 * params list of the pass field name
 */
const resizeErrorMessage = (...params: string[]) => {
    params.forEach(e => {
        const errorDivWidth = document.getElementById(`${e}-error`);
        if (errorDivWidth) {
            const element = document.getElementById(e);
            if (element) {
                const width = element.getBoundingClientRect().width;
                errorDivWidth.style.maxWidth = `${width}px`;
            }
        }
    })
}

export default resizeErrorMessage