import * as React from 'react';
// Import the useIntl hook from react-intl
import { useIntl } from 'react-intl';
//types props
import * as PropsFunction from "interface/PropsFunction";

//Define receiving props
export type Props = {
    name: string;
    placeholder: string;
    className: string;
    fieldValue: string;
    readOnly: boolean;
    disabled: boolean;
    dataTestId: string;
    inputValue: PropsFunction.PropsInputValueFunction;
}
const InputComponentText2: React.FC<Props> = (props: Props) => {
    const { name, placeholder, className, fieldValue, readOnly, disabled, inputValue, dataTestId } = props // Destructuring props
    const intl = useIntl();

    // function for input value changes
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const target = e.target
        inputValue(target.name, target.value);// Call the inputValue function with the updated value
    };

    return (
        <input
            type='text'
            name={name} //set field name
            id={name} //set field name
            placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
            className={className}
            data-testid={dataTestId}
            value={fieldValue} //set field value
            onChange={handleOnChange} //invoke on every key press
            readOnly={readOnly} //check field is readOnly or not
            disabled={disabled} //disabled field
        />
    )
}

export default InputComponentText2;