//default library
import * as React from 'react';
//custom components
import ButtonComponent from 'components/ButtonComponent/ButtonComponent';
import SelectOnBoarding from 'components/SelectComponent/SelectOnBoarding';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types import
import * as PropsFunction from "interface/PropsFunction";
import * as OnBoarding from "interface/OnBoarding";
import * as UserType from "api/APIMaster/UserType";
// dummy data
import * as OnBoardingData from "service/DummyData/OnBoardingData";
// custom function
import * as Local from 'service/utils/LocalStorageData';

//receiving props
type Props = {
  staticData: UserType.OnboardingStaticDataResponseModel
  propsData: PropsFunction.PropsPageDataFunction;
}

const EmploymentStatus: React.FC<Props> = (props: Props) => {
  const { staticData, propsData } = props//destructuring props

  const [employmentList, setEmploymentList] = React.useState<OnBoarding.OptionData[]>([]);//list of employment status options, initialized as an empty array
  const [employmentStatus, setEmploymentStatus] = React.useState<OnBoarding.OptionData>(OnBoardingData.defaultOptionValue);//selected employment status,
  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({});//field details, initialized with labelCitizenship from OnBoardingData
  const [nextPage, setNextPage] = React.useState<string>('');//next page, initialized as an empty string

  const sendText = false;
  const isRouteChange = true
  const submitText = false;
  const parentButton = false;

  React.useEffect(() => {
    // Accessing data from local storage
    const localStorageData = Local.getLocalData('onBoardingDetails');

    // props data 
    propsData({
      previousPage: localStorageData?.countryLabel === 'United States' ? 'page5' : 'page6',
      pageNumber: 7,
    });

    // mapping  employmentStatusList data for Select component
    const employmentStatusList = staticData.employmentStatusList.map((value) => {
      return {
        value: value.identifier,
        label: value.nameLocale,
        active: (value.name === 'EMPLOYED' || value.name === 'SELF_EMPLOYED') ? true : false
      };
    });
    setEmploymentList(employmentStatusList);

    // store local storage value if present
    if (localStorageData?.employmentIdentifier) {
      const isActive = staticData.employmentStatusList.find(e => e.identifier === localStorageData.employmentIdentifier);
      setEmploymentStatus({
        label: localStorageData.employmentLabel,
        value: localStorageData.employmentIdentifier,
        active: isActive ? isActive.active : false,
      });
    }
  }, []);

  // setting input value for employmentStatus
  const setInputValue: OnBoarding.SelectedOption = (fieldValue) => {
    setEmploymentStatus(fieldValue);
  };

  //updating fieldDetails and nextPage based on employmentStatus
  React.useEffect(() => {
    const newFieldDetails = {
      employmentIdentifier: employmentStatus.value,
      employmentLabel: employmentStatus.label,
    };

    //nextPage based on employmentStatus label
    const nextPage = employmentStatus.active ? 'page8' : 'page9';
    setNextPage(nextPage);

    setFieldDetails(newFieldDetails);
  }, [employmentStatus])


  return (
    <>
      <div className="gi-ob-slide-card" data-testid='employment-status'>
        {/* static text */}
        <div className="gi-ob-full-name-text gi-ob-employment-text">
          <h5>{IntlMessages('employment_status_text1')}</h5>
          <p>{IntlMessages('employment_status_text2')}</p>
        </div>

        {/* employment status */}
        <div className="gi-ob-full-name-form gi-ob-employment-form">
          <div className="gi-ob-input-box gi-ob-employment-input">
            {employmentList.length > 0 && (
              <SelectOnBoarding
                name={'employmentStatus'}
                option={employmentList}
                placeholder={'placeholder_employment_status'}
                fieldValue={employmentStatus}
                inputValue={setInputValue}
                ariaLabel={'employment-label'} />
            )}
          </div>
        </div>
      </div>

      {/* desktop button */}
      <div className="gi-ob-let-btn-group gi-ob-employment-btn-group">
        <ButtonComponent
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isFooter={false}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
        />
      </div>

      {/* footer button for mobile */}
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
      />
    </>
  );
};

export default EmploymentStatus;


