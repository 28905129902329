// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// message import
import IntlMessages from 'helper/IntlMessages';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, getLocale } from 'reduxStore/reducers/LanguageReducer';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// custom function
import * as Local from 'service/utils/LocalStorageData';
// custom component
import LocationEmailModal from 'components/ModalComponent/LocationEmailModal';
import SvgIcon from 'components/CommonComponent/SvgIcon';

export type Props = {
    workEmail: string;
    propsData: PropsFunction.PropsStringNumberFunction
}

const KYCStartPage: React.FC<Props> = (props: Props) => {
    const { workEmail, propsData } = props;
    const history = useHistory();

    const dispatch = useDispatch();
    const locale = useSelector(getLocale);

    const [openLocationEmailModal, setOpenLocationEmailModal] = React.useState<boolean>(false); //location adn email confirmation modal
    const [locationValue, setLocationValue] = React.useState<string>('');

    React.useEffect(() => {
        propsData('/on-boarding', 0);
        setLocationValue(locale.countryCode)
    }, [])

    // open/close location email selection modal
    const openCloseLocationEmailModal = (): void => {
        setOpenLocationEmailModal((prev) => !prev);
    }

    // change dropdown value
    const changeValue = (value: string): void => {
        setLocationValue(value)
    }

    // goto next page
    const gotoNextPage = (): void => {
        Local.setLocalData('onBoardingDetails', { start: 'start' })
        setTimeout(() => {
            history.push('/on-boarding/page1');
            changeCurrentLanguage(); //change the language
        }, 100);
    }

    // goto dashboard page
    const gotoDashboard = (): void => {
        history.push('/dashboard');
        changeCurrentLanguage();
    }

    // change the language based on the user selection
    const changeCurrentLanguage = (): void => {
        dispatch(setCurrentLanguage(locationValue));
    }

    return (
        <>
            <span className="gi-ob-close-btn" onClick={gotoDashboard}>
                <SvgIcon
                    iconId='close-white-icon'
                    width={16}
                    height={16}
                />
            </span>
            <div className="container-fluid px-2">
                <div className="row">
                    <div className="col-12">
                        <div className="gi-ob-intro-text">
                            {/* DESKTOP START */}
                            <h5 className="d-none d-md-block">
                                {IntlMessages('kyc_start_page_text1_desk')}
                            </h5>

                            <h6 className="d-none d-md-block mt-md-1 mt-2 px-md-0 px-3">
                                {IntlMessages('kyc_start_page_text2_desk')}
                            </h6>

                            <p className="d-none d-md-block mt-3 mb-1 mx-auto px-md-0 px-3">
                                {IntlMessages('kyc_start_page_text3_desk', { br: <br /> })}
                            </p>
                            {/* DESKTOP END */}

                            {/* MOBILE START */}
                            <h5 className="d-block d-md-none">
                                {IntlMessages('kyc_start_page_text1_mob', { br: <br /> })}
                            </h5>

                            <h6 className="d-block d-md-none mt-md-1 mt-2 px-md-0 px-3">
                                {IntlMessages('kyc_start_page_text2_mob', { br: <br /> })}
                            </h6>

                            <p className="d-block d-md-none mt-3 mb-1 mx-auto px-md-0 px-3">
                                {IntlMessages('kyc_start_page_text3_mob', { br: <br /> })}
                            </p>
                            {/* MOBILE END */}

                            <button className="mt-4" type="button" onClick={openCloseLocationEmailModal} data-testid="start-btn">
                                {IntlMessages('kyc_let_start')}
                            </button>

                            <span className="mt-4">
                                <a href="#!" onClick={gotoDashboard} data-testid="not-start-btn">
                                    {IntlMessages('kyc_not_start')}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Location, Work Email Modal open */}
            <LocationEmailModal
                openModal={openLocationEmailModal}
                locationValue={locationValue}
                workEmail={workEmail}
                changeValue={changeValue}
                onHandleClose={openCloseLocationEmailModal}
                onHandleContinue={gotoNextPage}
                onHandleDashboard={gotoDashboard}
            />
        </>
    )
}

export default KYCStartPage