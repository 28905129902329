import * as React from 'react';
import { useHistory } from 'react-router-dom';
// auth0 library
import { useAuth0 } from '@auth0/auth0-react';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, getLocale } from 'reduxStore/reducers/LanguageReducer';
// custom component
import Header from 'components/HeaderComponent/Header';
// intl message library 
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';


const PageNotFound: React.FC = () => {

    const { isAuthenticated } = useAuth0();
    const history = useHistory();
    const intl = useIntl();

    const dispatch = useDispatch();
    const locale = useSelector(getLocale);

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, [])

    React.useEffect(() => {
        if (!locale.isReducerCalled) {
            dispatch(setCurrentLanguage('USA'))
        }
    }, [])

    const handleWindowResize = (): void => {
        const errorImgHeight = document.getElementsByClassName("bg-error")[0] as HTMLElement;
        const errorSection = document.getElementsByClassName("error-section")[0] as HTMLElement;
        if (window.innerWidth >= 992) {
            errorSection.style.paddingBottom = errorImgHeight.offsetHeight - 80 + 'px';
        } else {
            errorSection.style.paddingBottom = '1px';
        }
    }

    const redirectHome = (): void => {
        history.push('/')
    }

    return (
        <>
            {isAuthenticated && <Header />}
            <section className="error-section min-vh-100 position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 mx-auto">
                            <div className="error-section-text position-relative">
                                <h1>
                                    {IntlMessages('error_404')}
                                </h1>
                                <h5 className="mt-3">
                                    {IntlMessages('error_notFound')}
                                </h5>
                                <h6 className="mt-4">
                                    {IntlMessages('error_validUrl')}
                                    <a onClick={redirectHome}>
                                        {IntlMessages('error_returnHome')}
                                    </a>
                                </h6>
                                <p className="mt-lg-1 mt-3">
                                    {IntlMessages('error_fixURL')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-error">
                    <img src={intl.formatMessage({ id: 'BACKGROUND_404' })} className="w-100 bg-error-desktop d-md-block d-none" alt="" />
                    <img src={intl.formatMessage({ id: 'BACKGROUND_404_MOB' })} className="w-100 bg-error-mob d-md-none d-block" alt="" />
                </div>
            </section>
        </>
    );
}

export default PageNotFound;
