import DriveWealthService from "api/APIServices/DriveWealthService";
import * as Local from 'service/utils/LocalStorageData';
import * as DriveWealth from "api/APIMaster/DriveWealthType"

const DiscloserService = new DriveWealthService();

export const getDisclosureUrl = async (accessToken: string) => {
    const localStorageData = Local.getLocalData("onBoardingDetails");

    const data = {
        citizenship: localStorageData.citizenship ?? '',
        country: localStorageData.countryDisclosure ?? '',
        language: 'en_US',
        usTaxPayer: localStorageData.taxPayer === 'true' || localStorageData?.countryLabel === 'United States' ? true : false,
    };

    return await DiscloserService.getDisclosureUrl(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const statementDetails = async (dateRange: DriveWealth.CommonStatementDateRequestModel, accessToken: string,) => {

    //set params
    const data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    }

    return await DiscloserService.statementDetails(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const taxStatementDetails = async (accessToken: string) => {

    return await DiscloserService.taxStatementDetails({ authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const tradeConfirmationDetails = async (dateRange: DriveWealth.CommonStatementDateRequestModel, accessToken: string) => {

    //set params
    const data = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
    }

    return await DiscloserService.tradeConfirmationDetails(data, { authToken: accessToken })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}

export const downloadPdfDetails = async (fileKey: string, accessToken: string) => {
    return await DiscloserService.downloadPdfDetails(fileKey, { authToken: accessToken })
        .then(res => res.data.url)
        .catch(err => { return { ...err } })
}