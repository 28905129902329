import { ApexOptions } from "apexcharts";
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
// chart language
import en from 'apexcharts/dist/locales/en.json';
import he from 'apexcharts/dist/locales/he.json';

// home page performance chart option
export const HomePerformanceChart: ApexOptions = {
    chart: {
        id: 'performance',
        height: 171,
        type: "area",
        zoom: { enabled: false },
        locales: [en, he],
        defaultLocale: 'en',
        toolbar: {
            show: false
        }
    },
    colors: ['#95E588'],
    stroke: {
        curve: 'smooth',
        width: 3,
    },
    dataLabels: {
        enabled: false
    },
    grid: {
        show: true,
        borderColor: 'rgba(129, 173, 175, 0.3)',
        strokeDashArray: 3,
    },
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 0,
            opacityFrom: 0,
            opacityTo: 0,
            stops: [0, 90, 100]
        }
    },
    xaxis: {
        type: 'category',
        categories: [],
        offsetX: 8,
        tickAmount: 5,
        labels: {
            rotate: 0,
            rotateAlways: false,
            style: {
                colors: '#DAFFD0',
                fontSize: '13px',
                fontFamily: 'Roboto Slab',
                fontWeight: '300',
                // letterSpacing: '0.02em',
            },
        },
        axisBorder: {
            show: true
        },
        axisTicks: {
            show: false
        },
        tooltip: {
            enabled: false
        }
    },
    yaxis: {
        tickAmount: 5,
        floating: false,
        opposite: true,
        showAlways: true,
        min: (min: any) => Math.floor(min),
        max: (max: any) => Math.ceil(max),
        labels: {
            align: 'right',
            style: {
                colors: '#DAFFD0',
                fontSize: '13px',
                fontFamily: 'Roboto Slab',
                fontWeight: 300,
                // letterSpacing: '0.02em',
                // opacity: 1
            },
            formatter: (val: any) => currencyFormatter(parseInt(val)).replace('.00', ''),
        },
        tooltip: {
            enabled: false
        },
    },
    responsive: [{
        breakpoint: 767,
        options: {
            xaxis: {
                tickAmount: 4,
            },
        }
    }],
    tooltip: {
        custom: function ({
            series,
            seriesIndex,
            dataPointIndex
        }: any) {
            return (
                '<div class="arrow-dash-graph-box">' +
                "<span>" + currencyFormatter(series[seriesIndex][dataPointIndex]) +
                "</span>" +
                "</div>"
            );

        },
    },
    annotations: {}
}

// basket detail page future chart option
export const FutureProjectionChart: ApexOptions = {
    chart: {
        id: 'future-projection',
        height: 171,
        type: "rangeArea",
        zoom: { enabled: false },
        locales: [en, he],
        defaultLocale: 'en',
        toolbar: {
            show: false
        }
    },
    colors: ["#05B675", "#95E588"],
    dataLabels: {
        enabled: false
    },
    fill: {
        opacity: [1, 1]
    },
    stroke: {
        curve: 'smooth',
        width: [0, 4],
    },
    legend: {
        show: false
    },
    grid: {
        show: true,
        borderColor: 'rgba(129, 173, 175, 0.3)',
        strokeDashArray: 3,
    },
    xaxis: {
        type: 'datetime',
        labels: {
            style: {
                colors: '#DAFFD0',
                fontSize: '13px',
                fontFamily: 'Roboto Slab',
                fontWeight: '300',
            },

        },
        axisBorder: {
            show: true
        },
        axisTicks: {
            show: false
        },
    },
    yaxis: {
        tickAmount: 6,
        floating: false,
        opposite: true,
        showAlways: true,
        forceNiceScale: true,
        labels: {
            align: 'right',
            style: {
                colors: '#DAFFD0',
                fontSize: '13px',
                fontFamily: 'Roboto Slab',
                fontWeight: 300,
            },
            formatter: function (val) {
                return currencyFormatter(val).replace('.00', '');
            },
            offsetY: 0,
            offsetX: 0,
        },
        axisBorder: {
            show: false,
            color: '#78909C',
            width: 6,
        },
        axisTicks: {
            show: false,
        }
    },

    tooltip: {
        enabled: false
    },
}

// basket detail page performance chart option
export const BasketPerformanceChart: ApexOptions = {
    chart: {
        id: 'performance-basket',
        height: 180,
        type: "area",
        zoom: { enabled: false },
        locales: [en, he],
        defaultLocale: 'en',
        toolbar: {
            show: false
        }
    },
    colors: ['#95E588', '#05B675'],
    stroke: {
        curve: 'smooth',
        width: [5, 1],
    },
    dataLabels: {
        enabled: false
    },
    grid: {
        show: true,
        borderColor: 'rgba(129, 173, 175, 0.3)',
        strokeDashArray: 3,
    },
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 0,
            opacityFrom: 0,
            opacityTo: 0,
            stops: [0, 90, 100]
        }
    },
    legend: {
        show: false,
    },
    xaxis: {
        type: 'category',
        categories: [],
        offsetX: 8,
        tickAmount: 5,
        labels: {
            rotate: 0,
            rotateAlways: false,
            style: {
                colors: '#DAFFD0',
                fontSize: '13px',
                fontFamily: 'Roboto Slab',
                fontWeight: '300',
            },
            format: 'MMM \'yy'
        },
        axisBorder: {
            show: true
        },
        axisTicks: {
            show: false
        },
        tooltip: {
            enabled: false
        }
    },
    yaxis: {
        tickAmount: 6,
        floating: false,
        opposite: true,
        showAlways: true,
        min: (min) => Math.floor(min),
        max: (max) => Math.ceil(max),
        labels: {
            align: 'right',
            style: {
                colors: '#DAFFD0',
                fontSize: '13px',
                fontFamily: 'Roboto Slab',
                fontWeight: 300,
            },
            formatter: (val) => currencyFormatter(val).replace('.00', ''),
            offsetY: 0,
            offsetX: 0,
        },
        tooltip: {
            enabled: false
        },
    },
    responsive: [{
        breakpoint: 767,
        options: {
            xaxis: {
                tickAmount: 4,
            },
        }
    }],
    tooltip: {
        custom: function ({
            series,
            seriesIndex,
            dataPointIndex,
        }) {
            return (
                '<div class="arrow-dash-graph-box">' +
                "<span>" + currencyFormatter(series[seriesIndex][dataPointIndex]) +
                "</span>" +
                "</div>"
            );

        },
    },
    annotations: {}
}

// basket detail page pie chart
export const BasketPieChart: ApexOptions = {
    chart: {
        id: 'basket-pie-chart',
        width: '100%',
        type: "donut",
        zoom: { enabled: false },
        locales: [en, he],
        defaultLocale: 'en',
        toolbar: {
            show: false
        }
    },
    plotOptions: {
        pie: {
            expandOnClick: false,
            donut: {
                size: '50%'
            }
        }
    },
    stroke: {
        show: false,
    },
    colors: ['#016168', '#FFFFFF', '#B6FF7D'],
    dataLabels: {
        enabled: false,
    },
    legend: {
        show: false
    },
    labels: ['Excluded companies', 'Added companies', 'Portfolio companies'],
    tooltip: {
        enabled: false
    },
    states: {
        hover: {
            filter: {
                type: 'none',
            }
        },
        active: {
            allowMultipleDataPointsSelection: false,
            filter: {
                type: 'none',
            }
        },
    }
}

// bar chart
export const BarChartOptions: ApexOptions = {
    chart: {
        id: 'barChartPortfolio',
        type: 'bar',
        height: 295,
        zoom: { enabled: false },
        locales: [en, he],
        defaultLocale: 'en',
        toolbar: {
            show: false
        }
    },
    plotOptions: {
        bar: {
            borderRadius: 2,
            horizontal: false,
            columnWidth: '55%',
        },
    },
    dataLabels: {
        enabled: false,
    },
    title: {
        text: 'Annual return:',
        align: 'left',
        margin: 0,
        offsetY: 20,
        offsetX: 7,
        style: {
            fontSize: '15px',
            fontWeight: 300,
            fontFamily: 'Roboto Slab',
            color: '#42474B'
        },
    },
    grid: {
        show: true,
        borderColor: 'rgba(0, 0, 0, 0.2)',
        strokeDashArray: 2,
        xaxis: {
            lines: {
                show: false
            }
        },
        yaxis: {
            lines: {
                show: true
            }
        },
    },
    stroke: {
        show: true,
        width: 5,
        colors: ['transparent']
    },
    xaxis: {
        categories: [],
        labels: {
            style: {
                colors: '#42474B',
                fontSize: '15px',
                fontFamily: 'Roboto Slab',
                fontWeight: 400,
            },
            offsetX: 0,
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false
        },
    },
    yaxis: {
        floating: false,
        opposite: true,
        labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            style: {
                colors: [],
                fontSize: '15px',
                fontFamily: 'Roboto Slab',
                fontWeight: 300,
                cssClass: 'apexcharts-yaxis-label',
            },
            formatter: function (val) {
                return val + '%';
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
        },
    },
    fill: {
        opacity: 1,
    },
    colors: ['#70DD5F', '#11706B'],
    legend: {
        show: true,
        horizontalAlign: 'left',
        floating: false,
        fontSize: '15px',
        fontFamily: 'Roboto Slab',
        fontWeight: 300,
        labels: {
            colors: '#42474B',
        },
        markers: {
            width: 15,
            height: 9,
            strokeWidth: 0,
            strokeColor: '#f60',
            fillColors: undefined,
            radius: 2,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -5,
            offsetY: 0
        },
        itemMargin: {
            horizontal: 15,
            vertical: 15
        },
    }
};

//line Chart page option
export const LineChartOptions: ApexOptions = {
    chart: {
        id: 'line-chart-graph',
        height: 277,
        type: 'line',
        zoom: { enabled: false },
        locales: [en, he],
        defaultLocale: 'en',
        toolbar: {
            show: false
        }
    },
    dataLabels: {
        enabled: false,
    },
    title: {
        text: 'Accumulated return:',
        align: 'left',
        margin: 10,
        offsetY: 15,
        style: {
            fontSize: '15px',
            fontWeight: 300,
            fontFamily: 'Roboto Slab',
            color: '#42474B',
        },
    },
    colors: ['#27B380', '#42474B'],
    stroke: {
        curve: 'smooth',
        width: [4, 1],
    },
    grid: {
        show: true,
        borderColor: '#CDD2D7',
    },
    yaxis: {
        labels: {
            style: {
                colors: ['#42474B'],
                fontSize: '15px',
                fontFamily: 'Roboto Slab',
                fontWeight: 400,
            },
            formatter: (val: any) => `${Math.floor(val)}%`,
        },
        tickAmount: 5,
        min: (min: number) => min - 0.2,
        max: (max: number) => max + 0.2,
        axisBorder: {
            show: true,
            color: 'rgba(255, 255, 255, 0.3)',
            offsetX: -1,
            offsetY: -2,
        },
    },
    xaxis: {
        type: 'datetime',
        tickPlacement: 'on',
        labels: {
            style: {
                colors: ['#42474B'],
                fontSize: '15px',
                fontFamily: 'Roboto Slab',
                fontWeight: 400,
            },
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
    legend: {
        show: true,
        horizontalAlign: 'left',
        floating: false,
        fontSize: '15px',
        fontFamily: 'Roboto Slab',
        fontWeight: 300,
        labels: {
            colors: '#42474B',
        },
        markers: {
            width: 15,
            height: 9,
            strokeWidth: 0,
            strokeColor: '#f60',
            fillColors: undefined,
            radius: 2,
            customHTML: undefined,
            onClick: undefined,
            offsetX: -5,
            offsetY: 0,
        },
        itemMargin: {
            horizontal: 15,
            vertical: 15,
        },
    },
};

//Area chart page option 
export const AreaChartOptions: ApexOptions = {
    chart: {
        id: 'area-chart-option',
        height: 255,
        type: "area",
        zoom: { enabled: false },
        locales: [en, he],
        defaultLocale: 'en',
        toolbar: {
            show: false
        }
    },
    dataLabels: {
        enabled: false,
    },
    title: {
        text: 'In thousands',
        align: 'left',
        margin: 10,
        offsetY: 5,
        offsetX: 7,
        style: {
            fontSize: '12px',
            fontWeight: 300,
            fontFamily: 'Roboto Serif',
            color: '#42474B',
        },
    },
    colors: ['#27B380'],
    fill: {
        type: "gradient",
        gradient: {
            shade: 'dark',
            gradientToColors: ['#F1F7F4'],
            shadeIntensity: 1,
            opacityFrom: 0.4,
            opacityTo: 0.8,
            stops: [0, 90, 100]
        }
    },
    grid: {
        show: true,
        borderColor: '#F1F7F4 ',
        row: {
            colors: ['#fafafb'],
            opacity: 0
        },
    },
    yaxis: {
        labels: {
            style: {
                colors: ['#42474B'],
                fontSize: '13px',
                fontFamily: 'Roboto Slab',
                fontWeight: 300,
            },
            formatter: (value) => {
                return '$' + (Math.ceil(value / 1000)).toLocaleString('en-US');
            }
        },
        tickAmount: 5,
        min: (min) => (min) < 100000 ? (min - 3000) : 100000,
        max: (max) => (max + 5000),
        floating: false,
        opposite: false,
        axisBorder: {
            show: false,
        },
    },
    xaxis: {
        type: 'datetime',
        labels: {
            style: {
                colors: ['#42474B'],
                fontSize: '14px',
                fontFamily: 'Roboto Slab',
                fontWeight: 400,
            },
        },
        axisBorder: {
            color: '#00000033',
        },
    },
    tooltip: {

        y: {
            formatter: function (value) {
                return `$${value}`
            }
        }
    },
};