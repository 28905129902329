//default props
import * as React from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// device detect library
import { isMobile } from "react-device-detect";
//types props
import * as BasketType from "api/APIMaster/BasketType"
// chart option
import { BarChartOptions } from 'config/ChartOptions';
// other lang import
import OtherTranslations from 'lang/OtherTranslations';

export type Props = {
    sp500GraphData: BasketType.YearlyPerformanceData[];
}

const BarChartPortfolio: React.FC<Props> = (props: Props) => {
    const { sp500GraphData } = props //destructuring props
    const locale = useSelector(getLocale);

    React.useEffect(() => {
        const updateOption: ApexOptions = {
            title: {
                text: OtherTranslations[locale.language].annualTitle,
                align: locale.direction === 'rtl' ? 'right' : 'left',
                style: {
                    ...BarChartOptions.title?.style,
                    fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
                }
            },
            xaxis: {
                ...BarChartOptions.xaxis,
                categories: props.sp500GraphData.map((e) => e.performanceYear),
            },
            yaxis: {
                ...BarChartOptions.yaxis,
                opposite: locale.direction !== 'rtl'
            },
            legend: {
                ...BarChartOptions.legend,
                horizontalAlign: locale.direction === 'rtl' ? 'right' : 'left',
                fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
            },
            tooltip: {
                ...BarChartOptions.tooltip,
                style: {
                    ...BarChartOptions.tooltip?.style,
                    fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
                }
            }
        }
        ApexCharts.exec('barChartPortfolio', 'updateOptions', { ...updateOption }, false, false, false);
        ApexCharts.exec('barChartPortfolio', 'setLocale', locale.direction === 'rtl' ? 'he' : 'en')
    }, [])

    const series: ApexCharts.ApexOptions['series'] = [
        {
            name: OtherTranslations[locale.language].series1,
            data: sp500GraphData.map((e) => e.personalizedPerformance),
        }, //personalized portfolio data series
        {
            name: OtherTranslations[locale.language].series2,
            data: sp500GraphData.map((e) => e.sp500Performance),
        }, //'S&P 500 data series
    ];

    return (
        <div className="row">
            <ReactApexChart
                options={BarChartOptions}
                series={series}
                type="bar"
                height={isMobile ? 350 : 295}
                dir='ltr'
            />
        </div>
    );
};

export default BarChartPortfolio;





