// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';

const PreventBackNavigation: React.FC = () => {
    const history = useHistory();

    React.useEffect(() => {
        const handleBeforeUnload = (event: BeforeUnloadEvent): void => {
            // Prevent browser back navigation
            event.preventDefault();
        };

        const handlePopState = (): void => {
            // Restore the previous location and prevent back navigation
            history.go(1);
        };

        // Attach the event listeners
        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handlePopState);

        // Clean up the event listeners on component unmount
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [history]);

    return <></>;
};

export default PreventBackNavigation;
