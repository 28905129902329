//default library
import * as React from "react"
import Modal from 'react-bootstrap/Modal';
//custom components
import InputTextComponent from 'components/InputComponent/InputTextComponent'
import TextAreaComponent3 from 'components/InputComponent/TextAreaComponent3'
//helper message
import IntlMessages from 'helper/IntlMessages';
//types import
import * as PropsFunction from "interface/PropsFunction";

//defining props
export type Props = {
    brokerDealer: boolean;
    stockOwner: boolean;
    publicOfficial: boolean;
    companyName: string;
    companyNameError: string;
    tickerSymbol: string;
    tickerSymbolError: string;
    publicOfficialText: string;
    publicOfficialTextError: string;
    onHandleCloseStock: PropsFunction.SimpleFunction;
    onHandleClosePublic: PropsFunction.SimpleFunction;
    onHandleConfirm: PropsFunction.SimpleFunction;
    setInputValue: PropsFunction.PropsInputValueFunction;
}


const EmploymentStockModalIL: React.FC<Props> = (props: Props) => {
    const { brokerDealer, onHandleConfirm, stockOwner, onHandleCloseStock, publicOfficial, onHandleClosePublic, companyName, tickerSymbol, publicOfficialText, setInputValue, companyNameError, tickerSymbolError, publicOfficialTextError } = props//destructuring props


    // Button enabling condition for stockButton
    const stockButton = (companyName && !companyNameError) && (tickerSymbol && !tickerSymbolError)

    // Button enabling condition for publicButton
    const publicButton = publicOfficialText && !publicOfficialTextError

    return (
        <>
            {/* broker dealer modal(question3) */}
            <Modal
                centered
                size='sm'
                show={brokerDealer}
                onHide={onHandleConfirm}
                backdrop="static"
                keyboard={false} className="gi-ob-white-bottom-curve enter-code-modal ">
                <Modal.Body className='py-4 px-4'>
                    <button type="button" className="btn-close opacity-100" onClick={onHandleConfirm} data-testid='close-modal'></button>
                    <div className="gi-ob-np-modal-text mt-3 px-3 mb-3" data-testid='broker-modal'>
                        <h5 className="mb-0">{IntlMessages('kyc_politically_exposed_yes3_head')}</h5>
                        <p>{IntlMessages('kyc_politically_exposed_yes3_body')}</p>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ticker symbol modal(question2) */}
            <Modal
                centered
                size='sm'
                show={stockOwner}
                onHide={onHandleCloseStock}
                backdrop="static"
                keyboard={false} className="gi-ob-white-bottom-curve enter-code-modal"
            >
                <button type="button" className="btn-close opacity-100" onClick={onHandleCloseStock} data-testid='close-modal'></button>
                <Modal.Body className='pt-5 pb-0 px-4' data-testid='stock-owner'>
                    {/* company and  ticker*/}
                    <div className="enter-code-modal-text px-1">
                        <div className="mx-auto px-3">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-ob-form form-group mb-3">
                                        <label className="mb-3 text-start d-block">
                                            {IntlMessages('kyc_politically_exposed_yes2_head', { br: <br /> })}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="gi-ob-form form-group mb-3">
                                        <InputTextComponent
                                            labelClassName={''}
                                            labelText={''}
                                            className=''
                                            fieldValue={companyName}
                                            name={'companyName'}
                                            placeholder={'kyc_employment_detail_company_name'}
                                            readOnly={false}
                                            disabled={false}
                                            isError={Boolean(companyNameError)}
                                            errorMessage={companyNameError}
                                            inputValue={setInputValue}
                                            dataTestId={"company-value"}
                                        />
                                    </div>
                                </div>
                                <div className="col-10">
                                    <div className="gi-ob-form form-group mb-3">
                                        <InputTextComponent
                                            labelClassName={''}
                                            labelText={''}
                                            className=''
                                            fieldValue={tickerSymbol}
                                            name={'tickerSymbol'}
                                            placeholder={'kyc_employment_detail_ticker'}
                                            readOnly={false}
                                            disabled={false}
                                            isError={Boolean(tickerSymbolError)}
                                            errorMessage={tickerSymbolError}
                                            inputValue={setInputValue}
                                            dataTestId={"ticker-symbol"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {/* confirm  button */}
                <Modal.Footer className="enter-code-modal-footer justify-content-start bg-white border-top-0 px-4 pt-0">
                    <div className="text-center pb-4 mb-3 px-3">
                        <button className="eng-btn" disabled={!stockButton} onClick={onHandleConfirm} data-testid='confirm-button'>
                            {IntlMessages('btn_continued')}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* family member info modal(question1) */}
            <Modal
                centered
                size='sm'
                show={publicOfficial}
                onHide={onHandleClosePublic}
                backdrop="static"
                keyboard={false} className="gi-ob-white-bottom-curve enter-code-modal"
            >
                <button type="button" className="btn-close opacity-100" onClick={onHandleClosePublic} data-testid='close-modal'></button>

                <Modal.Body className='pt-5 pb-0 px-4'>
                    <div className="enter-code-modal-text px-1" data-testid='public-official'>
                        <div className="gi-ob-form form-group mb-2 mx-auto px-3">
                            <label className="mb-3 text-start d-block">{IntlMessages('kyc_politically_exposed_yes1_head', { br: <br /> })}</label>
                            <TextAreaComponent3
                                labelText={''}
                                fieldValue={publicOfficialText}
                                name={'publicOfficialText'}
                                rows={5}
                                className={'text-center'}
                                placeholder={'placeholder_name_en'}
                                errorMessage={publicOfficialTextError}
                                inputValue={setInputValue}
                                dataTestId={'public-text'}
                            />
                        </div>
                    </div>
                </Modal.Body>
                {/* confirm  button */}
                <Modal.Footer className="enter-code-modal-footer justify-content-start bg-white border-top-0 px-4 pt-0">
                    <div className="text-center pb-4 mb-3 px-3">
                        <button className="eng-btn" disabled={!publicButton} onClick={onHandleConfirm} data-testid='confirm-button'>
                            {IntlMessages('btn_continued')}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EmploymentStockModalIL;

