// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
import Select, { StylesConfig } from 'react-select';
//types props
import * as PropsFunction from "interface/PropsFunction";
import * as DefaultValueType from "interface/DefaultValueType";
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// default value
import { locationSelector } from 'config/DefaultValue';

export type Props = {
    openModal: boolean;
    locationValue: string;
    onHandleClose: PropsFunction.SimpleFunction;
    confirmLocation: PropsFunction.SimpleFunction;
    changeValue: PropsFunction.ParamsIdStringFunction;
}

const LocationSelectorModal: React.FC<Props> = (props: Props) => {

    const { openModal, locationValue, onHandleClose, confirmLocation, changeValue } = props;
    const intl = useIntl();

    const checkIcon = (icon = 'GREY_WRONG') => ({
        alignItems: 'center',
        display: 'flex',
        color: icon === 'GREEN_RIGHT' ? '#00BA77' : '#42474B',

        ':before': {
            background: `url(${intl.formatMessage({ id: icon })})`,
            borderRadius: 2,
            content: '" "',
            display: 'block',
            height: 22,
            width: 22,
        },
    });

    const colorStyles: StylesConfig<DefaultValueType.LocationSelectorType> = {
        singleValue: (styles, { data }) => ({ ...styles, ...checkIcon(data.checkIcon) }),
    }

    const defaultValue = locationSelector.filter(e => e.value === locationValue)[0];
    const isDisabled = locationValue === 'Other'

    const onHandleChange = (e: any): void => {
        changeValue(e.value);
    };

    return (
        <Modal
            centered
            size='sm'
            show={openModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false} className="gi-ob-grey-bottom-curve enter-code-modal"
        >
            <button type="button" className="btn-close opacity-100" onClick={onHandleClose} data-testid="close-button"></button>

            <Modal.Body className={'pt-4 pb-3 mt-3 px-4'}>
                <div className="enter-code-modal-text px-1">
                    {isDisabled
                        ? <>
                            <h5>{IntlMessages('location_start_sorry_heading')}</h5>
                            <p className="mt-3 mb-4">{IntlMessages('location_start_sorry_text', { br: <br /> })}</p>
                        </>
                        : <>
                            <h5>{IntlMessages('location_start_heading')}</h5>
                            <p className="mt-3 mb-4">{IntlMessages('location_start_text', { br: <br /> })}</p>
                        </>
                    }
                    <div className="gi-ob-form form-group mb-2 w-75 mx-auto px-1">
                        <div className={'location-dropdown'}>
                            <Select
                                name={'locationDropdown'}
                                id={'locationDropdown'}
                                classNamePrefix='react-select'
                                options={locationSelector}
                                aria-label={'location-dropdown'}
                                onChange={onHandleChange}
                                placeholder={''}
                                value={defaultValue}
                                components={{ IndicatorSeparator: () => null }}
                                styles={colorStyles}
                            />
                        </div>

                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className="enter-code-modal-footer justify-content-center ">
                <div className="text-center py-3">
                    <button className="gi-ob-start-btn" disabled={isDisabled} onClick={confirmLocation} data-testid="continue-button">
                        {IntlMessages('location_btn')}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default LocationSelectorModal