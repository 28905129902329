// default library
import * as React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
    const { pathname } = useLocation()
    const history = useHistory();

    const scrollToTop = () => setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);

    React.useEffect(() => {
        scrollToTop()
    }, [pathname])

    // remove style element form body tag
    React.useEffect(() => {
        const htmlBody: HTMLElement | null = document.querySelector('body');
        if (htmlBody) {
            htmlBody.removeAttribute('style')
        }
    }, [history.location.pathname])

    return null;
}

export default ScrollToTop