//default library
import * as React from 'react';
//local message
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';

type Props = {
    message: PropsFunction.IntlMessages | string;
    fieldName: string;
}

const ErrorKYCIsrael: React.FC<Props> = (props: Props) => {
    const { message, fieldName } = props;

    return (
        <div className={'invalid-feedback show'} data-testid={fieldName}>
            {typeof (message) === 'object'
                ? IntlMessages(...message)
                : IntlMessages(message)
            }
        </div>
    )
}

export default ErrorKYCIsrael