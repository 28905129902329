// default library
import * as React from 'react';
import { useIntl } from 'react-intl';
// mask input library
import MaskedInput from 'react-text-mask';
// redux 
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
//types props
import * as PropsFunction from "interface/PropsFunction";
// message import
import IntlMessages from 'helper/IntlMessages';
// custom component
import ErrorKYCIsrael from 'components/ErrorComponent/ErrorKYCIsrael';

export type Props = {
    labelText: string;
    name: string;
    className: string;
    placeholder: string;
    fieldType: string;
    fieldValue: string;
    dataTestId: string;
    errorMessage: string;
    readOnly: boolean;
    guide: boolean;
    isError: boolean;
    inputValue: PropsFunction.PropsInputValueFunction;
}

// mask for different input fields
const otpMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
const phoneNumberMaskIL = [/0/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];


const MaskInputComponent: React.FC<Props> = (props: Props) => {

    const { labelText, name, className, placeholder, fieldType, fieldValue, errorMessage, dataTestId, readOnly, guide, isError, inputValue } = props;//Destructuring props
    const intl = useIntl()
    const locale = useSelector(getLocale);

    let mask: (string | RegExp)[] = [];
    const pipe: any = '';

    if (name === 'verificationCode' || name === 'verifyCode') {
        mask = otpMask;
    } else if (name === 'phoneNumberIL') {
        mask = phoneNumberMaskIL;
    }

    // function for input value changes
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const target = e.target
        inputValue(target.name, target.value);// Call the inputValue function with the updated value
    };

    return (
        <>
            {labelText && <label className="mb-2" htmlFor={name}>{IntlMessages(labelText)}</label>}
            <MaskedInput
                className={`form-control ${className} ${isError ? 'form-error' : ''}`}
                type={fieldType}
                name={name}
                id={name}
                placeholder={placeholder ? intl.formatMessage({ id: placeholder }) : ''} //set placeholder
                mask={mask} //mask field
                pipe={pipe} //implement pipe to handle input value
                value={fieldValue} //set default value
                readOnly={readOnly} //check field is readOnly or not
                guide={guide} //hide guide of mask input
                keepCharPositions={true}
                onChange={handleOnChange} //invoke on every key press
                dir={name === 'phoneNumberIL' ? 'ltr' : locale.direction} //direction
                data-testid={dataTestId}
            />
            {errorMessage && <ErrorKYCIsrael message={errorMessage} fieldName={name} />}
        </>
    )
}

export default MaskInputComponent