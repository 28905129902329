
//default library
import * as  React from 'react';
//message provider
import IntlMessages from 'helper/IntlMessages';
//import images
import { useIntl } from 'react-intl';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//types props
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as PropsFunction from 'interface/PropsFunction';
//api call
import * as DashboardService from "middleware/DashboardService"
//custom component
import PaginationNew from 'components/PaginationComponent/PaginationNew';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
//custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
import { DateFormatter } from 'service/utils/DateFormatter';
import { DateRange } from 'service/utils/DateFilter';
//default value
import { taxesDropdown } from 'config/DefaultValue';

export type Props = {
    transactionData: DashboardType.GetTransactionsData;
}

const TransactionsCard: React.FC<Props> = (props: Props) => {
    const { transactionData } = props

    const [dataLoad, setDataLoad] = React.useState<boolean>(false);//loading flag
    const [errorLog, setErrorLog] = React.useState<any>({});//error message
    const [errorAPI, setErrorAPI] = React.useState<boolean>(false);//error flag
    const [totalTransactionData, setTotalTransactionData] = React.useState<DashboardType.GetTransactionsData>([]);//transaction data,date wise
    const [currentTransactionData, setCurrentTransactionData] = React.useState<DashboardType.GetTransactionsData>([]);//current transaction data
    const [selectedValue, setSelectedValue] = React.useState<PropsFunction.OptionData>(taxesDropdown[0]);

    const intl = useIntl();

    const { initiateAction } = useAccessToken();

    React.useEffect(() => {
        if (totalTransactionData.length === 0) {
            setTotalTransactionData(transactionData);
            setDataLoad(true);
        }
    }, []);

    //page change function
    const onPageChanged: PropsFunction.OnPageChanged = (data): void => {
        const { currentPage, pageLimit } = data;
        const offset = (currentPage - 1) * pageLimit;

        const currentData = totalTransactionData.slice(offset, offset + pageLimit) || [];
        setCurrentTransactionData(currentData);
    };

    //selected value function 
    const onSelectValue: PropsFunction.SelectedOption = async (_fieldName, fieldValue): Promise<void> => {
        setDataLoad(false);
        const dateRange = DateRange(fieldValue.value.toString());
        const accessToken = await initiateAction();
        const transactionData = await DashboardService.transactionDetails(dateRange, accessToken);
        if (transactionData.response) {
            setErrorLog(transactionData)
            setErrorAPI(true);
        }
        else {
            setTotalTransactionData(transactionData);
            setDataLoad(true);
            setSelectedValue(fieldValue)
        }
    };

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {!dataLoad && <LoadingComponent />}
            <div className="row gx-20" data-testid="transaction-card">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6 col-md-6">
                            <div className="gi-d-ac-select-dropdown d-flex align-items-center flex-wrap gap-2 my-4 py-2">
                                <div>
                                    <label>{IntlMessages('table_range')}:</label>
                                </div>
                                <div className='activity-dropdown'>
                                    <SelectDropdown
                                        labelClassName={''}
                                        labelText={''}
                                        name='transaction'
                                        option={taxesDropdown}
                                        fieldValue={selectedValue}
                                        inputValue={onSelectValue}
                                        placeholder={''}
                                        ariaLabel={"transactions-label"}
                                        className={'custom-react-fund'}
                                        isDisabled={false}
                                        isModalDropdown={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="table-responsive">
                        <table className="table gi-d-table-striped-02 border-top mb-4" role="presentation">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <span>{IntlMessages('table_date')}</span>
                                    </th>
                                    <th scope="col">
                                        <span>{IntlMessages('table_description')}</span>
                                    </th>
                                    <th scope="col" className="amount-th text-start">
                                        <span>{IntlMessages('table_amount')}</span>
                                    </th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody >
                                {currentTransactionData.length > 0
                                    ? currentTransactionData.map((e, i) => {
                                        if (e.details) {
                                            return (
                                                <React.Fragment key={i}>
                                                    <tr className="bg-danger" data-testid="tabel-content">
                                                        <td>
                                                            <span className="text-gi-white">{DateFormatter(e.date)}</span>
                                                        </td>
                                                        <td>
                                                            <span className="text-gi-white" data-testid="description-name">{e.description}</span>
                                                        </td>
                                                        <td className="amount-td">
                                                            <span className="text-gi-white-grey ltr">{`+${currencyFormatter(e.amount)}`}</span>
                                                        </td>
                                                        <td className="align-middle">
                                                            <a className="gi-d-down-btn px-2 py-1 d-flex justify-content-end h-100 collapsed" data-bs-toggle="collapse" href={`#collapseHistory-${i}`} role="button" aria-expanded="false" aria-controls="collapseExample">
                                                                <img src={intl.formatMessage({ id: 'DOWN_ARROW_WHITE' })} alt="" className="down-collapsed" />
                                                                <img src={intl.formatMessage({ id: 'UP_ARROW_WHITE' })} alt="" className="up-collapsed" />
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr className="collapse" id={`collapseHistory-${i}`}>
                                                        <td colSpan={4} className="px-0 pt-20 pb-20">
                                                            <div className="gi-d-table-text px-4 py-2">
                                                                <p>{e.details}</p>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            )
                                        }
                                        else {
                                            return (
                                                <tr className={e.amount > 0 ? i % 2 === 0 ? 'bg-striped' : '' : 'bg-light'} data-testid="tabel-content" key={i}>
                                                    <td>
                                                        <span data-testid="date-name">{DateFormatter(e.date)}</span>
                                                    </td>
                                                    <td>
                                                        <span className="fst-italic" data-testid="description-name">{e.description}</span>
                                                    </td>
                                                    <td className="amount-td">
                                                        <span className={`${e.amount > 0 ? 'text-gi-green' : 'text-gi-clay'} ltr`}>
                                                            {e.amount > 0
                                                                ? `+${currencyFormatter(e.amount)}`
                                                                : currencyFormatter(e.amount)
                                                            }
                                                        </span>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            )
                                        }
                                    })
                                    : <tr className="bg-light" data-testid="tabel-content">
                                        <td colSpan={12}>
                                            <span>
                                                {IntlMessages('table_not_range')}
                                            </span>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    {dataLoad && <PaginationNew totalRecords={totalTransactionData.length || 0} pageLimit={5} pageNeighbors={1} onPageChanged={onPageChanged} />}
                </div>
            </div>
        </>
    );
};

export default TransactionsCard;