//default library
import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
//message provider
import IntlMessages from 'helper/IntlMessages';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//api call
import * as UserService from "middleware/UserService";
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
//config regex
import * as Config from "config/Config";
// custom function
import { getUserName } from 'service/utils/UtilFunctions';
// custom component
import SvgIcon from 'components/CommonComponent/SvgIcon';
import InputTextComponent from 'components/InputComponent/InputTextComponent';
import TextAreaComponent3 from 'components/InputComponent/TextAreaComponent3';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import PageHeader from 'components/PageHeaderComponent/PageHeader';

const ContactUsPage: React.FC = () => {
    const { user } = useAuth0();
    const { initiateAction } = useAccessToken();
    const locale = useSelector(getLocale);

    const [firstName, setFirstName] = React.useState<string>('');//storing first name
    const [email, setEmail] = React.useState<string>('');//storing email 
    const [description, setDescription] = React.useState<string>('');//storing description 
    const [checkEmail, setCheckEmail] = React.useState<boolean>(false);//email checking flag
    const [successText, setSuccessText] = React.useState<boolean>(false);//text checking flag

    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs
    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading flag
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    const localName = getUserName(locale.language);
    const firstNameHeader = localName.firstName ? localName.firstName : user?.email?.split('@')[0];

    React.useEffect(() => {
        window.scroll(0, 0);
    }, [])

    //send email function
    const sendEmail = async (): Promise<void> => {

        if (email.match(Config.EMAIL_REGEX)) {
            const contactUsData = { firstName, email, description };
            setIsLoading(true);

            const accessToken = await initiateAction();
            const emailSend = await UserService.contactUsAPI(contactUsData, accessToken);
            setIsLoading(false);

            if (emailSend.response) {
                setErrorLog(emailSend);
                setIsError(true);
            } else {
                setFirstName('');
                setEmail('');
                setDescription('');
                setSuccessText(emailSend);
                document.location.href = '#email-send-successfully';
            }

        } else {
            setCheckEmail(true);
        }
    };

    //handle input value 
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        fieldName === "firstName" && setFirstName(fieldValue);
        if (fieldName === "email") {
            setCheckEmail(false);
            setEmail(fieldValue);
        }
        fieldName === "description" && setDescription(fieldValue);
    };

    React.useEffect(() => {
        if (!firstName || firstName.length > 255) {
            setIsDisabled(true)
        } else if (!email || email.length > 255) {
            setIsDisabled(true)
        } else if (!description || description.length > 1000) {
            setIsDisabled(true)
        } else {
            setIsDisabled(false)
        }
    }, [firstName, email, description]);

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}

            <PageHeader
                className={'g-heading px-sm-0 px-2'}
                textStrong={'contact_us_header2'}
                userName={firstNameHeader}
                otherAttr={'contact_us_header4'}
            />
            {/* main content */}
            <div className="row" data-testid="contact-page">
                <div className="col-12">
                    <div className="gi-contact-card">
                        {/* static text */}
                        <div className='d-flex align-items-center gi-contact-header py-2 px-md-5 px-3'>
                            <h6>{IntlMessages('contact_us_header1')}</h6>
                        </div>
                        {/* content */}
                        <div className="p-5">
                            <div className="row">
                                <div className="col-12">
                                    <div className="gi-contact-text">
                                        <h5>{IntlMessages('contact_us_text1')}</h5>
                                        <p>{IntlMessages('contact_us_text2')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="py-5">
                                        <div className="row gx-0">
                                            {/* email us  */}
                                            <div className="col-md-4">
                                                <div className="gi-contact-details-text">
                                                    <div className="gi-contact-details-contact">
                                                        <span className='d-block pb-4'>
                                                            {IntlMessages('contact_us_text4')} <br />
                                                            <a href="mailto:support@giraffe.us">support@giraffe.us</a>
                                                        </span>

                                                        <p>{IntlMessages('contact_us_text5')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* input field and success message */}
                                            <div className="col-md-8">
                                                <div className='gi-contact-details-form '>
                                                    {successText
                                                        ? <div className="gi-contact-success" id="email-send-successfully">
                                                            <div className="gi-contact-details-form-main">
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <div className="gi-contact-thank-you-text">
                                                                            <h5>{IntlMessages('contact_us_text9')}</h5>
                                                                            <br />
                                                                            <p>{IntlMessages('contact_us_text10')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : <div className="gi-contact-details-form-main">
                                                            <div className="row gx-4">
                                                                {/* first name */}
                                                                <div className="col-sm-6">
                                                                    <div className="gi-contact-details-input">
                                                                        <InputTextComponent
                                                                            labelClassName={'pb-3'}
                                                                            labelText={'contact_us_text6'}
                                                                            name={'firstName'}
                                                                            placeholder={'placeholder_type_here'}
                                                                            className={'form-control'}
                                                                            fieldValue={firstName}
                                                                            inputValue={setInputValue}
                                                                            readOnly={false}
                                                                            disabled={false}
                                                                            dataTestId={'first-name'}
                                                                            isError={false}
                                                                            errorMessage={''}
                                                                        />
                                                                        <ErrorComponent message={['error_max_length', { number: 255 }]} dashboard={true} display={firstName.length > 255 ? 'block' : 'none'} />
                                                                    </div>
                                                                </div>
                                                                {/* email */}
                                                                <div className="col-sm-6">
                                                                    <div className="gi-contact-details-input">
                                                                        <InputTextComponent
                                                                            labelClassName={'pb-3'}
                                                                            labelText={'contact_us_text7'}
                                                                            name={'email'}
                                                                            placeholder={'placeholder_type_here'}
                                                                            className={'form-control'}
                                                                            fieldValue={email}
                                                                            inputValue={setInputValue}
                                                                            readOnly={false}
                                                                            disabled={false}
                                                                            dataTestId={'email-name'}
                                                                            isError={false}
                                                                            errorMessage={''}
                                                                        />
                                                                        <ErrorComponent message={['error_max_length', { number: 255 }]} dashboard={true} display={email.length > 255 ? 'block' : 'none'} />
                                                                        <ErrorComponent message={'error_email_not_match'} dashboard={true} display={checkEmail ? 'block' : 'none'} />
                                                                    </div>
                                                                </div>
                                                                {/* description */}
                                                                <div className="col-sm-12">
                                                                    <div className="gi-contact-details-input">
                                                                        <label className='pb-3'>{IntlMessages('contact_us_text8')}</label>
                                                                        <TextAreaComponent3
                                                                            name={'description'}
                                                                            placeholder={'placeholder_type_here'}
                                                                            className={'form-control'}
                                                                            fieldValue={description}
                                                                            inputValue={setInputValue}
                                                                            dataTestId={'description-data'}
                                                                            labelText={''}
                                                                            errorMessage={''}
                                                                            rows={10}
                                                                            cols={30}
                                                                        />
                                                                        <ErrorComponent message={['error_max_length', { number: 1000 }]} dashboard={true} display={description.length > 1000 ? 'block' : 'none'} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-12" data-testid='gi-contact'>
                                                                    <div className="gi-contact-details-button">
                                                                        <button
                                                                            type="button"
                                                                            onClick={sendEmail}
                                                                            disabled={isDisabled}
                                                                            data-testid="send-email"
                                                                        >
                                                                            <SvgIcon iconId={'white-arrow'} width={8} height={14} />
                                                                            {IntlMessages('button_send')}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* bank space */}
            <div className="row">
                <div className="col-12">
                    <div className="h-dash"></div>
                </div>
            </div>

        </>
    );
};

export default ContactUsPage;
