import moment from "moment";

const APIErrorHandle = (error: any) => {
    let message: string,
        timestamp: string,
        status: number;

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        message = error.response.data.message;
        timestamp = error.response.data.timestamp;
        status = error.response.status;
        if (typeof error.response.data === 'string') {
            message = 'Bad gateway';
            timestamp = moment().format();
            status = error.response.status;
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        message = 'The request was made but no response was received';
        timestamp = moment().format();
        status = 0;
    } else {
        // Something happened in setting up the request that triggered an Error
        message = 'We are unable to process your request at this time.';
        timestamp = moment().format();
        status = 0;
    }

    return {
        message: message,
        timestamp: timestamp,
        status: status,
    }
}

export default APIErrorHandle;