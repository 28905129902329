// default library
import * as React from 'react';
// jquery
import $ from 'jquery'
// react slick slider
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// custom function
import { DateFormatter3 } from 'service/utils/DateFormatter'
// message import
import { useIntl } from 'react-intl'
import IntlMessages from 'helper/IntlMessages';
// type import
import * as Dashboard from 'interface/Dashboard'
// custom component
import LottieAnimation from 'components/AnimationComponent/LottieAnimation';
// Animation JSON
import NotificationBell from 'service/animation/NotificationBell.json';

type Props = {
    notificationObj: Dashboard.NotificationArray
}

const NotificationSection: React.FC<Props> = (props: Props) => {

    const { notificationObj } = props; //props
    const intl = useIntl();

    // slider
    const sliderRef = React.useRef<Slider | null>(null);
    const [sliderIndex, setSliderIndex] = React.useState<number>(0);

    // when slider change
    const beforeChange = (_current: number, next: number) => {
        setSliderIndex(next);
        $(".gi-dash-ao-notification-text").removeClass("show-text");
    }

    // next slider button click
    const nextSlide = () => {
        if (sliderRef.current)
            sliderRef.current.slickNext();
    }

    // previous slider button click
    const prevSlide = () => {
        if (sliderRef.current)
            sliderRef.current.slickPrev()
    }

    // slider config
    const sliderSetting: Settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: beforeChange
    };

    React.useEffect(() => {
        $(".more-text-btn").click(function () {
            $(this).closest('.gi-dash-ao-notification-text').toggleClass("show-text");
        });
    }, [])

    return (
        <>
            {notificationObj.length > 0 && (
                <div className="gi-dash-ao-notification-card">
                    <Slider ref={sliderRef} {...sliderSetting} className="gi-dash-ao-notification-slider">
                        {notificationObj.map((e, i) => {
                            const notificationMessage = typeof (e.message) === 'object'
                                ? intl.formatMessage({ id: e.message[0] }, { ...e.message[1] })
                                : intl.formatMessage({ id: e.message });

                            return (
                                <div key={i} data-testid='notification-slider' >
                                    <div className="gi-dash-ao-notification-main py-3 my-lg-2 my-0">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="d-flex align-items-center gap-lg-4 gap-3">
                                                    {/* notification bell icon */}
                                                    <div className="gi-dash-ao-notification-img">
                                                        <LottieAnimation animationData={NotificationBell} width={49} height={49} />
                                                    </div>
                                                    {/* notification message */}
                                                    <div className="gi-dash-ao-notification-text">
                                                        {/* desktop */}
                                                        <p className="d-none d-lg-block">
                                                            <strong> {DateFormatter3(e.transactionDate)} </strong> |&nbsp;
                                                            {typeof notificationMessage === 'object'
                                                                ? notificationMessage
                                                                : <>
                                                                    {notificationMessage.substring(0, 176)}
                                                                    {notificationMessage.substring(176).length > 0 &&
                                                                        <span className="text-point">...
                                                                            <a className="more-text-btn" data-id={i}>{IntlMessages('gift_redeemed_button2')}</a>
                                                                        </span>
                                                                    }
                                                                </>
                                                            }
                                                            <span className="more-text">
                                                                {typeof notificationMessage === 'string' && notificationMessage.substring(176)}&nbsp;
                                                                <a className="more-text-btn" data-id={i}>{IntlMessages('gift_show_less')}</a>
                                                            </span>
                                                        </p>
                                                        {/* mobile */}
                                                        <p className="d-block d-sm-none">
                                                            <strong> {DateFormatter3(e.transactionDate)} </strong> |&nbsp;
                                                            {typeof notificationMessage === 'object'
                                                                ? notificationMessage
                                                                : <>
                                                                    {notificationMessage.substring(0, 76)}
                                                                    {notificationMessage.substring(76).length > 0 &&
                                                                        <span className="text-point">...
                                                                            <a className="more-text-btn" data-id={i}>{IntlMessages('gift_redeemed_button2')}</a>
                                                                        </span>
                                                                    }
                                                                </>
                                                            }

                                                            <span className="more-text">
                                                                {typeof notificationMessage === 'string' && notificationMessage.substring(76)}&nbsp;
                                                                <a className="more-text-btn" data-id={i}>{IntlMessages('gift_show_less')}</a>
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                    {/* next and previous button */}
                    <div className="notification-btn-wrap">
                        <button
                            className={`prev-btn d-lg-block d-none ${sliderIndex === 0 ? 'slick-disabled' : ''} `}
                            disabled={sliderIndex === 0}
                            onClick={prevSlide}
                            data-testid='prev-button'
                        >
                        </button>
                        <button
                            className={`next-btn ${sliderIndex >= notificationObj.length - 1 ? 'slick-disabled' : ''} `}
                            disabled={sliderIndex >= notificationObj.length - 1}
                            onClick={nextSlide}
                            data-testid='next-button'
                        ></button>
                    </div>
                </div>
            )}
        </>
    );
}

export default NotificationSection

