// default library
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
// auth0 library
import { useAuth0 } from '@auth0/auth0-react';
// react redux
import { useSelector, useDispatch } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
import { getAppCommonData, setCurrentAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
// custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
import { DateRange } from 'service/utils/DateFilter';
import * as Local from 'service/utils/LocalStorageData';
// default value
import { transferType, monthlySavingStatus } from 'config/DefaultValue';
// custom components
import KYCErrorModal from 'components/ModalComponent/KYCErrorModal';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import PendingGiftCard from 'components/CardComponent/PendingGiftCard';
import HomeBasketCard from 'components/CardComponent/HomeBasketCard';
import BankConnectModal from 'components/ModalComponent/BankConnectModal';
import FundModal from 'components/ModalComponent/FundModal';
import FundModalIL from 'components/ModalComponent/FundModalIL';
import MonthlySavingModals from 'components/ModalComponent/MonthlySavingModals';
import WithdrawMoneyModal from 'components/ModalComponent/WithdrawMoneyModal';
import WithdrawMoneyModalIL from 'components/ModalComponent/WithdrawMoneyModalIL';
import HomeGiftDetailsCard from 'components/CardComponent/HomeGiftDetailsCard';
import HomeInfoModal from 'components/ModalComponent/HomeInfoModal';
import GiraffeLearnBannerSection from 'components/RightSidebarComponent/GiraffeLearnBannerSection';
import ErrorPendingModal from 'components/ModalComponent/ErrorPendingModal';
import DatePageHeader from 'components/PageHeaderComponent/DatePageHeader';
import TotalCard from 'components/CardComponent/TotalCard';
import HomeMyImpact from 'components/SliderComponent/HomeMyImpact';
import GiraffeLearnBanner from 'components/RightSidebarComponent/GiraffeLearnBanner';
import SuccessModalNew from 'components/ModalComponent/SuccessModalNew';
import ChangeCashMonthlySavingModal from 'components/ModalComponent/ChangeCashMonthlySavingModal';
import MonthlySavingEditModal from 'components/ModalComponent/MonthlySavingEditModal';
import KYCStatusModal from 'components/ModalComponent/KYCStatusModal';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as BasketType from 'api/APIMaster/BasketType';
import * as UserType from 'api/APIMaster/UserType';
import * as DashboardInterface from 'interface/Dashboard';
import * as Routes from "interface/Route"
// api call
import * as DashboardService from 'middleware/DashboardService';
import * as BasketService from 'middleware/BasketService';
import * as UserService from 'middleware/UserService';
// dummy data
import * as UserData from 'service/DummyData/UserData';
import * as DashboardData from 'service/DummyData/DashboardData';

export type Props = {
    disabledAPI: boolean;
    withdrawButton: boolean;
    checkWithdrawalButton: PropsFunction.ParamsIdNumberFunction;
    createBasketRoute: PropsFunction.SimpleFunction;
    onHandleClose: PropsFunction.ParamsBooleanFunction;
}

const HomePage: React.FC<Props> = (props: Props) => {

    const { disabledAPI, withdrawButton, checkWithdrawalButton, createBasketRoute, onHandleClose } = props;
    const { user } = useAuth0();
    const { initiateAction } = useAccessToken();
    const history = useHistory();
    const intl = useIntl();
    const location = useLocation<Routes.LocationMonthly>();

    const dispatch = useDispatch()
    const locale = useSelector(getLocale);
    const appCommonData = useSelector(getAppCommonData);

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isLoading, setIsLoading] = React.useState<boolean>(true);  //error logs

    const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true); //check redux data render first time or not

    const [bankConnect, setBankConnect] = React.useState<boolean>(false); // Plaid connect

    const [basicDetails, setBasicDetails] = React.useState<UserType.UserBasicDetailResponse>(UserData.userBasicDetails); // Plaid connect
    const [userData, setUserData] = React.useState<UserType.GetDetailData>(UserData.userData);
    const [countryList, setCountryList] = React.useState<PropsFunction.Option>([]);

    const [isFunding, setIsFunding] = React.useState<DashboardType.IsFundTransferPendingData>([]);
    const [isBuyOrder, setIsBuyOrder] = React.useState<BasketType.BasketOrderPending>([]);
    const [portfolioSummaryData, setPortfolioSummary] = React.useState<DashboardType.GetPortfolioSummaryData>(DashboardData.portfolioSummary);

    const [getFundLocation, setGetFundLocation] = React.useState<DashboardType.GetFundLocationsData>([]);
    const [myImpactList, setMyImpactList] = React.useState<DashboardType.GetAllImpactDetailData>(DashboardData.myImpactData);
    const [basketArray, setBasketArray] = React.useState<DashboardType.BasketSummaryResponseModel[]>([]);
    const [giftArray, setGiftArray] = React.useState<DashboardType.BasketSummaryResponseModel[]>([]);
    const [pendingGiftDetails, setPendingGiftDetails] = React.useState<DashboardType.GiftDetailResponseModel[]>([]);

    const [openInfoModal, setOpenInfoModal] = React.useState<boolean>(false); //open information modal
    const [textHeading, setTextHeading] = React.useState<JSX.Element | string>(''); //info modal heading text
    const [messageBody, setMessageBody] = React.useState<JSX.Element | string>(''); //info modal content text
    const [bigModal, setBigModal] = React.useState<boolean>(false); //use big modal

    const [basketName, setBasketName] = React.useState<string>('');
    const [basketId, setBasketId] = React.useState<number>(0);

    const [openModelFund, setOpenModelFund] = React.useState<boolean>(false);
    const [openMonthlySavingModal, setOpenMonthlySavingModal] = React.useState<boolean>(false); //for open fund modal monthly saving
    const [openModelFundIL, setOpenModelFundIL] = React.useState<boolean>(false);

    const [openBankModal, setOpenBankModal] = React.useState<boolean>(false);
    const [openWithdrawModal, setOpenWithdrawModal] = React.useState<boolean>(false);
    const [loadingSavingModal, setLoadingSaving] = React.useState<boolean>(false);

    const [accountPerformance, setAccountPerformance] = React.useState<DashboardType.GetAccountPerformanceData>(DashboardData.accountPerformance);

    const [notificationObj, setNotificationObj] = React.useState<DashboardInterface.NotificationObject[]>([]);

    const [infoSavingModal, setInfoSavingModal] = React.useState<boolean>(false); //edit modal for portfolio and cash account
    const [monthlySavingAmount, setMonthlySavingAmount] = React.useState<number>(0);
    const [cashMonthlySaving, setCashMonthlySaving] = React.useState<boolean>(false); //edit cash monthly saving
    const [monthlySavingEditModal, setMonthlySavingEditModal] = React.useState<boolean>(false); //edit monthly saving modal

    const [kycStatusModalFlag, setKytStatusModalFlag] = React.useState<boolean>(false); //modal for KYC reject and denied
    const [isKYCDenied, setIsKYCDenied] = React.useState<boolean>(true); //kyc denied flag for the decide modal text

    React.useEffect(() => {
        if (!isFirstRender) {
            updateLocalBaseAPI(); //update the local language based api when language is change
        } else {
            setIsFirstRender(false);
            initialAPICall();
        }
    }, [locale.language])

    React.useEffect(() => {
        if ((appCommonData.isKycDenied || appCommonData.isKycRejected) && appCommonData.popUpCounter === 0) {
            setKytStatusModalFlag(true);
            setIsKYCDenied(appCommonData.isKycDenied);
        }
    }, [appCommonData.isKycDenied, appCommonData.isKycRejected])

    React.useEffect(() => {
        if (basicDetails.kycCountry === 'ISR') {
            setOpenWithdrawModal(withdrawButton);
        } else {
            setOpenWithdrawModal(bankConnect && withdrawButton);
            setOpenBankModal(!bankConnect && withdrawButton);
        }
    }, [withdrawButton])

    const initialAPICall = async (): Promise<void> => {
        window.scroll(0, 0)

        onHandleCloseFunction();
        setIsLoading(true)
        const dateRange = DateRange('all-time')//set default value date range 

        const accessToken = await initiateAction() //get access token
        if (disabledAPI) {
            const apiCallList = [
                UserService.isBankAccountLinked(accessToken),  //check bank is link or not
                UserService.userBasicDetails(accessToken), //get user data
                DashboardService.getPortfolioSummary(accessToken),  //get portfolio summary
                DashboardService.getFundLocations(accessToken),  //get list of basket and account
                DashboardService.getAllImpactDetail(accessToken),  //my impact details
                DashboardService.getAccountPerformance(dateRange, accessToken), //account performance
            ]

            if (appCommonData.isNewUser || !Local.getLocalData('dwUserIdentifier')) {
                apiCallList.push(Promise.resolve([]), Promise.resolve([]))
            } else {
                apiCallList.push(DashboardService.isFundTransferPending(accessToken)) //funding is pending or not check
                apiCallList.push(BasketService.getBasketOrderPending(accessToken)) //funding is pending or not check
            }

            const [bankConnect, basicDetails, portfolioSummaryData, getFundLocation, myImpactList, accountPerformance, isFunding, isBuyOrder] = await Promise.all(apiCallList)

            if (bankConnect.response || basicDetails.response || portfolioSummaryData.response || getFundLocation.response || myImpactList.response || accountPerformance.response || isFunding.response || isBuyOrder.response
                || bankConnect.request || basicDetails.request || portfolioSummaryData.request || getFundLocation.request || myImpactList.request || accountPerformance.request || isFunding.request || isBuyOrder.request
            ) {
                bankConnect.response && setErrorLog(bankConnect);
                basicDetails.response && setErrorLog(basicDetails);
                portfolioSummaryData.response && setErrorLog(portfolioSummaryData);
                getFundLocation.response && setErrorLog(getFundLocation);
                myImpactList.response && setErrorLog(myImpactList);
                accountPerformance.response && setErrorLog(accountPerformance);
                isFunding.response && setErrorLog(isFunding);
                isBuyOrder.response && setErrorLog(isBuyOrder);

                setIsError(true)
            }

            if (basicDetails.hasKycErrors) {
                const [staticData, userData] = await Promise.all([
                    UserService.getOnboardingStaticData(accessToken),
                    UserService.getDetail(accessToken), //get user data
                ])
                if (staticData.response || userData.response || staticData.request || userData.request) {
                    (staticData.response || staticData.request) && setErrorLog(staticData);
                    (userData.response || userData.request) && setErrorLog(userData);
                    setIsError(true)
                } else {
                    const countryList = staticData.countryList.map((value: UserType.CountryResponseModel) => {
                        return {
                            active: value.active,
                            label: value.name,
                            value: value.identifier,
                        }
                    })

                    setCountryList(countryList);
                    setUserData(userData);
                }

            }

            // create notification object for bank transfer
            const notificationObjBank: DashboardInterface.Notification[] = isFunding.map((e: DashboardType.FundTransferStatusResponseModel) => (
                {
                    isPending: e.isPending,
                    isGiftBasket: false,
                    isBankOrderPending: false,
                    transferType: e.transferType,
                    transactionDate: e.transactionDate,
                    isFirstInvestmentPending: false,
                    basketName: "",
                }
            )).filter((e: DashboardType.FundTransferStatusResponseModel) => e.transferType !== transferType.BANK_TO_BASKET && e.isPending)

            // create notification object for portfolio transfer
            const notificationObjPortfolio: DashboardInterface.Notification[] = portfolioSummaryData.baskets.map((e: DashboardType.BasketSummaryResponseModel) => (
                {
                    isPending: e.isPending,
                    isGiftBasket: e.isGiftBasket,
                    isBankOrderPending: e.isBankOrderPending,
                    transferType: e.pendingOrderType,
                    transactionDate: e.pendingTransactionDate,
                    isFirstInvestmentPending: e.isFirstInvestmentPending,
                    basketName: e.name,
                }
            )).filter((e: DashboardType.BasketSummaryResponseModel) => e.isPending)

            const isPortfolioCreated = portfolioSummaryData.baskets.length > 0; //if length is greater than 0 means portfolio created
            const isPendingGift = portfolioSummaryData.pendingGifts.length > 0; //if length is greater than 0 means gift is pending
            const isGiftBasketExists = portfolioSummaryData.baskets.filter((e: DashboardType.BasketSummaryResponseModel) => e.isGiftBasket).length === 0; //check the gift basket is exist or not in the basket object

            // create notification object
            const notificationObj: DashboardInterface.NotificationObject[] = [...notificationObjBank, ...notificationObjPortfolio].map(e => {
                let message: PropsFunction.IntlMessages | string = '';

                if (e.transferType === transferType.DEPOSIT && basicDetails.kycCountry !== 'ISR') {  //when fund are transfer from bank to cash, and user is not IL user
                    message = 'notification_bank_to_cash'
                } //for deposit

                if (e.transferType === transferType.WITHDRAWAL) { //when fund are transfer from cash to bank
                    message = 'notification_withdrawal'
                } //for withdrawal

                if (e.transferType === transferType.BUY && !e.isBankOrderPending) { //when fund are transfer from cash to basket
                    message = ['notification_buy', { basketName: e.basketName }];
                } //for buy

                if (e.transferType === transferType.SELL && !e.isBankOrderPending) { //when fund are transfer from basket to cash
                    message = ['notification_sell', { basketName: e.basketName }];
                } //for sell

                if (e.transferType === transferType.BUY && e.isBankOrderPending && basicDetails.kycCountry !== 'ISR') { //when fund are transfer from bank to basket, and user is not IL user
                    message = ['notification_bank_to_basket', { basketName: e.basketName }];
                } //for bank to basket

                if (e.isGiftBasket && e.isFirstInvestmentPending) { //when gift redeemed, but fund transfer was not complete
                    message = 'notification_new_gift'
                } //for new gift

                return {
                    transactionDate: e.transactionDate,
                    message: message,
                }
            })

            /* ----------------------------------------- MONTHLY SAVING SETUP --------------------------------------------------------------------------------- */
            if (basicDetails.monthlySaving) { //monthly saving is done
                if (basicDetails.monthlySaving.isDeductionInitiated) { //monthly saving deduction start
                    notificationObj.push({
                        transactionDate: appCommonData.userCreateDateTime,
                        message: 'il_monthly_deduction_start',
                    });
                }

                if (basicDetails.monthlySaving.forCashAccount) { //monthly saving set in the cash account
                    notificationObj.push({
                        transactionDate: appCommonData.userCreateDateTime,
                        message: ['il_monthly_saving_cash', { clickText: <a onClick={monthlyCashModal}>{IntlMessages('click_here')}</a> }],
                    });
                }

            } else if (!basicDetails.monthlySaving && basicDetails.kycCountry === 'ISR' && isPortfolioCreated && !appCommonData.isNewUser) { //if monthly saving setup is not done, user is IL, portfolio is created, and kyc is complete
                notificationObj.push({
                    transactionDate: appCommonData.userCreateDateTime,
                    message: 'il_set_monthly_saving',
                })
            }
            /* ------------------------------------------------------------------------------------------------------------------------------------------------ */

            /* ----------------------------------------- FIRST TIME NOTIFICATION FOR USER OR PORTFOLIO CREATION ----------------------------------------------- */
            if (appCommonData.isNewUser) { //when user not complete the KYC
                if (isPortfolioCreated) { //when portfolio is created
                    /**
                     * check is user is IL user or not
                     * il_set_monthly_saving: portfolio created, Set monthly savings
                     * notification_without_kyc_portfolio: portfolio created Add funds
                     */
                    notificationObj.push({
                        transactionDate: appCommonData.userCreateDateTime,
                        message: basicDetails.kycCountry === 'ISR' ? 'il_set_monthly_saving' : 'notification_without_kyc_portfolio',
                    })
                } else { //when portfolio is not created
                    notificationObj.push({
                        transactionDate: appCommonData.userCreateDateTime,
                        message: 'notification_kyc_not_done',
                    })
                }
            } else if (portfolioSummaryData.isInvestmentPending && portfolioSummaryData.investmentPendingDate && basicDetails.kycCountry !== 'ISR') { //notification message for when KYC is done but investment is pending for non Israel user
                notificationObj.push({
                    transactionDate: portfolioSummaryData.investmentPendingDate,
                    message: 'notification_new_portfolio',
                })
            } else if (!isPortfolioCreated && !appCommonData.isNewUser) { //when portfolio is not created, also KYC is complete`
                notificationObj.push({
                    transactionDate: appCommonData.userCreateDateTime,
                    message: 'notification_kyc_not_done',
                })
            }
            /* ------------------------------------------------------------------------------------------------------------------------------------------------ */


            /* ----------------------------------------- GIFT IS NOT REDEEMED --------------------------------------------------------------------------------- */
            if (isPendingGift) { //when gift pending is exist
                const pendingGiftFromGiftCode = portfolioSummaryData.pendingGifts.filter((e: DashboardType.GiftDetailResponseModel) => e.fromGiftCode); //pending gift but gift code
                const pendingGiftFromNotGiftCode = portfolioSummaryData.pendingGifts.filter((e: DashboardType.GiftDetailResponseModel) => !e.fromGiftCode); //pending gift but not gift code
                const isGiftPendingFromGiftCode = pendingGiftFromGiftCode.length > 0; //check gift is pending from the gift code

                if (appCommonData.isNewUser) {//when user not complete the KYC
                    /**
                     * if gift redeemed form the gift code show notification_gift_add_code else notification_gift_not_redeem
                     * notification_gift_add_code: To claim your gift, your next step
                     * notification_gift_not_redeem: We're excited that you've joined us
                     */
                    notificationObj.splice(0, 0, {
                        transactionDate: isGiftPendingFromGiftCode ? pendingGiftFromGiftCode[0].pendingDate : pendingGiftFromNotGiftCode[0].pendingDate,
                        message: isGiftPendingFromGiftCode ? 'notification_gift_add_code' : 'notification_gift_not_redeem',
                    })
                } else if (!appCommonData.isNewUser && !isGiftBasketExists) { //when user complete the KYC, check gift is pending and gift basket should not exist
                    notificationObj.splice(0, 0, {
                        transactionDate: isGiftPendingFromGiftCode ? pendingGiftFromGiftCode[0].pendingDate : pendingGiftFromNotGiftCode[0].pendingDate,
                        message: 'notification_gift_add_code_KYC',
                    })
                }
            }
            /* ------------------------------------------------------------------------------------------------------------------------------------------------ */


            /* ----------------------------------------- KYC STATUS CHECK --------------------------------------------------------------------------------- */
            // KYC DENIED
            if (basicDetails.isInternalKycDenied) {
                notificationObj.splice(0, 0, {
                    transactionDate: appCommonData.userCreateDateTime,
                    message: 'il_kyc_denied',
                }); //kyc denied
            }

            // KYC REJECT
            if (basicDetails.isInternalKycRejected) {
                notificationObj.splice(0, 0, {
                    transactionDate: appCommonData.userCreateDateTime,
                    message: ['il_kyc_reject', { clickText: <a onClick={redirectToKycUpdate}>{IntlMessages('click_here')}</a> }],
                }); //kyc rejected
            }
            /* ------------------------------------------------------------------------------------------------------------------------------------------------ */


            dispatch(setCurrentAppCommonData({
                ...appCommonData,
                isKycRejected: basicDetails.isInternalKycRejected,
                isKycProcessing: basicDetails.isInternalKycPending,
                isKycManualReview: basicDetails.isInternalKycManualReview,
                isKycDenied: basicDetails.isInternalKycDenied,
                popUpCounter: 1, //increment the pop up counter
            }))

            setBankConnect(bankConnect);
            setBasicDetails(basicDetails);
            setIsFunding(isFunding);
            setIsBuyOrder(isBuyOrder);
            setPortfolioSummary(portfolioSummaryData);
            setGetFundLocation(getFundLocation);
            setMyImpactList(myImpactList);
            setBasketArray(portfolioSummaryData.baskets.filter((e: DashboardType.BasketSummaryResponseModel) => !e.isGiftBasket));
            setGiftArray(portfolioSummaryData.baskets.filter((e: DashboardType.BasketSummaryResponseModel) => e.isGiftBasket));
            setPendingGiftDetails(portfolioSummaryData.pendingGifts);
            setAccountPerformance(accountPerformance);

            const sortNotificationObj = notificationObj.sort((a, b) => new Date(b.transactionDate).getTime() - new Date(a.transactionDate).getTime())
            setNotificationObj(sortNotificationObj)

            setIsLoading(false);

            checkWithdrawalButton(portfolioSummaryData.totalCashAvailableForWithdrawal);

            setMonthlySavingEditModal(location.state?.isMonthlySavingEditOpen ?? false); //monthly saving edit modal

            history.replace({ ...history.location, state: undefined });
        }
    }

    // update the local language based api when language is change
    const updateLocalBaseAPI = async (): Promise<void> => {
        setIsLoading(true);

        const accessToken = await initiateAction() //get access token
        const apiCallList = [
            DashboardService.getAllImpactDetail(accessToken),  //my impact details
            DashboardService.getPortfolioSummary(accessToken),  //get portfolio summary
        ]

        basicDetails.hasKycErrors ? apiCallList.push(UserService.getOnboardingStaticData(accessToken)) : apiCallList.push(Promise.resolve([]));

        const [myImpactList, portfolioSummaryData, staticData] = await Promise.all(apiCallList)

        setIsLoading(false);

        if ((myImpactList.response || myImpactList.request)
            || (portfolioSummaryData.response || portfolioSummaryData.request)
            || (staticData.response || staticData.request)
        ) {
            (myImpactList.response || myImpactList.request) && setErrorLog(myImpactList);
            (portfolioSummaryData.response || portfolioSummaryData.request) && setErrorLog(portfolioSummaryData);
            (staticData.response || staticData.request) && setErrorLog(staticData);
            setIsError(true);
        } else {
            if (basicDetails.hasKycErrors) {
                const countryList = staticData.countryList.map((value: UserType.CountryResponseModel) => ({
                    active: value.active,
                    label: value.name,
                    value: value.identifier,
                }))

                setCountryList(countryList);
            }

            setMyImpactList(myImpactList);
            setPortfolioSummary(portfolioSummaryData);
            setBasketArray(portfolioSummaryData.baskets.filter((e: DashboardType.BasketSummaryResponseModel) => !e.isGiftBasket));
            setGiftArray(portfolioSummaryData.baskets.filter((e: DashboardType.BasketSummaryResponseModel) => e.isGiftBasket));
            setPendingGiftDetails(portfolioSummaryData.pendingGifts);
        }
    }

    // redirect to kyc info update page if kyc is rejected
    const redirectToKycUpdate = (): void => {
        setKytStatusModalFlag(true);
    }

    // call user basic detail API
    const onHandleSuccessEmail = async (): Promise<void> => {
        setLoadingSaving(true);
        const accessToken = await initiateAction(); //get access token
        const basicDetails = await UserService.userBasicDetails(accessToken);

        setLoadingSaving(false);

        if (basicDetails.response || basicDetails.request) {
            setIsError(true)
            setErrorLog(basicDetails)
        } else {
            setBasicDetails(basicDetails);
        }
    }

    // not list modal open
    const onHandleNotListEmail = async (): Promise<void> => {
        setOpenMonthlySavingModal(false);
        setIsLoading(true);
        await onHandleSuccessEmail();
        setIsLoading(false);
    }

    // open info modal
    const openInfoModalFunction: PropsFunction.OpenInfoModal = (textHeading, messageBody, bigModal): void => {
        setOpenInfoModal(true);
        setTextHeading(textHeading);
        setMessageBody(messageBody);
        setBigModal(bigModal);
    }

    // change basket name
    const changeBasketName: PropsFunction.ParamsIdStringFunction = (value): void => {
        setBasketName(value)
    }

    // rename basket
    const renameBasket: PropsFunction.ParamsIdStringFunction = async (identifier): Promise<void> => {
        const basket = {
            identifier: identifier,
            name: basketName
        }
        setIsLoading(true);
        setBasketName('');

        const accessToken = await initiateAction() //get access token
        const isRenamed = await BasketService.renameBasket(basket, accessToken);
        if (isRenamed.response) {
            setErrorLog(isRenamed)
            setIsError(true)
        }

        initialAPICall();
        createBasketRoute();
    }

    //open fund modal from total card
    const fundModalOpen: PropsFunction.ParamsIdNumberFunction = (value): void => {

        if (appCommonData.isNewUser) {
            redirectToOnBoarding();
        } else {
            if (basicDetails.kycCountry === 'ISR') {
                if (basicDetails.isInternalKycDenied) {
                    setKytStatusModalFlag(true);
                    setIsKYCDenied(true)
                } else if (basicDetails.isInternalKycRejected) {
                    setKytStatusModalFlag(true);
                    setIsKYCDenied(false)
                } else {
                    basicDetails.monthlySavingSetup ? setOpenModelFundIL(true) : setOpenMonthlySavingModal(true);
                }
            } else {
                setOpenModelFund(bankConnect);
                setOpenBankModal(!bankConnect);
                setBasketId(value);
            }
        }
    }

    //open fund modal from basket detail card
    const setBuyOpenModal: PropsFunction.ParamsIdNumberFunction = (value): void => {
        if (appCommonData.isNewUser) {
            redirectToOnBoarding();
        } else {
            if (basicDetails.kycCountry === 'ISR') {
                if (basicDetails.isInternalKycDenied) {
                    setKytStatusModalFlag(true);
                    setIsKYCDenied(true)
                } else if (basicDetails.isInternalKycRejected) {
                    setKytStatusModalFlag(true);
                    setIsKYCDenied(false)
                } else {
                    basicDetails.monthlySavingSetup
                        ? portfolioSummaryData.totalCashAvailableForTrade > 0 ? setOpenModelFund(true) : setOpenModelFundIL(true)
                        : setOpenMonthlySavingModal(true);
                }
            } else {
                setOpenModelFund(bankConnect || portfolioSummaryData.totalCashAvailableForTrade > 0);
                setOpenBankModal(!bankConnect && portfolioSummaryData.totalCashAvailableForTrade === 0);
                setBasketId(value);
            }
        }
    }

    //close all modal
    const onHandleCloseFunction = (): void => {
        setOpenModelFund(false); //fund modal
        setOpenBankModal(false); //bank connect modal
        setOpenWithdrawModal(false); //withdraw modal
        setOpenInfoModal(false); //info modal
        setInfoSavingModal(false); //monthly saving info modal
        onHandleClose(false); //close modal
        setCashMonthlySaving(false); //cash monthly saving modal
        setMonthlySavingEditModal(false); //monthly saving edit modal
        setKytStatusModalFlag(false); //kyc status modal
        setOpenMonthlySavingModal(false); //monthly saving modal
        setOpenModelFundIL(false); //fund not started modal
    }

    // close fund modal
    const fundModalClose: PropsFunction.ParamsBooleanFunction = (value): void => {
        setOpenModelFund(value)

        onHandleClose(false)
    }

    // close fund modal
    const withdrawModal: PropsFunction.ParamsBooleanFunction = (value): void => {
        onHandleClose(value)
    }

    // redirect on-boarding
    const redirectToOnBoarding = (): void => {
        history.push('/on-boarding')
    }

    // open info monthly saving modal
    const infoMonthlySaving = (): void => {
        const monthlySavingBasket = basicDetails.monthlySaving?.afterCutoffAmount;
        monthlySavingBasket && setMonthlySavingAmount(monthlySavingBasket);
        setInfoSavingModal(true);
    }

    // open edit monthly saving modal
    const editMonthlySaving = (): void => {
        setMonthlySavingEditModal(true);
    }

    // open monthly saving cash 
    const monthlyCashModal = (): void => {
        setCashMonthlySaving(true);
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    const withdrawalPending = isFunding && isFunding?.filter(e => e.transferType === transferType.WITHDRAWAL)[0]

    const userAddress = basicDetails.userAddress
    const plaidData = {
        customerFirstName: basicDetails.firstNameEncrypted,
        customerMiddleName: basicDetails.middleNameEncrypted,
        customerLastName: basicDetails.lastNameEncrypted,
        customerPhoneNumber: basicDetails.phoneEncrypted,
        customerEmail: user?.email,
        customerAddress: `${userAddress?.street1} ${userAddress?.city} ${userAddress?.province} ${userAddress?.postalCode}`,
    }
    // check IL user
    const ILUser = basicDetails.kycCountry ? basicDetails.kycCountry === 'ISR' : (locale.code === 'ISR' || locale.code === 'ISR_EN')
    // monthly saving object
    const monthlySaving = basicDetails.monthlySaving ? basicDetails.monthlySaving : UserData.monthlySaving;
    // monthly saving info and edit body
    const portfolioEditBody: JSX.Element = <div className="gi-setup-modal-text-success mt-2 px-3 mb-3 ">
        <p className="mb-0">
            {IntlMessages('home_monthly_saving_portfolio_text1', { amount: monthlySavingAmount })}
        </p>
        {monthlySaving.status === 'PENDING' && (
            <h6 className="mb-0 mt-4">
                {monthlySavingStatus[monthlySaving.status].label}
            </h6>
        )}
        <div className="text-center mt-4">
            <button type="button" onClick={editMonthlySaving}>
                {IntlMessages('button_edit')}
            </button>
        </div>
    </div>

    return (
        <>
            {/* {kycError.length > 0 */}
            {basicDetails.kycError?.split('|') && basicDetails.hasKycErrors
                ? <KYCErrorModal openKYCErrorModal={basicDetails.hasKycErrors} kycError={basicDetails.kycError?.split('|')} countryList={countryList} userData={userData} componentDidMount={initialAPICall} />
                : basicDetails.hasKycErrors || isLoading
                    ? <LoadingComponent />
                    : <>
                        {/* header */}
                        <DatePageHeader />

                        {/* main content */}
                        <div className="row gx-2">
                            {/* content */}
                            <div className="col-lg-9">
                                <div className="row">
                                    <div className="col-12">
                                        {/* total card */}
                                        <TotalCard
                                            notificationObj={notificationObj}
                                            accountPerformance={accountPerformance} //account performance object
                                            portfolioSummaryData={portfolioSummaryData} //portfolio summary data
                                            isILUser={ILUser && !basicDetails.monthlySavingSetup} //check user is israel user or not
                                            isMonthlySavingCash={basicDetails.monthlySaving ? basicDetails.monthlySaving.forCashAccount : false} //check monthly saving is cash or not
                                            cashAccountFundModal={fundModalOpen} //add fund into cash account
                                            openInfoModal={openInfoModalFunction}
                                            onHandleClose={onHandleCloseFunction}
                                            openMonthlyCashModal={monthlyCashModal}
                                            isMatchAnimationHeight={pendingGiftDetails.length > 0 || basketArray.length > 0 || giftArray.length > 0}
                                        />

                                        {/* gift pending card */}
                                        {pendingGiftDetails.length > 0 && pendingGiftDetails.map((e, i) => {
                                            return (
                                                <PendingGiftCard
                                                    key={i}
                                                    pendingGiftDetail={e}
                                                    openInfoModal={openInfoModalFunction}
                                                    isNewUser={appCommonData.isNewUser}
                                                />
                                            )
                                        })}

                                        {/* basket card is pending*/}
                                        {basketArray.length > 0 && basketArray.map((e, i) => {
                                            if (e.isPending) {
                                                return (
                                                    <HomeBasketCard
                                                        key={i}
                                                        currentBasket={e}
                                                        monthlySaving={basicDetails.monthlySaving}
                                                        changeBasketName={changeBasketName}
                                                        renameBasket={renameBasket}
                                                        setBuyOpenModal={setBuyOpenModal}
                                                        openInfoModal={openInfoModalFunction}
                                                        infoMonthly={infoMonthlySaving}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* gift card is pending*/}
                                        {giftArray.length > 0 && giftArray.map((e, i) => {
                                            if (e.isPending) {
                                                return (
                                                    <HomeGiftDetailsCard
                                                        key={i}
                                                        currentGift={e}
                                                        monthlySaving={basicDetails.monthlySaving}
                                                        openInfoModal={openInfoModalFunction}
                                                        setBuyOpenModal={setBuyOpenModal}
                                                        infoMonthly={infoMonthlySaving}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* basket card is not pending*/}
                                        {basketArray.length > 0 && basketArray.map((e, i) => {
                                            if (!e.isPending) {
                                                return (
                                                    <HomeBasketCard
                                                        key={i}
                                                        currentBasket={e}
                                                        monthlySaving={basicDetails.monthlySaving}
                                                        changeBasketName={changeBasketName}
                                                        renameBasket={renameBasket}
                                                        setBuyOpenModal={setBuyOpenModal}
                                                        openInfoModal={openInfoModalFunction}
                                                        infoMonthly={infoMonthlySaving}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* gift card is not pending*/}
                                        {giftArray.length > 0 && giftArray.map((e, i) => {
                                            if (!e.isPending) {
                                                return (
                                                    <HomeGiftDetailsCard
                                                        key={i}
                                                        currentGift={e}
                                                        monthlySaving={basicDetails.monthlySaving}
                                                        openInfoModal={openInfoModalFunction}
                                                        setBuyOpenModal={setBuyOpenModal}
                                                        infoMonthly={infoMonthlySaving}
                                                    />
                                                )
                                            }
                                        })}

                                        {/* my impact card */}
                                        {myImpactList.impactDetailResponseModelList.length > 0 && (
                                            <HomeMyImpact
                                                myImpactList={myImpactList.impactDetailResponseModelList}
                                                isInvestmentPending={myImpactList.isInvestmentPending}
                                                isPortfolio={false}
                                                isExample={myImpactList.isExample}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* banner */}
                            <div className="col-lg-3">
                                {appCommonData.isLearnAccess
                                    ? <GiraffeLearnBanner />
                                    : <GiraffeLearnBannerSection />
                                }
                            </div>
                        </div>

                        {/* blank space */}
                        <div className="row">
                            <div className="col-12">
                                <div className="h-dash"></div>
                            </div>
                        </div>

                        {/* Bank connect modal */}
                        <BankConnectModal
                            userName={basicDetails.name}
                            openBankModal={openBankModal}
                            closeBankModal={onHandleCloseFunction}
                            componentDidMount={initialAPICall}
                            plaidData={plaidData}
                            message={'bank_text1'}
                        />

                        {/* fund modal */}
                        <FundModal
                            openFundModelFlag={openModelFund}
                            closeModal={fundModalClose}
                            componentDidMount={initialAPICall}
                            getFundLocation={getFundLocation}
                            isFunding={isFunding}
                            isBuyOrder={isBuyOrder}
                            totalCashAvailableForTrade={portfolioSummaryData.totalCashAvailableForTrade}
                            basketId={basketId}
                        />

                        {/* fund modal */}
                        <FundModalIL
                            openModal={openModelFundIL}
                            onHandleClose={onHandleCloseFunction}
                        />

                        {/* fund modal */}
                        <MonthlySavingModals
                            isModalOpen={openMonthlySavingModal}
                            organizationName={basicDetails.organizationName}
                            deductionDate={basicDetails.msCutoffDate}
                            defaultCurrency={basicDetails.defaultCurrency}
                            getFundLocation={getFundLocation}
                            loadingSavingModal={loadingSavingModal}
                            isKycPending={appCommonData.isKycProcessing}
                            onHandleClose={onHandleCloseFunction}
                            onHandleSuccessEmail={onHandleSuccessEmail}
                            onHandleSuccess={initialAPICall}
                            onHandleNotListEmail={onHandleNotListEmail}
                        />

                        {/* withdraw modal */}
                        {!withdrawalPending?.isPending
                            ? basicDetails.kycCountry === 'ISR'
                                // israel withdrawal modal
                                ? <WithdrawMoneyModalIL
                                    isModalOpen={openWithdrawModal}
                                    withdrawalAmount={portfolioSummaryData.totalCashAvailableForWithdrawal}
                                    cashSettlement={portfolioSummaryData.totalCashInSettlement}
                                    onHandleClose={withdrawModal}
                                    componentDidMount={initialAPICall}
                                />
                                // US withdrawal modal
                                : <WithdrawMoneyModal
                                    openWithdrawModal={openWithdrawModal}
                                    closeModal={withdrawModal}
                                    componentDidMount={initialAPICall}
                                    getFundLocation={getFundLocation}
                                    withdrawalAmount={portfolioSummaryData?.totalCashAvailableForWithdrawal}
                                />
                            : <ErrorPendingModal
                                openModelError={openWithdrawModal}
                                closeErrorModal={withdrawModal}
                                h3={['withdraw_message', { amount: currencyFormatter(withdrawalPending.transferAmount) }]}
                                label={'error_try_again'}
                                image={intl.formatMessage({ id: 'UNLINK_BANK_SVG' })}
                            />
                        }

                        {/* info modal */}
                        <HomeInfoModal
                            openInfoModal={openInfoModal}
                            textHeading={textHeading}
                            messageBody={messageBody}
                            bigModal={bigModal}
                            onHandleClose={onHandleCloseFunction}
                        />

                        {/* portfolio and cash account info modal */}
                        <SuccessModalNew
                            openModal={infoSavingModal}
                            bodyClassName='p-4 mt-2'
                            messageBody={portfolioEditBody}
                            showCloseButton={true}
                            onHandleClose={onHandleCloseFunction}
                        />

                        {/* monthly saving cash change */}
                        <ChangeCashMonthlySavingModal
                            openModal={cashMonthlySaving}
                            getFundLocation={getFundLocation}
                            onHandleClose={onHandleCloseFunction}
                            onHandleSuccess={initialAPICall}
                        />

                        {/* monthly saving modal edit */}
                        <MonthlySavingEditModal
                            isModalOpen={monthlySavingEditModal}
                            organizationName={basicDetails.organizationName}
                            deductionDate={basicDetails.msCutoffDate}
                            defaultCurrency={basicDetails.defaultCurrency}
                            monthlySaving={monthlySaving}
                            fundLocation={getFundLocation}
                            onHandleClose={onHandleCloseFunction}
                            onHandleSuccess={initialAPICall}
                        />

                        {/* KYC reject and denied modal */}
                        <KYCStatusModal
                            openModal={kycStatusModalFlag}
                            isKYCDenied={isKYCDenied}
                            onHandleClose={onHandleCloseFunction}
                        />
                    </>
            }
        </>
    )
}

export default HomePage