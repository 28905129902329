//default library
import * as  React from 'react';
import Modal from "react-bootstrap/Modal";
//helper message
import IntlMessages from 'helper/IntlMessages';
//custom component
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent';
import NumberAnimation from 'components/AnimationComponent/NumberAnimation';
import PortfolioAnimation from 'components/AnimationComponent/PortfolioAnimation';
//interweave library for html
import { Markup } from 'interweave';
// config
import * as Config from 'config/Config';
//custom function
import companyCountCalculation from 'service/utils/CompanyCountCalculation';
//types import
import * as BasketType from "api/APIMaster/BasketType"
import * as PropsFunction from "interface/PropsFunction"
import * as Dashboard from 'interface/Dashboard'
//dummy data
import * as BasketData from 'service/DummyData/BasketData'

// timeout variable
let animationTimeOut: NodeJS.Timeout;

//defining props
export type Props = {
    openAdvanceModal: boolean;
    informationId: string;
    userExcludedList: string[];
    esgcValueList: BasketType.BasketCustomizationEsgcValueResponseModel[];
    companyCountFunction: Dashboard.CompanyCountData;
    allCompanyList: BasketType.CompanyUserExclusionResponseModel[];
    sp500CompanyList: BasketType.CompanyUserExclusionResponseModel[];
    selectedEsgcList: BasketType.GiftEsgcValueRequestModel[];
    modifiedSelectedList: PropsFunction.PropsModifiedEsgcValue;
    onHandleClose: PropsFunction.SimpleFunction;

}

const AdvanceOptionModal: React.FC<Props> = (props: Props) => {
    const { openAdvanceModal, esgcValueList, informationId, companyCountFunction, onHandleClose, modifiedSelectedList, selectedEsgcList, allCompanyList, userExcludedList, sp500CompanyList } = props;  //destructuring props

    const [selectedEsgcValue, setSelectedEsgcValue] = React.useState<BasketType.GiftEsgcValueRequestModel[]>(selectedEsgcList);
    const [companyCount, setCompanyCount] = React.useState<Dashboard.CompanyCountData>(BasketData.companyCountData);
    const [companyCountFlag, setCompanyCountFlag] = React.useState<boolean>(false);

    const [confirmButton, setConfirmButton] = React.useState<boolean>(true);
    const [loadAnimation, setLoadAnimation] = React.useState<boolean>(false);


    React.useEffect(() => {
        return () => {
            clearTimeout(animationTimeOut)
        }
    }, [])

    React.useEffect(() => {
        setConfirmButton(true);
        setLoadAnimation(false)
        setSelectedEsgcValue(selectedEsgcList);
    }, [openAdvanceModal, selectedEsgcList])

    // select deep clean option for ESG-C value
    const deepClean = (identifier: string, value: boolean): void => {
        const modifiedObject = JSON.parse(JSON.stringify(selectedEsgcValue));

        const indexOf = modifiedObject.findIndex((e: { esgcValueIdentifier: string; }) => e.esgcValueIdentifier === identifier);
        if (indexOf !== -1) {
            modifiedObject[indexOf].deepClean = value;//modified value
        } else {
            //create selected ESG-C value object
            const selectedEsgcList = {
                esgcValueIdentifier: identifier,
                deepClean: value,
                addGoodCompanies: false,
            };

            modifiedObject.push(selectedEsgcList);
        }
        setSelectedEsgcValue(modifiedObject);
        setConfirmButton(false);
    };

    // select add good companies option for ESG-C value
    const addGoodCompany = (identifier: string, value: boolean): void => {
        const modifiedObject = JSON.parse(JSON.stringify(selectedEsgcValue));
        const indexOf = modifiedObject.findIndex((e: { esgcValueIdentifier: string; }) => e.esgcValueIdentifier === identifier);//find index of click ESG-C value from array
        if (indexOf !== -1) {
            modifiedObject[indexOf].addGoodCompanies = value;//modified value
        } else {
            //create selected ESG-C value object 
            const selectedEsgcList = {
                esgcValueIdentifier: identifier,
                deepClean: false,
                addGoodCompanies: value,
            };
            modifiedObject.push(selectedEsgcList);
        }

        setConfirmButton(false); //enabled confirm button
        setSelectedEsgcValue(modifiedObject);
    };

    // confirm button click
    const confirmClick = (): void => {
        modifiedSelectedList(selectedEsgcValue);
        onHandleClose();
    };

    // spinner animation time calculation
    const animationTimer = async (): Promise<void> => {
        clearTimeout(animationTimeOut); //clear previous timeout

        setLoadAnimation((_prev) => false);

        setLoadAnimation((prev) => !prev);

        animationTimeOut = setTimeout(() => {
            setLoadAnimation((_prev) => false);
        }, Config.PORTFOLIO_ANIMATION_TIMER);
    };

    //company count calculation
    const companyCountCal = (): void => {
        const companyCalculationResult: Dashboard.CompanyCalculationData = companyCountCalculation(
            esgcValueList,
            allCompanyList,
            selectedEsgcValue,
            userExcludedList,
            sp500CompanyList
        );

        const companyCount = {
            totalCompanyCount: companyCalculationResult.totalCompanyCount,
            excludedCompanyCount: companyCalculationResult.excludedCompaniesList.length,
            addedCompanyCount: companyCalculationResult.addCompaniesList.length,
        };

        setCompanyCount(companyCount);
        setCompanyCountFlag(true);
    }

    // all ESG-C value company calculation
    React.useEffect(() => {
        if (selectedEsgcValue.length > 0) {
            openAdvanceModal && animationTimer()
            companyCountCal()
        }
    }, [selectedEsgcValue])

    const excludedCompanyCount = companyCountFlag ? companyCount.excludedCompanyCount : companyCountFunction?.excludedCompanyCount;
    const addedCompanyCount = companyCountFlag ? companyCount.addedCompanyCount : companyCountFunction?.addedCompanyCount;
    const totalCompanyCount = companyCountFlag ? companyCount.totalCompanyCount : companyCountFunction?.totalCompanyCount;

    return (
        <Modal
            size='xl'
            centered
            show={openAdvanceModal}
            backdrop="static"
            keyboard={false}
            className="gi-port-ao-pc-companies-modal deep-clean-modal"
            onHide={onHandleClose}
            contentClassName='border-0'
            dialogClassName='modal-xl'
        >
            <Modal.Header className="d-md-flex d-lg-none p-2">
                <button type="button" className="btn-close p-0 m-0 border-0 opacity-100" onClick={onHandleClose} data-testid="close-btn"></button>
            </Modal.Header>
            <Modal.Body className='p-lg-4 ps-lg-5 p-1 pb-2 position-relative'>
                <button type="button" className="btn-close border-0 opacity-100 position-absolute d-lg-inline-block d-none" onClick={onHandleClose}></button>
                {/* advanced data */}
                <div className="deep-clean-modal-card">
                    <div className="row gx-4">
                        <div className="col-lg-6 order-lg-0 order-1">
                            <div className="deep-clean-modal-text px-lg-0 pe-lg-1 px-4">
                                {esgcValueList.map((e, i) => {
                                    const selectedEsgc = selectedEsgcValue.find((a: { esgcValueIdentifier: string; }) => a.esgcValueIdentifier === e.identifier);
                                    if (e.identifier === informationId) {
                                        return (
                                            <div className="deep-clean-modal-text-card" key={i}>
                                                {/* ESG-C value name */}
                                                <div className="deep-clean-modal-text-header py-4 mt-1">
                                                    {e.name}
                                                </div>
                                                {/* deep clean company */}
                                                <div className="deep-clean-modal-text-body mt-3">
                                                    <div className="deep-clean-modal-text-toggle form-check form-switch">
                                                        <InputCheckboxComponent
                                                            labelText={IntlMessages('portfolio_deep')}
                                                            labelClass={'form-check-label'}
                                                            fieldName={`${e.identifier}d`}
                                                            fieldValue={e.identifier}
                                                            checkValue={Boolean(selectedEsgc?.deepClean)}
                                                            inputClass={'form-check-input'}
                                                            inputValue={deepClean}
                                                            dataTestId={'check-box1'}
                                                        />
                                                    </div>
                                                    <div className="deep-clean-modal-text-text pt-3 mt-1 pb-4">
                                                        <Markup content={e.advancedDeepCleanText} />
                                                    </div>
                                                </div>
                                                {/* add good company */}
                                                {e.inclusionList.length > 0 && (
                                                    <div className="deep-clean-modal-text-body mt-3">
                                                        <div className="deep-clean-modal-text-body mt-3">
                                                            <div className="deep-clean-modal-text-toggle form-check form-switch">
                                                                <InputCheckboxComponent
                                                                    labelText={e.advancedGoodCompaniesTitle}
                                                                    labelClass={'form-check-label'}
                                                                    fieldName={`${e.identifier}a`}
                                                                    fieldValue={e.identifier}
                                                                    checkValue={Boolean(selectedEsgc?.addGoodCompanies)}
                                                                    inputClass={'form-check-input'}
                                                                    inputValue={addGoodCompany}
                                                                    dataTestId={'check-box2'}
                                                                />
                                                            </div>
                                                            <div className="deep-clean-modal-text-text pt-3 mt-1 pb-4">
                                                                <Markup content={e.advancedGoodCompaniesText} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }
                                })}
                                {/* btn */}
                                <div className="deep-clean-modal-text-footer d-none d-lg-flex justify-content-start px-3 py-3 ps-0">
                                    <button type="button" className="d-flex align-items-center gap-2 ps-3 border-0" disabled={confirmButton} onClick={confirmClick} data-testid="confirm-button-desk">
                                        <span className="check-icon d-block"></span>
                                        {IntlMessages('button_confirm')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* spinner card */}
                        <div className="col-lg-6 order-lg-1 order-0">
                            <div className="deep-clean-modal-spinner-card p-lg-5 px-5 pt-3 pb-4 h-100 ">
                                <div className="deep-clean-modal-spinner-main">
                                    {/* Animation Spinner */}
                                    <div className="row">
                                        <div className="col-12">
                                            <div className={`deep-clean-modal-spinner-img ${loadAnimation ? 'loading' : ''}`}>
                                                {/* desktop */}
                                                <div className="d-none d-sm-block">
                                                    <PortfolioAnimation loadAnimation={loadAnimation} width={348} />
                                                </div>
                                                {/* mobile */}
                                                <div className="d-block d-sm-none">
                                                    <PortfolioAnimation loadAnimation={loadAnimation} width={236} />
                                                </div>
                                                <div className="gi-p-graph-bg">
                                                    {esgcValueList.map((e, i) => {
                                                        if (e.identifier === informationId) {
                                                            return <img src={e.advancedScannerImage} className="img-fluid" alt="" key={i} loading="lazy" />;
                                                        }
                                                    })}
                                                </div>
                                                <div className="gift-p-stock-text">
                                                    <h6>
                                                        {loadAnimation
                                                            ? <NumberAnimation
                                                                counterAnimation={totalCompanyCount}
                                                                startNumber={0}
                                                                delayAnimation={Config.PORTFOLIO_ANIMATION_TIMER}
                                                            />
                                                            : totalCompanyCount
                                                        }
                                                    </h6>
                                                    <span>{IntlMessages('portfolio_chart1')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* added and excluded */}
                                    <div className="row justify-content-center">
                                        <div className="col-lg-7">
                                            <div className="deep-clean-modal-spinner-text mt-3">
                                                {/* excluded companies */}
                                                <ul className="d-flex justify-content-between">
                                                    <li className="d-flex align-items-center">
                                                        <h6>{IntlMessages('basket_excluded_companies')}</h6>
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        {loadAnimation
                                                            ? <span className="text-center">
                                                                <NumberAnimation
                                                                    counterAnimation={excludedCompanyCount}
                                                                    startNumber={500}
                                                                    delayAnimation={Config.PORTFOLIO_ANIMATION_TIMER}
                                                                />
                                                            </span>
                                                            : excludedCompanyCount === 0
                                                                ? <span className="text-center">000</span>
                                                                : excludedCompanyCount < 10
                                                                    ? <span className="text-center">00<i>{excludedCompanyCount}</i></span>
                                                                    : excludedCompanyCount < 100
                                                                        ? <span className="text-center">0<i>{excludedCompanyCount}</i></span>
                                                                        : <span className="text-center"><i>{excludedCompanyCount}</i></span>
                                                        }
                                                    </li>
                                                </ul>
                                                {/* added companies */}
                                                <ul className="d-flex justify-content-between">
                                                    <li className="d-flex align-items-center">
                                                        <h6>{IntlMessages('added_companies')}</h6>
                                                    </li>
                                                    <li className="d-flex align-items-center">
                                                        {loadAnimation
                                                            ? <span className="text-center">
                                                                <NumberAnimation
                                                                    counterAnimation={addedCompanyCount}
                                                                    startNumber={500}
                                                                    delayAnimation={Config.PORTFOLIO_ANIMATION_TIMER}
                                                                />
                                                            </span>
                                                            : addedCompanyCount === 0
                                                                ? <span className="text-center">000</span>
                                                                : addedCompanyCount < 10
                                                                    ? <span className="text-center">00<i>{addedCompanyCount}</i></span>
                                                                    : addedCompanyCount < 100
                                                                        ? <span className="text-center">0<i>{addedCompanyCount}</i></span>
                                                                        : <span className="text-center"><i>{addedCompanyCount}</i></span>
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* btn */}
                <div className="deep-clean-modal-text-footer-bottom d-lg-none d-lg-flex justify-content-end px-3 py-3">
                    <button type="button" className="d-flex align-items-center gap-2 ps-3 ms-auto border-0" disabled={confirmButton} onClick={confirmClick} data-testid="confirm-button-mob">
                        <span className="check-icon d-block"></span>
                        {IntlMessages('button_confirm')}</button>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default AdvanceOptionModal;