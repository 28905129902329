//default library
import * as React from "react";
//import regex
import * as Config from "config/Config";
//custom components
import InputComponentText2 from "components/InputComponent/InputComponentText2";
import ErrorKYC from "components/ErrorComponent/ErrorKYC";
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import KYCFooterButton from "components/FooterComponent/KYCFooterButton";
// custom function
import resizeErrorMessage from "service/utils/ResizeErrorMessage";
// local message
import IntlMessages from "helper/IntlMessages";
//types import
import * as OnBoarding from "interface/OnBoarding";
import * as PropsFunction from "interface/PropsFunction";
// local data
import * as Local from 'service/utils/LocalStorageData';

// Define the PropTypes for the PhoneNumber component
type Props = {
  propsData: PropsFunction.PropsPageDataFunction
}

// Accessing environment variables
const ENV = JSON.parse(import.meta.env.REACT_APP_UNDERSCORE_LASTNAME);

const UserName: React.FC<Props> = (props: Props) => {
  const { propsData } = props;

  const [firstName, setFirstName] = React.useState<string>(""); //represents the first name input value
  const [lastName, setLastName] = React.useState<string>(""); //represents the last name input value
  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({}); //containing 'firstName' and 'lastName'

  const nextPage = "page2"; //representing the next page 
  const sendText = false; //representing a text should be send
  const isRouteChange = true; //representing  change in the route
  const submitText = false; //representing  submit text is present
  const parentButton = false; //representing the presence of a parent button

  React.useEffect(() => {
    const localStorageData = Local.getLocalData("onBoardingDetails");

    // Setting initial state values from localStorage
    (localStorageData && localStorageData.firstName) && setFirstName(localStorageData.firstName);
    (localStorageData && localStorageData.lastName) && setLastName(localStorageData.lastName);

    propsData({
      previousPage: '',
      pageNumber: 1,
    }); //send to parent 
  }, []);

  //  updating input values in state
  const setInputValue = (fieldName: string, fieldValue: string): void => {

    fieldName === "firstName" && setFirstName(fieldValue);
    fieldName === "lastName" && setLastName(fieldValue);
  };

  React.useEffect(() => {
    const fieldDetails = {
      firstName: firstName,
      lastName: lastName,
    };

    setFieldDetails(fieldDetails);

    return () => {
      resizeErrorMessage("firstName", "lastName"); // resizing error messages
    };
  }, [firstName, lastName])

  return (
    <>
      <div className="gi-ob-slide-card" data-testid="user-page">
        {/* static text */}
        <div className="gi-ob-full-name-text">
          <h5>{IntlMessages("username_text1")}</h5>
          <p>{IntlMessages("username_text2")}</p>
        </div>

        {/* user name */}
        <div className="gi-ob-full-name-form">
          {/* first name */}
          <div className="gi-ob-input-box">
            <label>{IntlMessages("username_text3")}</label>
            <InputComponentText2
              name={"firstName"}
              placeholder={"placeholder_first_name"}
              className={""}
              fieldValue={firstName}
              inputValue={setInputValue}
              readOnly={false}
              disabled={false}
              dataTestId={"first-name"}
            />
            {(firstName.length > Config.MAX_NUMBER_36) && (
              <ErrorKYC
                message={"error_max_length_36_name"}
                fieldName={"firstName"}
              />
            )}
            {(firstName && !firstName.match(Config.NAME_REGEX)) && (
              <ErrorKYC
                message={"error_first_name"}
                fieldName={"firstName"}
              />
            )}
          </div>

          {/* last name */}
          <div className="gi-ob-input-box">
            <label>{IntlMessages("username_text4")}</label>
            <InputComponentText2
              name={"lastName"}
              placeholder={"placeholder_last_name"}
              className={""}
              fieldValue={lastName}
              inputValue={setInputValue}
              readOnly={false}
              disabled={false}
              dataTestId={"last-name"}
            />
            {(lastName.length > Config.MAX_NUMBER_36) && (
              <ErrorKYC
                message={"error_max_length_36_name"}
                fieldName={"lastName"}
              />
            )}
            {(lastName && !lastName.match(ENV ? Config.LAST_NAME_REGEX : Config.NAME_REGEX)) && (
              <ErrorKYC
                message={"error_last_name"}
                fieldName={"lastName"}
              />
            )}
          </div>
        </div>
      </div>

      {/* desktop button */}
      <div className="gi-ob-let-btn-group">
        <ButtonComponent
          isFooter={false}
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
        />
      </div>

      {/* footer button for mobile */}
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
      />
    </>
  );
};

export default UserName;













