// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { getKYCData, setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as UserService from 'middleware/UserService';
// message import
import IntlMessages from 'helper/IntlMessages';
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
// type import
import * as PropsFunction from 'interface/PropsFunction';
// default data
import { amountRangeInvestment, investmentPurposeOption } from 'config/DefaultValue';
// custom component
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import TabSelectComponent from 'components/SelectComponent/TabSelectComponent';
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

export type Props = {
    propsData: PropsFunction.PropsStringNumberFunction
}

const InvestmentPurpose: React.FC<Props> = (props: Props) => {
    const { propsData } = props;
    const history = useHistory();

    const dispatch = useDispatch();
    const kycData = useSelector(getKYCData);

    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state
    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false) //check page data change or not for api call

    const [investmentAmount, setInvestmentAmount] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue);

    const [investmentPurpose, setInvestmentPurpose] = React.useState<string>('')

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    React.useEffect(() => {
        propsData('/on-boarding/page6', 7)

        const investmentAmount = amountRangeInvestment.find(e => e.value === kycData.investmentAmount)
        investmentAmount && setInvestmentAmount(investmentAmount)

        setInvestmentPurpose(kycData.investmentPurpose)
    }, [])

    const selectValue: PropsFunction.SelectedOption = (_fieldName, value) => {
        setInvestmentAmount(value)
        setIsDataChanged(true)
    }

    //  updating input values in state
    const setInputValue = (_fieldName: string, fieldValue: string): void => {
        setInvestmentPurpose(fieldValue)
        setIsDataChanged(true)
    }

    // goto next page
    const clickNext = async (): Promise<void> => {
        const newKycData = {
            ...kycData,
            investmentAmount: investmentAmount.value,
            investmentPurpose: investmentPurpose
        }
        dispatch(setKYCData(newKycData))

        if (isDataChanged) {
            setIsLoading(true);
            const accessToken = await initiateAction();
            const updateUser = await UserService.userDataUpdateIL(newKycData, accessToken); //update user api call
            setIsLoading(false);
            if (updateUser.response || updateUser.request) {
                setErrorLog(updateUser)
                setIsErrorAPI(true)
            } else {
                history.push('/on-boarding/page8')
            }
        } else {
            history.push('/on-boarding/page8')
        }
    }

    React.useEffect(() => {
        setIsDisabled(true)
        if (investmentAmount.value && investmentPurpose) {
            setIsDisabled(false)
        }
    }, [investmentAmount, investmentPurpose])

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation">
                <div className="row">
                    {/* investment purpose label */}
                    <div className="col-12">
                        <div className="gi-ob-heading my-4">
                            <h5>{IntlMessages('kyc_investment_label')}</h5>
                        </div>
                    </div>
                    {/* page content */}
                    <div className="col-md-11">
                        <div className="row gx-4 gy-4">
                            {/* investment purpose tab selection */}
                            <div className="col-12">
                                <TabSelectComponent
                                    name={'investmentPurpose'}
                                    fieldValue={investmentPurpose}
                                    option={investmentPurposeOption}
                                    isShowInvestmentClass={true}
                                    inputValue={setInputValue}
                                    dataTestID={'investment-purpose'}
                                />
                            </div>

                            {/* investment amount label */}
                            <div className="col-12">
                                <div className="gi-ob-heading mt-4 pt-2">
                                    <h5>{IntlMessages('kyc_investment_money_label')}</h5>
                                    <p className="mt-2">{IntlMessages('kyc_investment_money_sub_label')}</p>
                                </div>
                            </div>

                            {/* investment amount dropdown */}
                            <div className="col-md-6">
                                <div className="gi-ob-form form-group mt-3">
                                    <SelectDropdown
                                        labelClassName={''}
                                        labelText={''}
                                        name={'investmentAmount'}
                                        placeholder={'placeholder_select_dropdown'}
                                        option={amountRangeInvestment}
                                        fieldValue={investmentAmount}
                                        className={'custom-react-dropdown-il'}
                                        ariaLabel={'investment-amount'}
                                        isDisabled={false}
                                        inputValue={selectValue}
                                        isModalDropdown={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* next button */}
                    <div className="col-12">
                        <IsraelKYCButton isDisabled={isDisabled} isSendButton={false} continueClick={clickNext} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default InvestmentPurpose