// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { getKYCData, setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as UserService from 'middleware/UserService';
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as OnBoarding from 'interface/OnBoarding';
// default data
import { sourceMoneyOption, otherSourceOption } from 'config/DefaultValue';
// custom component
import InputRadioComponent from 'components/InputComponent/InputRadioComponent';
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent';
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

export type Props = {
    propsData: PropsFunction.PropsStringNumberFunction
}

const SourceOfMoney: React.FC<Props> = (props: Props) => {

    const { propsData } = props;
    const history = useHistory();

    const dispatch = useDispatch();
    const kycData = useSelector(getKYCData);

    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state
    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false) //check page data change or not for api call

    const [sourceMoney, setSourceMoney] = React.useState<string>('');
    const [otherSource, setOtherSource] = React.useState<OnBoarding.CheckBoxList>(otherSourceOption);

    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    React.useEffect(() => {
        propsData('/on-boarding/page9', 10)

        setSourceMoney(kycData.sourceMoney)
        const otherSource = kycData.otherSource.split(',')
        const updatedOptions = otherSourceOption.map(option => {
            if (otherSource.includes(option.name)) {

                return { ...option, active: true };
            }
            return option;
        });
        setOtherSource(updatedOptions)
    }, [])

    //  updating input values in state
    const setInputValue = (_fieldName: string, fieldValue: string): void => {
        setSourceMoney(fieldValue)
        if (fieldValue === 'other') {
            setOtherSource((prev) => prev.map(e => ({
                label: e.label,
                name: e.name,
                active: false,
            })))
        }
        setIsDataChanged(true)
    }

    //  updating input values in state
    const setInputValueCheckbox = (fieldName: string, fieldValue: boolean): void => {
        setOtherSource((prev) => {
            const otherSource = [...prev]
            const index = otherSource.findIndex(e => e.name === fieldName);
            otherSource[index].active = fieldValue
            return otherSource
        })
        setIsDataChanged(true)
    }

    // goto next page
    const clickNext = async (): Promise<void> => {
        const otherSourceData = otherSource.filter(e => e.active)
        const newSource = [...new Set(otherSourceData.map((e) => e.name))].join(', ')
        const newKycData = {
            ...kycData,
            sourceMoney: sourceMoney,
            otherSource: newSource
        }
        dispatch(setKYCData(newKycData))

        if (isDataChanged) {
            setIsLoading(true);
            const accessToken = await initiateAction();
            const updateUser = await UserService.userDataUpdateIL(newKycData, accessToken); //update user api call
            setIsLoading(false);
            if (updateUser.response || updateUser.request) {
                setErrorLog(updateUser)
                setIsErrorAPI(true)
            } else {
                history.push('/on-boarding/page11')
            }
        } else {
            history.push('/on-boarding/page11')
        }
    }

    React.useEffect(() => {
        setIsDisabled(true)
        const isOtherSelected = otherSource.find(e => e.active)
        if (sourceMoney === 'other' && isOtherSelected) {
            setIsDisabled(false)
        }
        if (sourceMoney === 'salary') {
            setIsDisabled(false)
        }

    }, [sourceMoney, otherSource])

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation">
                <div className="row">
                    {/* page label */}
                    <div className="col-12">
                        <div className="gi-ob-heading my-4">
                            <h5>{IntlMessages('kyc_source_money_label')}</h5>
                        </div>
                    </div>

                    {/* page content */}
                    <div className="col-md-12">
                        <div className="row gx-4 gy-4" >
                            {/* radio button */}
                            <div className="col-12">
                                <div className="d-flex gap-2 custom-btn-radio mt-4">
                                    <InputRadioComponent
                                        name={'sourceMoney'}
                                        checkValue={sourceMoney}
                                        radioOption={sourceMoneyOption}
                                        inputValue={setInputValue}
                                        inputClass={'btn-check'}
                                        labelClass={'btn btn-dark align-items-center'}
                                        isSimpleRadio={true}
                                        dataTestId={'source-money'}
                                        divClassName='form-check form-check-inline'
                                    />
                                </div>
                            </div>

                            {/* Other option */}
                            {sourceMoney === 'other' && (
                                <div className="row gy-3 mt-5" data-testid='check-box'>
                                    <div className="col-md-7 mt-0">
                                        {otherSource.map((e, i) => {
                                            if (i % 2 === 0) {
                                                return (
                                                    <div className={"col-12 mt-3"} key={i}>
                                                        <div className="form-check">
                                                            <InputCheckboxComponent
                                                                labelText={e.label}
                                                                fieldName={e.name}
                                                                fieldValue={e.name}
                                                                checkValue={e.active}
                                                                inputClass={'form-check-input mt-1'}
                                                                labelClass={'gi-ob-form-check-label form-check-label mt-1'}
                                                                inputValue={setInputValueCheckbox}
                                                                dataTestId={'check-box1'}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>

                                    <div className="col-md-5 mt-0">
                                        {otherSource.map((e, i) => {
                                            if (i % 2 !== 0) {
                                                return (
                                                    <div className={"col-12 mt-3"} key={i}>
                                                        <div className="form-check">
                                                            <InputCheckboxComponent
                                                                labelText={e.label}
                                                                fieldName={e.name}
                                                                fieldValue={e.name}
                                                                checkValue={e.active}
                                                                inputClass={'form-check-input mt-1'}
                                                                labelClass={'gi-ob-form-check-label form-check-label mt-1'}
                                                                inputValue={setInputValueCheckbox}
                                                                dataTestId={'check-box2'}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* next button */}
                    <div className="col-12">
                        <IsraelKYCButton isDisabled={isDisabled} isSendButton={false} continueClick={clickNext} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default SourceOfMoney