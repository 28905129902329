/**
 * get the amount
 *
 * @param {number} value
 * @return {*}  {number}
 */
export const amountRangeCalculator = (value: string | number | null): number => {
    const amount = Number(value);

    return amount < 40000
        ? 20000 //value less then $40,000
        : amount >= 40000 && amount < 80000
            ? 60000 //between $40,000 and $80,000
            : amount >= 80000 && amount < 150000
                ? 115000 //between $80,000 and $150,000
                : amount >= 150000 && amount < 300000
                    ? 225000 //between $150,000 and $300,000
                    : 500000 //more than $300,000;
}

export const amountRangeCalculator2 = (value: string | number | null): number => {
    const amount = Number(value);

    return amount < 100000
        ? 50000 //value less then $100,000
        : amount >= 100000 && amount < 200000
            ? 150000 //between $100,000 and $200,000
            : amount >= 200000 && amount < 500000
                ? 350000 //between $200,000 and $500,000
                : amount >= 500000 && amount < 1000000
                    ? 750000 //between $500,000 and 1,000,000
                    : 2000000 //more than $1,000,000;
}