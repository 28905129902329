// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//types props
import * as PropsFunction from "interface/PropsFunction";

export type Props = {
    openModal: boolean;
    bodyClassName: string;
    messageBody: JSX.Element;
    showCloseButton: boolean;
    onHandleClose: PropsFunction.SimpleFunction;
}

const SuccessModalNew: React.FC<Props> = (props: Props) => {
    const { openModal, bodyClassName, messageBody, showCloseButton, onHandleClose } = props;

    return (
        <Modal
            centered
            size='sm'
            show={openModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            className={`gi-ob-green-bottom-curve enter-code-modal enter-code-modal-success`}
        >
            {showCloseButton ? <button type="button" className="btn-close opacity-100" onClick={onHandleClose}></button> : ''}
            <Modal.Body className={bodyClassName}>
                {messageBody}
            </Modal.Body>
        </Modal>
    )
}

export default SuccessModalNew