const IsraelOnBoarding: { [key: string]: string } = {
    /* common */
    'kyc_let_start': `בואו נתחיל`,
    'kyc_not_start': `לא עכשיו`,
    'privacy_policy_il': 'למדיניות  הפרטיות',
    'kyc_note': 'שים לב',

    /* dropdown or selection */
    'amount_less_50000': 'עד 50,000 ש״ח',
    'amount_bet_50000_250000': '50,000 ש״ח  - 250,000 ש״ח',
    'amount_bet_250000_500000': '250,000 ש״ח - 500,000 ש״ח',
    'amount_more_500000': '500,000 ש״ח ומעלה',
    'amount_less_160000': 'פחות מ 160,000 ₪',
    'amount_btw_160000_320000': 'בין 160,000 ל 320,000 ₪',
    'amount_btw_320000_600000': 'בין 320,000 ל 600,000 ₪',
    'amount_btw_600000_1200000': 'בין 600,000 ל 1,200,000 ₪',
    'amount_more_1200000': 'מעל 1,200,000 ₪',

    /* button */
    'btn_continued': 'המשך',
    'btn_send_otp': 'שלח שנית',
    'btn_attach_doc': 'הצמד מסמך',
    'btn_click_photo': 'צלם בנייד',
    'btn_click_edit': 'ערוך',

    /* header text */
    'il_header_return': 'חזור',

    /* footer text */
    'il_footer_privacy_policy': 'מדיניות הפרטיות',

    /* place holder */
    'placeholder_IL_first_name': 'כפי שמופיע בת.ז. (כולל שם שני אם קיים) ',
    'placeholder_IL_last_name': 'כפי שמופיע בת.ז.',
    'placeholder_IL_phone': 'עשר ספרות בלבד',
    'placeholder_IL_doc_id': 'כולל ספרת ביקורת',
    'placeholder_name_en': 'In English',
    'placeholder_select_dropdown': 'בחר/י',
    'placeholder_IL_company_name': 'שם החברה',

    /* error message */
    'fill_data': 'נא למלא את הנתונים',
    'error_invalid_ID_card': 'תעודת הזהות שהזנת אינה תקינה.',
    'error_invalid_otp': 'קוד אימות שגוי',
    'error_dob_18': 'שים לב. השירות ניתן מעל גיל 18 בלבד',

    /* start page */
    'kyc_start_page_text1_desk': `שמחים שבחרת להשקיע בעצמך!`,
    'kyc_start_page_text2_desk': `אנחנו מאמינים שחסכון והשקעות יכולים להיות גם פשוטים וגם משתלמים`,
    'kyc_start_page_text3_desk': `על מנת לפתוח עבורכם חשבון השקעות, עלינו להשלים את {br}הפרטים שלכם ולשאול מספר שאלות`,
    'kyc_start_page_text1_mob': 'שמחים שבחרת {br}להשקיע בעצמך!',
    'kyc_start_page_text2_mob': 'אנחנו מאמינים שחסכון והשקעות {br}יכולים להיות גם פשוטים וגם משתלמים',
    'kyc_start_page_text3_mob': 'על מנת לפתוח עבורכם חשבון {br} השקעות, עלינו להשלים את הפרטים {br}שלכם ולשאול מספר שאלות',

    /* Work Email modal */
    'work_email_modal_head': 'רגע לפני שנתחיל',
    'work_email_modal_sub_head': 'רוצה לבדוק האם החברה {br}שלך עובדת איתנו?',
    'work_email_label': 'מלא את מייל העבודה שלך:',
    'work_email_footer': 'מלבד שליחת קוד אימות, {br}לא נשתמש בכתובת המייל.',
    'work_email_verify_text1': 'יאיי! החברה שלך עובדת איתנו',
    'work_email_verify_text2': 'אנא מלא את קוד האימות שנשלח {br}אליך למייל ונתחיל בתהליך פתיחת {br}חשבון ההשקעות שלך',
    'work_email_verify_text3': 'לא קיבלת את הקוד? {br} אל תשכח לבדוק בספאם',
    'work_email_verify_resend': 'שלח שנית',
    'work_email_verify_error_text1': 'קוד אימות שגוי',
    'work_email_verify_error_text2': 'אנא בדוק את הקוד שהקלדת',
    'work_email_not_list_text1': 'אופס!',
    'work_email_not_list_text2': 'החברה שלך עדיין לא הצטרפה {br} למועדון החברות המציעות לעובדיהם {br}את השירותים שלנו.',
    'work_email_not_list_text3': 'אנחנו מזמינים אותך להמשיך בתהליך {br}פתיחת חשבון ההשקעות. נשמח ליידע אותך {br}כאשר נגיע להסדר עם החברה שלך ו/רו {br}שהפלטפורמה תיפתח לקהל הרחב.',
    'work_email_skip_modal_text1': 'שים לב,',
    'work_email_skip_modal_text2': 'הפלטפורמה פתוחה רק לעובדי{br} חברות שהגיעו איתנו להסדר.',
    'work_email_skip_modal_text3': 'אנחנו מזמינים אותך להמשיך {br}בתהליך פתיחת חשבון ההשקעות.',
    'work_email_skip_modal_text4': 'נשמח ליידע אותך כאשר נגיע להסדר {br} עם החברה שלך ו/או שהפלטפורמה {br} תיפתח לקהל הרחב.',
    'work_email_skip_modal_text5': 'בדוק בכל זאת',
    'work_email_btn_back_account': 'חזרה לחשבון',

    /* Full name, id page */
    'kyc_name_page_header': 'מילוי פרטים אישיים',
    'kyc_name_page_footer1': 'לחיצה על ״המשך״ תהווה הסכמתך למסירת פרטיך בהתאם',
    'kyc_name_page_footer2': ' שלנו וכן לכך שנציגינו יצרו עמך קשר (במסרון או במייל) בכדי לסייע לך בתהליך ההצטרפות לשירות ככל שיידרש.',
    'kyc_first_name_label': 'שם פרטי',
    'kyc_last_name_label': 'שם משפחה',
    'kyc_last_doc_id': 'מספר תעודת זהות',
    'kyc_last_phone_label': 'טלפון נייד',
    'kyc_otp_modal_heading': 'קוד אימות',
    'kyc_otp_modal_text': 'שלחנו קוד אימות לנייד שלך.{br}הזן את הקוד כדי להמשיך.',

    /* DOB, Gender page */
    'kyc_dob_label': `תאריך לידה`,
    'kyc_gender_label': 'מין',
    'kyc_gender_female': 'נקבה',
    'kyc_gender_male': 'זכר',
    'kyc_first_name_label_en': 'First Name',
    'kyc_last_name_label_en': 'Last Name ',
    'kyc_us_citizen': `אזרח ארה״ב לרבות תושב או מחזיק גרין קארד`,
    'kyc_info_us_yes_text1': `אזרח או תושב ארה״ב אינו יכול{br}להצטרף לשירות בשלב זה`,
    'kyc_info_us_yes_text2': `ניצור איתך קשר כשהשירות יתמך{br}לאזרחי ארה״ב`,
    'kyc_info_us_yes_btn1': 'הבנתי, תודה',
    'kyc_info_us_yes_btn2': 'בחזרה להמשך תהליך',
    'kyc_info_us_no_text1': `השירות מיועד ללקוחות בעלי תעודת{br}זהות ישראלית המתגוררים בישראל.{br}השירות יינתן בגבולות מדינת ישראל{br}בלבד ואין לעשות בו שימוש מחוץ{br}לישראל.`,
    'kyc_info_us_no_btn1': 'הבנתי, אפשר להמשיך',

    /* Address page */
    'kyc_address_header': 'כתובת מגורים בישראל',
    'kyc_address_street': 'רחוב',
    'kyc_address_city': 'עיר',
    'kyc_address_house_no': 'מספר בית',
    'kyc_address_apartment': 'דירה',
    'kyc_address_postal_code': 'מיקוד',

    /* Employment Detail page */
    'kyc_employment_detail_header': 'פרטי תעסוקה',
    'kyc_employment_detail_label': 'סטטוס תעסוקתי',
    'kyc_employment_detail_company_name': 'שם החברה',
    'kyc_employment_detail_ticker': 'Ticker',
    'kyc_employment_detail_position': 'תפקידך בחברה',
    'kyc_employment_detail_field': 'תחום עיסוק החברה',

    /* Annual Income page */
    'kyc_annual_income_header': 'מהי ההכנסה השנתית הממוצעת שלך?',
    'kyc_annual_income_label': 'הכנסה נטו כוללת של כל בני הבית. (משכורות, קצבאות, נכסים מניבים וכו׳)',

    /* Investment Experience page */
    'kyc_investment_experience_header': 'מה הרקע שלך בשוק ההון?',
    'kyc_investment_experience_label': 'אנחנו רוצים לדעת כדי שנוכל לייעץ לך בהתאם לידעותך בנושא.',

    /* Investment Purpose page */
    'kyc_investment_label': 'מהי המטרה העיקרית של ההשקעה שלך?',
    'kyc_investment_money_label': 'כמה כסף חשבת להשקיע?',
    'kyc_investment_money_sub_label': 'את סכום ההשקעה אפשר לשנות בקלות בהמשך.',

    /* Amount Intend To Invest page */
    'kyc_amount_intend_label': 'להערכתך, איזה חלק מהווה הסכום שבכוונתך להשקיע, מתוך נכסיך הנזילים?',
    'kyc_amount_intend_sub_label': 'מזומנים, פיקדונות בנקאיים, השקעות, ניירות ערך, נכסים פיננסיים וכדומה. {br}(לא כולל נדל"ן ופנסיה)',
    'kyc_amount_intend_modal_body_text': "ההשקעה בג'ירף היא השקעה במניות {br} בלבד ולכן כוללת סיכון. השקעה של {br} היקף משמעותי מסך הנכסים הנזילים{ br} גורמת לעליה ברמת הסיכון של כלל השקעותיך ונכסיך.",

    /* Investment Funds Page*/
    'kyc_investment_fund_label': 'בעוד כמה שנים להערכתך יהיה לך צורך להשתמש בכספי ההשקעה?',
    'kyc_investment_fund_sub_label': 'ניתן למשוך את הכסף בכל רגע.',
    'kyc_investment_fund_modal_body_text': 'השקעה לטווח זמן קצר בתיק {br} המבוסס על השקעה מנייתית בלבד {br} כוללת סיכון מוגבר להפסד לאור {br} תנודתיות שוק המניות ועלולה במקרים {br} מסוימים לגרום להפסד של חלקים {br} ניכרים מסכום ההשקעה.',

    /* Source of Money */
    'kyc_source_money_label': 'מה מקור הכסף העיקרי שיופקד בחשבון?',
    'kyc_source_money_salary': 'משכורת',
    'kyc_source_money_other': 'אחר',

    /* Politicly Exposed page */
    'kyc_politically_exposed_heading': 'האם כיום או ב-5 שנים האחרונות,{br} הינך אחד מהבאים:',
    'kyc_politically_exposed_question1': 'נושא/ת בתפקיד ציבורי בכיר, לרבות בן משפחה. ',
    'kyc_politically_exposed_question2': 'דירקטור, בעל שליטה או נושא משרה של חברה ציבורית הנסחרת בבורסה.',
    'kyc_politically_exposed_question3': 'רשום כברוקר או עובד בחברת בורסה, FINRA או קרן גידור.',
    'kyc_politically_exposed_info_head1': 'תפקיד ציבורי בכיר =',
    'kyc_politically_exposed_info_body1': 'לרבות ראש מדינה, נשיא מדינה, ראש{br} עיר, שופט, חבר פרלמנט, חבר מפלגה{br} בכיר, חבר ממשלה, קצין צבא או{br} משטרה בכיר, נושא משרה בכיר{br} בחברות ממשלתיות, בעל תפקיד בכיר{br} בארגונים בין לאומיים או כל ממלא{br} תפקיד כאמור אף אם תוארו שונה',
    'kyc_politically_exposed_info_head2': 'בן משפחה =',
    'kyc_politically_exposed_info_body2': 'בן זוג, אח, הורה, הורה של הורה, צאצא {br}או צאצא של בן הזוג או בן זוגו של כל{br} אחד מאלו',
    'kyc_politically_exposed_yes1_head': 'מהו שמו המלא של איש {br}הציבור ותפקידו?',
    'kyc_politically_exposed_yes2_head': 'אנא ציין את שם החברה{br} ושם המניה של החברה',
    'kyc_politically_exposed_yes3_head': 'אין בעיה, רק שים לב',
    'kyc_politically_exposed_yes3_body': 'הינך חייב לידע בכתב את מעסיקך על כוונתך לפתוח חשבון השקעות',
    'kyc_politically_exposed_info2_text1': 'אנו ממש מצטערים',
    'kyc_politically_exposed_info2_text2': 'אין באפשרותינו לפתוח חשבון השקעות לאנשי ציבור בשלב זה',
    'kyc_politically_exposed_info2_text3': 'לפרטים נוספים אנא פנה אלינו במייל:',

    /* Document Upload */
    'kyc_document_label': 'טעינת מסמכים מזהים',
    'kyc_document_sub_label': 'צרף מסמכים או צלם בנייד.',
    'kyc_document_driving': 'רישיון נהיגה',
    'kyc_document_passport': 'דרכון ישראלי',

    /* Israel Terms and Condition */
    'kyc_tc_heading': 'זהו. מסיימים. ',
    'kyc_tc_sub_heading': 'נותר רק לאשר כמה פרטים',
    'kyc_tc_term1': 'אני מאשר/ת כי הפעילות המתוכננת בחשבון היא הפקדה לצורך השקעה בלבד ו/או משיכת כספים.',
    'kyc_tc_term2': 'אני פועל/ת בעבור עצמי בלבד ואין נהנה נוסף בחשבון. אישורי לסעיף זה כמוהו כחתימת מקור על הצהרת הנהנים המצורפת.',
    'kyc_tc_term3': 'אני מבין/ה ומאשר/ת שההשקעה היא בפרופיל סיכון גבוה שכן ההשקעה כולה היא בתיק מנייתי אשר נועד לשקף השקעה אשר מבוססת על מדד ה S&P 500 האמריקאי, כך שעשויה להיות תנודתיות גבוהה בשווי התיק. זהו סיכון שאני מוכן/ה לקחת על מנת לקבל תשואה גם במחיר של ירידה בשווי התיק.    ',
    'kyc_tc_term4': 'לא נתקלתי בסירוב לפתוח חשבון במוסד פיננסי מסיבות של הלבנת הון או עבירות ניירות ערך.',
    'kyc_tc_term5': 'ניתנה לי האפשרות לעיין במסמכים הבאים ואני מסכים/ה לאמור בהם:',
    'kyc_tc_pdf1': 'נספח הצהרת נהנים',
    'kyc_tc_pdf2': 'נספח הסכם לקוח',
    'kyc_tc_pdf3': 'נספח הסכם DriveWealth',
    'kyc_tc_info_modal': 'בשלב זה לא ניתן להוסיף שותפים או נהנים לחשבון ההשקעות',

    /* KYC pending page */
    'kyc_pending_magnificent': 'מושלם!',
    'kyc_pending_question1': 'אנחנו מעבדים ומאמתים את הנתונים שהזנתם.',
    'kyc_pending_question2': `זה יקח כמה רגעים...`,

    /* KYC Success page */
    'kyc_complete_success_text1': `התהליך הושלם{br}בהצלחה!`,
    'kyc_complete_success_text2': `כל מה שנותר הוא להגדיר את סכום החיסכון לניכוי {br}מהמשכורת החודשית שיופקד בחשבון ההשקעות שלך.`,
    'kyc_complete_success_text3': `כל מה שנותר הוא להגדיר את{br} סכום החיסכון לניכוי מהמשכורת{br} החודשית שיופקד בחשבון{br}ההשקעות שלך.`,
    'kyc_complete_success_btn': `כניסה לחשבון`,
    'kyc_complete_document_upload_text1': 'יפה מאוד!',
    'kyc_complete_document_upload_text2': 'עכשיו רק צריך להעלות את המסמכים הרלוונטים',
    'kyc_complete_document_upload_dl_text1': 'רישיון נהיגה',
    'kyc_complete_document_upload_dl_text2': '/ ניקלט',
    'kyc_complete_document_upload_ip_text1': 'דרכון ישראלי',
    'kyc_complete_document_upload_ip_text2': '/ חסר',
    'kyc_complete_document_upload_btn': 'העלאת מסמכים',
    'kyc_complete_document_upload_skip': 'דלג לחשבון',
    'kyc_complete_kyc_error_text1': 'אנחנו ממש מצטערים',
    'kyc_complete_kyc_error_text2': 'לא עברת בהצלחה את תהליך פתיחת החשבון.{br} לפרטים נוספים אנא פנה אלינו במייל.',
    'kyc_complete_kyc_error_btn': 'חזרה לחשבון',

    /* KYC Review Page */
    'kyc_review_text1': `כמעט סיימנו...`,
    'kyc_review_text2_desk': `בזמן שאנחנו בודקים את כל הפרטים אתם מוזמנים{br}להמשיך בתהליך ולבחור את הסכום לחיסכון החודשי.`,
    'kyc_review_text2_mob': `בזמן שאנחנו בודקים את כל{br} הפרטים אתם מוזמנים להמשיך{br} בתהליך ולבחור את הסכום{br} לחיסכון החודשי.`,
    'kyc_review_text3': `אנחנו ממשיכים לעבוד ברקע`,
    'kyc_review_btn': `כניסה לחשבון`,

    /* KYC Denied page */
    'kyc_denied_text1': `אנחנו ממש מצטערים`,
    'kyc_denied_text2': `תהליך פתיחת החשבון לא{br} הושלם בהצלחה.`,
    'kyc_denied_btn': `חזרה לחשבון`,
    'kyc_denied_text3': `לפרטים נוספים אנא פנה אלינו במייל.`,
    'kyc_denied_modal_text1': 'אנחנו ממש מצטערים',
    'kyc_denied_modal_text2': 'תהליך פתיחת החשבון לא הושלם בהצלחה.',
    'kyc_denied_modal_text3': 'חזרה לחשבון',
    'kyc_denied_modal_text4': 'לפרטים נוספים אנא פנה אלינו במייל.',

    /* KYC Reject page */
    'kyc_reject_text1': 'אופס!',
    'kyc_reject_text2': `חלק מהפרטים שהזנת אינם{br} תואמים את מסמכי הזיהוי.`,
    'kyc_reject_btn': 'עדכן פרטים',
    'kyc_reject_text3': `לפרטים נוספים אנא פנה אלינו במייל.`,
    'kyc_reject_modal_text1': 'אופס!',
    'kyc_reject_modal_text2': 'חלק מהפרטים שהזנת אינם תואמים את מסמכי הזיהוי.',
    'kyc_reject_modal_text3': 'עדכן פרטים ',
    'kyc_reject_modal_text4': 'לפרטים נוספים אנא פנה אלינו במייל. ',

    /* KYC form page */
    'kyc_reject_form_text1': 'עדכון פרטים אישיים',
}

export default IsraelOnBoarding