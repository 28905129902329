//default library
import * as React from "react"
import Modal from 'react-bootstrap/Modal';
//custom components
import InputComponentText2 from 'components/InputComponent/InputComponentText2';
import TextAreaComponent2 from 'components/InputComponent/TextAreaComponent2';
import ErrorKYC from 'components/ErrorComponent/ErrorKYC';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types import
import * as PropsFunction from "interface/PropsFunction";
// config
import * as Config from 'config/Config';

//defining props
export type Props = {
    brokerDealer: boolean;
    stockOwner: boolean;
    publicOfficial: boolean;
    companyName: string;
    tickerSymbol: string;
    publicOfficialText: string;
    onHandleCloseStock: PropsFunction.SimpleFunction;
    onHandleClosePublic: PropsFunction.SimpleFunction;
    onHandleConfirm: PropsFunction.SimpleFunction;
    setInputValue: PropsFunction.PropsInputValueFunction;
}


const EmploymentStockModal: React.FC<Props> = (props: Props) => {
    const { brokerDealer, onHandleConfirm, stockOwner, onHandleCloseStock, publicOfficial, onHandleClosePublic, companyName, tickerSymbol, publicOfficialText, setInputValue } = props//destructuring props

    // Button enabling condition for stockButton
    const stockButton = companyName && tickerSymbol && !(companyName.length > Config.MAX_NUMBER_244) && !(tickerSymbol.length > Config.MAX_NUMBER_10) && !tickerSymbol.match(Config.TICKER_REGEX);

    // Button enabling condition for publicButton
    const publicButton = publicOfficialText && !(publicOfficialText.length > Config.MAX_NUMBER_255) && !(publicOfficialText.length < 3);

    return (
        <>
            {/* broker dealer modal */}
            <Modal centered show={brokerDealer} onHide={onHandleConfirm} backdrop="static" keyboard={false} className="gi-ob-problem-modal">
                <Modal.Body>
                    <div className="gi-ob-problem-card" data-testid='broker-modal'>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHandleConfirm} data-testid='close-modal'></button>
                        <div className="gi-ob-problem-text">
                            <h5>{IntlMessages('employment_stock_text4')}</h5>
                            <p>{IntlMessages('employment_stock_text5')}</p>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ticker symbol modal */}
            <Modal centered show={stockOwner} onHide={onHandleCloseStock} backdrop="static" keyboard={false} className="gi-ob-ticker-symbol-modal">
                <Modal.Body>
                    <div className="gi-ob-ticker-symbol-card" data-testid='stock-owner'>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHandleCloseStock} data-testid='close-modal'></button>
                        <div className="gi-ob-ticker-symbol-text">
                            <p>{IntlMessages('employment_stock_text6')}</p>
                        </div>
                        <div className="gi-ob-ticker-symbol-form">
                            <div className="gi-ob-ticker-symbol-input">
                                <InputComponentText2
                                    name={'companyName'}
                                    placeholder={'placeholder_company_name'}
                                    className={''}
                                    fieldValue={companyName}
                                    inputValue={setInputValue}
                                    readOnly={false}
                                    disabled={false}
                                    dataTestId={"stock-company"}
                                />
                                {companyName.length > Config.MAX_NUMBER_244 && <ErrorKYC message={'error_max_length_244'} fieldName={'companyName'} />}
                            </div>
                            <div className="gi-ob-ticker-symbol-input">
                                <InputComponentText2
                                    name={'tickerSymbol'}
                                    placeholder={'placeholder_ticker'}
                                    className={''}
                                    fieldValue={tickerSymbol}
                                    inputValue={setInputValue}
                                    readOnly={false}
                                    disabled={false}
                                    dataTestId={"ticker-symbol"}
                                />
                                {tickerSymbol.length > Config.MAX_NUMBER_10 && <ErrorKYC message={'error_ticker_max_length'} fieldName={'tickerSymbol'} />}
                                {tickerSymbol.match(Config.TICKER_REGEX) && <ErrorKYC message={'error_ticker_message'} fieldName={'tickerSymbol'} />}
                            </div>
                            <div className="gi-ob-ticker-symbol-button">
                                <button type="button" disabled={!stockButton} onClick={onHandleConfirm}>
                                    {IntlMessages('button_confirm')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* family member info modal */}
            <Modal centered show={publicOfficial} onHide={onHandleClosePublic} backdrop="static" keyboard={false} className="gi-ob-ticker-symbol-modal">
                <Modal.Body>
                    <div className="gi-ob-spouse-card" data-testid='public-official'>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onHandleClosePublic} data-testid='close-modal'></button>
                        <div className="gi-ob-spouse-text">
                            <p>{IntlMessages('employment_stock_text7')}</p>
                        </div>
                        <div className="gi-ob-spouse-form">
                            <div className="gi-ob-spouse-input">
                                <TextAreaComponent2
                                    name={'publicOfficialText'}
                                    placeholder={'placeholder_family_member'}
                                    className={''}
                                    fieldValue={publicOfficialText}
                                    inputValue={setInputValue}
                                    dataTestId={'public-text'}
                                />
                                {publicOfficialText.length > Config.MAX_NUMBER_255 && (
                                    <ErrorKYC message={'error_max_length_255_textarea'} fieldName={'publicOfficialText'} />
                                )}
                            </div>
                            <div className="gi-ob-spouse-button">
                                <button type="button" disabled={!publicButton} onClick={onHandleConfirm}>
                                    {IntlMessages('button_confirm')}
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default EmploymentStockModal;

