// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// message import
import IntlMessages from 'helper/IntlMessages';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentLanguage, getLocale } from 'reduxStore/reducers/LanguageReducer';
// custom function
import * as Local from 'service/utils/LocalStorageData';
// custom component
import KYCHeader from 'components/HeaderComponent/KYCHeader';
import KYCFooter from 'components/FooterComponent/KYCFooter';
import EncryptionInformation from 'components/SectionComponent/EncryptionInformation';
import KYCLogo from 'components/LogoComponent/KYCLogo';
import KYCProgressBar from 'components/ProcessBarComponent/KYCProgressBar';
import KYCFooterButton from 'components/FooterComponent/KYCFooterButton';
import LocationEmailModal from 'components/ModalComponent/LocationEmailModal';

const IL_FLOW = JSON.parse(import.meta.env.REACT_APP_IL_FLOW)

export type Props = {
    workEmail: string;
}

const OnBoardingStartPage: React.FC<Props> = (props: Props) => {
    const { workEmail } = props;

    const history = useHistory();

    const dispatch = useDispatch();
    const locale = useSelector(getLocale);

    const [openLocationEmailModal, setOpenLocationEmailModal] = React.useState<boolean>(false); //location adn email confirmation modal
    const [locationValue, setLocationValue] = React.useState<string>('');

    React.useEffect(() => {
        setLocationValue(locale.countryCode)
    }, [])

    // open/close location email selection modal
    const openCloseLocationEmailModal = (): void => {
        if (IL_FLOW) {
            setOpenLocationEmailModal((prev) => !prev);
        } else {
            gotoNextPage()
        }
    }

    // change dropdown value
    const changeValue = (value: string): void => {
        setLocationValue(value)
    }

    // goto dashboard
    const gotoDashboard = (): void => {
        history.push('/dashboard');
        changeCurrentLanguage(); //change the language
    }

    // start on-boarding
    const gotoNextPage = (): void => {
        setOpenLocationEmailModal(false);
        Local.setLocalData('onBoardingDetails', { start: 'start' })
        setTimeout(() => {
            history.push('/on-boarding/page1');
            changeCurrentLanguage(); //change the language
        }, 100);
    }

    // change the language based on the user selection
    const changeCurrentLanguage = (): void => {
        dispatch(setCurrentLanguage(locationValue));
    }

    return (
        <>
            <div className="gi-ob-body-main">
                {/* header */}
                <KYCHeader perviousPage={''} isFirstPage={true} />

                {/* progress bar */}
                <KYCProgressBar pageNumber={0} />

                {/* body */}
                <section className="gi-ob-main-section">
                    <div className="container">
                        <div className="row">
                            <div className="co-12">
                                {/* logo component for KYC */}
                                <KYCLogo />

                                {/* content */}
                                <div className="gi-ob-slide-card">
                                    <div className="gi-ob-let-start">
                                        <h5>{IntlMessages('start_page_text1')}</h5>
                                        <span>{IntlMessages('start_page_text2')}</span>
                                        <p>{IntlMessages('start_page_text3')}</p>
                                    </div>
                                    <div className="gi-ob-let-btn-group">
                                        <button type="button" className="gi-ob-let-btn d-none d-sm-block" onClick={openCloseLocationEmailModal}>{IntlMessages('button_let_start')}</button>
                                        <span onClick={gotoDashboard}>{IntlMessages('button_set_later')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Encryption Information Text */}
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-ob-advisory-text">
                                    <EncryptionInformation />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* footer */}
                <KYCFooter />

                <KYCFooterButton
                    letStartText={true}
                    startKYC={openCloseLocationEmailModal}
                    nextRoute={''}
                    sendText={false}
                    isRouteChange={false}
                    submitText={false}
                    parentButton={false}
                    fieldDetails={{}}
                />
            </div>

            {/* Location, Work Email Modal open */}
            <LocationEmailModal
                openModal={openLocationEmailModal}
                locationValue={locationValue}
                workEmail={workEmail}
                changeValue={changeValue}
                onHandleClose={openCloseLocationEmailModal}
                onHandleContinue={gotoNextPage}
                onHandleDashboard={gotoDashboard}
            />
        </>
    )
}

export default OnBoardingStartPage