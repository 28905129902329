// default function
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// types import
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as PropsFunction from 'interface/PropsFunction';
// message provider
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';

export type Props = {
    pendingGiftDetail: DashboardType.GiftDetailResponseModel,
    openInfoModal: PropsFunction.OpenInfoModal,
    isNewUser: boolean,
}

const PendingGiftCard: React.FC<Props> = (props: Props) => {

    const history = useHistory(); //history
    const { pendingGiftDetail, openInfoModal, isNewUser } = props;
    const intl = useIntl();

    /**
     * Redirect to gift page
     * 
     * if KYC pending then redirect to on-boarding page for KYC
     * if KYC done then redirect to gift pending page
     */
    const redirectGift = (): void => {
        localStorage.setItem('isClickRedeemed', JSON.stringify(true))
        if (isNewUser) {
            history.push('/on-boarding')
        } else {
            history.push(`/dashboard/gift/${pendingGiftDetail.identifier}`)
        }
    }

    /**
     * Gift Info modal
     * 
     * Gift info modal message
     */
    const giftInfoClick = (): void => {
        const textHeading = IntlMessages('info_gift_pending_heading')
        const messageBody = <p className='mt-3'>{IntlMessages('info_gift_pending_body', { br: <br /> })}</p>
        openInfoModal(textHeading, messageBody, false)
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="gi-gt-gift-card-pending mt-md-2 mt-1">
                    <div className="gi-dash-gift-card-main d-flex flex-md-row flex-column align-items-md-center align-items-start position-relative gap-4">
                        <div className="gi-dash-gift-img">
                            <img src={intl.formatMessage({ id: 'DASHBOARD_GIFT_IMG' })} className="img-fluid gi-dash-gift-img-desk" alt="" />
                            <img src={intl.formatMessage({ id: 'DASHBOARD_GIFT_IMG_MOB' })} className="img-fluid gi-dash-gift-img-mob" alt="" />
                            <span>${pendingGiftDetail.amount}</span>
                        </div>
                        <div className="gi-dash-gift-text">
                            <h5 className="d-block d-md-none">
                                {IntlMessages('home_pending_gift_text1')}
                            </h5>
                            <h5 className="d-none d-md-block">
                                {IntlMessages('home_pending_gift_text2')}
                            </h5>
                            <span>
                                {IntlMessages('awarded_by')} <strong> {pendingGiftDetail.giverName} </strong>
                            </span>
                        </div>
                        <div className="gi-dash-gift-button gi-gift-redeem-btn">
                            <button type="button" onClick={redirectGift}>
                                {IntlMessages('redeem_your_gift')}
                            </button>
                        </div>
                        <div className="gi-dash-gift-info">
                            <a className="info-icon" onClick={giftInfoClick}>
                                <img src={intl.formatMessage({ id: 'INFO_LIGHT_GREEN_ICON' })} alt="" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PendingGiftCard;
