// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// react redux
import { useSelector } from 'react-redux';
import { getKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as UserService from 'middleware/UserService';
// message import
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// type import
import * as OnBoarding from 'interface/OnBoarding';
import * as PropsFunction from 'interface/PropsFunction';
import * as UserType from "api/APIMaster/UserType"
// custom component
import InputCheckboxComponent from 'components/InputComponent/InputCheckboxComponent';
import IsraelKYCButton from 'components/ButtonComponent/IsraelKYCButton';
import InfoModalIL from 'components/ModalComponent/InfoModalIL';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

export type Props = {
    propsData: PropsFunction.PropsStringNumberFunction
    staticData: UserType.OnboardingStaticDataResponseModel
}

const termListOption = [
    { label: IntlMessages('kyc_tc_term1'), name: 'term1', active: false },
    { label: IntlMessages('kyc_tc_term2'), name: 'term2', active: false },
    { label: IntlMessages('kyc_tc_term3'), name: 'term3', active: false },
    { label: IntlMessages('kyc_tc_term4'), name: 'term4', active: false },
    { label: IntlMessages('kyc_tc_term5'), name: 'term5', active: false },
]

const pdfLinkList = [
    { label: IntlMessages('kyc_tc_pdf1'), link: `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-beneficiary-form-il.pdf#toolbar=0` },
    { label: IntlMessages('kyc_tc_pdf2'), link: `${import.meta.env.REACT_APP_LEGAL_FORM_LINK}assets/docs/giraffe-invest-advisory-agreement-il.pdf#toolbar=0` },
    { label: IntlMessages('kyc_tc_pdf3'), link: `#!` },
    // { label: IntlMessages('kyc_tc_pdf3'), link: `https://legal.drivewealth.com/privacy-policy` },
]

const infoModalMessageBody = [
    <p>{IntlMessages('kyc_tc_info_modal')}</p>
]

const IsraelTC: React.FC<Props> = (props: Props) => {

    const { propsData, staticData } = props;
    const intl = useIntl()
    const history = useHistory();

    const kycData = useSelector(getKYCData);

    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState<boolean>(false); //loading api state
    const [isErrorAPI, setIsErrorAPI] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isDataChanged, setIsDataChanged] = React.useState<boolean>(false) //check page data change or not for api call

    const [infoModal, setInfoModal] = React.useState<boolean>(false); //open info modal
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //change the button

    const [termList, setTermList] = React.useState<OnBoarding.CheckBoxList>(termListOption);

    React.useEffect(() => {
        propsData('/on-boarding/page11', 12)
        setTermList((prev) => {
            const newTermList = [...prev]
            newTermList.forEach((e) => e.active = false);
            return newTermList

        })
    }, [])

    //  updating input values in state
    const setInputValueCheckbox = (fieldName: string, fieldValue: boolean): void => {
        setTermList((prev) => {
            const newTermList = [...prev]
            const index = newTermList.findIndex(e => e.name === fieldName);
            newTermList[index].active = fieldValue

            return newTermList
        })
        setIsDataChanged(true)
    }

    const openCloseModal = (): void => {
        setInfoModal((prev) => !prev)
    }

    // goto next page
    const clickNext = async (): Promise<void> => {
        const riskToleranceIdentifier = staticData.investorRiskToleranceList.filter(e => e.name === 'HIGH')[0];
        const newKycData = {
            ...kycData,
            tcCheck: true,
            riskToleranceIdentifier: riskToleranceIdentifier ? riskToleranceIdentifier.identifier : null,
        }

        if (isDataChanged) {
            setIsLoading(true);
            const accessToken = await initiateAction();
            const updateUser = await UserService.userDataUpdateIL(newKycData, accessToken); //update user api call

            setIsLoading(false);
            if (updateUser.response || updateUser.request) {
                setErrorLog(updateUser)
                setIsErrorAPI(true)
            } else {
                history.push('/on-boarding/kyc-pending')
            }
        } else {
            history.push('/on-boarding/kyc-pending')
        }
    }

    React.useEffect(() => {
        setIsDisabled(true)

        const isTermSelected = termList.filter(e => e.active)
        isTermSelected.length === 5 && setIsDisabled(false)

    }, [termList])

    if (isErrorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
            {/* page content */}
            <div className="gi-ob-center-part-container pt-md-4 pt-5 needs-validation">
                <div className="row">
                    {/* term and condition label */}
                    <div className="col-12">
                        <div className="gi-ob-heading my-4">
                            <h5 className="pb-1">{IntlMessages('kyc_tc_heading')}</h5>
                            <h6 className="mt-3">{IntlMessages('kyc_tc_sub_heading')}</h6>
                        </div>
                    </div>

                    {/* terms checkbox */}
                    <div className="col-md-12">
                        <div className="row gy-4 gx-5">
                            {/* right side */}
                            <div className="col-md-7 gi-ob-custom-border-end">
                                {termList.map((e, i) => {
                                    if (i <= 2) {
                                        let props = {
                                            labelText: e.label,
                                            fieldName: e.name,
                                            fieldValue: e.name,
                                            checkValue: e.active,
                                            inputClass: 'form-check-input mt-1',
                                            labelClass: 'gi-ob-form-check-label-02 form-check-label mt-1',
                                            inputValue: setInputValueCheckbox,
                                        }; // create props for input

                                        (i === 1) && (props = { ...{ ...props, infoOpen: openCloseModal } }) //add info when index is 1 & forsecond checkbox

                                        return (
                                            <div className="form-check mt-2 pb-2" key={i}>
                                                <InputCheckboxComponent
                                                    dataTestId={'input-check-box'} {...props} />
                                            </div>
                                        )
                                    }
                                    return null
                                })}
                            </div>
                            {/* left side */}
                            <div className="col-md-5">
                                <div className="mb-2 pb-1">
                                    {termList.map((e, i) => {
                                        if (i > 2) {
                                            return (
                                                <div className="form-check mt-2 pb-2" key={i}>
                                                    <InputCheckboxComponent
                                                        labelText={e.label}
                                                        fieldName={e.name}
                                                        fieldValue={e.name}
                                                        checkValue={e.active}
                                                        inputClass={'form-check-input mt-1'}
                                                        labelClass={'gi-ob-form-check-label-02 form-check-label mt-1'}
                                                        inputValue={setInputValueCheckbox}
                                                        dataTestId={'check-box2'}
                                                    />
                                                </div>
                                            )
                                        }
                                        return null
                                    })}
                                </div>

                                {/* pdf link list */}
                                {pdfLinkList.map((e, i) => {
                                    return (
                                        <div className="form-check download-text py-2" key={i}>
                                            <a href={e.link} target="_blank" rel="noopener noreferrer">
                                                <img src={intl.formatMessage({ id: 'IL_ONBOARDING_PDF_INFO' })} className="me-1" alt="" />
                                                {e.label}
                                            </a>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    {/* next button */}
                    <div className="col-12">
                        <IsraelKYCButton isDisabled={isDisabled} isSendButton={true} continueClick={clickNext} />
                    </div>
                </div>
            </div>

            {/* modal  */}
            <InfoModalIL
                openModal={infoModal}
                messageBody={infoModalMessageBody}
                dialogClassName={''}
                bodyClassName={'py-4 px-4'}
                divClassName={'enter-code-modal-text-success  p-3 mb-1'}
                onHandleClose={openCloseModal}
            />
        </>
    )
}

export default IsraelTC