//config
import * as Config from 'config/Config';
// import ageCalculator
import AgeCalculator from 'service/utils/AgeCalculator';
// local data
import * as Local from 'service/utils/LocalStorageData';
//types import
import * as OnBoarding from "interface/OnBoarding";

export type AllValidationData = {
    nextDisabled: boolean;
    perviousDisabled: boolean;
    onBoardingDetails: OnBoarding.OnBoardingData;
}

const ButtonHandler = (value: any): AllValidationData => {
    // get localStorage data
    const localStorageData = Local.getLocalData("onBoardingDetails");
    // set button disable or not
    let nextDisabled = true;
    const perviousDisabled = true;
    // set data into localStorage
    let onBoardingDetails = {
        positionIdentifier: '',
        typeIdentifier: ''
    };

    // username check
    if (value.firstName && value.lastName) {
        const maxLength = value.firstName.length <= Config.MAX_NUMBER_36 && value.lastName.length <= Config.MAX_NUMBER_36; //maximum length
        const minLength = value.firstName.length >= 2 && value.lastName.length >= 2; //minimum length
        const regex = !!value.firstName.match(Config.NAME_REGEX) && !!value.lastName.match(Config.LAST_NAME_REGEX); //regex of name

        nextDisabled = maxLength && minLength && regex ? false : true;

        onBoardingDetails = {
            ...localStorageData,
            firstName: value.firstName,
            lastName: value.lastName,
        };
    }

    // phone number check
    if (value.phoneNumber) {
        if (value.phoneNumber.length === 10) {
            nextDisabled = false;
        }
        onBoardingDetails = {
            ...localStorageData,
            phoneNumber: `${value.phoneNumber}`,
            verifyPhoneNumber: value.verifyPhoneNumber,
        };
    }

    // otp check
    if (value.verificationCode) {
        if (value.verificationCode.length === 6) {
            nextDisabled = false;
        }
        onBoardingDetails = {
            ...localStorageData,
            verifyPhoneNumber: value.verifyPhoneNumber,
        };
    }

    // address check
    if (value.addressAutoFill && value.postalCode && !value.errorFlag) {
        if (value.apartmentUnit.length > Config.MAX_NUMBER_36) {
            nextDisabled = true;
        } else if (value.postalCode.length >= 2 && value.postalCode.length < Config.MAX_NUMBER_11 && value.postalCode.match(Config.POSTAL_CODE_REGEX)) {
            nextDisabled = false;
        } else {
            nextDisabled = true;
        }
        //create modal for set localStorage data
        onBoardingDetails = {
            ...localStorageData,
            apartmentUnit: value.apartmentUnit,
            postalCode: value.postalCode,
            addressAutoFill: value.addressAutoFill,
        };
    }

    // dob and ssn check
    if (value.dateOfBirth && value.ssnNumber) {
        const dobValidation =
            new Date(value.dateOfBirth) > new Date('1/1/1901') &&
            new Date(value.dateOfBirth) < new Date(Date.now()) &&
            AgeCalculator(new Date(value.dateOfBirth)) >= 18;

        const ssnValidation = value.ssnNumber.length === 9 && value.ssnNumberMask.match(Config.SSN_REGEX);

        if (dobValidation && ssnValidation) {
            nextDisabled = false;
        }

        onBoardingDetails = {
            ...localStorageData,
            dateOfBirth: value.dateOfBirth,
            ssnNumber: value.ssnNumber,
        };
    }

    // country check
    if (value.citizenship && value.active) {
        nextDisabled = false;
        onBoardingDetails = {
            ...localStorageData,
            citizenship: value.citizenship,
            countryLabel: value.countryLabel,
        };
    }

    // tax payer check
    if (value.taxPayer && value.taxPayer !== 'false') {
        nextDisabled = false;
        onBoardingDetails = {
            ...localStorageData,
            taxPayer: value.taxPayer,
        };
    }

    // employment status check
    if (value.employmentIdentifier) {
        nextDisabled = false;
        onBoardingDetails = {
            ...localStorageData,
            employmentIdentifier: value.employmentIdentifier,
            employmentLabel: value.employmentLabel,
        };
    }

    // employment details check
    if (value.company && value.position && value.industry) {
        if (value.company.length <= Config.MAX_NUMBER_64 && value.company.length >= 2) {
            nextDisabled = false;
        }
        onBoardingDetails = {
            ...localStorageData,
            company: value.company,
            positionIdentifier: value.position,
            typeIdentifier: value.industry,
        };
    }

    // employment stock details/broker information check
    if (value.brokerDealer && value.stockOwner && value.publicOfficial) {
        if (value.stockOwner === 'true' && value.publicOfficial === 'false') {
            if (
                value.companyName.length <= Config.MAX_NUMBER_244 &&
                value.companyName.length >= 2 &&
                value.tickerSymbol.length >= 2 &&
                value.tickerSymbol.length <= 10 &&
                !value.tickerSymbol.match(Config.TICKER_REGEX)
            ) {
                nextDisabled = false;
            }
        }
        if (value.stockOwner === 'false' && value.publicOfficial === 'true') {
            if (value.publicOfficialText.length <= Config.MAX_NUMBER_255 && value.publicOfficialText.length >= 3 && value.publicOfficialText) {
                nextDisabled = false;
            }
        }
        if (value.stockOwner === 'true' && value.publicOfficial === 'true') {
            if (
                value.publicOfficialText.length <= Config.MAX_NUMBER_255 &&
                value.publicOfficialText.length >= 3 &&
                value.publicOfficialText &&
                value.companyName.length <= Config.MAX_NUMBER_244 &&
                value.companyName.length >= 2 &&
                value.tickerSymbol.length >= 2 &&
                value.tickerSymbol.length <= 10 &&
                !value.tickerSymbol.match(Config.TICKER_REGEX)
            ) {
                nextDisabled = false;
            }
        }
        if (value.stockOwner === 'false' && value.publicOfficial === 'false') {
            nextDisabled = false;
        }
        onBoardingDetails = {
            ...localStorageData,
            brokerDealer: value.brokerDealer,
            stockOwner: value.stockOwner,
            publicOfficial: value.publicOfficial,
            directorOf: value.stockOwner === 'true' ? `${value.companyName} ${value.tickerSymbol}` : null,
            politicallyExposedNames: value.publicOfficial === 'true' ? value.publicOfficialText : null,
            companyName: value.companyName,
            tickerSymbol: value.tickerSymbol,
            publicOfficialText: value.publicOfficialText,
        };
    }

    // annual income check
    if (value.annualIncome) {
        nextDisabled = false;
        onBoardingDetails = {
            ...localStorageData,
            annualIncome: value.annualIncome,
        };
    }

    // net worth check
    if (value.netWorth) {
        nextDisabled = false;
        onBoardingDetails = {
            ...localStorageData,
            netWorth: value.netWorth,
        };
    }

    // investing experience check
    if (value.experienceIdentifier) {
        nextDisabled = false;
        onBoardingDetails = {
            ...localStorageData,
            experienceIdentifier: value.experienceIdentifier,
        };
    }

    // willing risk taking check
    if (value.riskTolerance) {
        nextDisabled = false;
        onBoardingDetails = {
            ...localStorageData,
            riskTolerance: value.riskTolerance,
        };
    }

    // t&c check
    if (value.terms) {
        nextDisabled = false;
        onBoardingDetails = {
            ...localStorageData,
            terms: value.terms,
        };
    }

    // return all values
    return {
        nextDisabled,
        perviousDisabled,
        onBoardingDetails,
    };
};

export default ButtonHandler;