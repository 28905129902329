// default library
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
// auth0 library
import { useAuth0 } from '@auth0/auth0-react';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
// custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
import { DateRange } from 'service/utils/DateFilter';
import * as Local from 'service/utils/LocalStorageData';
// react redux
import { useSelector, useDispatch } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
import { getAppCommonData, setCurrentAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
// default value
import { transferType, monthlySavingStatus } from 'config/DefaultValue';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as DashboardType from 'api/APIMaster/DashboardType';
import * as BasketType from 'api/APIMaster/BasketType';
import * as UserType from 'api/APIMaster/UserType';
import * as DashboardInterface from 'interface/Dashboard';
import * as Routes from "interface/Route"
// api call
import * as DashboardService from 'middleware/DashboardService';
import * as BasketService from 'middleware/BasketService';
import * as UserService from 'middleware/UserService';
//custom component
import DatePageHeader from 'components/PageHeaderComponent/DatePageHeader';
import ErrorPendingModal from 'components/ModalComponent/ErrorPendingModal';
import FundModal from 'components/ModalComponent/FundModal';
import SellModal from 'components/ModalComponent/SellModal';
import BasketDetailsCard from 'components/CardComponent/BasketDetailsCard';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import GiraffeLearnBannerSection from 'components/RightSidebarComponent/GiraffeLearnBannerSection'
import BankConnectModal from 'components/ModalComponent/BankConnectModal';
import ConfirmationModal from 'components/ModalComponent/ConfirmationModal';
import LoadingModal from 'components/LoadingComponent/LoadingModal';
import HomeInfoModal from 'components/ModalComponent/HomeInfoModal';
import HomeMyImpact from 'components/SliderComponent/HomeMyImpact';
import CompanyDetailSection from 'components/SectionComponent/CompanyDetailSection';
import GiraffeLearnBanner from 'components/RightSidebarComponent/GiraffeLearnBanner';
import MonthlySavingModals from 'components/ModalComponent/MonthlySavingModals';
import SuccessModalNew from 'components/ModalComponent/SuccessModalNew';
import MonthlySavingEditModal from 'components/ModalComponent/MonthlySavingEditModal';
import KYCStatusModal from 'components/ModalComponent/KYCStatusModal';
import FundModalIL from 'components/ModalComponent/FundModalIL';
// dummy data
import * as BasketData from 'service/DummyData/BasketData';
import * as UserData from 'service/DummyData/UserData';

export type Props = {
  createBasketRoute: PropsFunction.SimpleFunction;
}

const PortfolioPage: React.FC<Props> = (props: Props) => {

  const { createBasketRoute } = props;
  const { user } = useAuth0();
  const { initiateAction } = useAccessToken();
  const history = useHistory();
  const location = useLocation<Routes.LocationMonthly>();
  const intl = useIntl();

  const dispatch = useDispatch()
  const appCommonData = useSelector(getAppCommonData);
  const locale = useSelector(getLocale);

  const basketId: number = Number(location.pathname.split('/')[3])

  const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
  const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

  const [isLoading, setIsLoading] = React.useState<boolean>(true);  //error logs

  const [isFirstRender, setIsFirstRender] = React.useState<boolean>(true); //check redux data render first time or not

  const [bankConnect, setBankConnect] = React.useState<boolean>(false); // Plaid connect

  const [basketDetails, setBasketDetails] = React.useState<BasketType.BasketDetailResponseModel>(BasketData.basketDetail); // Plaid connect
  const [isFunding, setIsFunding] = React.useState<DashboardType.IsFundTransferPendingData>([]); //check fund transfer i.e. DEPOSIT
  const [isBuyOrder, setIsBuyOrder] = React.useState<BasketType.BasketOrderPending>([]); //check buy transfer i.e BUY/SELL
  const [getFundLocation, setGetFundLocation] = React.useState<DashboardType.GetFundLocationsData>([]); //list of basket and account
  const [basicDetails, setBasicDetails] = React.useState<UserType.UserBasicDetailResponse>(UserData.userBasicDetails); // Plaid connect
  const [performanceData, setPerformanceData] = React.useState<BasketType.GetBasketPerformanceHistoricalData>([]);
  const [futureProjectionData, setFutureProjectionData] = React.useState<BasketType.GetFutureProjectionData>([]);

  const [openSellModal, setOpenSellModal] = React.useState<boolean>(false); //open sell modal
  const [openBuyModelFund, setOpenBuyModelFund] = React.useState<boolean>(false); //for open fund modal
  const [openMonthlySavingModal, setOpenMonthlySavingModal] = React.useState<boolean>(false); //for open fund modal monthly saving
  const [openBankModal, setOpenBankModal] = React.useState<boolean>(false); //bank modal

  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false); //delete confirmation modal
  const [openLoadingModal, setOpenLoadingModal] = React.useState<boolean>(false); //loading modal for delete

  const [openInfoModal, setOpenInfoModal] = React.useState<boolean>(false); //open information modal
  const [textHeading, setTextHeading] = React.useState<JSX.Element | string>(''); //info modal heading text
  const [messageBody, setMessageBody] = React.useState<JSX.Element | string>(''); //info modal content text
  const [bigModal, setBigModal] = React.useState<boolean>(false); //use big modal
  const [loadingSavingModal, setLoadingSavingModal] = React.useState<boolean>(false);

  const [notificationObj, setNotificationObj] = React.useState<DashboardInterface.NotificationObject[]>([]);
  const [myImpactList, setMyImpactList] = React.useState<DashboardType.ImpactDetailResponseModel[]>([]);

  const [sellErrorMessage, setSellErrorMessage] = React.useState<PropsFunction.IntlMessages | string>('');

  const [infoSavingModal, setInfoSavingModal] = React.useState<boolean>(false); //edit modal for portfolio and cash account
  const [monthlySavingAmount, setMonthlySavingAmount] = React.useState<number>(0);
  const [monthlySavingEditModal, setMonthlySavingEditModal] = React.useState<boolean>(false); //edit monthly saving modal
  const [isMonthlySavingInfo, setIsMonthlySavingInfo] = React.useState<boolean>(false); //used for open specific info modal true: monthly saving edit info, false: open delete info

  const [kycStatusModalFlag, setKytStatusModalFlag] = React.useState<boolean>(false); //modal for KYC reject and denied
  const [isKYCDenied, setIsKYCDenied] = React.useState<boolean>(true); //kyc denied flag for the decide modal text

  const [openModelFundIL, setOpenModelFundIL] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (!isFirstRender) {
      updateLocalBaseAPI(); //update the local language based api when language is change
    } else {
      setIsFirstRender(false)
      initialAPICall()
    }
  }, [locale.language])

  const initialAPICall = async (): Promise<void> => {
    window.scrollTo(0, 0) //scroll to top
    const dateRange = DateRange('all-time')//set default value date range

    const accessToken = await initiateAction()

    onHandleClose();
    setIsLoading(true);
    setBasketDetails(BasketData.basketDetail);

    const apiCallList = [
      BasketService.getBasketDetail(basketId, accessToken), //basket detail for
      BasketService.getBasketPerformanceHistorical({ basketId, dateRange }, accessToken), //get data for historical chart
      BasketService.getBasketPerformanceProjection({ basketId, yearRange: 50 }, accessToken), //get data for future chart
      DashboardService.getFundLocations(accessToken),  //get list of basket and account
      UserService.isBankAccountLinked(accessToken),  //check bank is link or not
      UserService.userBasicDetails(accessToken), //get user data
    ]

    if (appCommonData.isNewUser || !Local.getLocalData('dwUserIdentifier')) {
      apiCallList.push(Promise.resolve([]), Promise.resolve([]))
    } else {
      apiCallList.push(DashboardService.isFundTransferPending(accessToken)) //funding is pending or not check
      apiCallList.push(BasketService.getBasketOrderPending(accessToken)) //funding is pending or not check
    }

    const [
      basketDetails,
      historicalData,
      futureData,
      getFundLocation,
      bankConnect,
      basicDetails,
      isFunding,
      isBuyOrder,
    ] = await Promise.all(apiCallList)

    if (basketDetails.response || historicalData.response || futureData.response || getFundLocation.response || bankConnect.response || basicDetails.response || isBuyOrder.response || isFunding.response
      || basketDetails.request || historicalData.request || futureData.request || getFundLocation.request || bankConnect.request || basicDetails.request || isBuyOrder.request || isFunding.request) {

      (basketDetails.response || basketDetails.request) && setErrorLog(basketDetails);
      (historicalData.response || historicalData.request) && setErrorLog(historicalData);
      (futureData.response || futureData.request) && setErrorLog(futureData);
      (getFundLocation.response || getFundLocation.request) && setErrorLog(getFundLocation);
      (bankConnect.response || bankConnect.request) && setErrorLog(bankConnect);
      (basicDetails.response || basicDetails.request) && setErrorLog(basicDetails);
      (isBuyOrder.response || isBuyOrder.request) && setErrorLog(isBuyOrder);
      (isFunding.response || isFunding.request) && setErrorLog(isFunding);

      setIsError(true)
    } else {

      // notification object
      const notificationObj: DashboardInterface.NotificationObject[] = []
      if (basketDetails.isPending) { //when transfer process started
        let message: PropsFunction.IntlMessages | string = '';

        if (basketDetails.pendingOrderType === transferType.BUY && !basketDetails.isBankOrderPending) {
          message = ['notification_buy', { basketName: basketDetails.name }];
        } //for buy

        if (basketDetails.pendingOrderType === transferType.SELL && !basketDetails.isBankOrderPending) {
          message = ['notification_sell', { basketName: basketDetails.name }];
        } //for sell

        if (basketDetails.pendingOrderType === transferType.BUY && basketDetails.isBankOrderPending && basicDetails.kycCountry !== 'ISR') {
          message = ['notification_bank_to_basket', { basketName: basketDetails.name }];
        } //for bank to basket

        if (basketDetails.isGiftBasket && basketDetails.isFirstInvestmentPending) {
          message = 'notification_new_gift';
        } //for new gift

        notificationObj.push({
          transactionDate: basketDetails.transactionDate,
          message: message,
        })
      }


      /* ----------------------------------------- MONTHLY SAVING SETUP --------------------------------------------------------------------------------- */
      if (basicDetails.monthlySaving) { //monthly saving is done
        if (basicDetails.monthlySaving.isDeductionInitiated) { //monthly saving deduction start
          notificationObj.push({
            transactionDate: appCommonData.userCreateDateTime,
            message: 'il_monthly_deduction_start',
          });
        }
      } else if (!basicDetails.monthlySaving && basicDetails.kycCountry === 'ISR') { //if monthly saving setup is not done and user is IL
        notificationObj.push({
          transactionDate: appCommonData.userCreateDateTime,
          message: 'il_set_monthly_saving',
        })
      }
      /* ------------------------------------------------------------------------------------------------------------------------------------------------ */

      /* ----------------------------------------- FIRST TIME NOTIFICATION FOR USER OR PORTFOLIO CREATION ----------------------------------------------- */
      if (appCommonData.isNewUser && basicDetails.kycCountry !== 'ISR') { //when KYC is not complete and user is not IL user
        notificationObj.push({
          transactionDate: appCommonData.userCreateDateTime,
          message: 'notification_without_kyc_portfolio',
        })
      } else if (basketDetails.isInvestmentPending && basicDetails.kycCountry !== 'ISR') { //when KYC is complete, investment is pending, and user is not IL user
        notificationObj.push({
          transactionDate: basketDetails.investmentPendingDate,
          message: 'notification_new_portfolio',
        })
      }
      /* ------------------------------------------------------------------------------------------------------------------------------------------------ */


      /* ----------------------------------------- KYC STATUS CHECK --------------------------------------------------------------------------------- */
      // KYC DENIED
      if (basicDetails.isInternalKycDenied) {
        notificationObj.splice(0, 0, {
          transactionDate: appCommonData.userCreateDateTime,
          message: 'il_kyc_denied',
        }); //kyc denied

        setIsKYCDenied(true); //set kyc denied flag true
      }

      // KYC REJECT
      if (basicDetails.isInternalKycRejected) {
        notificationObj.splice(0, 0, {
          transactionDate: appCommonData.userCreateDateTime,
          message: ['il_kyc_reject', { clickText: <a onClick={redirectToKycUpdate}>{IntlMessages('click_here')}</a> }],
        }); //kyc rejected

        setIsKYCDenied(false); //set kyc denied flag false
      }
      /* ------------------------------------------------------------------------------------------------------------------------------------------------ */


      // create obj for my impact list
      const myImpactList = basketDetails.esgcValueList.map((e: BasketType.BasketDetailEsgcValueResponseModel) => ({
        name: e.name,
        deepClean: e.deepClean,
        myImpactDashboardBannerDesktop: e.myImpactPortfolioBannerDesktop,
        myImpactDashboardBannerMobile: e.myImpactPortfolioBannerMobile,
        myImpactDashboardBannerText: e.myImpactPortfolioBannerText,
        myImpactDashboardDeepCleanBannerText: e.myImpactPortfolioDeepCleanBannerText,
      }))

      dispatch(setCurrentAppCommonData({
        ...appCommonData,
        isKycRejected: basicDetails.isInternalKycRejected,
        isKycProcessing: basicDetails.isInternalKycPending,
        isKycManualReview: basicDetails.isInternalKycManualReview,
        isKycDenied: basicDetails.isInternalKycDenied,
      }))

      setBasketDetails(basketDetails);
      setIsFunding(isFunding);
      setIsBuyOrder(isBuyOrder);
      setGetFundLocation(getFundLocation);
      setBasicDetails(basicDetails);
      setPerformanceData(historicalData);
      setFutureProjectionData(futureData);
      setMyImpactList(myImpactList);

      const sortNotificationObj = notificationObj.sort((a, b) => new Date(b.transactionDate).getTime() - new Date(a.transactionDate).getTime())
      setNotificationObj(sortNotificationObj);

      setBankConnect(bankConnect);

      setSellErrorMessage(
        basketDetails.pendingOrderAmount === 0
          ? 'sell_all_message'
          : ['sell_error_message', { type: isFunding?.transferType === transferType.BANK_TO_BASKET ? 'bank to portfolio transfer' : basketDetails.pendingOrderType?.toLowerCase(), amount: currencyFormatter(basketDetails.pendingOrderAmount).split('.')[0] }]
      );

      setMonthlySavingEditModal(location.state?.isMonthlySavingEditOpen ?? false); //monthly saving edit modal

      history.replace({ ...history.location, state: undefined });
    }


    setIsLoading(false);
  }

  // update the local language based api when language is change
  const updateLocalBaseAPI = async (): Promise<void> => {
    setIsLoading(true);

    const accessToken = await initiateAction() //get access token
    const basketDetails = await BasketService.getBasketDetail(basketId, accessToken);

    setIsLoading(false);

    if (basketDetails.response || basketDetails.request) {
      setErrorLog(basketDetails);
      setIsError(true)
    } else {
      setBasketDetails(basketDetails);
    }
  }

  // redirect to kyc info update page if kyc is rejected
  const redirectToKycUpdate = (): void => {
    setKytStatusModalFlag(true);
  }

  // call user basic detail API
  const onHandleSuccessEmail = async (): Promise<void> => {
    setLoadingSavingModal(true);
    const accessToken = await initiateAction(); //get access token
    const basicDetails = await UserService.userBasicDetails(accessToken);

    setLoadingSavingModal(false);

    if (basicDetails.response || basicDetails.request) {
      setIsError(true)
      setErrorLog(basicDetails)
    } else {
      setBasicDetails(basicDetails);
    }
  }

  // not list modal open
  const onHandleNotListEmail = (): void => {
    setOpenMonthlySavingModal(false);
    setIsLoading(true);
    onHandleSuccessEmail();
    setIsLoading(false);
  }

  // open info modal
  const openInfoModalFunction: PropsFunction.OpenInfoModal = (textHeading, messageBody, bigModal): void => {
    setOpenInfoModal(true);
    setTextHeading(textHeading);
    setMessageBody(messageBody);
    setBigModal(bigModal);
  }

  // rename basket API
  const renameBasket = async (basketName: string): Promise<void> => {
    const basket = {
      identifier: basketDetails.identifier,
      name: basketName
    }
    setIsLoading(true);

    const accessToken = await initiateAction() //get access token
    const isRenamed = await BasketService.renameBasket(basket, accessToken);
    if (isRenamed.response) {
      setErrorLog(isRenamed)
      setIsError(true)
    }

    initialAPICall();
    createBasketRoute();
  }

  //open delete confirmation modal
  const openDeleteModalFunction = (): void => {
    if ((monthlySaving && (basketDetails.id === monthlySaving.basketId))) {
      setInfoSavingModal(true);
      setIsMonthlySavingInfo(false);
    } else {
      setOpenDeleteModal(true);
    }
  }

  //delete basket API call
  const deleteBasket = async (): Promise<void> => {
    setOpenLoadingModal(true);
    setOpenDeleteModal(false);

    const accessToken = await initiateAction() //get access token
    const isDelete = await BasketService.deleteBasket(basketDetails.identifier, accessToken);

    if (isDelete.response) {
      setErrorLog(isDelete)
      setIsError(true)
    } else {
      history.push('/dashboard');
      onHandleClose();
      createBasketRoute();
    }
  }

  //open & close sell modal
  const openCloseSellModalFunction = (): void => {
    setOpenSellModal((prevOpenSellModal) => !prevOpenSellModal);
  }

  //is fund transfer is pending at that time error modal will open else fund transfer modal is open
  const setBuyOpenModal = (): void => {
    if (appCommonData.isNewUser) {
      history.push('/on-boarding');
    } else {
      if (basicDetails.kycCountry === 'ISR') {
        if (basicDetails.isInternalKycDenied) {
          setKytStatusModalFlag(true);
          setIsKYCDenied(true)
        } else if (basicDetails.isInternalKycRejected) {
          setKytStatusModalFlag(true);
          setIsKYCDenied(false)
        } else {
          basicDetails.monthlySavingSetup
            ? basketDetails.totalCashAvailableForTrade > 0 ? setOpenBuyModelFund(true) : setOpenModelFundIL(true)
            : setOpenMonthlySavingModal(true);
        }
      } else {
        setOpenBuyModelFund(bankConnect || basketDetails.totalCashAvailableForTrade > 0);
        setOpenBankModal(!bankConnect && basketDetails.totalCashAvailableForTrade === 0);
      }
    }
  }

  //close bank modal
  const closeBankModal = (): void => {
    setOpenBankModal(false);
    onHandleClose();
  }

  //close fund transfer modal
  const closeModal = (value: boolean): void => {
    setOpenBuyModelFund(value);
  }

  // close modal
  const onHandleClose = (): void => {
    setOpenLoadingModal(false); //loading modal
    setOpenBuyModelFund(false); //fund modal
    setOpenSellModal(false); //sell modal
    setOpenDeleteModal(false); //delete modal
    setOpenBankModal(false); //bank connect modal
    setOpenInfoModal(false); //info modal
    setInfoSavingModal(false); //info saving modal
    setMonthlySavingEditModal(false); //monthly saving edit modal
    setKytStatusModalFlag(false); //kyc status modal
    setOpenMonthlySavingModal(false); //monthly saving modal
    setOpenModelFundIL(false); //fund modal 
  }

  // open info monthly saving modal
  const infoMonthlySaving = (): void => {
    const monthlySavingBasket = Number(basicDetails.monthlySaving?.afterCutoffAmount);
    setMonthlySavingAmount(monthlySavingBasket);
    setInfoSavingModal(true);
    setIsMonthlySavingInfo(true);
  }

  // open edit monthly saving modal
  const editMonthlySaving = (): void => {
    setMonthlySavingEditModal(true);
  }

  if (isError) {
    throw new Error(JSON.stringify(errorLog));
  }

  const userAddress = basicDetails.userAddress
  const plaidData = {
    customerFirstName: basicDetails.firstNameEncrypted,
    customerMiddleName: basicDetails.middleNameEncrypted,
    customerLastName: basicDetails.lastNameEncrypted,
    customerPhoneNumber: basicDetails.phoneEncrypted,
    customerEmail: user?.email,
    customerAddress: `${userAddress?.street1} ${userAddress?.city} ${userAddress?.province} ${userAddress?.postalCode}`,
  }
  // monthly saving object
  const monthlySaving = basicDetails.monthlySaving ? basicDetails.monthlySaving : UserData.monthlySaving;
  // monthly saving info and edit body
  const portfolioEditBody: JSX.Element = <div className="gi-setup-modal-text-success mt-2 px-3 mb-3 ">
    <p className="mb-0">
      {IntlMessages('home_monthly_saving_portfolio_text1', { amount: monthlySavingAmount })}
    </p>
    {monthlySaving.status === 'PENDING' && (
      <h6 className="mb-0 mt-4">
        {monthlySavingStatus[monthlySaving.status].label}
      </h6>
    )}
    <div className="text-center mt-4">
      <button type="button" onClick={editMonthlySaving}>
        {IntlMessages('button_edit')}
      </button>
    </div>
  </div>

  // delete modal info body
  const deleteMonthlySavingBody: JSX.Element = <div className="gi-setup-modal-text-success mt-2 px-3 mb-3 ">
    <p className="mb-0">
      {IntlMessages('basket_monthly_saving_delete_text1', { br: <br /> })}
    </p>
  </div>

  // check IL user
  const ILUser = basicDetails.kycCountry ? basicDetails.kycCountry === 'ISR' : (locale.code === 'ISR' || locale.code === 'ISR_EN')

  return (
    <>
      {isLoading
        ? <LoadingComponent />
        : <>
          {/* page header */}
          <DatePageHeader />

          {/* content */}
          <div className="row gx-2" data-testid='portfolio-content'>
            {/* main content */}
            <div className="col-lg-9">
              <div className="row">
                <div className="col-12">
                  {/* portfolio detail card */}
                  <BasketDetailsCard
                    basketDetails={basketDetails}
                    notificationObj={notificationObj}
                    performanceData={performanceData}
                    futureProjectionData={futureProjectionData}
                    monthlySaving={basicDetails.monthlySaving}
                    isILUser={ILUser && !basicDetails.monthlySavingSetup} //check user is israel user or not
                    openInfoModal={openInfoModalFunction}
                    renameBasket={renameBasket}
                    openCloseSellModal={openCloseSellModalFunction}
                    openDeleteModal={openDeleteModalFunction}
                    openFundModal={setBuyOpenModal}
                    infoMonthly={infoMonthlySaving}
                  />

                  {/* pie chart and graph detail card */}
                  <CompanyDetailSection
                    companySummary={basketDetails.companySummary}
                  />

                  {/* my impact card detail */}
                  <HomeMyImpact
                    myImpactList={myImpactList}
                    isInvestmentPending={false}
                    isPortfolio={true}
                    isExample={false}
                  />
                </div>
              </div>
            </div>
            {/* banner */}
            <div className="col-lg-3">
              {appCommonData.isLearnAccess
                ? <GiraffeLearnBanner />
                : <GiraffeLearnBannerSection />
              }
            </div>
          </div>

          {/* blank space */}
          <div className="row">
            <div className="col-12">
              <div className="h-dash"></div>
            </div>
          </div>

          {/* Bank connect modal */}
          <BankConnectModal
            userName={basicDetails.name}
            openBankModal={openBankModal}
            closeBankModal={closeBankModal}
            componentDidMount={initialAPICall}
            plaidData={plaidData}
            message={'bank_text1'}
          />

          {/* fund modal */}
          <FundModal
            openFundModelFlag={openBuyModelFund}
            totalCashAvailableForTrade={basketDetails.totalCashAvailableForTrade}
            basketId={basketId}
            getFundLocation={getFundLocation}
            isFunding={isFunding}
            isBuyOrder={isBuyOrder}
            closeModal={closeModal}
            componentDidMount={initialAPICall}
          />

          {/* fund modal monthly saving modal*/}
          <MonthlySavingModals
            isModalOpen={openMonthlySavingModal}
            organizationName={basicDetails.organizationName}
            deductionDate={basicDetails.msCutoffDate}
            defaultCurrency={basicDetails.defaultCurrency}
            getFundLocation={getFundLocation}
            loadingSavingModal={loadingSavingModal}
            isKycPending={appCommonData.isKycProcessing}
            onHandleClose={onHandleClose}
            onHandleSuccessEmail={onHandleSuccessEmail}
            onHandleSuccess={initialAPICall}
            onHandleNotListEmail={onHandleNotListEmail}
          />

          {/* sell modal */}
          {basketDetails.isPending
            ? <ErrorPendingModal
              openModelError={openSellModal}
              closeErrorModal={openCloseSellModalFunction}
              h3={sellErrorMessage}
              label={'error_try_again'}
              image={intl.formatMessage({ id: 'UNLINK_BANK_SVG' })}
            />
            : <SellModal
              openSellModal={openSellModal}
              getFundLocation={getFundLocation}
              basketId={basketId}
              basketDetails={basketDetails}
              closeModal={openCloseSellModalFunction}
              componentDidMount={initialAPICall}
            />
          }

          {/* delete basket confirmation modal */}
          <ConfirmationModal
            openConfirmationModal={openDeleteModal}
            message={'basket_delete'}
            onHandleClose={onHandleClose}
            onHandleConfirm={deleteBasket}
          />

          <LoadingModal
            openLoadingModal={openLoadingModal}
            title={'loading'}
            onHandleClose={onHandleClose} />

          {/* info modal */}
          <HomeInfoModal
            openInfoModal={openInfoModal}
            textHeading={textHeading}
            messageBody={messageBody}
            bigModal={bigModal}
            onHandleClose={onHandleClose}
          />

          {/* portfolio info modal */}
          <SuccessModalNew
            openModal={infoSavingModal}
            bodyClassName='p-4 mt-2'
            messageBody={isMonthlySavingInfo ? portfolioEditBody : deleteMonthlySavingBody}
            showCloseButton={true}
            onHandleClose={onHandleClose}
          />

          {/* monthly saving modal edit */}
          <MonthlySavingEditModal
            isModalOpen={monthlySavingEditModal}
            organizationName={basicDetails.organizationName}
            deductionDate={basicDetails.msCutoffDate}
            defaultCurrency={basicDetails.defaultCurrency}
            monthlySaving={monthlySaving}
            fundLocation={getFundLocation}
            onHandleClose={onHandleClose}
            onHandleSuccess={initialAPICall}
          />

          {/* KYC reject and denied modal */}
          <KYCStatusModal
            openModal={kycStatusModalFlag}
            isKYCDenied={isKYCDenied}
            onHandleClose={onHandleClose}
          />

          {/* fund modal IL */}
          <FundModalIL
            openModal={openModelFundIL}
            onHandleClose={onHandleClose}
          />
        </>
      }
    </>
  )
}

export default PortfolioPage