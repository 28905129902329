import * as GiftType from "api/APIMaster/GiftType";
import { AxiosClient, RequestParams, ExceptionResponse } from "api/AxiosClient";
import * as URLList from 'api/URLList'


class GiftService<SecurityDataType = unknown> extends AxiosClient<SecurityDataType> {

    constructor() {
        super({
            baseURL: URLList.BASE_URL.GIFT
        });
    }

    /**
     * @description Returns gift user list response model
     *
     * @name GetGiftUserByAccessToken
     * @summary GiftUserByAccessToken
     * @request GET:/user/giftaccessuser/gift-user-by-access-token
     * @secure
     */
    getGiftUserByAccessToken = (params: RequestParams = {}) =>
        this.request<GiftType.GetGiftUserByAccessTokenData, void | ExceptionResponse>({
            path: URLList.GIFT_SERVICE.GIFT_USER_BY_ACCESS_TOKEN.path,
            method: URLList.GIFT_SERVICE.GIFT_USER_BY_ACCESS_TOKEN.method,
            secure: true,
            ...params,
        });

    /**
     * @description Returns true/false on the basis of update
     *
     * @name UpdateGiftUser
     * @summary Update Gift User
     * @request POST:/user/giftaccessuser/update-gift-user
     * @secure
     */
    updateGiftUser = (data: GiftType.GiftUserRequestModel, params: RequestParams = {}) =>
        this.request<GiftType.UpdateGiftUser1Data, void | ExceptionResponse>({
            path: URLList.GIFT_SERVICE.UPDATE_GIFT_USER.path,
            method: URLList.GIFT_SERVICE.UPDATE_GIFT_USER.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns Gift received details
     *
     * @name GiftReceived
     * @summary  Gift Received
     * @request POST:/user/giftaccessuser/gift-received
     * @secure
     */
    giftReceivedDetails = (data: GiftType.GiftReceivedRequestModel, params: RequestParams = {}) =>
        this.request<GiftType.GiftReceivedResponseModel, void | ExceptionResponse>({
            path: URLList.GIFT_SERVICE.GIFT_RECEIVED.path,
            method: URLList.GIFT_SERVICE.GIFT_RECEIVED.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Return status true if can redeem gift code
     *
     * @name CanRedeemGiftCode
     * @summary Check can redeem gift code
     * @request GET:/user/giftaccessuser/can-redeem-gift-code/{redemptionCode}
     * @secure
     */
    redeemGiftCodeDetails = (giftCode: string, params: RequestParams = {}) =>
        this.request({
            path: `${URLList.GIFT_SERVICE.REDEEM_GIFT_CODE.path}/${giftCode}`,
            method: URLList.GIFT_SERVICE.REDEEM_GIFT_CODE.method,
            secure: true,
            ...params,
        });

}

export default GiftService