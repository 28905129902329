interface PageData {
    [key: string]: string | number | boolean | string[];
}

/**
 * Check if any field in a page is empty
 *
 * @param {PageData} pageData
 * @return {*}  {boolean}
 */
export const isPageEmpty = (pageData: PageData): boolean => {
    for (const key in pageData) {
        if (Object.prototype.hasOwnProperty.call(pageData, key) && !pageData[key]) {
            return true;
        }
    }
    return false;
};


/**
 * Helper function to get data for a specific page
 *
 * @param {PageData} data
 * @param {number} page
 * @return {*}  {PageData}
 */
export const getPageData = (data: PageData, page: number): PageData => {
    const pageData: PageData = {};
    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key) && key.startsWith(`page${page}_`)) {
            const field = key.split('_')[1];
            pageData[field] = data[key];
        }
    }
    return pageData;
};

/**
 * Helper function to get the number of page for data
 * 
 * @param {PageData} data
 * @returns {*}  {string[]}
 */
export const getNumberOfPages = (data: PageData): string[] => {
    const numberPage: string[] = ['page1']
    for (const key in data) {
        if (key.startsWith('page')) {
            const field = key.split('_')[0];
            !numberPage.includes(field) && numberPage.push(field)
        }
    }
    return numberPage
}


/**
 * Function to determine the next page based on empty fields
 *
 * @param {PageData} data
 */
export const pageRedirectFunction = (data: PageData): string => {
    const pageNumber = getNumberOfPages(data)
    if (isPageEmpty(data)) {
        for (let page = 1; page <= (pageNumber.length + 1); page++) {
            const pageData = getPageData(data, page);
            if (isPageEmpty(pageData)) {
                return pageNumber[page - 1]
            }
        }
    }
    return '';
};
