// default library
import * as React from 'react';
// intl message library
import { useIntl } from 'react-intl';

const KYCLogo: React.FC = () => {
    const intl = useIntl();

    return (
        <div className="gi-ob-logo">
            <img src={intl.formatMessage({ id: 'KYC_ONBOARDING_LOGO' })} alt="" />
        </div>
    );
};

export default KYCLogo;