//default library
import * as React from 'react';
import { useLocation } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// company count calculation
import companyCountCalculation from 'service/utils/CompanyCountCalculation';
// config
import * as Config from 'config/Config';
//types props
import * as Dashboard from "interface/Dashboard"
import * as BasketType from "api/APIMaster/BasketType"
import * as PropsFunction from "interface/PropsFunction";
import * as DashboardType from "api/APIMaster/DashboardType"
//dummy data
import * as DashboardData from 'service/DummyData/DashboardData'
import * as BasketData from 'service/DummyData/BasketData';
// api call
import * as DashboardService from "middleware/DashboardService"
import * as BasketService from "middleware/BasketService"
//custom component
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'
import SelectEsgcPortfolioDesktop from 'components/SectionComponent/SelectEsgcPortfolioDesktop';
import CompanyListModal from 'components/ModalComponent/CompanyListModal';
import UserExcludedModal from 'components/ModalComponent/UserExcludedModal';
import AdvanceOptionModal from 'components/ModalComponent/AdvanceOptionModal';
import MultiGraphSection from 'components/SectionComponent/MultiGraphSection';
import AreaChartPortfolio from 'components/ChartSection/AreaChartPortfolio';
import PortfolioEsgcActivityCard from 'components/CardComponent/PortfolioEsgcActivityCard';
import ESGCInformationModal from 'components/ModalComponent/ESGCInformationModal';
import GiftAcceptModal from 'components/ModalComponent/GiftAcceptModal';
import LoadingModal from 'components/LoadingComponent/LoadingModal';
import DatePageHeader from 'components/PageHeaderComponent/DatePageHeader';

// timeout variable
let animationTimeOut: NodeJS.Timeout;

// defining props
export type Props = {
  componentDidMount: PropsFunction.SimpleFunction;
}

const GiftPage: React.FC<Props> = (props: Props) => {

  const { componentDidMount } = props
  const location = useLocation();
  const { initiateAction } = useAccessToken();

  const locale = useSelector(getLocale);

  const giftIdentifier: string = (location.pathname.split('/')[3])


  const [errorLog, setErrorLog] = React.useState<any>({});  //error logs
  const [errorAPI, setErrorAPI] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [esgcValueList, setEsgcValueList] = React.useState<BasketType.BasketCustomizationEsgcValueResponseModel[]>([]);//list of ESG-C value
  const [sp500CompanyList, setSp500CompanyList] = React.useState<BasketType.CompanyUserExclusionResponseModel[]>([]);//initial s&p company count
  const [allCompanyList, setAllCompanyList] = React.useState<BasketType.CompanyUserExclusionResponseModel[]>([]);//list of all company
  const [companyCount, setCompanyCount] = React.useState<Dashboard.CompanyCountData>(BasketData.companyCountData);// company count

  const [sp500GraphData, setSp500GraphData] = React.useState<BasketType.BasketPerformanceComparisonData>(BasketData.sp500GraphData);//S&P500 company comparison graph data
  const [investmentDetailGraph, setInvestmentDetailGraph] = React.useState<BasketType.Sp500ReturnOnInvestmentResponseModel[]>([]);// graph data for S&P500
  const [selectedEsgcValueList, setSelectedEsgcValueList] = React.useState<BasketType.GiftEsgcValueRequestModel[]>([]);//list of ESG-C value
  const [userExcludedList, setUserExcludedList] = React.useState<string[]>([]);//user Excluded company list
  const [excludedCompaniesList, setExcludedCompaniesList] = React.useState<BasketType.BasketCompanyResponseModel[]>([]); //company exclusion list
  const [addedCompaniesList, setAddedCompaniesList] = React.useState<BasketType.BasketCompanyResponseModel[]>([]);//list of added company list

  const [resetUserInput, setResetUserInput] = React.useState<boolean>(true);//reset user excluded company input
  const [excludedCompanyInput, setExcludedCompanyInput] = React.useState<string>('');//excluded company name
  const [esgcIdentifier, setEsgcIdentifier] = React.useState<string>('');//ESG-C value identifier

  const [openUserExclusionDataModal, setOpenUserExclusionDataModal] = React.useState<boolean>(false);//user exclusion modal
  const [openReadMoreDataModal, setOpenReadMoreDataModal] = React.useState<boolean>(false); //read more modal
  const [openAdvanceDataModal, setOpenAdvanceDataModal] = React.useState<boolean>(false);//advance modal
  const [addedCompanyModal, setAddedCompanyModal] = React.useState<boolean>(false);//add company modal
  const [acceptModalOpen, setAcceptModalOpen] = React.useState<boolean>(false);//open modal flag
  const [excludedCompanyModal, setExcludedCompanyModal] = React.useState<boolean>(false);//exclude company modal

  const [isLoadingModal, setIsLoadingModal] = React.useState<boolean>(false);//loading page
  const [loadAnimation, setLoadAnimation] = React.useState<boolean>(false);//load spinner animation

  const [getGiftReceive, setGetGiftReceive] = React.useState<DashboardType.GiftDetailResponseModel>(DashboardData.getGiftReceiveData);//received gift details

  React.useEffect(() => {
    initialAPICall()
  }, [locale.language])

  // initial API call
  const initialAPICall = async (): Promise<void> => {
    window.scroll(0, 0);
    setIsLoading(true);

    const accessToken = await initiateAction();
    const [giftReceive, customizationDetail, allCompanyListResponse] = await Promise.all([
      DashboardService.getGiftReceived(giftIdentifier, accessToken),//gift detail
      BasketService.getBasketCustomizationDetail(accessToken), //get basket customization details
      BasketService.getAllCompanyForUserExclusion(accessToken),//get all company list for user exclusion
    ]);

    if ((giftReceive.response || giftReceive.request) || (customizationDetail.response || customizationDetail.request) || (allCompanyListResponse.response || allCompanyListResponse.request)) {
      (giftReceive.response || giftReceive.request) && setErrorLog(giftReceive);
      (customizationDetail.response || customizationDetail.request) && setErrorLog(customizationDetail);
      (allCompanyListResponse.response || allCompanyListResponse.request) && setErrorLog(allCompanyListResponse);

      setErrorAPI(true);
    } else {
      setEsgcValueList(customizationDetail.esgcValueList);
      setSp500CompanyList(customizationDetail.sp500CompanyList);
      setAllCompanyList(allCompanyListResponse);

      setCompanyCount({
        totalCompanyCount: customizationDetail.sp500CompanyList.length,
        excludedCompanyCount: 0,
        addedCompanyCount: 0,
      });

      setSelectedEsgcValueList(
        giftReceive.esgcValues.map((e: { esgcValueIdentifier: string; deepClean: boolean; addGoodCompanies: boolean; }) => ({
          esgcValueIdentifier: e.esgcValueIdentifier,
          deepClean: e.deepClean,
          addGoodCompanies: e.addGoodCompanies,
        }))
      );
      setUserExcludedList(giftReceive.userExcludedCompanies);
      setGetGiftReceive(giftReceive);
    }
  };

  // get S&P500 company comparison graph data
  const getBasketPerformanceComparison = async (): Promise<void> => {
    const summaryData = {
      selectedEsgcValueList: selectedEsgcValueList,
      userExcludedList: userExcludedList,
    }

    const accessToken = await initiateAction() //get access token

    const [sp500GraphData, investmentDetailGraph] = await Promise.all([
      BasketService.getBasketPerformanceComparison(summaryData, accessToken), //get S&P500 company comparison graph data
      BasketService.getSp500ReturnOnInvestment(accessToken), //get graph data for S&P500 company(static)
    ])

    if (sp500GraphData.response || sp500GraphData.request || investmentDetailGraph.response || investmentDetailGraph.request) {
      (sp500GraphData.response || sp500GraphData.request) && setErrorLog(sp500GraphData);
      (investmentDetailGraph.response || investmentDetailGraph.request) && setErrorLog(investmentDetailGraph);

      setErrorAPI(true)
    }
    else {
      setSp500GraphData(sp500GraphData)
      setInvestmentDetailGraph(investmentDetailGraph)
      setIsLoading(false)
    }
  };

  // spinner animation time calculation
  const animationTimer = (): void => {
    clearTimeout(animationTimeOut) //clear previous timeout

    setLoadAnimation((_prev) => false)
    setLoadAnimation((prev) => !prev)

    animationTimeOut = setTimeout(() => {
      setLoadAnimation((prev) => !prev)
    }, Config.PORTFOLIO_ANIMATION_TIMER);
  }

  // select and remove selected ESG-C value
  const selectRemoveEsgcValue = (id: string): void => {
    const currentEsgcList: BasketType.GiftEsgcValueRequestModel[] = JSON.parse(JSON.stringify(selectedEsgcValueList));  // Create a copy of the current selected ESG-C value list

    if (currentEsgcList.some((e) => e.esgcValueIdentifier === id)) {
      // check selected value is present or not
      const clickValue = selectedEsgcValueList.findIndex(a => a.esgcValueIdentifier === id)   // If found, find the index and remove it from the list  
      currentEsgcList.splice(clickValue, 1)
    } else {
      // if value is not present
      const selectedValue = esgcValueList.filter((e) => {
        return e.identifier === id
      })[0] //get selected ESG-C value

      const selectedEsgc = {
        esgcValueIdentifier: selectedValue.identifier,
        deepClean: false,
        addGoodCompanies: false
      } //create selected ESG-C value object

      currentEsgcList.push(selectedEsgc);   // Add the selected ESG-C value to the current list
    }

    animationTimer();
    setSelectedEsgcValueList(currentEsgcList); //set value to selectedEsgcValueList
  }

  // user exclusion modal
  const openUserExclusionModal = (): void => {
    setExcludedCompanyInput(excludedCompanyInput);
    setOpenUserExclusionDataModal(true);
  };

  // user excluded input
  const setCompanyName = (value: string): void => {
    setExcludedCompanyInput(value);
  };

  // open read more modal
  const openReadMoreModal = (): void => {
    setOpenReadMoreDataModal(true);
  };

  // open advance modal
  const openAdvanceModal = (identifier: string): void => {
    setOpenAdvanceDataModal(true);
    setEsgcIdentifier(identifier);
  };

  // modified selected ESG-C value list with deepClean and addGoodCompanies
  const modifiedSelectedList = (modifiedEsgcValue: BasketType.GiftEsgcValueRequestModel[]): void => {
    setSelectedEsgcValueList(modifiedEsgcValue);
  };
  // open company list table
  const openCompanyTableModal = (e: Event): void => {
    const targetElement = e.target as HTMLElement;
    setExcludedCompanyModal(targetElement.id === 'excluded-companies');
    setAddedCompanyModal(targetElement.id === 'added-companies');
  };

  // Function to update the user-excluded company list
  const userExcludedCompanyList = async (addExcludedList: string[], removedExcluded: string[]): Promise<void> => {
    const excludedList = [...userExcludedList, ...addExcludedList];        // Combine the existing user-excluded list with the newly added and removed lists
    const uniqueExcluded = [...new Set(excludedList)];    // Remove duplicate entries by converting the list to a Set and then back to an array
    const uniqueExcludedList = uniqueExcluded.filter(e => !removedExcluded.includes(e));// Filter out the companies that were removed from the excluded list
    setUserExcludedList(uniqueExcludedList);    // Update the state with the modified user-excluded company list

  };

  //modal close function
  const onHandleClose = (): void => {
    setOpenUserExclusionDataModal(false);
    setExcludedCompanyInput('');
    setResetUserInput(false);
    setOpenAdvanceDataModal(false);
    setOpenReadMoreDataModal(false);
    setExcludedCompanyModal(false);
    setAddedCompanyModal(false);
    setAcceptModalOpen(false)
    setResetUserInput(true);
  };

  // excluded company list calculation
  const addExcludedCompanyList = (): void => {
    const excludedCompanyList: BasketType.BasketCompanyResponseModel[] = [];

    esgcValueList.map((e) => {
      const selectedEsgc = selectedEsgcValueList.find((a) => a.esgcValueIdentifier === e.identifier);

      // Check if the ESG-C value is selected for deep clean (exclusion)
      if (selectedEsgc) {
        const excludedCompany = selectedEsgc.deepClean ? e.deepCleanExclusionList : e.exclusionList;
        excludedCompanyList.push(...excludedCompany.map(e => ({
          companyName: e.name,
          tickerSymbol: e.tickerSymbol,
          industry: e.industry,
          weight: 0,
          value: 0
        })));
      }

      return null;
    });

    // user specific company exclusion list
    const userExcludedCompanyList = allCompanyList.filter(e => userExcludedList.includes(e.identifier));
    userExcludedCompanyList.length > 0 && excludedCompanyList.push(...userExcludedCompanyList.map(e => ({
      companyName: e.name,
      tickerSymbol: e.tickerSymbol,
      industry: e.industry,
      weight: 0,
      value: 0
    })));

    const uniqueExcludedCompanyList = excludedCompanyList.filter((e, i) => i === excludedCompanyList.findIndex(o => e.tickerSymbol === o.tickerSymbol));

    setExcludedCompaniesList(uniqueExcludedCompanyList);    // Update state with the unique list of excluded companies
  };

  // redeem gift 
  const redeemClick = (): void => {
    setAcceptModalOpen(true);
  };

  // redeem gift 
  const redeemGift = async (): Promise<void> => {
    setIsLoadingModal(true);
    setAcceptModalOpen(false);

    const redeemedGiftDetails = {
      giftIdentifier: giftIdentifier,
      esgcValueList: selectedEsgcValueList,
      userExcludedCompanyList: userExcludedList
    }
    const accessToken = await initiateAction();
    const redeemGiftResponse = await BasketService.redeemedGift(redeemedGiftDetails, accessToken,);

    if (redeemGiftResponse.response) {
      setErrorLog(redeemGiftResponse)
      setErrorAPI(true);
    }
    else {
      setIsLoadingModal(false);
      await componentDidMount();

    }

  };

  //company count calculation
  const companyCountCal = (): void => {
    const companyCalculationResult: Dashboard.CompanyCalculationData = companyCountCalculation(
      esgcValueList,
      allCompanyList,
      selectedEsgcValueList,
      userExcludedList,
      sp500CompanyList
    );

    const companyCount = {
      totalCompanyCount: companyCalculationResult.totalCompanyCount,
      excludedCompanyCount: companyCalculationResult.excludedCompaniesList.length,
      addedCompanyCount: companyCalculationResult.addCompaniesList.length,
    };

    setCompanyCount(companyCount);
    addExcludedCompanyList();

    const addedCompanyList = allCompanyList.filter(e => companyCalculationResult.addCompaniesList.includes(e.tickerSymbol));
    setAddedCompaniesList([...addedCompanyList.map(e => ({
      companyName: e.name,
      tickerSymbol: e.tickerSymbol,
      industry: e.industry,
      weight: 0,
      value: 0
    }))]);
  }

  React.useEffect(() => {
    sp500CompanyList.length > 0 && getBasketPerformanceComparison() //S&P500 graph data comparison
    companyCountCal(); //company count calculation
  }, [selectedEsgcValueList, userExcludedList, sp500CompanyList])


  if (errorAPI) {
    throw new Error(JSON.stringify(errorLog));
  }

  return (
    <>
      {isLoading
        ? <LoadingComponent />
        : <>
          {/* header */}
          <DatePageHeader />

          {/* ESG-C value section */}
          <SelectEsgcPortfolioDesktop
            selectedEsgcValueList={selectedEsgcValueList} //next button disabled
            esgcValueList={esgcValueList} //list of ESG-C value
            companyCount={companyCount} //company count object
            resetUserInput={resetUserInput} //user exclusion input reset
            excludedCompanyInput={excludedCompanyInput} //excluded company input
            loadAnimation={loadAnimation} //load spinner animation
            nextClick={redeemClick} //next button click
            openReadMoreModal={openReadMoreModal} //open read more modal
            openAdvanceModal={openAdvanceModal} //open advanced modal
            openCompanyTableModal={openCompanyTableModal} //open list of company table modal
            openUserExclusionModal={openUserExclusionModal} //user exclusion modal
            setCompanyName={setCompanyName} //user excluded company name
            selectRemoveEsgcValue={selectRemoveEsgcValue} //select and remove ESG-C value
            headingText={'gift_card_redeem_page'}
          />

          {/* multi graph */}
          <MultiGraphSection sp500GraphData={sp500GraphData} />


          {/* line graph */}
          <AreaChartPortfolio investmentDetailGraph={investmentDetailGraph} />

          {/* ESG-C value slider */}
          <PortfolioEsgcActivityCard selectedEsgcValueList={selectedEsgcValueList} esgcValueList={esgcValueList} />

          {/* blank space */}
          <div className="row">
            <div className="col-12">
              <div className="h-dash"></div>
            </div>
          </div>

          {/* advanced modal */}
          <AdvanceOptionModal
            openAdvanceModal={openAdvanceDataModal} //modal flag
            esgcValueList={esgcValueList} //all ESG-C value
            allCompanyList={allCompanyList} //list of all company
            selectedEsgcList={selectedEsgcValueList} //selected ESG-C value
            userExcludedList={userExcludedList} //user excluded company list
            sp500CompanyList={sp500CompanyList} //sp500 company list
            companyCountFunction={companyCount} //company count object
            modifiedSelectedList={modifiedSelectedList} //modified selected ESG-C value
            informationId={esgcIdentifier} //selected ESG-C value identifier
            onHandleClose={onHandleClose} //close modal
          />

          {/* Read more modal */}
          <ESGCInformationModal
            openInformationModal={openReadMoreDataModal} //modal flag
            esgcValueList={esgcValueList} //all ESG-C value
            closeInformationModal={onHandleClose} //close modal
          />

          {/* User Excluded modal */}
          <UserExcludedModal
            openUserExclusionModal={openUserExclusionDataModal} //modal flag
            excludedCompanyInput={excludedCompanyInput} //excluded company input
            allCompanyList={allCompanyList} //list of all company
            userExcludedList={userExcludedList} //user excluded company list
            userExcludedCompanyList={userExcludedCompanyList} //excluded company
            onHandleClose={onHandleClose} //close modal
          />

          {/* gift redeem modal */}
          <GiftAcceptModal
            acceptModalOpen={acceptModalOpen} //modal flag
            getGiftReceive={getGiftReceive} //current gift
            esgcValueList={esgcValueList} //all ESG-C value
            selectedEsgcValueList={selectedEsgcValueList} //selected ESG-C value
            userExcludedList={userExcludedList} //user excluded company list
            redeemGift={redeemGift}
            onHandleClose={onHandleClose} //close modal
          />

          <LoadingModal
            openLoadingModal={isLoadingModal} //modal flag
            title={'loading'} //loading title
            onHandleClose={onHandleClose} />

          {/* excluded company list */}
          <CompanyListModal
            companyListModal={excludedCompanyModal}
            excludedCompanyTable={true}
            companyList={excludedCompaniesList}
            companyCount={companyCount.excludedCompanyCount}
            onHandleClose={onHandleClose}
            totalCompanyTable={false}
            addedCompanyTable={false}
          />

          {/* added company list */}
          <CompanyListModal
            companyListModal={addedCompanyModal}
            addedCompanyTable={true}
            companyList={addedCompaniesList}
            companyCount={companyCount.addedCompanyCount}
            onHandleClose={onHandleClose}
            totalCompanyTable={false}
            excludedCompanyTable={false}
          />
        </>
      }
    </>
  )
}

export default GiftPage;

