//default library
import * as React from "react";
import Modal from "react-bootstrap/Modal";
import ScrollBar from "react-perfect-scrollbar";
// device detect library
import { isMobile } from "react-device-detect";
//types props
import * as PropsFunction from 'interface/PropsFunction';
import * as BasketType from 'api/APIMaster/BasketType';
//helper message
import IntlMessages from 'helper/IntlMessages';
//custom components
import InputCheckboxComponent from "components/InputComponent/InputCheckboxComponent";
import InputTextComponent from "components/InputComponent/InputTextComponent";

//defining props
export type Props = {
    openUserExclusionModal: boolean;
    excludedCompanyInput: string;
    userExcludedList: string[];
    allCompanyList: BasketType.BasketCustomizationCompanyResponseModel[];
    onHandleClose: PropsFunction.SimpleFunction;
    userExcludedCompanyList: PropsFunction.PropsUserExcludedCompanyList;
}

const UserExcludedModal: React.FC<Props> = (props: Props) => {
    const { openUserExclusionModal, excludedCompanyInput, allCompanyList, userExcludedList, onHandleClose, userExcludedCompanyList } = props; //destructing prpps

    const [searchInput, setSearchInput] = React.useState<string>(""); //excluded company name
    const [componentInput, setComponentInput] = React.useState<boolean>(false);//excluded company name

    const [excludedCompanyList, setExcludedCompanyList] = React.useState<string[]>([]);//excluded company name
    const [removedExcludedList, setRemovedExcludedList] = React.useState<string[]>([]);// add remove excluded company name
    const [searchCompany, setSearchCompany] = React.useState<string>("");//final search

    const [componentSearch, setComponentSearch] = React.useState<boolean>(false);//excluded company search
    const [isChanged, setIsChanged] = React.useState<boolean>(false); //selected company changed

    const uniqueExcludedList: string[] = [];

    // user excluded input
    const setCompanyName = (_name: string, value: string): void => {
        setSearchInput(value);
        setComponentInput(true);
    };

    // search company
    const performSearchCompany = (): void => {
        setSearchCompany(searchInput ? searchInput : excludedCompanyInput);
        setComponentSearch(true);
    };

    // select company for user excluded company
    const selectedCompany = (identifier: string, value: boolean): void => {
        if (value) {
            excludedCompanyList.push(identifier); //added into excluded company array
        } else {
            const indexOf = excludedCompanyList.indexOf(identifier);//find index of unchecked company
            excludedCompanyList.splice(indexOf, 1); //remove that company
            removedExcludedList.push(identifier);
        }


        setExcludedCompanyList(excludedCompanyList);
        setRemovedExcludedList(removedExcludedList);
        setIsChanged(!isChanged);
    };

    // confirm excluded company
    const confirmExcluded = (): void => {
        const updatedExcludedCompanyList = excludedCompanyList.filter(
            (company) => !removedExcludedList.includes(company)
        );

        userExcludedCompanyList(
            updatedExcludedCompanyList,
            removedExcludedList
        );
        onHandleClose();
    };

    if (excludedCompanyList.length === 0) {
        const excludedArr = [...excludedCompanyList]; //merge excluded company list
        if (!isChanged) {
            excludedArr.push(...userExcludedList); //merge excluded company list
        }
        uniqueExcludedList.push(...new Set(excludedArr)); //remove duplicate
        excludedCompanyList.push(...uniqueExcludedList);
    } else {
        uniqueExcludedList.push(...new Set(excludedCompanyList)); //remove duplicate
    }

    // if componentInput true means modal input and false means page input
    const inputSearch = componentInput ? searchInput : excludedCompanyInput;

    // if componentSearch true means modal search and false means page search
    const searchExcluded = componentSearch ? searchCompany : excludedCompanyInput;

    // list of company list by search
    const listOfUserExclusion = allCompanyList.filter((e: { name: string; }) => {
        if (searchExcluded === "") {
            return e;
        } else if (e.name.toLowerCase().match(searchExcluded.toLowerCase())) {
            return e;
        }
        return null
    });

    const scrollData: JSX.Element = <>
        <div className="your-choices-modal-search-ul pt-1">
            {listOfUserExclusion.map((e, i) => (
                <div className="your-choices-modal-search-li d-flex align-items-center justify-content-between py-2" key={i} data-testid='company-element'>
                    <div className="your-choices-radio-check form-check" >
                        <InputCheckboxComponent
                            labelText={e.name}
                            labelClass={'form-check-label d-flex align-items-center'}
                            fieldName={e.identifier}
                            fieldValue={e.identifier}
                            checkValue={uniqueExcludedList.includes(e.identifier)}
                            inputClass={'form-check-input'}
                            inputValue={selectedCompany}
                            dataTestId={`checkbox-${e.identifier}`}
                        />
                    </div>
                    {uniqueExcludedList.includes(e.identifier) && (
                        <div className="your-choices-modal-excluded-link">
                            <a href="#">{IntlMessages('basket_excluded')}</a>
                        </div>
                    )}
                </div>
            ))}
        </div>
    </>

    return (
        <Modal
            size='xl'
            centered
            show={openUserExclusionModal}
            backdrop="static"
            keyboard={false}
            className="gi-port-ao-pc-companies-modal your-choices-modal"
            contentClassName="border-0"
            onHide={onHandleClose}
        >
            <Modal.Header className='px-3 py-2'>
                <button type="button" className="btn-close p-0 m-0 border-0 opacity-100" onClick={onHandleClose} data-testid='close-icon'></button>
            </Modal.Header>
            <Modal.Body className='p-lg-5 py-4 px-3'>
                <div className="row justify-content-center">
                    <div className="col-lg-11">
                        {/*  */}
                        <div className="row gx-5 gy-5 justify-content-between">
                            {/* search box, btn */}
                            <div className="col-lg-6">
                                <div className="your-choices-modal-text-card">
                                    <div className="your-choices-modal-text">
                                        <h5 className="pb-1">{IntlMessages('portfolio_text5')}</h5>
                                        <p className="mt-3">{IntlMessages('portfolio_text6', { br: <br /> })}</p>
                                    </div>
                                    <div className="your-choices-modal-company-02 mt-3 pt-1 d-flex gap-2 align-items-center">
                                        <InputTextComponent
                                            labelClassName={''}
                                            labelText={''}
                                            name={'openUserExclusion'}
                                            placeholder={'placeholder_type_here'}
                                            className={'form-control'}
                                            fieldValue={inputSearch}
                                            inputValue={setCompanyName}
                                            readOnly={false}
                                            disabled={false}
                                            dataTestId={'company-input'}
                                            isError={false}
                                            errorMessage={''}
                                        />
                                        <button type="button" className="rounded-circle" disabled={inputSearch.length === 0} onClick={performSearchCompany} data-testid="search-button"></button>
                                    </div>
                                </div>
                            </div>
                            {/* company list */}
                            <div className="col-lg-5">
                                <div className="your-choices-modal-form">
                                    <div className="your-choices-modal-search pt-1">
                                        {/* static text */}
                                        <div className="your-choices-modal-search-heading pb-3">
                                            {listOfUserExclusion.length === 0
                                                ? IntlMessages('portfolio_text22')
                                                : IntlMessages('portfolio_text23')
                                            }
                                        </div>
                                        {/* scroll */}
                                        {isMobile
                                            ? <div className='user-exclusion-modal-scroll scroll-thumb p-0 pe-3' >
                                                {scrollData}
                                            </div>
                                            : <ScrollBar component="div" className="user-exclusion-modal-scroll pe-3">
                                                {scrollData}
                                            </ScrollBar>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* blank white space */}
                        <div className="your-choices-modal-card">

                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className='px-4 py-3'>
                <div className="your-choices-modal-btn px-lg-3 py-lg-3 p-0">
                    <button type="button" className="d-flex align-items-center gap-2 border-0 justify-content-center" onClick={confirmExcluded} data-testid="confirm-button">
                        <span className="d-block check-icon"></span>
                        {IntlMessages('button_confirm')}
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )

};

export default UserExcludedModal;