// default library
import * as React from 'react'
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';


export type Props = {
    display?: string;
    message?: string | PropsFunction.IntlMessages;
    message2?: string;
}

const TextError: React.FC<Props> = (props: Props) => {

    const { display, message, message2 } = props


    return (
        <div className="error-text" style={{ display: display }}>
            {message
                ? typeof (message) === 'object'
                    ? IntlMessages(...message)
                    : IntlMessages(message)
                : null
            }
            {message2 ? message2 : ''}
        </div>
    )
}

export default TextError