import * as UserType from "api/APIMaster/UserType";
import { AxiosClient, RequestParams, ExceptionResponse } from "api/AxiosClient";
import * as URLList from 'api/URLList'


class UserService<SecurityDataType = unknown> extends AxiosClient<SecurityDataType> {

    constructor() {
        super({
            baseURL: URLList.BASE_URL.USER
        });
    }

    /**
     * @description Return User on the basis of accessToken
     *
     * @name GetByAccessToken
     * @summary Get User by accessToken
     * @request GET:/user/access-token
     * @secure
     */
    getByAccessToken = (params: RequestParams = {}) =>
        this.request<UserType.GetByAccessTokenData, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.ACCESS_TOKEN.path,
            method: URLList.USER_SERVICE.ACCESS_TOKEN.method,
            secure: true,
            ...params,
        });

    /**
     * @description Get email subscription details
     *
     * @name EmailSubscriptionDetail
     * @summary Get email subscription details
     * @request GET:/any/email-subscription-detail/{emailIdentifier}
     * @secure
     */
    emailSubscriptionDetail = (emailIdentifier: string, params: RequestParams = {}) =>
        this.request<UserType.EmailSubscriptionDetailResponseModel, void | ExceptionResponse>({
            path: `${URLList.USER_SERVICE.EMAIL_SUBSCRIPTION_DETAIL.path}/${emailIdentifier}`,
            method: URLList.USER_SERVICE.EMAIL_SUBSCRIPTION_DETAIL.method,
            secure: false,
            ...params,
        });

    /**
     * @description Update email subscription
     *
     * @name UpdateEmailSubscription
     * @summary Update email subscription
     * @request PUT:/any/update-email-subscription
     * @secure
     */
    updateEmailSubscription = (data: UserType.EmailSubscriptionRequestModel, params: RequestParams = {}) =>
        this.request<UserType.UpdateEmailSubscriptionData, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.UPDATE_EMAIL_SUBSCRIPTION.path,
            method: URLList.USER_SERVICE.UPDATE_EMAIL_SUBSCRIPTION.method,
            body: data,
            secure: false,
            ...params,
        });

    /**
     * @description Return true if user bank account is linked else false
     *
     * @name IsBankAccountLinked
     * @summary Check if user bank account is linked
     * @request GET:/user/userbankaccount/is-bank-account-linked
     * @secure
     */
    isBankAccountLinked = (params: RequestParams = {}) =>
        this.request<UserType.IsBankAccountLinkedData, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.IS_BANK_ACCOUNT_LINKED.path,
            method: URLList.USER_SERVICE.IS_BANK_ACCOUNT_LINKED.method,
            secure: true,
            ...params,
        });

    /**
     * @description Return User Detail on the basis of access token
     *
     * @name GetDetail
     * @summary Get User Detail by access token
     * @request GET:/user/detail
     * @secure
     */
    getDetail = (params: RequestParams = {}) =>
        this.request<UserType.GetDetailData, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.USER_DETAILS.path,
            method: URLList.USER_SERVICE.USER_DETAILS.method,
            secure: true,
            ...params,
        });

    /**
     * @description Get onboarding static data
     *
     * @name GetOnboardingStaticData
     * @summary Get onboarding static data
     * @request POST:/user/onboarding-static-data
     * @secure
     */
    getOnboardingStaticData = (data: UserType.OnboardingStaticDataRequestModel, params: RequestParams = {}) =>
        this.request<UserType.GetOnboardingStaticDataData, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.ONBOARDING_STATIC_DATA.path,
            method: URLList.USER_SERVICE.ONBOARDING_STATIC_DATA.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Send otp to user phone
     *
     * @name SendOtp
     * @summary Send otp
     * @request POST:/user/send-otp
     * @secure
     */
    sendOtp = (data: UserType.UserPhoneOtpRequestModel, params: RequestParams = {}) =>
        this.request<UserType.UserPhoneOtpModelResponse, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.SEND_OTP.path,
            method: URLList.USER_SERVICE.SEND_OTP.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns boolean
     *
     * @name ValidateOtp
     * @summary Validate otp
     * @request POST:/user/validate-otp
     * @secure
     */
    verifyOTP = (data: UserType.UserOtpValidateRequestModel, params: RequestParams = {}) =>
        this.request<UserType.UserOtpValidateRequestModelResponse, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.VERIFY_OTP.path,
            method: URLList.USER_SERVICE.VERIFY_OTP.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns updated User
     *
     * @name UpdateUserData 
     * @summary Update User
     * @request PUT:/user/update
     * @secure
     */
    userDataUpdate = (data: UserType.UserRequestModel, params: RequestParams = {}) =>
        this.request<UserType.GetDetailData, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.USER_DATA_UPDATE.path,
            method: URLList.USER_SERVICE.USER_DATA_UPDATE.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description send the verify email from auth0
     *
     * @name ResendEmail 
     * @summary send email from auth0
     * @request GET:/user/auth0/resend-email
     * @secure
     */
    resendEmail = (params: RequestParams = {}) =>
        this.request<UserType.ResendEmailResponse, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.RESEND_EMAIL.path,
            method: URLList.USER_SERVICE.RESEND_EMAIL.method,
            secure: true,
            ...params,
        });

    /**
     * @description Returns true if unlinked else false
     *
     * @name UnlinkBank
     * @summary Unlink UserBankAccount  by id
     * @request GET:/user/userbankaccount/unlink/{id}
     * @secure
     */
    unlinkBank = (id: number, params: RequestParams = {}) =>
        this.request<UserType.UnlinkData, void | ExceptionResponse>({
            path: `${URLList.USER_SERVICE.BANK_UNLINK.path}/${id}`,
            method: URLList.USER_SERVICE.BANK_UNLINK.method,
            secure: true,
            ...params,
        });

    /**
     * @description Contact us
     *
     * @name ContactUsAPI 
     * @summary contact us page
     * @request POST:/user/contact-us
     * @secure
     */
    contactUsAPI = (data: UserType.ContactUsRequestModel, params: RequestParams = {}) =>
        this.request<UserType.ContactUsResponseModel, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.CONTACT_US_URL.path,
            method: URLList.USER_SERVICE.CONTACT_US_URL.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Return list of all UserBankAccount on the basis of access token
     *
     * @name GetAllByAccessToken
     * @summary Get all UserBankAccount by access token
     * @request GET:/user/userbankaccount/all-by-access-token
     * @secure
     */
    getAllBankList = (params: RequestParams = {}) =>
        this.request<UserType.GetAllByBankList, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.BANK_LIST.path,
            method: URLList.USER_SERVICE.BANK_LIST.method,
            secure: true,
            ...params,
        });

    /**
     * @description Update email notification
     *
     * @name UpdateEmailNotification
     * @summary Update email notification
     * @request PUT:/user/update-email-notification
     * @secure
     */
    updateEmailNotification = (data: UserType.UserEmailNotificationRequestModel, params: RequestParams = {}) =>
        this.request<UserType.UpdateEmailNotificationData, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.UPDATE_EMAIL_NOTIFICATION.path,
            method: URLList.USER_SERVICE.UPDATE_EMAIL_NOTIFICATION.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Send work email verification code
     *
     * @name SendWorkEmailCode
     * @summary Send work email verification code
     * @request POST:/user/send-work-email-code
     * @secure
     */
    sendWorkEmailCode = (data: UserType.SendWorkEmailRequest, params: RequestParams = {}) =>
        this.request<UserType.SendEmailVerificationCodeResponse, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.SEND_WORK_EMAIL_CODE.path,
            method: URLList.USER_SERVICE.SEND_WORK_EMAIL_CODE.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Verify work email
     *
     * @name VerifyWorkEmail
     * @summary Verify work email code
     * @request POST:/user/verify-work-email-code
     * @secure
     */
    verifyWorkEmail = (data: UserType.VerifyWorkEmailRequest, params: RequestParams = {}) =>
        this.request<UserType.VerifyWorkEmailResponse, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.VERIFY_WORK_EMAIL_CODE.path,
            method: URLList.USER_SERVICE.VERIFY_WORK_EMAIL_CODE.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description set-up user monthly saving amount
     *
     * @name AddMonthlySaving
     * @summary user monthly saving setup
     * @request POST:/user/usermonthlysaving/add
     * @secure
     */
    addMonthlySaving = (data: UserType.AddUserMonthlySavingRequestModal, params: RequestParams = {}) =>
        this.request<UserType.UserMonthlySavingResponseModal, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.USER_MONTHLY_SAVING_ADD.path,
            method: URLList.USER_SERVICE.USER_MONTHLY_SAVING_ADD.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description user basic details 
     *
     * @name UserBasicDetails
     * @summary user basic details 
     * @request GET:/user/basic-detail
     * @secure
     */
    userBasicDetails = (params: RequestParams = {}) =>
        this.request<UserType.UserBasicDetailResponse, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.USER_BASIC_DETAIL.path,
            method: URLList.USER_SERVICE.USER_BASIC_DETAIL.method,
            secure: true,
            ...params,
        });

    /**
     * @description edit user monthly saving
     *
     * @name EditMonthlySaving
     * @summary user monthly saving setup edit
     * @request POST:/user/usermonthlysaving/edit
     * @secure
     */
    editMonthlySaving = (data: UserType.EditUserMonthlySavingRequestModal, params: RequestParams = {}) =>
        this.request<UserType.UserMonthlySavingResponseModal, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.USER_MONTHLY_SAVING_EDIT.path,
            method: URLList.USER_SERVICE.USER_MONTHLY_SAVING_EDIT.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
    * @description update user monthly saving basket
    *
    * @name UpdateBasketMonthlySaving
    * @summary update user monthly saving setup basket
    * @request POST:/user/usermonthlysaving/update-basket
    * @secure
    */
    updateBasketMonthlySaving = (data: UserType.UpdateUserMonthlySavingBasketRequest, params: RequestParams = {}) =>
        this.request<UserType.UpdateUserMonthlySavingBasketResponse, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.USER_MONTHLY_SAVING_UPDATE_BASKET.path,
            method: URLList.USER_SERVICE.USER_MONTHLY_SAVING_UPDATE_BASKET.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description get user monthly saving agreement 
     *
     * @name MonthlySavingAgreement
     * @summary user monthly saving agreement 
     * @request POST:/user/usermonthlysaving/monthly-saving-agreement
     * @secure
     */
    monthlySavingAgreement = (data: UserType.MonthlySavingAgreementRequest, params: RequestParams = {}) =>
        this.request<UserType.MonthlySavingAgreementResponse, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.MONTHLY_SAVING_AGREEMENT.path,
            method: URLList.USER_SERVICE.MONTHLY_SAVING_AGREEMENT.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description to update the the report mail option
     *
     * @name ReportsMailingChoice
     * @summary update the the report mail option
     * @request PUT:/user/update-reports-mailing-choice
     * @secure
     */
    reportsMailingChoice = (data: UserType.ReportsMailingChoiceRequest, params: RequestParams = {}) =>
        this.request<UserType.ReportsMailingChoiceResponse, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.REPORTS_MAILING_CHOICE.path,
            method: URLList.USER_SERVICE.REPORTS_MAILING_CHOICE.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description when KYC is rejected and get the data  for resubmit data
     *
     * @name KYCRejectedDetails
     * @summary give the list of the invalid data
     * @request GET:/user/kyc-rejected-detail
     * @secure
     */
    kycRejectedDetails = (params: RequestParams = {}) =>
        this.request<UserType.KYCRejectedDetailsRequest, void | ExceptionResponse>({
            path: URLList.USER_SERVICE.KYC_REJECTED_DETAIL.path,
            method: URLList.USER_SERVICE.KYC_REJECTED_DETAIL.method,
            secure: true,
            ...params,
        });

    /**
     * @description update the language based on the user selection
     *
     * @name UploadLocale
     * @summary locale update
     * @request GET:/user/update-locale/{locale}
     * @secure
     */
    uploadLocale = (locale: string, params: RequestParams = {}) =>
        this.request<UserType.UpdateLocaleResponse, void | ExceptionResponse>({
            path: `${URLList.USER_SERVICE.UPDATE_LOCALE.path}/${locale}`,
            method: URLList.USER_SERVICE.UPDATE_LOCALE.method,
            secure: true,
            ...params,
        });
}

export default UserService