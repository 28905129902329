// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// message import
import IntlMessages from 'helper/IntlMessages';

const KYCReject: React.FC = () => {
    const history = useHistory();

    React.useEffect(() => {
        const mainSection: HTMLElement | null = document.querySelector('.gi-ob-center-part-intro')
        if (mainSection) {
            mainSection.classList.add('gi-ob-backdrop', 'position-relative')
        }

        return () => {
            mainSection && mainSection.classList.remove('gi-ob-backdrop', 'position-relative')
        }

    }, []);

    // redirect to dashboard
    const gotoDashboard = (): void => {
        history.push({
            pathname: '/dashboard',
            state: {
                updateAccessToken: false,
                bankConnectPage: false,
                isILKyc: true,
            }
        })
    }

    return (
        <div className="container-fluid px-4">
            <div className="row">
                <div className="col-12">
                    <div className="gi-ob-magnificent-text text-center position-relative">
                        <h5>{IntlMessages('kyc_reject_text1')}</h5>
                        <h6 className='mt-3 pb-1 d-none d-sm-block'>{IntlMessages('kyc_reject_text2', { br: '' })}</h6>
                        <h6 className='mt-3 pb-1 d-block d-sm-none'>{IntlMessages('kyc_reject_text2', { br: <br /> })}</h6>
                        <button type="button" className="mt-4" onClick={gotoDashboard} data-testid="dashboard-redirect">{IntlMessages('kyc_reject_btn')}</button>
                        <p className="mt-4 pb-1 gi-ob-upload-text ">{IntlMessages('kyc_denied_text3')}</p>
                        <span className="d-block pb-1">
                            <a href="mailto:support@giraffe.us">
                                support@giraffe.us
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KYCReject