// default library
import * as React from 'react';
// image import
import { useIntl } from 'react-intl';
// message provider
import IntlMessages from 'helper/IntlMessages';

const AchievementSection: React.FC = () => {
    const intl = useIntl();

    return (
        <div className="row">
            <div className="col-12">
                <div className="gi-g-achievements-card p-md-5 p-4 mb-2">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-sm-11">
                            <div className="px-md-3 px-0 py-md-0 py-2">
                                <h3>
                                    {IntlMessages('giraffe_learn_achievement_text1')}
                                </h3>
                                <p className="my-3">
                                    {IntlMessages('giraffe_learn_achievement_text2')}
                                </p>
                                <img src={intl.formatMessage({ id: "EXPERT_ACHIEVEMENT" })} className="img-fluid" alt="EXPERT_ACHIEVEMENT" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AchievementSection;

