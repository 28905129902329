//default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//custom component
import KycCompleteFooter from 'components/FooterComponent/KycCompleteFooter';
import SvgIcon from 'components/CommonComponent/SvgIcon';
//helper message
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
// custom function
import * as Local from 'service/utils/LocalStorageData';

const Congratulation: React.FC = () => {
    const intl = useIntl();
    const history = useHistory();

    const isClickRedeemed = Local.getLocalData('isClickRedeemed');
    React.useEffect(() => {
        const intervalId = setInterval(() => {
            isClickRedeemed
                ? history.push({ pathname: '/dashboard', state: { updateAccessToken: true, bankConnectPage: false, isILKyc: false } })
                : history.push('/on-boarding/bank-connect');
        }, 2000); // call reload function every 2 sec

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <div className="gi-ob-body-main">
            <section className="gi-ob-confirm-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-ob-white-logo">
                                <img src={intl.formatMessage({ id: 'WHITE_LOGO_GIRAFFE' })} alt="WHITE_LOGO_GIRAFFE" />
                            </div>
                            <div className="gi-ob-rock-card">
                                <div className="gi-ob-rock-text">
                                    <h3>{IntlMessages('congratulation_text1')}</h3>
                                    <div className="gi-ob-confirm">
                                        <SvgIcon iconId="right-check-icon" width={86} height={86} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <KycCompleteFooter />
        </div>
    );
};

export default Congratulation;