import moment from 'moment';
import 'moment/dist/locale/he';
// custom function
import * as Local from 'service/utils/LocalStorageData';

/**
 * update the moment locale based on the language
 */
const momentLocale = (): void => {
    const language = Local.getCurrentLanguage();
    if (language === 'he_IL') {
        moment.locale('he');
    } else {
        moment.locale('en');
    }
}

export const DateFormatter = (value: moment.MomentInput) => {
    momentLocale();

    const date = moment(value).format('MMM Do, YYYY');
    return date
}

export const DateFormatter2 = (value: moment.MomentInput) => {
    momentLocale();

    const date = moment(value).format('MMM DD YYYY');
    return date
}

export const DateFormatter3 = (value: moment.MomentInput) => {
    momentLocale();

    const date = moment(value).format('ll');
    return date
}