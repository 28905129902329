//default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// type import
import * as PropsFunction from 'interface/PropsFunction';
// lottie animation
import lottie from 'lottie-web/build/player/lottie_light';
// loading animation
import LoadingAnimation from 'service/animation/Loading.json';
// message import
import IntlMessages from 'helper/IntlMessages';


export type Props = {
    openLoadingModal: boolean,
    title: string,
    onHandleClose: PropsFunction.SimpleFunction
}

const animationStyle = {
    height: 295,
    width: 250,
    margin: '0 auto',
}

const LoadingModal: React.FC<Props> = (props: Props) => {

    const { openLoadingModal, title, onHandleClose } = props

    const lottieAnimation = React.useRef<HTMLDivElement | null>(null);

    // load lottie animation
    React.useEffect(() => {
        lottie.loadAnimation({
            container: lottieAnimation.current as any,
            renderer: 'svg',
            animationData: LoadingAnimation,
            loop: true, // Set to true if you want the animation to loop
            autoplay: true, // Set to true if you want the animation to play automatically
        });
    }, [openLoadingModal])


    return (
        <Modal
            centered
            show={openLoadingModal}
            backdrop="static"
            keyboard={false} className="gi-ob-white-bottom-curve gi-port-ao-pc-companies-modal source-pop-up error-modal"
            onHide={onHandleClose}>
            <Modal.Body className='px-5 pt-5 pb-4'>
                <div className="error-modal-body">
                    <div className="error-modal-text">
                        <div ref={lottieAnimation} style={animationStyle} />
                        <h5 className='mt-4'>
                            {title && IntlMessages(title)}
                        </h5>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default LoadingModal;