//default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//message provider
import IntlMessages from 'helper/IntlMessages';
//type import
import * as LearnType from 'api/APIMaster/LearnType';
//dummy data
import * as LearnData from 'service/DummyData/LearnData'

export type Props = {
    userCalendar: LearnType.UserCalendarSection
}

const LearnWeekCard: React.FC<Props> = (props: Props) => {
    const { userCalendar } = props;
    const history = useHistory();

    const [userCalendarData, setUserCalendarData] = React.useState<LearnType.CurrentUserCalenderType[]>([]);
    const [currentCalendarData, setCurrentCalendarData] = React.useState<LearnType.CurrentUserCalenderType>(LearnData.currentUserCalenderType);
    const [currentWeekIndex, setCurrentWeekIndex] = React.useState<number>(0);

    React.useEffect(() => {
        const currentIndex = userCalendar.userCalendar.findIndex((e) => e.current);
        setUserCalendarData(userCalendar.userCalendar);
        setCurrentCalendarData(userCalendar.currentCalendar);
        setCurrentWeekIndex(currentIndex);
    }, []);

    // show user to current week
    const handleCurrentWeek = (): void => {
        history.push('financial-playlist');
    };

    // go to previous week
    const handlePrevWeek = (): void => {
        setCurrentCalendarData(userCalendarData[currentWeekIndex - 1]);
        setCurrentWeekIndex(currentWeekIndex - 1);
    };

    // go to next week
    const handleNextWeek = (): void => {
        setCurrentCalendarData(userCalendarData[currentWeekIndex + 1]);
        setCurrentWeekIndex(currentWeekIndex + 1);
    };

    // go to selected section
    const handleGotoSection = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        setCurrentCalendarData(userCalendarData[Number(e.currentTarget.id)]);
        setCurrentWeekIndex(Number(e.currentTarget.id));
    };

    return (
        <>
            {/* main content */}
            <div className="row">
                <div className="col-12">
                    <div className="gi-g-schedule-card mb-2">
                        {/* back giraffe learn page */}
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-g-full-view d-flex align-items-center justify-content-end px-3 py-2 mb-2 shadow-none">
                                    <a className="d-flex align-items-center gap-2 mt-1" type='button' onClick={handleCurrentWeek} data-testid="back-button">
                                        {IntlMessages('giraffe_learn_back')}
                                        <span className="gi-g-close-icon d-block"></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* calendar data */}
                        <div className="row">
                            <div className="col-12">
                                <div className="gi-g-schedule-card-body p-lg-5 px-3">
                                    {/* heading */}
                                    <div className="gi-g-schedule-heading">
                                        <h3>
                                            {IntlMessages('giraffe_learn_path')}
                                        </h3>
                                    </div>
                                    {/* points active */}
                                    <div className="gi-g-schedule-month">
                                        <div className="gi-g-schedule-month-main w-100 d-flex align-items-center py-3 mt-1" >
                                            {userCalendarData.length > 0 && userCalendarData.map((_e, i) => (
                                                <div className={`gi-g-month ${currentWeekIndex === i ? 'gi-g-current-month' : currentWeekIndex > i ? 'gi-g-done-month' : ''}`} key={i} id={i.toString()} data-testid='active-icon'>
                                                    <a
                                                        className="d-flex align-items-center justify-content-center h-100"
                                                        onClick={handleGotoSection}
                                                        id={i.toString()}
                                                        data-testid="go-to-section"
                                                    >
                                                        <span id={i.toString()}
                                                            className="d-block rounded-circle">
                                                        </span>
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* week content section */}
                                    <div className="gi-g-schedule-text">
                                        <div className="row gx-md-4 gy-md-5 gx-3 gy-4">
                                            {currentCalendarData.userCalendarContentResponseList.length > 0 && currentCalendarData.userCalendarContentResponseList.map((e, i) => (
                                                <div className="col-md-6" key={i}>
                                                    <div className="gi-g-week-text-card">
                                                        <div className="gi-g-week-text-card-header py-3">
                                                            <h5 className="mb-1">
                                                                {IntlMessages('giraffe_learn_of_week', { title: e.title, forWeek: e.forWeek })}
                                                            </h5>
                                                        </div>
                                                        <div className="gi-g-week-text-card-body pt-4">
                                                            {e.userContentVideoResponseList.map((a) => (
                                                                <p className="pb-3" key={a.identifier}>{a.title}</p>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    {/* week change arrow */}
                                    <div className="gi-g-week-change d-flex align-items-center justify-content-end pt-4 mt-3">
                                        <div className="gi-g-weekbox gi-g-new-weekbox d-flex align-items-center gap-2 position-relative">
                                            {currentWeekIndex > 0 && <a className="gi-g-start-icon" onClick={handlePrevWeek} data-testid='prev-week' ></a>}
                                            {currentWeekIndex < userCalendarData.length - 1 && <a className="gi-g-end-icon" onClick={handleNextWeek} data-testid='next-week' ></a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LearnWeekCard;
