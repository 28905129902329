//default library
import * as React from "react";
import Modal from "react-bootstrap/Modal";
// encrypt and decrypt
import * as CryptoJS from "crypto-js";
//import regex
import * as Config from "config/Config";
//custom components
import ButtonComponent from "components/ButtonComponent/ButtonComponent";
import MaskInput2 from "components/InputComponent/MaskInput2";
import KYCFooterButton from "components/FooterComponent/KYCFooterButton";
//message import
import IntlMessages from "helper/IntlMessages";
import { useIntl } from "react-intl";
//types import
import * as OnBoarding from "interface/OnBoarding";
import * as PropsFunction from "interface/PropsFunction";
// custom function
import * as Local from 'service/utils/LocalStorageData'

// Define the PropTypes for the PhoneNumber component
type Props = {
  propsData: PropsFunction.PropsPageDataFunction;
};

const linkedinURL = 'https://www.linkedin.com/company/giraffeinvest';

const PhoneNumber: React.FC<Props> = (props: Props) => {
  const { propsData } = props; // Destructuring the receiving props
  const intl = useIntl();

  const [phoneNumber, setPhoneNumber] = React.useState<string>(""); //storing the phone number input value
  const [verifyPhoneNumber, setVerifyPhoneNumber] = React.useState<string>(CryptoJS.AES.encrypt(JSON.stringify(false), Config.SECRET_KEY).toString()); //encrypted value  'false' using CryptoJS library
  const [fieldDetails, setFieldDetails] = React.useState<OnBoarding.OnBoardingData>({}); // including phoneNumber and verifyPhoneNumber
  const [nextPage, setNextPage] = React.useState<string>(""); //next page in the onboarding process
  const [sendText, setSendText] = React.useState<boolean>(true); // 'true' to enable sending text by default

  const [infoModal, setInfoModal] = React.useState<boolean>(false); //phone number info modal

  const isRouteChange = true; //a route change is in progress
  const submitText = false; //form submission text is present or not
  const parentButton = false; //parent button

  // useEffect hook to fetch data when the component mounts
  React.useEffect(() => {
    // Accessing data from localStorage
    const localStorageData = Local.getLocalData("onBoardingDetails");

    // Call the propsData function with page details
    propsData({
      previousPage: "page1",
      pageNumber: 2,
    });

    if (localStorageData?.phoneNumber) {
      const phoneNumber = localStorageData.phoneNumber;
      const phoneNumber1 = phoneNumber.substring(0, 3);
      const phoneNumber2 = phoneNumber.substring(3, 6);
      const phoneNumber3 = phoneNumber.substring(6, 10);

      const number = `(${phoneNumber1}) ${phoneNumber2} - ${phoneNumber3}`;

      setPhoneNumber(number);
      setVerifyPhoneNumber(localStorageData.verifyPhoneNumber);
      setSendText(!JSON.parse(CryptoJS.AES.decrypt(localStorageData.verifyPhoneNumber, Config.SECRET_KEY).toString(CryptoJS.enc.Utf8)));

    } else {
      const fieldDetails = {
        ...localStorageData,
        verifyPhoneNumber: CryptoJS.AES.encrypt(JSON.stringify(false), Config.SECRET_KEY).toString()
      }
      Local.setLocalData("onBoardingDetails", fieldDetails);
    }

  }, []);

  // Function to set input value and update state
  const setInputValue = (_fieldName: string, fieldValue: string): void => {
    setPhoneNumber(fieldValue);

    setVerifyPhoneNumber(CryptoJS.AES.encrypt(JSON.stringify(false), Config.SECRET_KEY).toString());
    setSendText(true);
  };

  // open info modal
  const openCloseInfoModal = (): void => {
    setInfoModal((prev) => !prev);
  }

  React.useEffect(() => {
    let fieldDetails = {
      phoneNumber: "",
      verifyPhoneNumber: verifyPhoneNumber,
    };

    // formate phone number
    const part1 = phoneNumber.split("(");
    if (part1.length === 2 && phoneNumber.length === 16) {
      const part2 = part1[1].split(")");
      const part3 = part2[1].split("-");

      const formattedNumber = `${part2[0]}${part3[0].trim()}${part3[1].trim()}`;

      if (formattedNumber) {
        fieldDetails = {
          phoneNumber: formattedNumber,
          verifyPhoneNumber: verifyPhoneNumber,
        };
      }
    }

    const gotoOTP = JSON.parse(CryptoJS.AES.decrypt(verifyPhoneNumber, Config.SECRET_KEY).toString(CryptoJS.enc.Utf8));
    const nextPage = gotoOTP ? "page3" : "verification";

    setFieldDetails(fieldDetails);
    setNextPage(nextPage);

  }, [phoneNumber]);

  return (
    <>
      <div className="gi-ob-slide-card" data-testid="phone-number-page">
        {/* static text */}
        <div className="gi-ob-full-name-text gi-ob-mobile-number-text">
          <h5>{IntlMessages("phone_number_text1")}</h5>
          <p>{IntlMessages("phone_number_text2")}</p>
        </div>
        {/* phone number */}
        <div className="gi-ob-full-name-form gi-ob-mobile-number-form">
          <div className="gi-ob-input-box gi-ob-mobile-number">
            <label data-testid="phone_number_text3">
              {IntlMessages("phone_number_text3")}&nbsp;
              <a onClick={openCloseInfoModal} data-testid='info-modal-click'>
                <img src={intl.formatMessage({ id: 'INFO_GREY_ICON' })} alt="INFO_GREY_ICON" />
              </a>
            </label>
            <div className="gi-ob-mn-input" data-testid='mask-input' >
              <span>+1</span>
              <MaskInput2
                placeholder={"placeholder_phone"}
                name={"phoneNumber"}
                readOnly={false}
                fieldType={"text"}
                fieldValue={phoneNumber}
                inputValue={setInputValue}
                guide={false}
                dataTestId='phone-number'
                className={''}
              />
            </div>
          </div>
        </div>
      </div>

      {/* desktop button */}
      <div className="gi-ob-let-btn-group">
        <ButtonComponent
          isFooter={false}
          fieldDetails={fieldDetails}
          nextRoute={nextPage}
          sendText={sendText}
          isRouteChange={isRouteChange}
          submitText={submitText}
          parentButton={parentButton}
        />
      </div>
      {/* footer button for mobile */}
      <KYCFooterButton
        letStartText={false}
        fieldDetails={fieldDetails}
        nextRoute={nextPage}
        sendText={sendText}
        isRouteChange={isRouteChange}
        submitText={submitText}
        parentButton={parentButton}
      />

      {/* info modal */}
      <Modal
        centered
        show={infoModal}
        onHide={openCloseInfoModal}
        backdrop="static"
        keyboard={false}
        className="gi-ob-white-bottom-curve gi-en-info-modal"
      >
        <button type="button" className="btn-close opacity-100" onClick={openCloseInfoModal} data-testid='close-btn'></button>
        <Modal.Body className='p-5' data-testid='info-modal'>
          <div className="gi-en-info-text mb-2">
            <h5 data-testid='head-text'>{IntlMessages('phone_number_info_modal_text1')}</h5>
            <p className="mt-3" data-testid='paragraph-text1'>
              {IntlMessages('phone_number_info_modal_text2')}&nbsp;
              <a href={linkedinURL} target="_blank" rel="noreferrer">{IntlMessages('phone_number_info_modal_text3')}</a>
              &nbsp;{IntlMessages('phone_number_info_modal_text4')}
            </p>
            <p className="mt-3" data-testid='paragraph-text2'>{IntlMessages('phone_number_info_modal_text5')}</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PhoneNumber;
