// default library
import * as React from 'react';
// intl message library 
import { useIntl } from 'react-intl';
import IntlMessages from 'helper/IntlMessages';
// custom component
import EncryptionInformation from 'components/SectionComponent/EncryptionInformation';


const KYCFooter: React.FC = () => {
    const intl = useIntl();

    return (
        <footer className="gi-ob-footer">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="gi-ob-footer-main">
                            <div className="gi-ob-footer-heading">
                                <img src={intl.formatMessage({ id: 'LOCK_ICON_WHITE' })} alt="" />
                                <span>{IntlMessages('onboarding_footer_text3')}</span>
                            </div>
                            <div className="gi-ob-footer-text">
                                <EncryptionInformation />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default KYCFooter