import * as defaultValue from "config/DefaultValue";

/**
 * Fetch is data from localStorage
 * 
 * @param key string
 * @returns localStorage value
 */
export const getLocalData = (key: string): any => {
    const data = localStorage.getItem(key) as string;

    return JSON.parse(data)
}

/**
 * Set data into localStorage
 * 
 * @param key localStorage store data name
 * @param data data
 */
export const setLocalData = (key: string, data: any): void => {
    const value = JSON.stringify(data);

    localStorage.setItem(key, value);
}

/**
 * remove data from localStorage
 * 
 * @param key string
 */
export const removeLocalData = (key: string): void => {
    localStorage.removeItem(key);
}

/**
 * Retrieves the current language preference from the localStorage object.
 * If the preference is not found or is not a valid locale, it falls back to the default locale ('en_US').
 * @returns {string} The current language locale.
 */
export const getCurrentLanguage = (): string => {
    const defaultLang = defaultValue.defaultLocale;
    const localLanguage = getLocalData('currentLanguage');

    if (!localLanguage) {
        setLocalData('currentLanguage', defaultLang);
        return defaultLang;
    }

    return localLanguage;
};