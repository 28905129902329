import * as BasketType from "api/APIMaster/BasketType";
import { AxiosClient, RequestParams, ExceptionResponse } from "api/AxiosClient";
import * as URLList from 'api/URLList'


class BasketService<SecurityDataType = unknown> extends AxiosClient<SecurityDataType> {

    constructor() {
        super({
            baseURL: URLList.BASE_URL.BASKET
        });
    }

    /**
     * @description Returns basket order pending statuses
     *
     * @name GetBasketOrderPending
     * @summary Get basket order pending statuses
     * @request GET:/user/basket/basket-order-pending
     * @secure
     */
    getBasketOrderPending = (params: RequestParams = {}) =>
        this.request<BasketType.BasketOrderPending, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.BASKET_ORDER_PENDING.path,
            method: URLList.BASKET_SERVICE.BASKET_ORDER_PENDING.method,
            secure: true,
            ...params,
        });

    /**
     * @description Returns true if basket is renamed else false
     *
     * @name RenameBasket
     * @summary Rename basket
     * @request POST:/user/basket/rename
     * @secure
     */
    renameBasket = (data: BasketType.BasketRenameRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.RenameBasketData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.RENAME_BASKET.path,
            method: URLList.BASKET_SERVICE.RENAME_BASKET.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns basket cash out order placement details
     *
     * @name PlaceBasketCashOutOrder1
     * @summary Place basket cash out order
     * @request GET:/user/basket/place-basket-cash-out-order/{basketIdentifier}
     * @secure
     */
    placeBasketCashOutOrder = (basketIdentifier: string, params: RequestParams = {}) =>
        this.request<BasketType.PlaceBasketCashOutOrderData, void | ExceptionResponse>({
            path: `${URLList.BASKET_SERVICE.PLACE_BASKET_CASH_OUT.path}/${basketIdentifier}`,
            method: URLList.BASKET_SERVICE.PLACE_BASKET_CASH_OUT.method,
            secure: true,
            ...params,
        });

    /**
     * @description Returns basket detail
     *
     * @name GetBasketDetail
     * @summary Get basket detail
     * @request POST:/user/basket/detail
     * @secure
     */
    getBasketDetail = (data: BasketType.BasketDetailRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.GetBasketDetailData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.BASKET_DETAIL.path,
            method: URLList.BASKET_SERVICE.BASKET_DETAIL.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Return list of BasketPerformanceHistorical on the basis of basket and date range
     *
     * @name GetBasketPerformanceHistorical
     * @summary Get list of BasketPerformanceHistorical by basket and date range
     * @request POST:/user/basket/basket-performance-historical
     * @secure
     */
    getBasketPerformanceHistorical = (data: BasketType.BasketPerformanceHistoricalRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.GetBasketPerformanceHistoricalData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.BASKET_PERFORMANCE_HISTORICAL.path,
            method: URLList.BASKET_SERVICE.BASKET_PERFORMANCE_HISTORICAL.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Return list of BasketPerformanceProjectionResponseModel on the basis of basket and years
     *
     * @name GetBasketPerformanceProjection
     * @summary Get list of BasketPerformanceHistorical by basket and years
     * @request POST:/user/basket/basket-performance-projection
     * @secure
     */
    getBasketPerformanceProjection = (data: BasketType.BasketPerformanceProjectionRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.GetFutureProjectionData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.BASKET_FUTURE_PROJECTION.path,
            method: URLList.BASKET_SERVICE.BASKET_FUTURE_PROJECTION.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Boolean
     *
     * @name DeleteBasket
     * @summary Delete basket
     * @request POST:/user/basket/delete-basket/{identifier}
     * @secure
     */
    deleteBasket = (identifier: string, params: RequestParams = {}) =>
        this.request<BasketType.DeleteBasketData, void | ExceptionResponse>({
            path: `${URLList.BASKET_SERVICE.BASKET_DELETE.path}/${identifier}`,
            method: URLList.BASKET_SERVICE.BASKET_DELETE.method,
            secure: true,
            ...params,
        });

    /**
     * @description Returns updated basket
     *
     * @name UpdateBasket
     * @summary Update basket
     * @request PUT:/user/basket/update
     * @secure
     */
    updateBasket = (data: BasketType.BasketUpdateRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.UpdateBasketData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.PORTFOLIO_UPDATE.path,
            method: URLList.BASKET_SERVICE.PORTFOLIO_UPDATE.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns basket customization detail
     *
     * @name GetBasketCustomizationDetail
     * @summary Get basket customization detail
     * @request POST:/user/basket/customization-detail
     * @secure
     */
    getBasketCustomizationDetail = (data: BasketType.BasketCustomizationRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.GetBasketCustomizationDetailData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.CUSTOMIZATION_DETAIL.path,
            method: URLList.BASKET_SERVICE.CUSTOMIZATION_DETAIL.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns all company for user exclusion
     *
     * @name GetAllCompanyForUserExclusion
     * @summary Get all company for user exclusion
     * @request POST:/user/basket/user-exclusion-companies
     * @secure
     */
    getAllCompanyForUserExclusion = (data: BasketType.CompanyUserExclusionRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.GetAllCompanyForUserExclusionData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.USER_EXCLUSION_COMPANIES.path,
            method: URLList.BASKET_SERVICE.USER_EXCLUSION_COMPANIES.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns basket performance comparison
     *
     * @name GetBasketPerformanceComparison
     * @summary Get basket performance comparison
     * @request POST:/user/basket/basket-performance-comparison
     * @secure
     */
    getBasketPerformanceComparison = (data: BasketType.BasketPerformanceComparisonRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.GetBasketPerformanceComparisonData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.BASKET_PERFORMANCE_COMPARISON.path,
            method: URLList.BASKET_SERVICE.BASKET_PERFORMANCE_COMPARISON.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns SP500 return on investment
     *
     * @name GetSp500ReturnOnInvestment
     * @summary Get SP500 return on investment
     * @request GET:/user/basket/sp500-return-on-investment
     * @secure
     */
    getSp500ReturnOnInvestment = (params: RequestParams = {}) =>
        this.request<BasketType.GetSp500ReturnOnInvestmentData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.SP500_RETURN_ON_INVESTMENT.path,
            method: URLList.BASKET_SERVICE.SP500_RETURN_ON_INVESTMENT.method,
            secure: true,
            ...params,
        });

    /**
   * @description Returns basket basic detail
   *
   * @name GetBasketBasicDetail
   * @summary Get basket basic detail
   * @request POST:/user/basket/basic-detail
   * @secure
   */
    getBasketBasicDetail = (data: BasketType.BasketBasicDetailRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.GetBasketBasicDetailData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.PORTFOLIO_BASIC_DETAILS.path,
            method: URLList.BASKET_SERVICE.PORTFOLIO_BASIC_DETAILS.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
     * @description Returns created basket
     *
     * @name CreateBasket1
     * @summary Create basket
     * @request POST:/user/basket/create
     * @secure
     */
    createBasket = (data: BasketType.BasketCreateRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.CreateBasketData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.CREATE_PORTFOLIO.path,
            method: URLList.BASKET_SERVICE.CREATE_PORTFOLIO.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
   * @description Returns basket blueprint
   *
   * @name GetBasketBlueprint
   * @summary Get basket blueprint
   * @request POST:/user/basket/blueprint
   * @secure
   */
    getBasketBlueprint = (data: BasketType.BasketBlueprintRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.GetBasketBlueprintData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.PORTFOLIO_BLUEPRINT.path,
            method: URLList.BASKET_SERVICE.PORTFOLIO_BLUEPRINT.method,
            body: data,
            secure: true,
            ...params,
        });

    /**
    * @description Returns boolean
    *
    * @name RedeemGift
    * @summary Redeem gift
    * @request POST:/user/basket/redeem-gift
    * @secure
    */
    redeemGift = (data: BasketType.GiftRedemptionRequestModel, params: RequestParams = {}) =>
        this.request<BasketType.RedeemGiftData, void | ExceptionResponse>({
            path: URLList.BASKET_SERVICE.REDEEM_GIFT.path,
            method: URLList.BASKET_SERVICE.REDEEM_GIFT.method,
            body: data,
            secure: true,
            ...params,
        });
}

export default BasketService