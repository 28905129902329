// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// datadog library
import { datadogLogs } from '@datadog/browser-logs'
// plaid link token
import { usePlaidLink, PlaidLinkOptions, PlaidLinkError, PlaidLinkOnExitMetadata, PlaidLinkOnSuccessMetadata } from 'react-plaid-link';
// config
import * as Config from 'config/Config';
//auth0 library
import { useAuth0 } from "@auth0/auth0-react";
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// api call
import * as PlaidService from 'middleware/PlaidService';
import * as UserService from 'middleware/UserService';
// local storage 
import * as Local from 'service/utils/LocalStorageData';
// custom component
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';

const useCustomerName = JSON.parse(import.meta.env.REACT_APP_USE_CUSTOMER_NAME);

const PlaidRedirect: React.FC = () => {
    const { user } = useAuth0();
    const history = useHistory();
    const { initiateAction } = useAccessToken();

    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    // The Link token from the first Link initialization
    const linkToken = Local.getLocalData('plaidToken');

    // successfully connect bank
    const onSuccess = async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata): Promise<void> => {
        // send public_token to server, retrieve access_token and item_id
        // return to "https://example.com" upon completion
        setIsLoading(true);

        const accessToken = await initiateAction();
        const userData = await UserService.getDetail(accessToken); //get user data
        if (userData.response || userData.request) {
            setIsError(true);
            setErrorLog(userData);
        } else {
            const userAddress = userData.userAddress
            const processorTokenRequestData = {
                customerIdentifier: Local.getLocalData('dwUserIdentifier'),
                customerFirstName: useCustomerName ? Config.PLAID_CUSTOMER_FIRST_NAME : userData.firstNameEncrypted,
                customerMiddleName: useCustomerName ? Config.PLAID_CUSTOMER_LAST_NAME : userData.middleNameEncrypted,
                customerLastName: useCustomerName ? Config.PLAID_CUSTOMER_MIDDLE_NAME : userData.lastNameEncrypted,
                customerPhoneNumber: useCustomerName ? Config.PLAID_CUSTOMER_PHONE : userData.phoneEncrypted,
                customerEmail: useCustomerName ? Config.PLAID_CUSTOMER_EMAIL : user?.email ?? '',
                customerAddress: useCustomerName ? Config.PLAID_CUSTOMER_ADDRESS : `${userAddress.street1} ${userAddress.city} ${userAddress.province} ${userAddress.postalCode}`,
                publicToken: publicToken,
                institutionName: metadata?.institution?.name ?? '',
                account: {
                    id: metadata.accounts[0].id,
                    mask: metadata.accounts[0].id,
                    name: metadata.accounts[0].id,
                    subtype: metadata.accounts[0].id,
                    type: metadata.accounts[0].id
                }
            };
            const processToken = await PlaidService.generateProcessorToken(processorTokenRequestData, accessToken);

            setIsLoading(false);

            if (processToken.response || processToken.request) {
                setIsError(true);
                setErrorLog(processToken);
            } else {
                history.push('/dashboard');
                Local.removeLocalData('plaidToken')
            }
        }
    }

    // exit form plaid bank connection
    const onExit = (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata): void => {
        // handle error...
        datadogLogs.logger.info(`Plaid ${error}`, { plaidR: metadata, plaidToken: linkToken })
        history.push('/dashboard');
    };

    const config: PlaidLinkOptions = {
        token: linkToken,
        receivedRedirectUri: window.location.href, //the redirect URI with an OAuth state ID parameter
        onSuccess: onSuccess,
        onExit: onExit,
    }

    const { open, ready } = usePlaidLink(config)
    if (ready) {
        open()
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading && <LoadingComponent />}
        </>
    )
}

export default PlaidRedirect