import enUs from '../locales/en-US';

import * as ConfigInterface from 'interface/Config';


const Language: ConfigInterface.AppLang = {
  messages: { ...enUs },
  locale: 'en-US',
};
export default Language;
