// default library
import * as React from 'react';
import { Switch, useLocation, useHistory } from 'react-router-dom';
// auth0
import { useAuth0 } from '@auth0/auth0-react';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//ProtectedRoute 
import ProtectedRoute from 'routes/ProtectedRoute';
// type import
import * as RouteInterface from 'interface/Route';
import * as PropsFunction from 'interface/PropsFunction';
import * as UserType from "api/APIMaster/UserType"
// import route
import { israelKYCRoute } from 'routes/Route';
// api call
import * as UserService from 'middleware/UserService';
// scroll top
import ScrollToTop from 'helper/ScrollToTop';
// custom fucntion
import { pageRedirectFunction } from 'service/utils/PageRedirectFunction';
import * as Local from 'service/utils/LocalStorageData';
// react redux
import { useDispatch, useSelector } from 'react-redux';
import { setKYCData } from 'reduxStore/reducers/IsraelKYCReducer';
import { getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
import { setCurrentLanguage, getLocale } from 'reduxStore/reducers/LanguageReducer';
// dummy data
import * as UserData from 'service/DummyData/UserData';
// custom component
import IsraelKYCHeader from 'components/HeaderComponent/IsraelKYCHeader';
import IsraelKYCFooter from 'components/FooterComponent/IsraelKYCFooter';
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import SvgIcon from 'components/CommonComponent/SvgIcon';

export type Props = {
    isGiftUser: boolean,
}

const IsraelOnBoarding: React.FC<Props> = (props: Props) => {
    const { isGiftUser } = props;
    const location = useLocation();
    const history = useHistory();
    const { initiateAction } = useAccessToken();
    const { user } = useAuth0();

    const dispatch = useDispatch();

    const appCommonData = useSelector(getAppCommonData);
    const locale = useSelector(getLocale);

    const onBoardingPath: string[] = ['/on-boarding/', '/on-boarding'];
    const afterKYCPath: string[] = ['/on-boarding/kyc-pending', '/on-boarding/kyc-success', '/on-boarding/kyc-review', '/on-boarding/kyc-denied', '/on-boarding/kyc-reject'];
    const hideHeaderFooter: string[] = [...onBoardingPath, ...afterKYCPath];

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [staticData, setStaticData] = React.useState<UserType.OnboardingStaticDataResponseModel>(UserData.onBoardingStaticData);
    const [workEmail, setWorkEmail] = React.useState<string>('');

    const [previousPage, setPreviousPage] = React.useState<string>('')
    const [currentPageIndex, setCurrentPageIndex] = React.useState<number>(0)
    const [showHeader, setShowHeader] = React.useState<boolean>(false)
    const [showFooter, setShowFooter] = React.useState<boolean>(true)


    const isOnBoardingPath = onBoardingPath.includes(location.pathname); //check the current url is on-boarding url or not
    const [isAPICalled, setIsAPICalled] = React.useState<boolean>(false); //check api called or not

    // remove the background from header and change font family
    React.useEffect(() => {
        const sectionOneBG = document.getElementById('sectionOne')
        if (sectionOneBG) {
            sectionOneBG.style.background = 'none'
        }

        const documentBody = document.querySelector('body')
        documentBody && (documentBody.style.fontFamily = '"Rubik", sans-serif')
        return () => {
            documentBody && documentBody.removeAttribute('style')
        }
    }, [])

    // show/hide header and footer based on the location
    React.useEffect(() => {
        const showHidePath = hideHeaderFooter.includes(location.pathname)
        setShowHeader(!showHidePath)
        setShowFooter(showHidePath)
    }, [location])

    React.useEffect(() => {
        if (isOnBoardingPath) {
            const countryCode = locale.apiLang === 'en_US' ? 'ISR_EN' : 'ISR'; //
            updateLocal(countryCode);
            Local.setLocalData('currentLanguage', "he_IL");
        } else if (locale.language !== 'he_IL') {
            updateLocal('ISR'); //set alway hebrew language when IL kyc start
        }

        !isAPICalled && initialAPICall();
    }, [isOnBoardingPath])

    // update the location language to IL
    const updateLocal = (countryCode: string): void => {
        dispatch(setCurrentLanguage(countryCode));
    }

    const initialAPICall = async (): Promise<void> => {
        const lastPathName = Local.getLocalData('lastPathName');

        if (appCommonData.isNewUser) {
            setIsLoading(true);

            const accessToken = await initiateAction() //get access token
            const [staticData, userDetails] = await Promise.all([
                UserService.getOnboardingStaticData(accessToken), //on-boarding details api call
                UserService.getDetail(accessToken), //user details api call
            ])


            setIsLoading(false)
            if (staticData.response || staticData.request) {
                setErrorLog(staticData)
                setIsError(true)
            } else {
                setWorkEmail(userDetails.workEmail);
                setStaticData(staticData);
                setReduxData(userDetails); //store data to redux
                if (location.pathname !== lastPathName) {
                    const redirectPage = redirectFunction(userDetails); //get the redirect page
                    redirectPage !== 'page1' && redirectPage && history.push(`/on-boarding/${redirectPage}`);
                }
            }
        }
        setIsLoading(false);
        setIsAPICalled(true);
    }

    // validate user redirect page
    const redirectFunction = (userDetails: UserType.GetDetailData): string => {
        const sourceMoney = userDetails.metadataList.find(item => item.fieldName === 'sourceMoney')?.fieldValue;
        const otherSource = userDetails.metadataList.find(item => item.fieldName === 'otherSource')?.fieldValue;

        const pageData = {
            // page 1
            page1_firstNameLocale: userDetails.metadataList.find(item => item.fieldName === 'firstNameLocale')?.fieldValue ?? '',
            page1_lastNameLocale: userDetails.metadataList.find(item => item.fieldName === 'lastNameLocale')?.fieldValue ?? '',
            page1_phoneNumber: userDetails.phoneEncrypted,
            page1_docId: userDetails.taxIdNumber,

            // page 2
            page2_dob: userDetails.dob,
            page2_firstNameEn: userDetails.firstNameEncrypted,
            page2_lastNameEn: userDetails.lastNameEncrypted,
            page2_taxPayer: userDetails.usTaxPayer ? '' : 'false',
            page2_gender: userDetails.gender,

            // page 3
            page3_street1: userDetails.userAddress.street1,
            page3_houseNumber: userDetails.userAddress.street2 ?? '',
            page3_city: userDetails.userAddress.city,
            page3_postalCode: userDetails.userAddress.postalCode,
            page3_province: userDetails.userAddress.province,
            page3_reportsMailingChoice: userDetails.metadataList.find(item => item.fieldName === 'reportsMailingChoice')?.fieldValue ?? '',

            // page 4
            page4_employmentStatus: userDetails.userEmployment.statusIdentifier,

            // page 5
            page5_annualIncome: userDetails.userInvestor.annualIncome,

            // page 6
            page6_investingExperience: userDetails.userInvestor.experienceIdentifier,

            // page 7
            page7_investmentAmount: userDetails.metadataList.find(item => item.fieldName === 'investmentAmount')?.fieldValue ?? '',
            page7_investmentPurpose: userDetails.metadataList.find(item => item.fieldName === 'investmentPurpose')?.fieldValue ?? '',

            // page 8
            page8_amountIntent: userDetails.metadataList.find(item => item.fieldName === 'amountIntent')?.fieldValue ?? '',

            // page 9
            page9_investmentFunds: userDetails.metadataList.find(item => item.fieldName === 'investmentFunds')?.fieldValue ?? '',

            // page 10
            page10_sourceMoney: sourceMoney ?? '',
            page10_otherSource: sourceMoney === 'other' ? otherSource ?? '' : 'ads',

            // page 11
            page11_publicOfficial: userDetails.politicallyExposedNames ? 'true' : 'false',
            page11_stockOwner: userDetails.userEmployment.directorOf ? 'true' : 'false',
            page11_brokerDealer: userDetails.userEmployment.affiliatedWithBroker ? userDetails.userEmployment.affiliatedWithBroker.toString() : 'false',
        }

        const pageNumber = pageRedirectFunction(pageData);
        return pageNumber ? pageNumber : 'page12';
    }

    // store data to redux
    const setReduxData = (userDetails: UserType.GetDetailData): void => {
        const reduxData = {
            // page 1
            email: user?.email,
            firstNameLocale: userDetails.metadataList.find(item => item.fieldName === 'firstNameLocale')?.fieldValue ?? '',
            lastNameLocale: userDetails.metadataList.find(item => item.fieldName === 'lastNameLocale')?.fieldValue ?? '',
            phoneNumber: userDetails.phoneEncrypted,
            otpVerified: Boolean(userDetails.phone),

            // page 2
            dob: userDetails.dob,
            firstNameEn: userDetails.firstNameEncrypted,
            lastNameEn: userDetails.lastNameEncrypted,
            taxPayer: userDetails.usTaxPayer?.toString(),
            gender: userDetails.gender,

            // page 3
            street1: userDetails.userAddress.street1,
            city: userDetails.userAddress.city,
            apartmentUnit: userDetails.userAddress.street3,
            postalCode: userDetails.userAddress.postalCode,
            houseNumber: userDetails.userAddress.street2,
            province: userDetails.userAddress.province,
            countryIdentifier: userDetails.userAddress.countryIdentifier,
            reportsMailingChoice: userDetails.metadataList.find(item => item.fieldName === 'reportsMailingChoice')?.fieldValue ?? '',

            // page 4
            employmentStatus: userDetails.userEmployment.statusIdentifier,
            companyName: userDetails.userEmployment.company,
            position: userDetails.userEmployment.positionIdentifier,
            occupationField: userDetails.userEmployment.typeIdentifier,

            // page 5
            annualIncome: userDetails.userInvestor.annualIncome,

            // page 6
            investingExperience: userDetails.userInvestor.experienceIdentifier,

            // page 7
            investmentAmount: userDetails.metadataList.find(item => item.fieldName === 'investmentAmount')?.fieldValue ?? '',
            investmentPurpose: userDetails.metadataList.find(item => item.fieldName === 'investmentPurpose')?.fieldValue ?? '',

            // page 8
            amountIntent: userDetails.metadataList.find(item => item.fieldName === 'amountIntent')?.fieldValue ?? '',
            netWorthTotal: userDetails.userInvestor.networthTotal,

            // page 9
            investmentFunds: userDetails.metadataList.find(item => item.fieldName === 'investmentFunds')?.fieldValue ?? '',

            // page 10
            sourceMoney: userDetails.metadataList.find(item => item.fieldName === 'sourceMoney')?.fieldValue ?? '',
            otherSource: userDetails.metadataList.find(item => item.fieldName === 'otherSource')?.fieldValue ?? '',

            // page 11
            publicOfficial: userDetails.politicallyExposedNames ? 'true' : 'false',
            publicOfficialText: userDetails.politicallyExposedNames ?? '',
            stockOwner: userDetails.userEmployment.directorOf ? 'true' : 'false',
            companyNameStock: userDetails.userEmployment.directorOf?.split(' ')[0] ?? '',
            tickerSymbol: userDetails.userEmployment.directorOf?.split(' ')[1] ?? '',
            brokerDealer: userDetails.userEmployment.affiliatedWithBroker ? userDetails.userEmployment.affiliatedWithBroker.toString() : 'false',

            // page 12
            riskToleranceIdentifier: userDetails.userInvestor.riskToleranceIdentifier
        }

        dispatch(setKYCData(reduxData));
    }

    const propsData: PropsFunction.PropsStringNumberFunction = (previousPage, currentIndex): void => {
        setPreviousPage(previousPage)
        setCurrentPageIndex(currentIndex)
    }

    // close KYC 
    const closeKYC = (): void => {
        history.push({
            pathname: '/dashboard',
            state: {
                updateAccessToken: false,
                bankConnectPage: false,
                isILKyc: afterKYCPath.includes(location.pathname),
            }
        })
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <div>
            <ScrollToTop />

            {isLoading
                ? <LoadingComponent />
                : <>
                    <IsraelKYCHeader
                        previousPage={previousPage}
                        currentPageIndex={currentPageIndex}
                        showHeader={showHeader}
                        showDots={true}
                    />

                    {showHeader
                        ? <section className={'gi-ob-center-part'}>
                            <div className="container-md">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="px-sm-3 px-4">
                                            <div className="px-sm-0 px-3">
                                                <Switch>
                                                    {israelKYCRoute.map((ele: RouteInterface.Route, i) => {
                                                        return (
                                                            <ProtectedRoute
                                                                exact
                                                                path={ele.path}
                                                                key={i}
                                                                component={ele.Component}
                                                                staticData={staticData}
                                                                isGiftUser={isGiftUser}
                                                                propsData={propsData}
                                                                workEmail={workEmail}
                                                            />
                                                        )
                                                    })}
                                                </Switch >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : <section className={'gi-ob-center-part-intro d-block d-md-flex align-items-center justify-content-center'}>
                            <span className="gi-ob-close-btn" onClick={closeKYC}>
                                <SvgIcon
                                    iconId='close-white-icon'
                                    width={16}
                                    height={16}
                                />
                            </span>
                            <Switch>
                                {israelKYCRoute.map((ele: RouteInterface.Route, i) => {
                                    return (
                                        <ProtectedRoute
                                            exact
                                            path={ele.path}
                                            key={i}
                                            component={ele.Component}
                                            staticData={staticData}
                                            isGiftUser={isGiftUser}
                                            propsData={propsData}
                                            workEmail={workEmail}
                                        />
                                    )
                                })}
                            </Switch >
                        </section>
                    }

                    <IsraelKYCFooter showLogo={showFooter} />
                </>
            }
        </div >
    )
}

export default IsraelOnBoarding