import * as React from 'react';

import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'styles/scss/utils/loading.scss';
import 'styles/page/index.scss';

import LTR_CSS from 'styles/css/main-ltr.css?inline';
import RTL_CSS from 'styles/css/main-rtl.css?inline';

// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';


export type Props = {
    children: React.ReactElement
}

const ThemeSelector: React.FC<Props> = (props: Props) => {
    const { children } = props;

    const locale = useSelector(getLocale);
    const direction = locale.direction; //current application direction

    React.useEffect(() => {
        updateDirectionHTML();
        updateStyles();
    }, [locale]);

    // update the direction on body and html
    const updateDirectionHTML = (): void => {
        const htmlDir = document.querySelector('html') as HTMLHtmlElement;

        if (direction === 'rtl') {
            document.body.classList.add('rtl');
            document.body.classList.remove('ltr');

            htmlDir.dir = 'rtl'
        } else {
            document.body.classList.add('ltr');
            document.body.classList.remove('rtl');

            htmlDir.dir = 'ltr'
        }
    }

    // update the style based on the direction
    const updateStyles = (): void => {
        if (direction) {
            const prevDirection = direction === 'ltr' ? 'rtl' : 'ltr'; //it set the user previous direction

            const prevStyles = document.querySelectorAll(`style[type="text/css"][id=main-${prevDirection}]`); //get the all previous direction css file
            prevStyles && prevStyles.forEach(e => e.remove()); //if previous style is exist the remove


            const directionCssData = direction === 'ltr' ? LTR_CSS : RTL_CSS; //get the css file data based on teh direction

            const newStyle = document.createElement('style'); //create new style element
            newStyle.type = `text/css`; //add type in style element
            newStyle.id = `main-${direction}`; //add id in style element based on teh current direction
            newStyle.appendChild(document.createTextNode(directionCssData)); //add css data to style element based on the direction

            document.head.appendChild(newStyle); // Load the new style element to head
        }
    }

    return (
        <>
            <React.Suspense fallback={<></>}>
                {children}
            </React.Suspense>
        </>
    )
}

export default ThemeSelector;
