import DataService from 'api/APIServices/DataService';
// custom function
import * as Local from 'service/utils/LocalStorageData';

const Data = new DataService();

/**
 * @description Return all bank list basis of locale
 *
 * @name GetAllBankList
 * @summary Get All Bank List for 
 * @request POST:/user/bank/all-localize
 * @secure
 */
export const getAllBankList = async (accessToken: string) => {
    const data = {
        locale: Local.getCurrentLanguage()
    }

    return await Data.getAllBankList(data, { authToken: accessToken, })
        .then(res => res.data)
        .catch(err => { return { ...err } })
}
