import Common from 'lang/locales/en-US/Language/Common';
import Footer from 'lang/locales/en-US/Language/Footer';
import Dashboard from 'lang/locales/en-US/Language/Dashboard';
import OnBoarding from 'lang/locales/en-US/Language/OnBoarding';
import Error from 'lang/locales/en-US/Language/Error';
import Notification from 'lang/locales/en-US/Language/Notification';
import IsraelOnBoarding from 'lang/locales/en-US/Language/IsraelOnBoarding';

import IMAGES from 'lang/locales/en-US/Images';


const enUs: { [key: string]: string } = {
    ...Common, //common 
    ...Footer, //app footer
    ...Dashboard, //home, basket detail, gift, etc. page
    ...OnBoarding, //on-boarding journey
    ...Error, //error message
    ...Notification, //notification
    ...IsraelOnBoarding, //israel onboarding

    ...IMAGES, //images

};
export default enUs