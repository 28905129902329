// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
//import config
import { MAX_NUMBER_16 } from "config/Config";
// react redux
import { useSelector } from 'react-redux';
import { getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
//message provider
import IntlMessages from 'helper/IntlMessages';
import { useIntl } from 'react-intl';
//api call
import * as GiftService from 'middleware/GiftService';
// custom function
import * as Local from 'service/utils/LocalStorageData';
import { DateFormatter2 } from 'service/utils/DateFormatter';
//type import 
import * as GiftType from 'api/APIMaster/GiftType';
import * as PropsFunction from 'interface/PropsFunction';
//custom components
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import InputTextComponent from 'components/InputComponent/InputTextComponent';
import DatePageHeader from 'components/PageHeaderComponent/DatePageHeader';
import GiraffeLearnBanner from 'components/RightSidebarComponent/GiraffeLearnBanner';
import GiraffeLearnBannerSection from 'components/RightSidebarComponent/GiraffeLearnBannerSection';
import LoadingModal from 'components/LoadingComponent/LoadingModal';
import HomeInfoModal from 'components/ModalComponent/HomeInfoModal';
import GiftRedemptionErrorModal from 'components/ModalComponent/GiftRedemptionErrorModal';
import GiftRedemptionSuccessModal from 'components/ModalComponent/GiftRedemptionSuccessModal';

export type Props = {
    createGiftRoute: PropsFunction.SimpleFunction;
}

const GiftGivenPage: React.FC<Props> = (props: Props) => {

    const { createGiftRoute } = props;

    const { initiateAction } = useAccessToken();
    const history = useHistory();
    const intl = useIntl();

    const appCommonData = useSelector(getAppCommonData);

    const [errorLog, setErrorLog] = React.useState<any>({}); //error logs
    const [errorAPI, setErrorAPI] = React.useState<boolean>(false); //error flag
    const [isLoading, setIsLoading] = React.useState<boolean>(true); //loading flag

    const [successModal, setSuccessModal] = React.useState<boolean>(false); //successfully redeemed gift modal
    const [openLoadingModal, setOpenLoadingModal] = React.useState<boolean>(false); //loading modal
    const [openInfoModal, setOpenInfoModal] = React.useState<boolean>(false); //info modal
    const [errorModal, setErrorModal] = React.useState<boolean>(false); //error  modal

    const [errorMessage1, setErrorMessage1] = React.useState<string>('error_valid_gift_code'); //error message1
    const [errorMessage2, setErrorMessage2] = React.useState<string>('error_try_again'); //error message2

    const [giftReceived, setGiftReceived] = React.useState<GiftType.GiftReceivedResponseModel>({
        redeemedGifts: [],
        nonRedeemedGifts: []
    }); //gift receive data

    const [redemptionCode, setRedemptionCode] = React.useState<string>(''); //redeem code
    const [redemptionCodeError, setRedemptionCodeError] = React.useState<string>(''); //redeem code error

    React.useEffect(() => {
        initialAPICall();
    }, []);

    const initialAPICall = async (): Promise<void> => {
        window.scroll(0, 0);
        setIsLoading(true);

        const accessToken = await initiateAction();
        const giftReceivedData = await GiftService.giftReceivedDetails(accessToken);

        setIsLoading(false);

        if (giftReceivedData.response || giftReceivedData.request) {
            setErrorLog(giftReceivedData)
            setErrorAPI(true);
        } else {
            setGiftReceived(giftReceivedData);
        }
    }

    // redirect gift function
    const redirectGift = (e: React.MouseEvent): void => {
        const giftIdentifier = (e.target as HTMLElement).id
        Local.setLocalData('isClickRedeemed', true);

        history.push(appCommonData.isNewUser
            ? { pathname: '/on-boarding' }
            : { pathname: `/dashboard/gift/${giftIdentifier}` }
        );
    }

    // redemption code
    const redemptionCodeFunction = (_fieldName: string, fieldValue: string): void => {
        setRedemptionCode(fieldValue);
        setRedemptionCodeError('');
    }

    // redemption code check
    const redeemGiftCode = async (): Promise<void> => {

        if (redemptionCode.length === MAX_NUMBER_16) {
            setOpenLoadingModal(true);

            const accessToken = await initiateAction(); //get access token
            const redeemGiftCode = await GiftService.redeemGiftCodeDetails(redemptionCode, accessToken)

            setOpenLoadingModal(false);

            if (redeemGiftCode.response || redeemGiftCode.request) {
                setErrorLog(redeemGiftCode)
                setErrorAPI(true)
            } else {
                if (redeemGiftCode.status) {
                    setSuccessModal(true);
                } else if (redeemGiftCode.isRedeemed) {
                    setErrorMessage1('error_gift_already_redeem1');
                    setErrorMessage2('error_gift_already_redeem2');
                    setErrorModal(true);
                } else if (redeemGiftCode.isInvalid) {
                    setErrorMessage1('error_gift_redemption_code_invalid1');
                    setErrorMessage2('error_gift_redemption_code_invalid2');
                    setErrorModal(true);
                } else if (redeemGiftCode.isExpired) {
                    setErrorMessage1('error_gift_redemption_code_expired1');
                    setErrorMessage2('error_gift_redemption_code_expired2');
                    setErrorModal(true);
                }
            }
        } else {
            setRedemptionCodeError('error_redemption_code');
        }

    }

    // close all modal
    const onHandleClose = (): void => {
        setRedemptionCode(''); //reset the input
        setSuccessModal(false); //close success modal
        setOpenLoadingModal(false); //close loading modal
        setOpenInfoModal(false); //close info modal
        setErrorModal(false); //close error modal
    }

    // handle gift redeem
    const onHandleGiftRedeemed = (): void => {
        onHandleClose(); //close all modal
        initialAPICall(); //call initial api
        createGiftRoute(); //update the router
    }

    // open gift info modal
    const giftInfoClick = (): void => {
        setOpenInfoModal(true);
    }

    if (errorAPI) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            {isLoading
                ? <LoadingComponent />
                : <>
                    {/* header */}
                    <DatePageHeader />

                    {/* main content */}
                    <div className="row gx-2">
                        <div className="col-lg-9">
                            <div className="row" data-testid='gift-code-redeem-page'>
                                <div className="col-12">
                                    <div className={`gi-gt-gift-card bg-white p-sm-4 p-0 pt-4`} data-testid='redeem-code-section'>
                                        {/* static text */}
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="px-sm-0 px-3">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="gi-gt-gift-form-text text-center pt-4 pb-3 mb-2">
                                                                <h5>{IntlMessages('new_gift_redeem_text1')}</h5>
                                                                <p className="mt-3 d-sm-block d-none">{IntlMessages('new_gift_redeem_text2', { br: '' })}</p>
                                                                <p className="mt-3 d-sm-none d-block">{IntlMessages('new_gift_redeem_text2', { br: <br /> })}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* input */}
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row justify-content-center">
                                                    <div className="col-10">
                                                        <div className="d-sm-flex flex-sm-row flex-column text-center justify-content-center gap-2 pb-4 mb-3">
                                                            <div className="gi-gt-gift-form-input d-flex justify-content-center">
                                                                <div>
                                                                    <InputTextComponent
                                                                        labelClassName={''}
                                                                        labelText={''}
                                                                        name={'redemptionCode'}
                                                                        placeholder={'placeholder_code'}
                                                                        className={''}
                                                                        fieldValue={redemptionCode}
                                                                        inputValue={redemptionCodeFunction}
                                                                        readOnly={false}
                                                                        disabled={false}
                                                                        dataTestId={'redeem-code'}
                                                                        isError={false}
                                                                        errorMessage={redemptionCodeError}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="gi-gt-gift-form-btn mt-sm-0 mt-3">
                                                                <button type="button" disabled={!redemptionCode} onClick={redeemGiftCode} data-testid='confirm-btn'>{IntlMessages('button_enter')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* gift blank and gift pending list */}
                                        <div className="row">
                                            <div className="col-12">
                                                {giftReceived.nonRedeemedGifts.length > 0
                                                    ? <div className="gi-gt-gift-gift" data-testid='pending-gift-card'>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="px-sm-0 px-3">
                                                                    <div className="gi-gt-gift-gift-text py-4">
                                                                        <p className="d-none d-md-block">
                                                                            <strong> {IntlMessages('new_gift_redeem_text3')}</strong> {IntlMessages('new_gift_redeem_text4')}
                                                                        </p>
                                                                        <p className="d-block d-md-none">
                                                                            <strong> {IntlMessages('new_gift_redeem_text3')}</strong> {IntlMessages('new_gift_redeem_text4')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {giftReceived.nonRedeemedGifts.map((e, i) => (
                                                                    <div className={`gi-gt-gift-card-pending ${i > 0 ? 'mt-md-2 mt-1' : ''}`} key={i} data-testid='pending-gift-card-list'>
                                                                        <div className="gi-dash-gift-card-main d-flex flex-md-row flex-column align-items-md-center align-items-start position-relative gap-4">
                                                                            <div className="gi-dash-gift-img">
                                                                                <img src={intl.formatMessage({ id: 'DASHBOARD_GIFT_IMG' })} className="img-fluid gi-dash-gift-img-desk" alt="" />
                                                                                <img src={intl.formatMessage({ id: 'DASHBOARD_GIFT_IMG_MOB' })} className="img-fluid gi-dash-gift-img-mob" alt="" />
                                                                                <span data-testid='gift-amount'>${e.receivedValue}</span>
                                                                            </div>
                                                                            <div className="gi-dash-gift-text">
                                                                                <h5 className="d-block d-md-none">{IntlMessages('new_gift_redeem_text6')}</h5>
                                                                                <h5 className="d-none d-md-block">{IntlMessages('new_gift_redeem_text6')}</h5>
                                                                                <span data-testid='gift-giver-name'>
                                                                                    {IntlMessages('new_gift_redeem_text7')}&nbsp;
                                                                                    <strong>
                                                                                        {e.giftGiverName}
                                                                                    </strong>
                                                                                </span>
                                                                            </div>
                                                                            <div className="gi-dash-gift-button">
                                                                                <button type="button" id={e.identifier} onClick={redirectGift} data-testid='redirect-redeem-btn'>
                                                                                    {IntlMessages('button_redeem')}
                                                                                </button>
                                                                            </div>
                                                                            <div className="gi-dash-gift-info">
                                                                                <a className="info-icon" href="#!" onClick={giftInfoClick} data-testid='info-btn'>
                                                                                    <img src={intl.formatMessage({ id: 'INFO_LIGHT_GREEN_ICON' })} alt="INFO_LIGHT_GREEN_ICON" />
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : <div className="gi-gt-gift-blank" data-testid='blank-card-section'>
                                                        <div className="gi-gt-gift-blank-card d-flex justify-content-center align-items-center">
                                                            <img src={intl.formatMessage({ id: 'GIRAFFE_ICON_EMPTY' })} alt="" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {(giftReceived.redeemedGifts.length > 0 || giftReceived.nonRedeemedGifts.length > 0) && (
                                <div className="row" data-testid='gift-list'>
                                    <div className="col-12">
                                        <div className="gi-gt-my-gifts-card p-4 pb-5 my-md-2 my-0">
                                            <div className="gi-gt-my-gifts-header pb-3 mb-1">
                                                <h5>{IntlMessages('new_gift_redeem_text8')}</h5>
                                            </div>
                                            <div className="gi-gt-my-gifts-body">
                                                <div className="table-responsive">
                                                    <table className="gi-gt-my-gifts-table table table-success table-striped text-nowrap" role="presentation">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col"><strong>{IntlMessages('new_gift_redeem_header1')}</strong>
                                                                </th>
                                                                <th scope="col">
                                                                    <strong>{IntlMessages('new_gift_redeem_header2')}</strong>
                                                                </th>
                                                                <th scope="col">
                                                                    <strong>{IntlMessages('new_gift_redeem_header3')}</strong>
                                                                </th>
                                                                <th scope="col">
                                                                    <strong>{IntlMessages('new_gift_redeem_header4')}</strong>
                                                                </th>
                                                                <th scope="col"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {/* pending gifts */}
                                                            {giftReceived.nonRedeemedGifts.length > 0 && giftReceived.nonRedeemedGifts.map((e, i) => (
                                                                <tr key={i} data-testid='pending-gift-list'>
                                                                    <td><strong> {e.giftGiverName}</strong></td>
                                                                    <td><strong>${e.receivedValue}</strong></td>
                                                                    <td><strong>{DateFormatter2(e.receivedDate)}</strong></td>
                                                                    <td>
                                                                        <a className="text-success" id={e.identifier} onClick={redirectGift} >
                                                                            <span id={e.identifier}>{IntlMessages('transfer_pending')}</span>...
                                                                        </a>
                                                                    </td>
                                                                    <td></td>
                                                                </tr>
                                                            ))}

                                                            {/* redeemed gift */}
                                                            {giftReceived.redeemedGifts.length > 0 && giftReceived.redeemedGifts.map((e, i) => (
                                                                <tr key={i} data-testid='redeem-gift-list'>
                                                                    <td><span> {e.giftGiverName}</span></td>
                                                                    <td><span>${e.receivedValue}</span></td>
                                                                    <td><span>{DateFormatter2(e.receivedDate)}</span></td>
                                                                    <td>
                                                                        <span>{DateFormatter2(e.redeemedDate)}</span>
                                                                    </td>
                                                                    <td>
                                                                        <div className="check-icon"></div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                        {/* banner section */}
                        <div className="col-lg-3">
                            {appCommonData.isLearnAccess
                                ? <GiraffeLearnBanner />
                                : <GiraffeLearnBannerSection />
                            }
                        </div>
                    </div>
                    {/* empty space */}
                    <div className="row">
                        <div className="col-12">
                            <div className="h-dash"></div>
                        </div>
                    </div>

                    {/* Gift redemption code error */}
                    <GiftRedemptionErrorModal
                        openRedeemedErrorModal={errorModal}
                        h3={errorMessage1}
                        label={errorMessage2}
                        onHandleClose={onHandleClose}
                    />

                    {/* Gift redeemed success modal */}
                    <GiftRedemptionSuccessModal
                        openSuccessModal={successModal}
                        successMessage={'gift_redeemed_success'}
                        onHandleClose={onHandleGiftRedeemed}
                    />

                    {/* Loading Modal */}
                    <LoadingModal
                        openLoadingModal={openLoadingModal}
                        title={''}
                        onHandleClose={onHandleClose}
                    />

                    {/* info modal */}
                    <HomeInfoModal
                        openInfoModal={openInfoModal}
                        textHeading={IntlMessages('info_gift_pending_heading')}
                        messageBody={<p className='mt-3'>{IntlMessages('info_gift_pending_body', { br: <br /> })}</p>}
                        bigModal={false}
                        onHandleClose={onHandleClose}
                    />
                </>
            }
        </>
    )
}

export default GiftGivenPage