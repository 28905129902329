import * as Local from 'service/utils/LocalStorageData';

export type UserNameReturnType = {
    firstName: string | null;
    lastName: string | null;
    userName: string | null;
}


/**
 * To get the user name based ont the user language
 *
 * @param {string} language - User selected language
 * @returns {UserNameReturnType} The user firstName and lastName form localStorage
 */
export const getUserName = (language: string): UserNameReturnType => {

    let firstName: string = ''
    let lastName: string = ''

    const firstNameHe: string = Local.getLocalData('firstNameHe');
    const lastNameHe: string = Local.getLocalData('lastNameHe');
    const firstNameEn: string = Local.getLocalData('firstNameEn');
    const lastNameEn: string = Local.getLocalData('lastNameEn');

    if (language === 'he_IL') {
        firstName = firstNameHe ? firstNameHe : firstNameEn;
        lastName = lastNameHe ? lastNameHe : lastNameEn;
    } else {
        firstName = firstNameEn;
        lastName = lastNameEn;
    }

    const userName = firstName && lastName ? `${firstName} ${lastName}` : null;

    return ({
        firstName,
        lastName,
        userName,
    })
}

/**
 * detect the current history page
 *
 */
const detectHistory = (): void => {
    window.history.pushState(
        { id: window.history.state?.key ?? '1' },
        '',
        window.location.pathname
    );
}

/**
 * When this function is call user not able to browser back
 *
 */
export const stopBrowserBack = (): void => {
    detectHistory();
    window.addEventListener('popstate', () => {
        detectHistory();
    });
}

