// default library
import * as React from 'react';
// custom function
import { currencyFormatter } from 'service/utils/CurrencyFormatter';
import { DateFormatter3 } from 'service/utils/DateFormatter';
// type import
import * as BasketType from 'api/APIMaster/BasketType';
import * as PropsFunction from 'interface/PropsFunction';
import * as Dashboard from 'interface/Dashboard';
import * as UserType from 'api/APIMaster/UserType';
// react redux
import { useSelector } from 'react-redux';
import { getAppCommonData } from 'reduxStore/reducers/AppCommonDataReducer';
// message import
import IntlMessages from 'helper/IntlMessages';
// custom component
import SvgIcon from 'components/CommonComponent/SvgIcon';
import BasketRenameModal from 'components/ModalComponent/BasketRenameModal';
import NotificationSection from 'components/SectionComponent/NotificationSection';
import FutureProjection from 'components/ChartSection/FutureProjection';
import PortfolioPerformance from 'components/ChartSection/PortfolioPerformance';

export type Props = {
    performanceData: BasketType.GetBasketPerformanceHistoricalData;
    futureProjectionData: BasketType.GetFutureProjectionData;
    monthlySaving: UserType.MonthlySaving | null;
    renameBasket: PropsFunction.ParamsIdStringFunction;
    openInfoModal: PropsFunction.OpenInfoModal;
    isILUser: boolean;
    basketDetails: BasketType.GetBasketDetailData;
    notificationObj: Dashboard.NotificationArray;
    openFundModal: PropsFunction.SimpleFunction;
    openDeleteModal: PropsFunction.SimpleFunction;
    openCloseSellModal: PropsFunction.SimpleFunction;
    infoMonthly: PropsFunction.SimpleFunction,
}

const BasketDetailsCard: React.FC<Props> = (props: Props) => {

    const { performanceData, futureProjectionData, monthlySaving, renameBasket, openInfoModal, isILUser, basketDetails, notificationObj, openFundModal, openDeleteModal, openCloseSellModal, infoMonthly } = props

    const appCommonData = useSelector(getAppCommonData);

    const [basketName, setBasketName] = React.useState<string>('');
    const [showPerformanceGraph, setShowPerformanceGraph] = React.useState<boolean>(false);
    const [showFutureGraph, setShowFutureGraph] = React.useState<boolean>(false);

    React.useEffect(() => {
        // ------------------ BASKET PERFORMANCE CHART ---------------------- //
        const showPerformanceArray = [...new Set(performanceData.map(e => e.gain))]
        const showPerformanceGraphFlag = showPerformanceArray.length > 1
            ? true
            : showPerformanceArray.length === 0
                ? false
                : showPerformanceArray[0] !== 0
                    ? true
                    : false

        setShowPerformanceGraph(showPerformanceGraphFlag)

        // ------------------ BASKET FUTURE PROJECTION CHART ---------------------- //
        const showFutureArray = [...new Set(futureProjectionData.map(e => e.growthRate))]
        const showFutureGraphFlag = showFutureArray.length > 1
            ? true
            : showFutureArray.length === 0
                ? false
                : showFutureArray[0] !== 0
                    ? true
                    : false

        setShowFutureGraph(showFutureGraphFlag)
    }, [])

    // total information 
    const currentValueInfoClick = (): void => {
        const textHeading = IntlMessages('info_portfolio_basket_heading')
        const messageBody = <p className='mt-3'>{IntlMessages('info_portfolio_basket_body')}</p>
        openInfoModal(textHeading, messageBody, false)
    }

    // earning information
    const earningInfoClick = (): void => {
        const textHeading = IntlMessages('info_earning_heading')
        const messageBody = <p className='mt-3'>{IntlMessages('info_earning_body')}</p>
        openInfoModal(textHeading, messageBody, false)
    }

    // cash balance information
    const twentyYearInfoClick = (): void => {
        const textHeading = IntlMessages('info_twenty_year_heading')
        const messageBody = <p className='mt-3'>{IntlMessages('info_twenty_year_body', { br: <br /> })}</p>
        openInfoModal(textHeading, messageBody, true)
    }

    // performance info
    const clickPerformance = (): void => {
        const textHeading = IntlMessages('info_basket_performance_heading')
        const messageBody = <p className='mt-3'>{IntlMessages('info_basket_performance_body')}</p>
        openInfoModal(textHeading, messageBody, false)
    }

    // change basket name
    const changeBasketName = (_fieldName: string, fieldValue: string): void => {
        setBasketName(fieldValue)
    }

    // rename the basket
    const basketRename = (): void => {
        renameBasket(basketName)
    }

    const showMonthlySaving = monthlySaving && (basketDetails.id === monthlySaving.basketId) && !appCommonData.isKycDenied;

    return (
        <div className="row">
            <div className="col-12">
                <div className="gi-dash-ao-green-card">
                    {/* basket name button */}
                    <div className="row">
                        <div className="col-12">
                            <div className="gi-dash-ao-green-header d-flex align-items-center justify-content-between ps-lg-5 pe-lg-4 py-lg-2 px-3 py-3">
                                {/* name */}
                                <div className="col-md-5">
                                    <h5>{basketDetails.name}</h5>
                                </div>
                                {/* button and pending state */}
                                <div className="col-md-7 d-flex align-items-center">
                                    {/* monthly saving state */}
                                    <div className="col-sm-5">
                                        {showMonthlySaving && (
                                            <div className="gi-port-ao-monthly-saving d-none d-sm-flex">
                                                <a className='d-flex align-items-center gap-2 fst-normal' id={basketDetails.id.toString()} onClick={infoMonthly}>
                                                    <SvgIcon iconId='user-icon' width={13} height={13} />
                                                    {IntlMessages('home_monthly_saving')}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                    {/* sell, delete, add-fund point, mobile and rename */}
                                    <div className="col-sm-7 d-flex justify-content-end">
                                        {/*  */}
                                        <div className="gi-port-ao-green-group d-none d-sm-flex align-items-center gap-2">
                                            {/* sell & delete button */}
                                            {(basketDetails.canBeDeleted || (monthlySaving && (basketDetails.id === monthlySaving.basketId)))
                                                ? <button type="button" className="gi-port-ao-green-sell-btn gi-port-ao-green-delete-btn" onClick={openDeleteModal} data-testid='delete-btn'>
                                                    {IntlMessages('button_delete')}
                                                </button>
                                                : <button type="button" className="gi-port-ao-green-sell-btn" onClick={openCloseSellModal} data-testid='sell-btn'>
                                                    {IntlMessages('button_sell')}
                                                </button>
                                            }
                                            {/* add button */}
                                            <button type="button" className={`gi-port-ao-green-funds-btn d-flex align-items-center justify-content-center gap-2 border-0 ${isILUser ? 'w-auto px-3 text-nowrap' : ''}`} onClick={openFundModal} data-testid='fund-btn'>
                                                <SvgIcon iconId='white-plus' width={10} height={10} />
                                                {IntlMessages(isILUser ? 'button_monthly_saving' : 'button_add_funds')}
                                            </button>
                                            {/* rename section */}
                                            {!basketDetails.isGiftBasket
                                                ? <BasketRenameModal
                                                    basketName={basketName}
                                                    identifier={basketDetails.identifier}
                                                    changeBasketName={changeBasketName}
                                                    renameBasket={basketRename}
                                                    basketID={basketDetails.id}
                                                    showEdit={basketDetails.isInvestmentPending}
                                                />
                                                : <div className='change-dropdown' data-testid='gift-toggle'>
                                                    <button type="button" className="gi-dash-ellipsis-btn border-0 bg-transparent" data-bs-toggle="dropdown" aria-expanded="false" >
                                                        &nbsp;
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                        {/* mobile and rename basket */}
                                        <div className="mob-date d-flex d-sm-none">
                                            <span>
                                                {DateFormatter3(new Date(Date.now()))}
                                            </span>
                                            {!basketDetails.isGiftBasket
                                                ? <BasketRenameModal
                                                    basketName={basketName}
                                                    identifier={basketDetails.identifier}
                                                    changeBasketName={changeBasketName}
                                                    renameBasket={basketRename}
                                                    basketID={basketDetails.id}
                                                    showEdit={basketDetails.isInvestmentPending}
                                                />
                                                : <div className='change-dropdown' data-testid='gift-mob-toggle'>
                                                    <button type="button" className="gi-dash-ellipsis-btn border-0 bg-transparent" data-bs-toggle="dropdown" aria-expanded="false">
                                                        &nbsp;
                                                    </button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* transaction information */}
                    <div className="gi-dash-ao-green-body px-lg-5 px-3">
                        {/* Notification */}
                        <NotificationSection notificationObj={notificationObj} />
                        {/* basket transaction information */}
                        <div className={`gi-dash-ao-total-card py-lg-4 py-3 ${notificationObj.length === 0 ? 'border-top-0' : ''}`}>
                            <div className="row gx-3">
                                {/* portfolio and mobile */}
                                <div className="col-md-5">
                                    <div className="gi-dash-ao-total-main">
                                        {/* portfolio */}
                                        <div className="gi-dash-ao-total-text py-lg-0 my-lg-0 py-2 my-1">
                                            <h6 className="d-flex align-items-center gap-2">
                                                {IntlMessages('portfolio_value')}
                                                <a className="d-flex align-items-center" onClick={currentValueInfoClick} data-testid='portfolio-value-info'>
                                                    <SvgIcon iconId='info-icon' width={16} height={16} />
                                                </a>
                                            </h6>
                                            <h3 className='ltr'>{currencyFormatter(basketDetails.currentValue)}</h3>
                                        </div>
                                        {/* 20 year and earning */}
                                        <div className="gi-port-ao-total-fund-btn pt-2 mt-1 d-flex flex-column align-items-end gap-2 d-lg-none">
                                            {/* Monthly saving */}
                                            {(monthlySaving && (basketDetails.id === monthlySaving.basketId)) && (
                                                <div className="gi-port-ao-monthly-saving">
                                                    <a className='d-flex align-items-center gap-2 fst-normal' id={basketDetails.id.toString()} onClick={infoMonthly}>
                                                        <SvgIcon iconId='user-icon' width={13} height={13} />
                                                        {IntlMessages('home_monthly_saving')}
                                                    </a>
                                                </div>
                                            )}
                                            {/* add funds */}
                                            <button type="button" className={`gi-port-ao-green-funds-btn d-flex align-items-center justify-content-center gap-2 border-0 ${isILUser ? 'w-auto px-3 text-nowrap' : ''}`} onClick={openFundModal} data-testid='fund-mob-btn'>
                                                <SvgIcon iconId='white-plus' width={10} height={10} />
                                                {IntlMessages(isILUser ? 'button_monthly_saving' : 'button_add_funds')}
                                            </button>
                                            {/* sell & delete button */}
                                            {(basketDetails.canBeDeleted || (monthlySaving && (basketDetails.id === monthlySaving.basketId)))
                                                ? <button type="button" className="gi-port-ao-green-sell-btn gi-port-ao-green-delete-btn" onClick={openDeleteModal} data-testid='delete-mob-btn'>
                                                    {IntlMessages('button_delete')}
                                                </button>
                                                : <button type="button" className="gi-port-ao-green-sell-btn" onClick={openCloseSellModal} data-testid='sell-mob-btn'>
                                                    {IntlMessages('button_sell')}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* projection and earning */}
                                <div className="col-lg-7">
                                    <div className="row">
                                        <div className="col-lg-7">
                                            <div className="gi-dash-ao-total-text py-lg-0 my-lg-0 py-2 my-1">
                                                <h6 className="d-flex align-items-center gap-2">
                                                    {IntlMessages('transaction_projection')}
                                                    <a className="d-flex align-items-center" onClick={twentyYearInfoClick} data-testid='projection-info'>
                                                        <SvgIcon iconId='info-icon' width={16} height={16} />
                                                    </a>
                                                </h6>
                                                <h5 className="mt-lg-4 pt-lg-2 mt-1 pt-0 ltr">{currencyFormatter(basketDetails.twentyYearProjection)}</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="gi-dash-ao-total-text py-lg-0 my-lg-0 py-2 my-1">
                                                <h6 className="d-flex align-items-center gap-2">
                                                    {IntlMessages('transaction_earning')}
                                                    <a className="d-flex align-items-center" onClick={earningInfoClick} data-testid='earning-info'>
                                                        <SvgIcon iconId='info-icon' width={16} height={16} />
                                                    </a>
                                                </h6>
                                                <h5 className="mt-lg-4 pt-lg-2 mt-1 pt-0 ltr">{basketDetails.totalGain > 0 ? `+${currencyFormatter(basketDetails.totalGain)}` : currencyFormatter(basketDetails.totalGain)}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* performance data & future projections */}
                        {(showPerformanceGraph || showFutureGraph) && (
                            <div className="gi-port-ao-tabs-card py-2">
                                <div className="gi-p-tabs-btn-group mt-1">
                                    <ul className="nav nav-pills nav-justified" id="pills-tab" role="tablist">
                                        {showPerformanceGraph && <li className="nav-item" role="presentation">
                                            <a className="nav-link active" id="pills-Historical-tab" data-bs-toggle="pill" data-bs-target="#pills-Historical" href="#pills-Historical" role="tab" aria-controls="pills-Historical" aria-selected="true">
                                                {IntlMessages('transaction_performance')}
                                            </a>
                                        </li>}
                                        {showFutureGraph && <li className="nav-item" role="presentation">
                                            <a className={`nav-link ${!showPerformanceGraph && 'active'}`} id="pills-Future-tab" data-bs-toggle="pill" data-bs-target="#pills-Future" href="#pills-Future" role="tab" aria-controls="pills-Future" aria-selected="false">
                                                {IntlMessages('transaction_future_projection')}
                                            </a>
                                        </li>}
                                    </ul>
                                </div>
                                <div className="tab-content" id="pills-tabContent">
                                    {/* performance graph */}
                                    {showPerformanceGraph && <div className="tab-pane fade show active" id="pills-Historical" role="tabpanel" aria-labelledby="pills-Historical-tab">
                                        <PortfolioPerformance
                                            performanceData={performanceData}
                                            clickPerformance={clickPerformance}
                                        />
                                    </div>}
                                    {/* future graph */}
                                    {showFutureGraph && <div className={`tab-pane fade ${!showPerformanceGraph && 'show active'}`} id="pills-Future" role="tabpanel" aria-labelledby="pills-Future-tab">
                                        <FutureProjection
                                            futureProjectionData={futureProjectionData}
                                            twentyYearInfoClick={twentyYearInfoClick}
                                        />
                                    </div>}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BasketDetailsCard