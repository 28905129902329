const IsraelOnBoarding: { [key: string]: string } = {
    /* common */
    'kyc_let_start': `Let's start`,
    'kyc_not_start': 'Later',
    'privacy_policy_il': 'to the privacy policy',
    'kyc_note': 'Note',

    /* dropdown or selection */
    'amount_less_50000': 'Up to NIS 50,000',
    'amount_bet_50000_250000': '50,000 NIS - 250,000 NIS',
    'amount_bet_250000_500000': '250,000 NIS - 500,000 NIS',
    'amount_more_500000': '500,000 NIS or more',
    'amount_less_160000': 'Less than NIS 160,000',
    'amount_btw_160000_320000': 'Between 160,000 and 320,000 NIS',
    'amount_btw_320000_600000': 'Between 320,000 and 600,000 NIS',
    'amount_btw_600000_1200000': 'Between 600,000 and 1,200,000 NIS',
    'amount_more_1200000': 'Over NIS 1,200,000',

    /* button */
    'btn_continued': 'Continued',
    'btn_send_otp': 'Send again',
    'btn_attach_doc': 'Attach a document',
    'btn_click_photo': 'Take a photo with a mobile phone',
    'btn_click_edit': 'Edit',

    /* header text */
    'il_header_return': 'Return',

    /* footer text */
    'il_footer_privacy_policy': 'Privacy Policy',

    /* place holder */
    'placeholder_IL_first_name': 'As it appears in (including middle name if any)',
    'placeholder_IL_last_name': 'As appears in B. 7.',
    'placeholder_IL_phone': 'Only ten digits',
    'placeholder_IL_doc_id': 'Includes audit trail',
    'placeholder_name_en': 'In English',
    'placeholder_select_dropdown': 'Choose',
    'placeholder_IL_company_name': 'Company Name',

    /* error message */
    'fill_data': 'Please fill the data',
    'error_invalid_ID_card': 'The ID card you entered is invalid.',
    'error_invalid_otp': 'Invalid verification code',
    'error_dob_18': 'Note. The service is provided over the age of 18 only',

    /* start page */
    'kyc_start_page_text1_desk': `We're glad you've decided to invest in yourself!`,
    'kyc_start_page_text2_desk': `We invite you to discover how saving and investing can be both profitable and simple.`,
    'kyc_start_page_text3_desk': `To get started, we'll first need to collect some details from you and ask a few questions.{br}The process is easy and quick - we'll guide you through every step.`,
    'kyc_start_page_text1_mob': `We're glad you've decided {br}to invest in yourself!`,
    'kyc_start_page_text2_mob': `We invite you to discover how saving and investing {br}can be both simple and profitable`,
    'kyc_start_page_text3_mob': `To get started, {br} we'll first need to collect some details from you and ask a few questions. {br}The process is easy and quick - we'll guide you through every step.`,

    /* Work Email modal */
    'work_email_modal_head': 'Just before we start',
    'work_email_modal_sub_head': 'Want to check if your {br}company works with us?',
    'work_email_label': 'Fill in your work email:',
    'work_email_footer': 'Other than sending a verification code, {br}we will not use the email address.',
    'work_email_verify_text1': 'Yay! Your company works with us',
    'work_email_verify_text2': 'Please fill in the verification code sent {br}to you by email and we will begin the process of opening {br}your investment account',
    'work_email_verify_text3': `Didn't get the code? {br} Don't forget to check spam`,
    'work_email_verify_resend': 'Resend',
    'work_email_verify_error_text1': 'Wrong verification code',
    'work_email_verify_error_text2': 'Please verify the code you typed',
    'work_email_not_list_text1': 'Oops!',
    'work_email_not_list_text2': 'Your company has not yet {br} joined the club of companies that offer their employees {br}our services.',
    'work_email_not_list_text3': 'We invite you to continue the process of {br}opening the investment account. We will be happy to inform you {br}when we reach an agreement with your company and/or {br}that the platform will be opened to the general public.',
    'work_email_skip_modal_text1': 'Note,',
    'work_email_skip_modal_text2': 'The platform is only open to employees of {br} companies that have reached an agreement with us.',
    'work_email_skip_modal_text3': 'We invite you to continue {br}with the process of opening the investment account.',
    'work_email_skip_modal_text4': 'We will be happy to inform you when we reach an agreement {br} with your company and/or the platform {br} is opened to the general public.',
    'work_email_skip_modal_text5': 'Check anyway',
    'work_email_btn_back_account': 'Back to account',

    /* Full name, id page */
    'kyc_name_page_header': 'Filling in personal details',
    'kyc_name_page_footer1': 'Clicking "Continue" will constitute your consent to the provision of your details accordingly',
    'kyc_name_page_footer2': ' ours as well as for our representatives to contact you (by text message or email) to assist you in the process of joining the service as needed.',
    'kyc_first_name_label': 'First Name',
    'kyc_last_name_label': 'Last Name',
    'kyc_last_doc_id': 'ID number',
    'kyc_last_phone_label': 'Mobile phone',
    'kyc_otp_modal_heading': 'Verification Code',
    'kyc_otp_modal_text': 'We have sent a verification code to your mobile.{br}Please enter the code to continue.',

    /* DOB, Gender page */
    'kyc_dob_label': `Date of birth`,
    'kyc_gender_label': 'Gender',
    'kyc_gender_female': 'Female',
    'kyc_gender_male': 'Male',
    'kyc_first_name_label_en': 'First Name',
    'kyc_last_name_label_en': 'Last Name ',
    'kyc_us_citizen': 'US citizen including resident or green card holder',
    'kyc_info_us_yes_text1': `A US citizen or resident cannot{ br }join the service at this time`,
    'kyc_info_us_yes_text2': `We will contact you when the service is supported{ br } for US citizens`,
    'kyc_info_us_yes_btn1': 'I understood, thank you',
    'kyc_info_us_yes_btn2': 'Back to continue the process',
    'kyc_info_us_no_text1': `The service is intended for customers with { br }Israeli identity cards who live in Israel.{ br }The service will be provided within the borders of the State of Israel{ br }only and may not be used outside of { br } Israel.`,
    'kyc_info_us_no_btn1': 'Got it, we can continue',

    /* Address page */
    'kyc_address_header': 'Residential address in Israel',
    'kyc_address_street': 'Street',
    'kyc_address_city': 'City',
    'kyc_address_house_no': 'House number',
    'kyc_address_apartment': 'Apartment',
    'kyc_address_postal_code': 'Postal Code',

    /* Employment Detail page */
    'kyc_employment_detail_header': 'Employment Details',
    'kyc_employment_detail_label': 'Employment Status',
    'kyc_employment_detail_company_name': 'Company Name',
    'kyc_employment_detail_ticker': 'Ticker',
    'kyc_employment_detail_position': 'Your position in the company',
    'kyc_employment_detail_field': 'Field of occupation of the company',

    /* Annual Income page */
    'kyc_annual_income_header': 'What is your average annual income?',
    'kyc_annual_income_label': 'Total net income of all household members. (salaries, allowances, income-generating assets, etc.)',

    /* Investment Experience page */
    'kyc_investment_experience_header': 'What is your background in the capital market?',
    'kyc_investment_experience_label': 'We want to know so we can advise you according to your knowledge on the subject.',

    /* Investment Purpose page */
    'kyc_investment_label': 'What is the main purpose of your investment?',
    'kyc_investment_money_label': 'How much money were you thinking of investing?',
    'kyc_investment_money_sub_label': 'The investment amount can be easily changed later.',

    /* Amount Intend To Invest page */
    'kyc_amount_intend_label': 'In your opinion, what part of your liquid assets is the amount you intend to invest?',
    'kyc_amount_intend_sub_label': 'Cash, bank deposits, investments, securities, financial assets and the like. {br}(not including real estate and pension)',
    'kyc_amount_intend_modal_body_text': "The investment in Giraffe is an investment in  shares only {br} and therefore includes risk. {br} Investing a significant amount of total liquid assets {br} causes an increase in the risk level of all your investments and assets.",

    /* Investment Funds Page*/
    'kyc_investment_fund_label': 'In how many years do you think you will need to use the investment funds?',
    'kyc_investment_fund_sub_label': 'The money can be withdrawn at any time.',
    'kyc_investment_fund_modal_body_text': 'A short-term investment in a portfolio {br} based on equity investment only {br} includes an increased risk of loss in light of the {br} volatility of the stock market and may in certain {br} cases result in the loss of substantial {br} parts of the investment amount.',

    /* Source of Money */
    'kyc_source_money_label': 'What is your main source of money?',
    'kyc_source_money_salary': 'Salary',
    'kyc_source_money_other': 'Other',

    /* Politicly Exposed page */
    'kyc_politically_exposed_heading': 'Are you currently or in the last 5 years {br} one of the following:',
    'kyc_politically_exposed_question1': 'Holder of a senior public position, including a family member. ',
    'kyc_politically_exposed_question2': 'Director, controlling owner or officer of a public company traded on the stock exchange.',
    'kyc_politically_exposed_question3': 'Registered as a broker or employee of a stock exchange company, FINRA or hedge fund.',
    'kyc_politically_exposed_info_head1': 'Senior Public Position =',
    'kyc_politically_exposed_info_body1': 'including a head of state, president of a state, mayor {br} of a city, judge, member of parliament, senior member of a party {br}, member of the government, senior military or {br} police officer, senior official {br} in government companies, senior official {br} br} in international organizations or anyone who fulfills {br} such a position even if their description is different',
    'kyc_politically_exposed_info_head2': 'Family Member =',
    'kyc_politically_exposed_info_body2': 'Spouse, sibling, parent, parent of a parent, child {br}or child of the spouse or partner of any {br} of these',
    'kyc_politically_exposed_yes1_head': `What is a person's full name?{br}The public and its role?`,
    'kyc_politically_exposed_yes2_head': `Please state the name of the company{br} and the name of the company's stock`,
    'kyc_politically_exposed_yes3_head': 'No problem, just be aware',
    'kyc_politically_exposed_yes3_body': 'You must inform your employer in writing of your intention to open an investment account',
    'kyc_politically_exposed_info2_text1': 'We are really sorry',
    'kyc_politically_exposed_info2_text2': 'We are unable to open an investment account for public figures at this time',
    'kyc_politically_exposed_info2_text3': 'For more details please contact us by email:',

    /* Document Upload */
    'kyc_document_label': 'Loading identification documents',
    'kyc_document_sub_label': 'Attach documents or take a photo with a mobile phone.',
    'kyc_document_driving': 'Driving License',
    'kyc_document_passport': 'An Israeli passport',

    /* Israel Terms and Condition */
    'kyc_tc_heading': 'This finishing',
    'kyc_tc_sub_heading': 'It only remains to confirm a few details',
    'kyc_tc_term1': 'I confirm that the activity planned in the account is a deposit for the purpose of investment only and/or withdrawal of funds.',
    'kyc_tc_term2': 'I work for myself only and there is no other beneficiary in the account. My confirmation of this section is the same as an original signature on the attached beneficiary declaration.',
    'kyc_tc_term3': 'I understand and confirm that the investment has a high risk profile since the entire investment is in a stock portfolio which is intended to reflect an investment based on the American S&P 500 index, so there may be high volatility in the value of the portfolio. This is a risk I am willing to take in order to get a return even at the price of a decrease in the value of the portfolio.',
    'kyc_tc_term4': 'I have not encountered a refusal to open an account in a financial institution for reasons of money laundering or securities offenses.',
    'kyc_tc_term5': 'I was given the opportunity to review the following documents and I agree to state in them:',
    'kyc_tc_pdf1': 'Beneficiary Declaration Annex',
    'kyc_tc_pdf2': 'Client Agreement Annex',
    'kyc_tc_pdf3': 'DriveWealth Agreement Addendum',
    'kyc_tc_info_modal': 'At this stage it is not possible to add partners or beneficiaries to the investment account',

    /* KYC pending page */
    'kyc_pending_magnificent': 'Perfect!',
    'kyc_pending_question1': 'We process and verify the data you entered.',
    'kyc_pending_question2': `It will take a few moments...`,

    /* KYC Success page */
    'kyc_complete_success_text1': `The process has been completed {br}successfully!`,
    'kyc_complete_success_text2': `All that remains is to define the amount of savings to be deducted {br} from the monthly salary that will be deposited in your investment account.`,
    'kyc_complete_success_text3': `All that remains is to define the {br} amount of savings to be deducted from the {br} monthly salary that will be deposited into your {br}investment account.`,
    'kyc_complete_success_btn': 'Login to the account',
    'kyc_complete_document_upload_text1': 'Very nice!',
    'kyc_complete_document_upload_text2': 'Now you just need to upload the relevant documents',
    'kyc_complete_document_upload_dl_text1': 'Driving License',
    'kyc_complete_document_upload_dl_text2': '/ Nicklet',
    'kyc_complete_document_upload_ip_text1': 'An Israeli passport',
    'kyc_complete_document_upload_ip_text2': '/ missing',
    'kyc_complete_document_upload_btn': 'Uploading documents',
    'kyc_complete_document_upload_skip': 'Skip to account',
    'kyc_complete_kyc_error_text1': 'We are really sorry',
    'kyc_complete_kyc_error_text2': 'You did not successfully complete the account opening process.{br}For more details please contact us by email.',
    'kyc_complete_kyc_error_btn': 'Back to account',

    /* KYC Review Page */
    'kyc_review_text1': `We're almost done...`,
    'kyc_review_text2_desk': `While we check all the details, you are invited {br}to continue the process and choose the amount for the monthly savings.`,
    'kyc_review_text2_mob': `While we check all the {br} details, you are invited to continue {br} the process and choose the amount {br} for the monthly savings.`,
    'kyc_review_text3': `We continue to work in the background`,
    'kyc_review_btn': `Login to the account`,

    /* KYC Denied page/modal */
    'kyc_denied_text1': `We are really sorry.`,
    'kyc_denied_text2': `The account opening process for {br} has been successfully completed.`,
    'kyc_denied_btn': `Return to the account`,
    'kyc_denied_text3': `For more details please contact us by email.`,
    'kyc_denied_modal_text1': 'We are really sorry',
    'kyc_denied_modal_text2': 'The account opening process was not completed successfully.',
    'kyc_denied_modal_text3': 'Back to account',
    'kyc_denied_modal_text4': 'For more details please contact us by email.',

    /* KYC Reject page/modal */
    'kyc_reject_text1': 'Oops!',
    'kyc_reject_text2': `Some of the details you have entered do not {br} match the identification documents.`,
    'kyc_reject_btn': 'Update',
    'kyc_reject_text3': `For more details please contact us by email.`,
    'kyc_reject_modal_text1': 'Oops!',
    'kyc_reject_modal_text2': 'Some of the details you entered do not match the identification documents.',
    'kyc_reject_modal_text3': 'Update details',
    'kyc_reject_modal_text4': 'For more details please contact us by email. ',

    /* KYC form page */
    'kyc_reject_form_text1': 'Update personal details',
}

export default IsraelOnBoarding