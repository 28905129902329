// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
import { useHistory } from 'react-router-dom';
//auth0 library
import { useAccessToken } from 'hook/UseAccessToken';
import { useAuth0 } from '@auth0/auth0-react';
//config import
import * as Config from 'config/Config';
import { actionValue } from 'config/DefaultValue';
// message import
import IntlMessages from 'helper/IntlMessages';
// api call
import * as UserService from 'middleware/UserService';
//custom function
import AgeCalculator from 'service/utils/AgeCalculator';
// type import
import * as PropsFunction from 'interface/PropsFunction';
import * as UserType from 'api/APIMaster/UserType';
// dummy data
import * as OnBoardingData from 'service/DummyData/OnBoardingData';
//custom component
import AutoComplete from 'components/AutoComplete/AutoComplete';
import ErrorComponent from 'components/ErrorComponent/ErrorComponent';
import InputComponentRadio2 from 'components/InputComponent/InputComponentRadio2';
import InputComponentText2 from 'components/InputComponent/InputComponentText2';
import MaskInput2 from 'components/InputComponent/MaskInput2';
import SelectDropdown from 'components/SelectComponent/SelectDropdown';
import LoadingModal from 'components/LoadingComponent/LoadingModal';


export type Props = {
    openKYCErrorModal: boolean;
    kycError: string[];
    userData: UserType.GetDetailData;
    countryList: PropsFunction.Option;
    componentDidMount: PropsFunction.SimpleFunction;
}

const errorCodeArray: string[] = ['K101', 'K102', 'K103', 'K104', 'K108', 'K110', 'K111']; //list of all error code of KYC

const KYCErrorModal: React.FC<Props> = (props: Props) => {
    const { openKYCErrorModal, kycError, countryList, userData, componentDidMount } = props;

    const history = useHistory();
    const { initiateAction } = useAccessToken();
    const { user } = useAuth0();

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isLoading, setIsLoading] = React.useState<boolean>(false);  //error logs
    const [isDisabled, setIsDisabled] = React.useState<boolean>(true); //button state where is disabled or not
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(true);  //error logs

    const [handleKYC, setHandleKYC] = React.useState<string[]>([]); //check kyc error is handle or not

    const [countryOption, setCountryOption] = React.useState<UserType.CountryResponseModel[]>([]);//storing the list of countries
    const [addressAutoFill, setAddressAutoFill] = React.useState<string>('');//auto field text
    const [street1, setStreet1] = React.useState<string>(''); //street 1 for data submit
    const [postalCode, setPostalCode] = React.useState<string>(''); //postal code
    const [errorFlag, setErrorFlag] = React.useState(false);//load component
    const [apartmentUnit, setApartmentUnit] = React.useState<string>('');//apartment unit
    const [city, setCity] = React.useState<string>(''); //auto complete city,
    const [province, setProvince] = React.useState<string>(''); //auto complete province,
    const [countryAutoComplete, setCountryAutoComplete] = React.useState<string>(''); //auto complete country,

    const [ssnNumber, setSsnNumber] = React.useState(""); //storing the SSN
    const [ssnNumberFormatted, setSsnNumberFormatted] = React.useState(""); //storing the SSN
    const [errorFlagSSN, setErrorFlagSSN] = React.useState(false); //tracking if there is an error in the SSN input
    const [fieldType, setFieldType] = React.useState("password"); //toggling the visibility of the password field

    const [dateOfBirth, setDateOfBirth] = React.useState(""); //storing the date of birth
    const [dateOfBirthFormatted, setDateOfBirthFormatted] = React.useState(""); //storing the date of birth
    const [errorFlagDOB, setErrorFlagDOB] = React.useState(false); //tracking if there is an error
    const [validAgeFlag, setValidAgeFlag] = React.useState(false); //entered age is valid (not under 18)

    const [firstName, setFirstName] = React.useState<string>(''); //set firstname
    const [lastName, setLastName] = React.useState<string>(''); //set last name

    const [phoneNumber, setPhoneNumber] = React.useState<string>(""); //storing the phone number input value
    const [phoneNumberFormatted, setPhoneNumberFormatted] = React.useState<string>(""); //with-out and formatted number

    const [citizenship, setCitizenship] = React.useState<PropsFunction.OptionData>(OnBoardingData.defaultOptionValue);//managing the citizenship data
    const [isActive, setIsActive] = React.useState(true);//managing the active state of the country
    const [taxPayer, setTaxPayer] = React.useState<string>('');//tax payer, initialized with an empty string

    React.useEffect(() => {
        if (openKYCErrorModal) {
            const isHandleKYC = errorCodeArray.filter(e => {
                return kycError.includes(e)
            })

            setHandleKYC(isHandleKYC)
            setIsModalOpen(true);

            // mapping country data
            const countryOption: UserType.CountryResponseModel[] = countryList.map((e, i) => ({
                id: i,
                identifier: e.value as string,
                code: '',
                name: e.label as string,
                active: e.active ?? false,
            }));

            setCountryOption(countryOption);
        }
    }, [openKYCErrorModal])

    // set default value
    React.useEffect(() => {
        if (openKYCErrorModal) {
            const userAddress = userData.userAddress;
            setAddressAutoFill([userAddress.street1, userAddress.city, userAddress.province].filter(Boolean).join(', ')); //auto complete address
            setStreet1(userAddress.street1); //street1
            setApartmentUnit(userAddress.street2 ?? ''); //apartment unit
            setPostalCode(userAddress.postalCode); //postal code
            setCity(userAddress.city);
            setProvince(userAddress.province);
            setCountryAutoComplete(userAddress.countryIdentifier);

            setFirstName(userData.firstNameEncrypted)
            setLastName(userData.lastNameEncrypted)

            setTaxPayer(JSON.stringify(userData.usTaxPayer))
        }
    }, [openKYCErrorModal])

    //close modal
    const onHandleClose = (): void => {
        setIsModalOpen(false);
        setIsLoading(false);
    }

    //contact us
    const contactUs = (): void => {
        window.location.href = 'mailto:giraffeimpact@support.com';
    }

    // auto complete field data
    const setAutoCompleteValue: PropsFunction.PropsInputAutoComplete = (params): void => {
        setAddressAutoFill(params.addressAutoFill);
        setStreet1(params.addressAutoFill);
        setPostalCode(params.postalCode);
        setErrorFlag(params.errorFlag);
        setCity(params.city);
        setProvince(params.province);
        setCountryAutoComplete(params.country);
    }

    // set input value
    const setInputValue = (fieldName: string, fieldValue: string): void => {
        fieldName === 'apartmentUnit' && setApartmentUnit(fieldValue);
        fieldName === "ssnNumber" && setSsnNumber(fieldValue);
        fieldName === "dateOfBirth" && setDateOfBirth(fieldValue);
        fieldName === "firstName" && setFirstName(fieldValue);
        fieldName === "lastName" && setLastName(fieldValue);
        fieldName === "phoneNumber" && setPhoneNumber(fieldValue);
        fieldName === "taxPayer" && setTaxPayer(fieldValue);
    };

    // set dropdown value
    const selectDropDownValue: PropsFunction.SelectedOption = (_fieldName, fieldValue) => {
        // Updating citizenship state with the selected values
        setCitizenship(fieldValue);

        setIsActive(fieldValue.active);// Update isActive state
    };

    //toggle the visibility of the password field
    const toggleFieldType = (): void => {
        setFieldType((prev) => prev === "password" ? "text" : "password");
    };

    React.useEffect(() => {
        // set default value
        setErrorFlagSSN(false);

        // format SSN
        const ssn = ssnNumber.split("-");
        const ssn1 = ssn[0];
        const ssn2 = ssn[1];
        const ssn3 = ssn[2];

        if (ssnNumber.length === 11) {
            setErrorFlagSSN(!ssnNumber.match(Config.SSN_REGEX));

            setSsnNumberFormatted(`${ssn1}${ssn2}${ssn3}`)
        }
        setSsnNumberFormatted('')
    }, [ssnNumber])

    React.useEffect(() => {
        // set default values
        setErrorFlagDOB(false);
        setValidAgeFlag(false);

        // format data
        const date = dateOfBirth.split("/");
        const mm = date[0]; //month
        const dd = date[1]; //day
        const yyyy = date[2]; //year

        if (mm.length === 2 && dd.length === 2 && yyyy.length === 4) {
            // check validation
            const comparisonDate = new Date(dateOfBirth);

            if (comparisonDate < new Date("1/1/1901") || comparisonDate > new Date(Date.now())) {
                setErrorFlagDOB(true);
            }

            if (AgeCalculator(comparisonDate) < 18 && comparisonDate < new Date(Date.now())) {
                setValidAgeFlag(true);
            }

            setDateOfBirthFormatted(`${yyyy}-${mm}-${dd}`);
        }

        setDateOfBirthFormatted("");
    }, [dateOfBirth])

    React.useEffect(() => {
        const part1 = phoneNumber.split("(");
        if (part1.length === 2 && phoneNumber.length === 16) {
            const part2 = part1[1].split(")");
            const part3 = part2[1].split("-");

            const formattedNumber = `${part2[0]}${part3[0].trim()}${part3[1].trim()}`;

            if (formattedNumber) {
                setPhoneNumberFormatted(formattedNumber)
            }
        }
    }, [phoneNumber])

    React.useEffect(() => {
        if (handleKYC.includes('K101') && (errorFlag || apartmentUnit.length > Config.MAX_NUMBER_36)) {
            setIsDisabled(true)
        } else if (handleKYC.includes('K102') && !ssnNumberFormatted) {
            setIsDisabled(true)
        } else if (handleKYC.includes('K103') && !dateOfBirthFormatted) {
            setIsDisabled(true)
        } else if ((handleKYC.includes('K104') || handleKYC.includes('K110')) && !firstName && !lastName) {
            setIsDisabled(true)
            if (firstName && (firstName.length > Config.MAX_NUMBER_36 || firstName.match(Config.NAME_REGEX))) {
                setIsDisabled(true)
            }
            if (lastName && (lastName.length > Config.MAX_NUMBER_36 || lastName.match(Config.LAST_NAME_REGEX))) {
                setIsDisabled(true)
            }
        } else if (handleKYC.includes('K108') && !phoneNumberFormatted) {
            setIsDisabled(true)
        } else if (handleKYC.includes('K111') && (!citizenship.value || taxPayer === 'false')) {
            setIsDisabled(true)
            if (!isActive) {
                setIsDisabled(true)
            }
            if (citizenship.label !== 'United States' && taxPayer === 'false') {
                setIsDisabled(true)
            }
        } else if (handleKYC.includes('K111') && citizenship.label === 'United States') {
            setIsDisabled(false)
        } else {
            setIsDisabled(false)
        }
    }, [addressAutoFill, errorFlag, apartmentUnit, ssnNumberFormatted, dateOfBirthFormatted, firstName, lastName, phoneNumberFormatted, isActive, taxPayer, citizenship])

    // update user data
    const createRequestModal = (): UserType.UserRequestModel => {
        const userAddress: UserType.UserAddressCommonModel = {
            street1: street1,
            street2: apartmentUnit ?? '',
            city: city,
            province: province,
            postalCode: postalCode,
            countryIdentifier: countryAutoComplete,
            street3: null
        }

        const formData: UserType.UserRequestModel = {
            identifier: userData.identifier,
            name: `${firstName} ${lastName}`,
            firstName: firstName,
            middleName: null,
            lastName: lastName,
            email: user?.email ?? '',
            marketing: true,
            phone: phoneNumberFormatted ? phoneNumberFormatted : userData.phone,
            language: userData.language,
            taxIdNumber: ssnNumberFormatted ? ssnNumberFormatted : null,
            citizenship: citizenship.value ? citizenship.value.toString() : userData.citizenship,
            dob: dateOfBirthFormatted ? dateOfBirthFormatted : userData.dob,
            politicallyExposedNames: userData.politicallyExposedNames,
            irsBackupWithholdings: false,
            showKycStatus: true,
            usTaxPayer: JSON.parse(taxPayer),
            userAddress: userAddress,
            userEmployment: userData.userEmployment,
            userInvestor: userData.userInvestor,
            userDisclosure: userData.userDisclosure,
            metadataList: userData.metadataList ?? [],
            gender: userData.gender
        }

        return formData;
    }

    //submit verify data
    const submitData = async (): Promise<void> => {
        setIsModalOpen(false);
        setIsLoading(true);

        const formData = createRequestModal();

        const accessToken = await initiateAction() //get access token
        const updatedData = await UserService.userDataUpdateAC(formData, accessToken);
        setIsLoading(false);
        if (updatedData.response || updatedData.request) {
            setErrorLog(updatedData)
            setIsError(true)
        } else {
            if (updatedData.data.isKycProcessing) {
                history.push('/on-boarding/task-complete');
            }
            componentDidMount(); //api all api
        }
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    return (
        <>
            <Modal
                centered
                show={isModalOpen}
                onHide={onHandleClose}
                backdrop="static"
                keyboard={false}
                className="gi-ob-white-bottom-curve gi-port-ao-pc-companies-modal source-pop-up error-modal kyc-error-modal"
            >
                <Modal.Header className='px-3 py-2'>

                </Modal.Header>
                <Modal.Body className='px-5 pt-3 pb-4'>
                    <div className="error-modal-body d-flex align-items-center justify-content-center">
                        <div className="error-modal-text">
                            {handleKYC.length > 0
                                ? <>
                                    {kycError.length > 1 && (
                                        <h5 className="mb-4">
                                            {IntlMessages('kyc_trouble_data')}
                                        </h5>
                                    )}

                                    {kycError.length > 0 && kycError.map((e, i) => {
                                        // kyc address
                                        if (e === 'K101') {
                                            return (
                                                <div key={i}>
                                                    {/* only if 1 error */}
                                                    {kycError.length === 1 && (
                                                        <h5 className="mb-4">
                                                            {IntlMessages('kyc_address')}
                                                        </h5>
                                                    )}

                                                    <p className={'mb-2'}>{IntlMessages('kyc_address_label')}</p>
                                                    {/* auto complete address */}
                                                    <div className="row">
                                                        <div className="col-10 mx-auto">
                                                            <div className="gi-g-new-input mb-4 pb-2">
                                                                <AutoComplete
                                                                    className="react-google-place"
                                                                    inputValue={setAutoCompleteValue}
                                                                    value={addressAutoFill}
                                                                    countryList={countryOption}
                                                                    textCenter={false}
                                                                    dataTestId={"auto-complete-value"}
                                                                />
                                                                <ErrorComponent
                                                                    message={'error_invalid_address'}
                                                                    dashboard={true}
                                                                    display={errorFlag ? 'block' : 'none'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* postal code and apartment unit */}
                                                    <div className="row mx-md-n2">
                                                        {/* apartment unit */}
                                                        <div className="col-md-6 offset-1">
                                                            <p className='mb-2'>{IntlMessages('kyc_apartment_label')}</p>
                                                            <div className="gi-g-new-input mb-4 pb-2">
                                                                <InputComponentText2
                                                                    name={'apartmentUnit'}
                                                                    placeholder={'placeholder_apartment_unit'}
                                                                    className={'form-control text-center'}
                                                                    fieldValue={apartmentUnit}
                                                                    inputValue={setInputValue}
                                                                    readOnly={false}
                                                                    disabled={false}
                                                                    dataTestId={'apartment-unit'}
                                                                />
                                                                <ErrorComponent
                                                                    message={['error_max_length', { number: 36 }]}
                                                                    dashboard={true}
                                                                    display={apartmentUnit.length > Config.MAX_NUMBER_36 ? 'block' : 'none'}
                                                                />
                                                            </div>
                                                        </div>
                                                        {/* postal code */}
                                                        <div className="col-md-4">
                                                            <p className='mb-2'>{IntlMessages('personal_page4_label3')}</p>
                                                            <div className={`gi-g-new-input mb-4 pb-2 ${postalCode ? 'valid-field' : ''}`}>
                                                                <InputComponentText2
                                                                    name={'postalCode'}
                                                                    placeholder={'placeholder_zip_code'}
                                                                    className={`form-control text-center zip-code ${postalCode ? 'success' : ''}`}
                                                                    fieldValue={postalCode}
                                                                    inputValue={setInputValue}
                                                                    readOnly={true}
                                                                    disabled={false}
                                                                    dataTestId={'postal-code'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (e === 'K102') { //for ssn number
                                            return (
                                                <div key={i}>
                                                    {/* only if 1 error */}
                                                    {kycError.length === 1 && (
                                                        <h5 className="mb-4">
                                                            {IntlMessages('kyc_ssn')}
                                                        </h5>
                                                    )}
                                                    {/* label */}
                                                    <p className={'mb-2'}>{IntlMessages('kyc_ssn')}</p>
                                                    {/* input box for ssn */}
                                                    <div className="row">
                                                        <div className="col-12 col-md-6  mx-auto">
                                                            <div className="gi-g-new-input mb-4 pb-2">
                                                                <div className="ssn-input-box">
                                                                    <MaskInput2
                                                                        placeholder={"placeholder_ssn"}
                                                                        name={"ssnNumber"}
                                                                        readOnly={false}
                                                                        fieldType={fieldType}
                                                                        fieldValue={ssnNumber}
                                                                        inputValue={setInputValue}
                                                                        guide={false}
                                                                        className='form-control text-center'
                                                                        dataTestId={"ssn-number"}
                                                                    />

                                                                    <div className={`show-password ${fieldType === 'text' && 'hide-password'}`} onClick={toggleFieldType} data-testid='toggle-field'></div>

                                                                </div>
                                                                <ErrorComponent
                                                                    message={'error_invalid_ssn_number'}
                                                                    dashboard={true}
                                                                    display={errorFlagSSN ? 'block' : 'none'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (e === 'K103') { //for dob
                                            return (
                                                <div key={i}>
                                                    {/* only if 1 error */}
                                                    {kycError.length === 1 && (
                                                        <h5 className="mb-4">
                                                            {IntlMessages('kyc_dob')}
                                                        </h5>
                                                    )}
                                                    {/* label for dob */}
                                                    <p className={'mb-2'}>{IntlMessages('kyc_dob_label')}</p>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6  mx-auto">
                                                            <div className="gi-g-new-input mb-4 pb-2">
                                                                <MaskInput2
                                                                    placeholder={"placeholder_dob"}
                                                                    name={"dateOfBirth"}
                                                                    readOnly={false}
                                                                    fieldType={"text"}
                                                                    fieldValue={dateOfBirth}
                                                                    inputValue={setInputValue}
                                                                    guide={true}
                                                                    dataTestId={"dob-number"}
                                                                    className={"form-control text-center"}
                                                                />
                                                                <ErrorComponent
                                                                    message={'error_not_valid_dob'}
                                                                    dashboard={true}
                                                                    display={errorFlagDOB ? 'block' : 'none'}
                                                                />
                                                                <ErrorComponent
                                                                    message={'error_dob_under_18'}
                                                                    dashboard={true}
                                                                    display={validAgeFlag ? 'block' : 'none'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (e === 'K104' || e === 'K110') { //for name
                                            return (
                                                <div key={i}>
                                                    {/* only if 1 error */}
                                                    {kycError.length === 1 && (
                                                        <h5 className="mb-4">
                                                            {IntlMessages('kyc_name')}
                                                        </h5>
                                                    )}
                                                    {/* first name label */}
                                                    <p className={'mb-2'}>{IntlMessages('username_text3')}</p>
                                                    {/* first name */}
                                                    <div className="row">
                                                        <div className="col-12 col-md-6  mx-auto">
                                                            <div className="gi-g-new-input mb-4 pb-2">
                                                                <InputComponentText2
                                                                    name={'firstName'}
                                                                    placeholder={'placeholder_type_here'}
                                                                    className={'form-control text-center'}
                                                                    fieldValue={firstName}
                                                                    inputValue={setInputValue}
                                                                    readOnly={false}
                                                                    disabled={false}
                                                                    dataTestId={'first-name'}
                                                                />
                                                                <ErrorComponent
                                                                    message={'error_max_length_36_name'}
                                                                    dashboard={true}
                                                                    display={firstName.length > Config.MAX_NUMBER_36 ? 'block' : 'none'}
                                                                />
                                                                <ErrorComponent
                                                                    message={'error_first_name'}
                                                                    dashboard={true}
                                                                    display={firstName && !firstName.match(Config.NAME_REGEX) ? 'block' : 'none'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* last name label */}
                                                    <p className='mb-2'>{IntlMessages('username_text4')}</p>
                                                    {/* last name */}
                                                    <div className="row">
                                                        <div className="col-12 col-md-6  mx-auto">
                                                            <div className="gi-g-new-input mb-4 pb-2">
                                                                <InputComponentText2
                                                                    name={'lastName'}
                                                                    placeholder={'placeholder_type_here'}
                                                                    className={'form-control text-center'}
                                                                    fieldValue={lastName}
                                                                    inputValue={setInputValue}
                                                                    readOnly={false}
                                                                    disabled={false}
                                                                    dataTestId={'last-name'}
                                                                />
                                                                <ErrorComponent
                                                                    message={'error_max_length_36_name'}
                                                                    dashboard={true}
                                                                    display={lastName.length > Config.MAX_NUMBER_36 ? 'block' : 'none'}
                                                                />
                                                                <ErrorComponent
                                                                    message={'error_last_name'}
                                                                    dashboard={true}
                                                                    display={lastName && !lastName.match(Config.LAST_NAME_REGEX) ? 'block' : 'none'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (e === 'K108') { //for phone number
                                            return (
                                                <div key={i}>
                                                    {/* only if 1 error */}
                                                    {kycError.length === 1 && (
                                                        <h5 className="mb-4">
                                                            {IntlMessages('kyc_number')}
                                                        </h5>
                                                    )}
                                                    {/* label */}
                                                    <p className={'mb-2'}>{IntlMessages('kyc_number_label')}</p>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 mx-auto">
                                                            <div className="gi-g-new-input mb-4 pb-2">
                                                                <div className="gi-ob-mn-input">
                                                                    <span>+1</span>
                                                                    <MaskInput2
                                                                        placeholder={"placeholder_phone"}
                                                                        name={"phoneNumber"}
                                                                        readOnly={false}
                                                                        fieldType={"text"}
                                                                        fieldValue={phoneNumber}
                                                                        inputValue={setInputValue}
                                                                        guide={false}
                                                                        dataTestId='phone-number'
                                                                        className={'form-control text-center'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        } else if (e === 'K111') { //for country
                                            return (
                                                <div key={i}>
                                                    {/* only if 1 error */}
                                                    {kycError.length === 1 && (
                                                        <h5 className="mb-4">
                                                            {IntlMessages('kyc_citizenship')}
                                                        </h5>
                                                    )}
                                                    {/* label */}
                                                    <p className={'mb-2'}>{IntlMessages('account_setting_citizenship')}</p>
                                                    {/* dropdown country */}
                                                    <div className="row">
                                                        <div className="col-12 col-md-6  mx-auto">
                                                            <div className={`gi-g-new-input mb-4 pb-2 `}>
                                                                <SelectDropdown
                                                                    labelClassName={''}
                                                                    labelText={''}
                                                                    name='citizenship'
                                                                    option={countryList}
                                                                    fieldValue={citizenship}
                                                                    inputValue={selectDropDownValue}
                                                                    placeholder={'placeholder_country'}
                                                                    ariaLabel={"from-account"}
                                                                    className={'custom-react-fund'}
                                                                    isDisabled={false}
                                                                    isModalDropdown={true}
                                                                />
                                                                <ErrorComponent
                                                                    message={'error_invalid_citizenship'}
                                                                    dashboard={true}
                                                                    display={!isActive ? 'block' : 'none'}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* if country is not US */}
                                                    {citizenship.label !== 'United States' && citizenship.label && (
                                                        <>
                                                            <p className='mb-2'>{IntlMessages('tax_payer_text1')}</p>
                                                            {actionValue.map((e, i) => {
                                                                const checked = e.value === taxPayer;
                                                                return (
                                                                    <div className="customize-radio form-check mb-4 pb-2" key={i}>
                                                                        <label className={`form-check-label ${taxPayer === e.value ? 'active' : ''}`} htmlFor={e.id}>
                                                                            <InputComponentRadio2
                                                                                name={'taxPayer'}
                                                                                fieldLabel={e.label}
                                                                                fieldId={`${e.id}`}
                                                                                fieldValue={e.value}
                                                                                checked={checked}
                                                                                inputValue={setInputValue}
                                                                                dataTestId="radio-input"
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                );
                                                            })}
                                                            <ErrorComponent message={'error_non_us_taxpayer'} dashboard={true} display={taxPayer === 'false' ? 'block' : 'none'} />
                                                        </>
                                                    )}
                                                </div>
                                            )
                                        }
                                    })}

                                    {/* update button */}
                                    <div className="text-center">
                                        <button className="contact-btn mt-3" type="button" onClick={submitData} disabled={isDisabled}>
                                            {IntlMessages('button_submit')}
                                        </button>
                                    </div>
                                </>
                                : <>
                                    {kycError.length > 0 && kycError.map((e, i) => {
                                        if (e === 'K802') {
                                            return (
                                                <React.Fragment key={i}>
                                                    <h5 >
                                                        {IntlMessages('error_multiple_account_holder')}
                                                    </h5>
                                                    <p>
                                                        {IntlMessages('error_contact_support2')}
                                                    </p>
                                                </React.Fragment>
                                            )
                                        } else {
                                            return (
                                                <React.Fragment key={i}>
                                                    <h5 className="mb-4">
                                                        {IntlMessages('error_something_wrong')}
                                                    </h5>
                                                    <p>
                                                        {IntlMessages('error_contact_support')}
                                                    </p>
                                                </React.Fragment>
                                            )
                                        }
                                    })}

                                    <div className="text-center">
                                        <button className="contact-btn" type="button" onClick={contactUs}>
                                            {IntlMessages('button_contact_us')}
                                        </button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {/* loading modal */}
            {<LoadingModal openLoadingModal={isLoading} title={'modal_submit'} onHandleClose={onHandleClose} />}
        </>
    )
}

export default KYCErrorModal