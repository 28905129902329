//default library
import * as React from 'react';
//config import 
import * as Config from 'config/Config';
import { useIntl } from 'react-intl';
//helper message
import IntlMessages from 'helper/IntlMessages';
//types props
import * as PropsFunction from "interface/PropsFunction"
import * as Dashboard from 'interface/Dashboard'
//custom components
import PortfolioAnimation from 'components/AnimationComponent/PortfolioAnimation';
import NumberAnimation from 'components/AnimationComponent/NumberAnimation';

//defining props
export type Props = {
    loadAnimation: boolean;
    nextDisabled: boolean;
    companyCount: Dashboard.CompanyCountData;
    openCompanyTable: PropsFunction.ParamEventFunction;
    nextClick: PropsFunction.SimpleFunction;
}

const PieChartPortfolio: React.FC<Props> = (props: Props) => {
    const { companyCount, loadAnimation, nextDisabled, openCompanyTable, nextClick, } = props;
    const intl = useIntl();

    const excludedCompanyCount = companyCount.excludedCompanyCount;//count excluded company
    const addedCompanyCount = companyCount.addedCompanyCount;//count added company
    const totalCompanyCount = companyCount.totalCompanyCount;//count total company for spinner

    return (
        <>
            <div className="gift-p-green-spinner-card px-sm-4 px-0">
                {/*  */}
                <div className="gift-p-green-spinner-body pb-lg-0 pb-4">
                    <div className="row justify-content-center">
                        <div className="col-lg-9 col-md-5">
                            <div className={`gift-p-green-spinner-img px-2 ${loadAnimation ? 'loading' : ''}`}>
                                {/* Animation Spinner */}
                                <PortfolioAnimation
                                    loadAnimation={loadAnimation}
                                    width={320}
                                />
                                <img src={intl.formatMessage({ id: 'SPINNER_IMG' })} className="img-fluid gi-p-graph-bg" alt="" />

                                <div className="gift-p-stock-text">
                                    <h6 className="pl-2 mt-1">
                                        {loadAnimation
                                            ? <NumberAnimation
                                                counterAnimation={totalCompanyCount || 0}
                                                startNumber={0}
                                                delayAnimation={Config.PORTFOLIO_ANIMATION_TIMER}
                                            />
                                            : totalCompanyCount
                                        }
                                    </h6>
                                    <span className="d-block pl-2 mt-2">
                                        {IntlMessages('portfolio_chart1')}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-9 col-md-5">
                            <div className="gift-p-green-customize-text d-block d-sm-none">
                                {IntlMessages('portfolio_text2', { br: <br /> })}
                            </div>
                            {/* Excluded & Added Companies */}
                            <div className="gift-p-green-spinner-text">
                                {/* Excluded Companies */}
                                <ul className="d-flex justify-content-center">
                                    <li className="d-flex align-items-center">
                                        <h6>{IntlMessages('excluded_companies')}</h6>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        {loadAnimation
                                            ? <span>
                                                <NumberAnimation
                                                    counterAnimation={excludedCompanyCount || 0}
                                                    startNumber={500}
                                                    delayAnimation={Config.PORTFOLIO_ANIMATION_TIMER}
                                                />
                                            </span>
                                            : excludedCompanyCount === 0
                                                ? <span>000</span>
                                                : excludedCompanyCount < 10
                                                    ? <span>00<i>{excludedCompanyCount}</i></span>
                                                    : excludedCompanyCount < 100
                                                        ? <span>0<i>{excludedCompanyCount}</i></span>
                                                        : <span><i>{excludedCompanyCount}</i></span>
                                        }
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <button
                                            type="button"
                                            id="excluded-companies"
                                            className={`d-block ps-2 text-center ${excludedCompanyCount !== 0 && !loadAnimation ? 'active' : 'no-active'}`}
                                            disabled={!(excludedCompanyCount !== 0 && !loadAnimation)}
                                            onClick={openCompanyTable}
                                        >
                                            {IntlMessages('basket_view_list')}
                                        </button>
                                    </li>
                                </ul>
                                {/* Added Companies */}
                                <ul className="d-flex justify-content-center">
                                    <li className="d-flex align-items-center">
                                        <h6>{IntlMessages('added_companies')}</h6>
                                    </li>
                                    <li className="d-flex align-items-center">
                                        {loadAnimation
                                            ? <span>
                                                <NumberAnimation
                                                    counterAnimation={addedCompanyCount || 0}
                                                    startNumber={500}
                                                    delayAnimation={Config.PORTFOLIO_ANIMATION_TIMER}
                                                />
                                            </span>
                                            : addedCompanyCount === 0
                                                ? <span>000</span>
                                                : addedCompanyCount < 10
                                                    ? <span>00<i>{addedCompanyCount}</i></span>
                                                    : addedCompanyCount < 100
                                                        ? <span>0<i>{addedCompanyCount}</i></span>
                                                        : <span><i>{addedCompanyCount}</i></span>
                                        }
                                    </li>
                                    <li className="d-flex align-items-center">
                                        <button
                                            type="button"
                                            id="added-companies"
                                            className={`d-block ps-2 text-center ${addedCompanyCount !== 0 && !loadAnimation ? 'active' : 'no-active'}`}
                                            disabled={!(addedCompanyCount !== 0 && !loadAnimation)}
                                            onClick={openCompanyTable}
                                        >
                                            {IntlMessages('basket_view_list')}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                <div className="gift-p-green-spinner-footer py-4 d-none d-lg-flex">
                    <button type="button" className="d-flex align-items-center justify-content-center gap-2 border-0 ms-auto" disabled={nextDisabled} onClick={nextClick}>
                        {IntlMessages('button_next')}
                        <span className="icon-end"></span>
                    </button>
                </div>
                {/*  */}
                <div className="gift-p-green-spinner-footer py-4 d-flex d-lg-none">
                    <button type="button" className="d-flex align-items-center justify-content-center gap-2 border-0 ms-auto" disabled={nextDisabled} onClick={nextClick}>
                        {IntlMessages('button_next')}
                        <span className="icon-end"></span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default PieChartPortfolio;























