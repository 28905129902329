// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// message import
import IntlMessages from 'helper/IntlMessages';

const KYCReview: React.FC = () => {
    const history = useHistory();

    // redirect to dashboard
    const gotoDashboard = (): void => {
        history.push({
            pathname: '/dashboard',
            state: {
                updateAccessToken: false,
                bankConnectPage: false,
                isILKyc: true,
            }
        })
    }

    return (
        <div className="container-fluid px-4">
            <div className="row">
                <div className="col-12">
                    <div className="gi-ob-magnificent-text text-center">
                        <h5>{IntlMessages('kyc_review_text1')}</h5>
                        <h6 className='mt-3 pb-1 d-none d-sm-block'>{IntlMessages('kyc_review_text2_desk', { br: <br /> })}</h6>
                        <h6 className='mt-3 pb-1 d-block d-sm-none'>{IntlMessages('kyc_review_text2_mob', { br: <br /> })}</h6>
                        <p className="mt-3 pb-1 gi-ob-upload-text ">{IntlMessages('kyc_review_text3')}</p>
                        <button type="button" className="mt-4" onClick={gotoDashboard} data-testid="dashboard-redirect">{IntlMessages('kyc_review_btn')}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KYCReview