// default library
import * as React from "react";
import { Modal } from "react-bootstrap";
//session timeout library
import { useIdleTimer } from "react-idle-timer";
//config
import { COUNTDOWN, DELAY, SESSION_EXPIRED } from "config/Config";
// message import
import IntlMessages from "helper/IntlMessages";
// custom function
import * as Locale from "service/utils/LocalStorageData";
// custom hook
import { useLogoutHook } from "helper/Logout";
// type import
import * as PropsFunction from "interface/PropsFunction";

export type Props = {
    isAuthenticated: boolean;
    sessionTimeOut: PropsFunction.SimpleFunction;
};

let timeout: NodeJS.Timeout;
let countdownInterval: NodeJS.Timeout;
let interval: NodeJS.Timeout;

const SessionTimeout: React.FC<Props> = (props: Props) => {
    const { isAuthenticated, sessionTimeOut } = props;
    const logoutUser = useLogoutHook();

    const [countdownModalOpen, setCountdownModalOpen] = React.useState<boolean>(false);
    const [timeoutModalOpen, setTimeoutModalOpen] = React.useState<boolean>(false);
    const [timeoutCountdown, setTimeoutCountdown] = React.useState<number>(0);

    React.useEffect(() => {
        const isAuthenticate = Locale.getLocalData("isAuthenticate");

        const interval = setInterval(() => {
            const expiredTime = parseInt(Locale.getLocalData("expiredTime"));
            if (expiredTime < Date.now() && isAuthenticate) {
                handleClose();
                clearInterval(interval);
            }
        }, 2000);

        // set data for session expired
        if (isAuthenticated) {
            const authenticated = Locale.getLocalData("isAuthenticate");
            if (authenticated || authenticated === null) {
                Locale.setLocalData("expiredTime", Date.now() + (SESSION_EXPIRED + 12 * 1000));
                Locale.setLocalData("isAuthenticate", isAuthenticated);
            } else {
                sessionLogout();
            }
        }

        return () => {
            clearInterval(interval);
        };
    }, []);

    // logout user
    const sessionLogout = (): void => {
        setTimeoutModalOpen(false);

        logoutUser();
    };

    // reset the session
    const clearSessionTimeout = (): void => {
        clearTimeout(timeout);
    };

    // reset the session interval
    const clearSessionInterval = (): void => {
        clearInterval(countdownInterval);
    };

    // 
    const handleClose = (): void => {
        clearInterval(interval);
        clearSessionInterval();
        clearSessionTimeout();
        setCountdownModalOpen(false);
        setTimeoutModalOpen(true);

        Locale.setLocalData('isAuthenticate', false)
        sessionTimeOut();
    };

    // when user click on continue btn
    const handleContinue = (): void => {
        clearSessionInterval();
        clearSessionTimeout();
        setCountdownModalOpen(false);

        onAction();
    };

    // when user do some action on screen
    const onAction = (): void => {
        Locale.setLocalData('expiredTime', (Date.now() + (SESSION_EXPIRED + 12 * 1000)))
    };

    // when user is active
    const onActive = (): void => {
        clearSessionTimeout();
    };

    // when user idle till session timeout
    const onIdle = (): void => {
        if (isAuthenticated && !countdownModalOpen) {
            timeout = setTimeout(() => {
                let countDown = COUNTDOWN;
                setCountdownModalOpen(timeoutModalOpen ? false : true);
                setTimeoutCountdown(countDown);

                countdownInterval = setInterval(() => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        handleClose();
                    }
                }, DELAY);
            }, DELAY);
        }
    };

    useIdleTimer({
        onIdle,
        onActive,
        onAction,
        timeout: SESSION_EXPIRED,
        debounce: 250,
    });

    return (
        <Modal
            centered
            show={countdownModalOpen}
            backdrop="static"
            keyboard={false}
            className="gi-ob-white-bottom-curve gi-port-ao-pc-companies-modal source-pop-up error-modal"
        >
            <Modal.Header className="px-3 py-2">
                <button className="btn-close p-0 m-0 border-0 opacity-100" type="button" onClick={handleContinue} aria-label="Close"></button>
            </Modal.Header>
            <Modal.Body className="px-5 pt-3 pb-3">
                <div className="error-modal-body d-flex align-items-center justify-content-center p-0 m-0">
                    <div>
                        <div className="error-modal-text">
                            <h5>
                                {IntlMessages("session_timeout_t1")}
                                &nbsp;
                                <span className="red-text">{timeoutCountdown}</span>
                                &nbsp;
                                {IntlMessages("session_timeout_t2")}
                            </h5>
                            <div className="text-center mt-4 pt-1 d-flex flex-wrap justify-content-center gap-3">
                                <button type="button" className="contact-btn" onClick={sessionLogout}>
                                    {IntlMessages("logout")}
                                </button>
                                <button type="button" className="contact-btn" onClick={handleContinue}  >
                                    {IntlMessages("button_continue")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SessionTimeout;
