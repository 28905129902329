// default library
import * as React from 'react';
// auth0 library
import { useAuth0 } from '@auth0/auth0-react';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// intl messages
import IntlMessages from 'helper/IntlMessages';
// custom function
import { getUserName } from 'service/utils/UtilFunctions';
import { DateFormatter3 } from 'service/utils/DateFormatter';

const DatePageHeader: React.FC = () => {

    const { user } = useAuth0();
    const [firstName, setFirstName] = React.useState<string>('');//store first name
    const locale = useSelector(getLocale);

    const userName = getUserName(locale.language);

    React.useEffect(() => {
        if (user?.email) {
            const name = userName.firstName ? userName.firstName : user.email.split('@')[0]
            setFirstName(name)
        }
    }, [])



    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="g-heading px-sm-0 px-2 d-md-block d-none">
                        <p>
                            <strong>
                                {DateFormatter3(new Date(Date.now()))}
                                &nbsp;&nbsp;|&nbsp;&nbsp;
                                {IntlMessages('heading_text_hi')} {firstName}!
                            </strong>
                            &nbsp;{IntlMessages('heading_text_date')}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DatePageHeader;