import * as DataType from "api/APIMaster/DataType";
import { AxiosClient, RequestParams, ExceptionResponse } from "api/AxiosClient";
import * as URLList from 'api/URLList'


class DataService<SecurityDataType = unknown> extends AxiosClient<SecurityDataType> {

    constructor() {
        super({
            baseURL: URLList.BASE_URL.DATA
        });
    }

    /**
     * @description Return all bank list basis of locale
     *
     * @name GetAllBankList
     * @summary Get All Bank List for 
     * @request POST:/user/bank/all-localize
     * @secure
     */
    getAllBankList = (data: DataType.BankListLocalizedRequest, params: RequestParams = {}) =>
        this.request<DataType.BankListLocalizedResponseModal, void | ExceptionResponse>({
            path: URLList.DATA_SERVICE.BANK_ALL_LOCALIZED.path,
            method: URLList.DATA_SERVICE.BANK_ALL_LOCALIZED.method,
            body: data,
            secure: true,
            ...params,
        });

}

export default DataService