// default library
import * as React from 'react'
//chart
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
// message import
import IntlMessages from 'helper/IntlMessages';
// default value
import * as DefaultValue from 'config/DefaultValue';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// chart option
import { FutureProjectionChart } from 'config/ChartOptions';
// type import
import * as BasketType from 'api/APIMaster/BasketType';
import * as PropsFunction from 'interface/PropsFunction';
// local import
import * as Local from 'service/utils/LocalStorageData'
// other lang import
import OtherTranslations from 'lang/OtherTranslations';

type Props = {
    futureProjectionData: BasketType.GetFutureProjectionData;
    twentyYearInfoClick: PropsFunction.SimpleFunction;
}

type FutureProjectionSeries = {
    type: string;
    name: string;
    data: {
        x: number;
        y: number[] | number;
    }[]
}[];

const FutureProjection: React.FC<Props> = (props: Props) => {

    const { futureProjectionData, twentyYearInfoClick } = props;
    const locale = useSelector(getLocale);

    // fetch the current language
    const currentLang = Local.getCurrentLanguage()

    const [selectedYears, setSelectedYears] = React.useState<string>('15');
    const [futureProjectionSeries, setFutureProjectionSeries] = React.useState<FutureProjectionSeries>([]);

    React.useEffect(() => {
        updateSeriesFuture(selectedYears);

        const updateOption: ApexOptions = {
            yaxis: [{
                ...FutureProjectionChart.yaxis,
                opposite: locale.direction !== 'rtl'
            }],
            tooltip: {
                ...FutureProjectionChart.tooltip,
                style: {
                    ...FutureProjectionChart.tooltip?.style,
                    fontFamily: locale.direction === 'rtl' ? 'Rubik' : 'Roboto Slab'
                }
            }
        }
        ApexCharts.exec('future-projection', 'updateOptions', { ...updateOption }, false, false, false);
        ApexCharts.exec('future-projection', 'setLocale', locale.direction === 'rtl' ? 'he' : 'en')

    }, [])

    // change range of the performance graph
    const changeYearRange = (e: React.MouseEvent): void => {
        updateSeriesFuture((e.target as HTMLLIElement).id)
    }

    // update the series
    const updateSeriesFuture = (range: string): void => {

        // get diff of the years
        const tempProjectionData: BasketType.GetFutureProjectionData = JSON.parse(JSON.stringify(futureProjectionData))
        const seriesList: BasketType.GetFutureProjectionData = tempProjectionData.slice(0, Number(range) + 1)

        setSelectedYears(range);

        const series = [{
            type: "rangeArea",
            name: OtherTranslations[currentLang].average,
            data: seriesList.map(e => {
                return ({
                    x: e.year,
                    y: [e.minGrowthRate, e.maxGrowthRate]
                })
            })
        },
        {
            type: "line",
            name: OtherTranslations[currentLang].range,
            data: seriesList.map(e => {
                return ({
                    x: e.year,
                    y: e.growthRate
                })
            })
        }]

        setFutureProjectionSeries(series);
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="gi-port-ao-future-card py-4">
                    <div className="gi-port-ao-future-tabs">
                        <ul className="d-flex align-items-center">
                            {DefaultValue.futureDropdown.map((e, i) => {
                                return (
                                    <li key={i} className={`${e.value === selectedYears && 'active'}`} id={e.value.toString()} onClick={changeYearRange}>
                                        <a id={e.value.toString()} data-testid={e.value}>
                                            {e.label}
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="gi-port-ao-future-graph">
                        <ReactApexChart options={FutureProjectionChart} series={futureProjectionSeries} type='rangeArea' height={171} dir='ltr' />
                    </div>
                    <div className="row gx-4">
                        <div className="col-lg-3">
                            <div className="gi-port-ao-future-legend">
                                <ul>
                                    <li className="portfolio-legend">
                                        <a href="#!">
                                            {IntlMessages('future_average')}
                                        </a>
                                    </li>
                                    <li className="sp-legend">
                                        <a href="#!">
                                            {IntlMessages('future_range')}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="gi-port-ao-future-legend">
                                <p>
                                    {IntlMessages('future_text1')}
                                    <a onClick={twentyYearInfoClick} data-testid='twenty-info'>
                                        {IntlMessages('gift_redeemed_button2')}
                                    </a>
                                    {IntlMessages('future_text2')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FutureProjection