// default library
import * as React from 'react'
// auth0 library
import { useAuth0 } from '@auth0/auth0-react';
//custom hook
import { useAccessToken } from 'hook/UseAccessToken';
// react redux
import { useSelector } from 'react-redux';
import { getLocale } from 'reduxStore/reducers/LanguageReducer';
// api call
import * as DashboardService from 'middleware/DashboardService';
// type import
import * as DashboardType from 'api/APIMaster/DashboardType';
// dummy data
import * as DashboardData from 'service/DummyData/DashboardData';
// custom function
import { getUserName } from 'service/utils/UtilFunctions';
// custom component
import GiraffeLearnBannerSection from 'components/RightSidebarComponent/GiraffeLearnBannerSection'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent';
import MyImpactSection from 'components/SectionComponent/MyImpactSection';
import PageHeader from 'components/PageHeaderComponent/PageHeader';
import ESGCDescriptionModal from 'components/ModalComponent/ESGCDescriptionModal';

const MyImpactPage: React.FC = () => {
    const { user } = useAuth0();
    const { initiateAction } = useAccessToken();
    const locale = useSelector(getLocale);

    const [isError, setIsError] = React.useState<boolean>(false);  //error occurred flag
    const [errorLog, setErrorLog] = React.useState<any>({});  //error logs

    const [isLoading, setIsLoading] = React.useState<boolean>(true);  //error logs

    const [myImpactList, setMyImpactList] = React.useState<DashboardType.GetAllImpactDetailData>(DashboardData.myImpactData);
    const [openDescriptionModal, setOpenDescriptionModal] = React.useState<boolean>(false);  //error occurred flag

    const [esgcIdentifier, setEsgcIdentifier] = React.useState<string>(''); //select ESG-C value identifier
    const [selectedTab, setSelectedTab] = React.useState<string>(''); //selected tab

    React.useEffect(() => {
        initialAPICall()
    }, [locale.language])

    const initialAPICall = async (): Promise<void> => {
        window.scroll(0, 0);
        setIsLoading(true);

        const accessToken = await initiateAction(); //get access token
        const myImpact = await DashboardService.getAllImpactDetail(accessToken);  //my impact details

        setIsLoading(false);

        if (myImpact.response || myImpact.request) {
            setErrorLog(myImpact);
            setIsError(true)
        } else {
            setMyImpactList(myImpact);
        }
    }

    // open ESG-C value description modal
    const openDescriptionModalFunction = (id: string, dataId: string): void => {
        setOpenDescriptionModal(true);
        setEsgcIdentifier(id);
        setSelectedTab(dataId)
    }

    // close modal
    const onHandleClose = (): void => {
        setOpenDescriptionModal(false);
    }

    if (isError) {
        throw new Error(JSON.stringify(errorLog));
    }

    const userName = getUserName(locale.language);
    const firstName = userName.firstName ? userName.firstName : user?.email?.split('@')[0];

    return (
        <>
            {isLoading
                ? <LoadingComponent />
                : <>
                    <PageHeader
                        className={'g-heading px-sm-0 px-2'}
                        textStrong={'contact_us_header2'}
                        userName={firstName}
                        otherAttr={myImpactList.isInvestmentPending ? 'impact_selected_value' : 'impact_value'}
                    />

                    {/* if not invest in any ESG-C value */}
                    <div className="row gx-2">
                        <div className="col-lg-9">
                            <div className="row" data-testid='render-data'>
                                <div className="col-12">
                                    {/* <!-- ESG-C value list --> */}
                                    {myImpactList && (
                                        <MyImpactSection
                                            myImpactList={myImpactList.isExample ? [myImpactList.impactDetailResponseModelList[0]] : myImpactList.impactDetailResponseModelList}
                                            isExample={myImpactList.isExample}
                                            openDescriptionModal={openDescriptionModalFunction}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Banner section */}
                        <div className="col-lg-3">
                            <GiraffeLearnBannerSection />
                        </div>
                    </div>

                    {/* bank space */}
                    <div className="row">
                        <div className="col-12">
                            <div className="h-dash"></div>
                        </div>
                    </div>

                    <ESGCDescriptionModal
                        openDescriptionModal={openDescriptionModal}
                        esgcIdentifier={esgcIdentifier}
                        myImpactList={myImpactList.impactDetailResponseModelList}
                        selectedTab={selectedTab}
                        onHandleClose={onHandleClose}
                    />
                </>
            }

        </ >
    )
}

export default MyImpactPage