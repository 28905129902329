// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';
// type import
import * as DashboardType from 'api/APIMaster/DashboardType';
// slider 
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// message provider
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    myImpactList: DashboardType.ImpactDetailResponseModel[],
    isInvestmentPending: boolean,
    isPortfolio: boolean,
    isExample: boolean,
}

const HomeMyImpact: React.FC<Props> = (props: Props) => {
    const { myImpactList, isInvestmentPending, isPortfolio, isExample } = props;
    const history = useHistory();

    const sliderRef = React.useRef<Slider | null>(null);
    const [sliderIndex, setSliderIndex] = React.useState<number>(0);

    // when slider change
    const beforeChange = (_current: number, next: number) => {
        setSliderIndex(next)
    }

    // redirect to my impact page
    const gotoMyImpact = (): void => {
        history.push('/dashboard/my-impact')
    }

    // next slider button click
    const nextSlide = () => {
        if (sliderRef.current)
            sliderRef.current.slickNext();
    }

    // previous slider button click
    const prevSlide = () => {
        if (sliderRef.current)
            sliderRef.current.slickPrev()
    }

    // slider config
    const sliderSetting: Settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        beforeChange: beforeChange,
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="gi-dash-ao-impact-card mt-md-2 mt-0">
                    {/* slider heading, next/prev btn */}
                    <div className={`${'gi-dash-ao-impact-header d-flex align-items-center justify-content-between px-lg-5 py-4 px-3'}`}>
                        {isPortfolio
                            ? <>
                                <h5 className="d-none d-sm-block">{IntlMessages('my_impact_slider_heading1')}</h5>
                                <h5 className="d-block d-sm-none">{IntlMessages('my_impact_slider_heading2')}</h5>
                            </>
                            : <h5>{isExample ? IntlMessages('my_impact_slider_body1') : isInvestmentPending ? IntlMessages('my_impact_slider_body2') : IntlMessages('my_impact_slider_body3')}</h5>
                        }
                        <div className="btn-wrap d-lg-flex d-none align-items-center justify-content-between gap-2">
                            <button
                                className={`prev-btn-impact-slider ${sliderIndex === 0 ? 'slick-disabled' : ''} `}
                                disabled={sliderIndex === 0}
                                onClick={prevSlide}
                                data-testid='prev-button'
                            >
                            </button>
                            <button
                                className={`next-btn-impact-slider ${sliderIndex >= myImpactList.length - 1 ? 'slick-disabled' : ''} `}
                                disabled={sliderIndex >= myImpactList.length - 1}
                                onClick={nextSlide}
                                data-testid='next-button'
                            >
                            </button>
                        </div>
                    </div>
                    {/* slider data */}
                    <div className="gi-dash-ao-impact-body p-lg-5 pt-lg-0 p-3 pe-0 pt-0" data-testid="slider-element">
                        <Slider ref={sliderRef} {...sliderSetting} className="gi-dash-ao-impact-slider pb-lg-0 pb-4">
                            {myImpactList.map((e, i) => (
                                <div key={i}>
                                    <div className="gi-dash-ao-impact-value-card">
                                        {/* ESG-C value image */}
                                        <div className="gi-dash-ao-impact-img">
                                            <img src={e.myImpactDashboardBannerDesktop} className="img-fluid d-none d-lg-block" alt="" />
                                            <img src={e.myImpactDashboardBannerMobile} className="img-fluid d-block d-lg-none" alt="" />
                                        </div>
                                        {/* ESG-C value text */}
                                        <div className="gi-dash-ao-impact-text pt-3 pb-4 px-4">
                                            <h5>{e.name}</h5>
                                            <p className="pt-2">{e.deepClean ? e.myImpactDashboardDeepCleanBannerText : e.myImpactDashboardBannerText}</p>
                                            <button className="mt-3" type="button" onClick={gotoMyImpact} data-testid='read-more'>
                                                {IntlMessages('gift_redeemed_button2')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeMyImpact;
