// default library
import * as React from 'react';
import { useHistory } from 'react-router-dom';

export type Props = {
    isFirstPage: boolean;
    perviousPage: string;
}

const KYCHeader: React.FC<Props> = (props: Props) => {
    const { isFirstPage, perviousPage } = props;
    const history = useHistory();

    // close KYC journey
    const closeKYC = (): void => {
        history.push("/dashboard")
    }

    // go back to previous page
    const navigateBack = (): void => {
        if (isFirstPage) {
            closeKYC();
        } else {
            history.push(`/on-boarding/${perviousPage}`)
        }
    };

    return (
        <header className="gi-ob-header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="gi-ob-header-btn-group">
                            <button className="gi-ob-header-back-btn" type="button" onClick={navigateBack}></button>
                            <button className="gi-ob-header-close-btn" type="button" onClick={closeKYC}></button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default KYCHeader