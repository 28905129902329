// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
// message import
import IntlMessages from 'helper/IntlMessages';
// type import
import * as PropsFunction from 'interface/PropsFunction';

export type Props = {
    openConfirmationModal: boolean;
    message: string;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleConfirm: PropsFunction.SimpleFunction;
}

const ConfirmationModal: React.FC<Props> = (props: Props) => {

    const { openConfirmationModal, message, onHandleClose, onHandleConfirm } = props;

    return (
        <Modal
            centered
            show={openConfirmationModal}
            backdrop="static"
            keyboard={false}
            className='gi-ob-white-bottom-curve gi-port-ao-pc-companies-modal source-pop-up error-modal modal fade'
            onHide={onHandleClose}>

            <Modal.Header className='px-3 py-2'>
                <button className="btn-close p-0 m-0 border-0 opacity-100" type="button" aria-label="Close" onClick={onHandleClose} data-testid='close-button'></button>
            </Modal.Header>

            <Modal.Body className='px-5 pt-5 pb-4'>
                <div className='error-modal-body d-flex align-items-center justify-content-center'>
                    <div className="error-modal-text">
                        <h5 className='mt-4 pb-1'>
                            {IntlMessages(message)}
                        </h5>

                        <div className="text-center mt-4 pt-1">
                            <button className="contact-btn" type="button" onClick={onHandleConfirm} data-testid='confirm-button'>
                                {IntlMessages('button_confirm')}
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ConfirmationModal