// default library
import React from 'react';
//image 
import { useIntl } from 'react-intl';
//message provider
import IntlMessages from 'helper/IntlMessages';
//type props
import * as PropsFunction from 'interface/PropsFunction'
import * as Dashboard from 'interface/Dashboard'

export type Props = {
    learnList: boolean,
    scheduleSection: Dashboard.ScheduleSection,
    forWeek: PropsFunction.ParamsIdStringFunction
}

const ScheduleSection: React.FC<Props> = (props: Props) => {
    const { scheduleSection, learnList, forWeek } = props;
    const intl = useIntl();

    //handle for week function
    const handleForWeek = (e: React.MouseEvent<HTMLAnchorElement>): void => {
        const targetId = e.currentTarget.id;
        forWeek(targetId);
    };

    return (
        <>
            {/* giraffe view week */}
            <div className="row">
                <div className="col-12">
                    <div className="gi-g-learn-week-dark d-flex flex-wrap align-items-center justify-content-between px-lg-4 py-lg-2 px-3 py-3 mb-2">
                        <div className="gi-g-giraffe-path d-flex align-items-center gap-3">
                            <div className="gi-g-giraffe-path-img">
                                <img src={intl.formatMessage({ id: 'GIRAFFE_VIEW_ACTIVE' })} alt="GIRAFFE_VIEW_ACTIVE" />
                            </div>
                            <div className="gi-g-giraffe-path-text">
                                {IntlMessages('dashboard_giraffe_view')}
                            </div>
                        </div>
                        {learnList && (
                            <div className="gi-g-learn-week-date">
                                <div className="gi-g-learn-week-date-main d-flex align-items-center position-relative">
                                    {/* pervious week icon */}
                                    {scheduleSection.previousWeek && (
                                        <a id={scheduleSection.previousWeek.toString()}
                                            className="gi-g-start-icon"
                                            onClick={handleForWeek}
                                            data-testid="previous-week"
                                        />
                                    )
                                    }
                                    {/* current week number */}
                                    <span className='d-block text-center' data-testid="current-week">{IntlMessages('week')} {scheduleSection.forWeek}</span>
                                    {/* next week icon */}
                                    {scheduleSection.nextWeek && (
                                        <a id={scheduleSection.nextWeek.toString()}
                                            className="gi-g-end-icon"
                                            onClick={handleForWeek}
                                            data-testid="next-week"
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ScheduleSection;

