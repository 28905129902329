// default library
import * as React from 'react';
import Modal from "react-bootstrap/Modal";
//types props
import * as PropsFunction from "interface/PropsFunction";
// message import
import IntlMessages from 'helper/IntlMessages';

export type Props = {
    textHeading: JSX.Element | string;
    openModal: boolean;
    messageBody: JSX.Element | string;
    onHandleClose: PropsFunction.SimpleFunction;
    onHandleSuccess: PropsFunction.SimpleFunction;
}

const InfoModalIL2: React.FC<Props> = (props: Props) => {
    const { openModal, onHandleClose, onHandleSuccess, textHeading, messageBody } = props;

    return (
        <Modal
            centered
            show={openModal}
            onHide={onHandleClose}
            backdrop="static"
            keyboard={false}
            className={`gi-ob-grey-bottom-curve modal modal-sm enter-code-modal`}
        >
            <button type="button" className="btn-close" onClick={onHandleClose} data-testid='close-modal'></button>
            <Modal.Body className='py-4 my-3 px-4 '>
                <div className="enter-code-modal-text px-1">
                    <h5>{textHeading}</h5>
                    <p className='mt-3'>{messageBody}</p>
                </div>
            </Modal.Body>

            <Modal.Footer className="enter-code-modal-footer justify-content-center">
                <div className="text-center py-3" >
                    <button type='button' onClick={onHandleSuccess} data-testid="success-button">
                        {IntlMessages('kyc_info_us_no_btn1')}
                    </button>
                    <span className="d-block mt-3" onClick={onHandleClose} data-testid='close-modal'>
                        <a href="#!" >
                            {IntlMessages('kyc_info_us_yes_btn2')}
                        </a>
                    </span>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default InfoModalIL2;